import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { FinanceSettings } from './settings';
import { FinanceValue } from './value';
import { FinanceScreen } from './finance.index';
import { RouterModule } from '@angular/router';
import { FinanceConfigDetail } from './config';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { MapValue } from './config/map';
import { ActivateSettingsDialog } from './settings/activate-dialog';
import { DeleteSettingsDialog } from './settings/delete-dialog';
import { SelectFormulaDialog } from './settings/select-formula';
import { DetailModule } from '../base/detail.module';

@NgModule({
    imports: [
        CommonModule,
        NzListModule,
        RouterModule,
        NzTableModule,
        NzInputModule,
        NzIconModule,
        FormsModule,
        NzButtonModule,
        NzFormModule,
        NzDatePickerModule,
        DetailModule,
    ],
    declarations: [
        FinanceSettings,
        FinanceValue,
        FinanceScreen,
        FinanceConfigDetail,
        MapValue,
        ActivateSettingsDialog,
        SelectFormulaDialog,
        DeleteSettingsDialog,
    ],
    exports: [
        FinanceSettings,
        FinanceValue,
        FinanceScreen,
        FinanceConfigDetail,
    ],
    providers: [
    ]
})
export class FinanceModule {}