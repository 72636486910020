import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DataorchService } from "@services/dataorch.service";
import dayjs from "dayjs";
import _ from "underscore";

@Component({
    selector: '[linehaul-update-pickup-time-form]',
    templateUrl: './index.html',
    styleUrls: ['./index.scss']
})
export class LinehaulUpdatePickupTimeForm extends BaseComponent {
    dataorch: DataorchService
    public fromTime: Date = null
    public toTime: Date = null

    public isReady: boolean = false
    public rolling: boolean = false
    public today = dayjs().startOf('day')

    @Input() job

    @Input() onClose
    @Input() onOk

    constructor() {
        super()
        this.dataorch = new DataorchService(this.api)
    }

    checkReady(event) {
        if (!this.fromTime || !this.toTime) {
            this.isReady = false
            return
        }
        if (!this.job.pickDt) {
            console.log(`Something is wrong job ${this.job.id} currently does not have pickup time`)
            this.isReady = false
            return
        }

        if (this.fromTime > this.toTime) {
            this.isReady = false
            return
        }

        this.isReady = true
    }

    disabledDate = (current: Date): boolean => dayjs(current).isBefore(this.today)

    onConfirm() {
        this.rolling = true
        // determine days
        const jobDate = dayjs(this.job.pickDt.time).tz( this.job.pickDt.timezone ).startOf('day').toISOString()
        const date = jobDate.split('T')[0]
        const fromTime = this.fromTime.toTimeString().substring(0, 5)
        const toTime = this.toTime.toTimeString().substring(0, 5)
        const fromDateTime = `${date}T${fromTime}:00`
        const toDateTime = `${date}T${toTime}:00`

        this.dataorch.updateJobPickupTime(this.job.id, fromDateTime, toDateTime).subscribe(res => {
            console.log(res)
            this.rolling = false
            this.onOk && this.onOk()
        })
    }
}