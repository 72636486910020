<div *ngIf="info">
    <div class="group">
        <!-- <div class="section-header">Location</div> -->
        <div nz-row [nzGutter]="{ md: 16 }">
            <div nz-col nzXs="24" nzMd="12">
                <div class="input-title">
                    Location Name <span class="label-mark-required"></span>
                </div>
                <nz-form-item>
                    <input nz-input
                    [nzAutocomplete]="autoComplete1"
                    [(ngModel)]="locationName" (ngModelChange)="onLocationNameChange()"
                    >
                    <nz-autocomplete #autoComplete1 [nzDefaultActiveFirstOption]="false">
                        <nz-auto-option (selectionChange)="onLocationSelected($event, item)" *ngFor="let item of locationsFiltered; let i = index" [nzValue]="item" [nzLabel]="item.name">
                            {{item.name}}
                        </nz-auto-option>
                    </nz-autocomplete>
                </nz-form-item>
            </div>
            <div nz-col nzXs="24" nzMd="12">
                <div class="input-title">
                    Address <span class="label-mark-required"></span>
                </div>
                <nz-form-item>
                  <nz-form-control>
                    <form-address [zipcode]="zipcodeOriginal" [(ngModel)]="info.addr" ></form-address>
                  </nz-form-control>
                </nz-form-item>
            </div>
        </div>
        <div nz-row [nzGutter]="{ md: 16 }">
            <div nz-col nzXs="24" nzMd="12">
                <div class="input-title">
                    Appointment
                </div>
                <div name="appointment-required" nz-checkbox [(ngModel)]="info.requiresAppointment">
                    Location requires Appointment
                </div>    
            </div>
            <div nz-col nzXs="24" nzMd="12">
                <div class="input-title">
                    Access Code
                </div>
                <div>
                    <nz-form-item>
                        <input nz-input placeholder="Access Code" [(ngModel)]="info.accessCode">
                    </nz-form-item>
                </div>
            </div>
        </div>
    </div>

    <div class="group">
        <div class="section-header">Contact</div>
        <div nz-row [nzGutter]="{ md: 16}">
            <div nz-col nzXs="24" nzMd="12">
                <div class="input-title">
                    Name <span class="label-mark-required"></span>
                </div>
                <nz-form-item>
                    <input nz-input placeholder="Contact Name" [(ngModel)]="info.contact.name" (ngModelChange)="checkReady()">
                </nz-form-item>
            </div>
            <div nz-col nzXs="24" nzMd="12">
                <div nz-row [nzGutter]="{ md: 16 }">
                    <div nz-col nzXs="24" nzMd="12">
                        <div class="input-title">
                            Phone Number<span class="label-mark-required"></span>
                        </div>
                        <nz-form-item>
                            <input nz-input placeholder="Phone Number" [(ngModel)]="info.contact.phone" (keypress)="onPhoneNumberInputKeyPress($event)" (ngModelChange)="onPhoneNumberUpdate($event)">
                        </nz-form-item>
                    </div>
                    <div nz-col nzXs="24" nzMd="12">
                        <div class="input-title">
                            Email
                        </div>
                        <div>
                            <nz-form-item>
                                <input nz-input placeholder="Email" [(ngModel)]="info.contact.email">
                            </nz-form-item>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="group">
        <div class="section-header">Time Window</div>
        <div nz-row [nzGutter]="{ md: 16 }">
            <div nz-col nzSm="12">
                <div class="input-title">Date<span class="label-mark-required"></span></div>
                <div>
                    <nz-date-picker (ngModelChange)="onDateTimeUpdate()" [(ngModel)]="serviceDate" [nzDisabledDate]="disabledDate" nzFormat="yyyy-MM-dd"style="width: 100%;"></nz-date-picker>
                    <!-- {{ serviceDate}} -->
                </div>        
            </div>
            <div nz-col nzSm="6">
                <div class="input-title">From Time<span class="label-mark-required"></span></div>
                <div>
                    <nz-time-picker style="width: 100%;" [nzUse12Hours]="true" nzFormat="h:mm a" nzPlaceHolder="from time"
                        [nzMinuteStep]="30" [(ngModel)]="fromTime" (ngModelChange)="onDateTimeUpdate()">
                    </nz-time-picker>
                </div>
            </div>
            <div nz-col nzSm="6">
                <div class="input-title">To Time<span class="label-mark-required"></span></div>
                <div>
                    <nz-time-picker style="width: 100%;" [nzUse12Hours]="true" nzFormat="h:mm a" nzPlaceHolder="to time"
                        [nzMinuteStep]="30" [(ngModel)]="toTime" (ngModelChange)="onDateTimeUpdate()">
                    </nz-time-picker>
                </div>
            </div>
        </div>
    </div>

    <div class="group">
        <div nz-row [nzGutter]="{ md: 16 }">
            <div nz-col nzSm="12">
                <div class="input-title">Service Options</div>
                <div style="border: solid 1px #eee; border-radius: 2px; padding: 2px; min-height: 32px;">
                    <div *ngIf="!info.serviceOptions || !info.serviceOptions.length" class="label">N/A</div>
                    <span class="service-option" *ngFor="let service of info.serviceOptions">{{service}}</span>
                </div>        
            </div>
            <div nz-col nzSm="12">
                <div class="input-title">
                    Reference Numbers
                </div>
                <div>
                    <nz-form-item>
                        <input nz-input placeholder="Reference Number" [(ngModel)]="info.refNum">
                    </nz-form-item>
                </div>        
            </div>
        </div>
    </div>

    <div class="group">
        <div class="section-header">Additional info</div>
        <div nz-row [nzGutter]="{ md: 16 }">
            <div nz-col nzSm="12">
                <div class="input-title">Instructions</div>
                <div>
                    <textarea nz-input placeholder="Instructions" [(ngModel)]="info.instructions"
                        [nzAutosize]="{minRows: 2, maxRows: 4}">
                    </textarea>
                </div>
            </div>
            <div nz-col nzSm="12">
                <div class="input-title">Note</div>
                <div>
                    <textarea nz-input placeholder="Notes" [(ngModel)]="info.note" [nzAutosize]="{minRows: 2, maxRows: 4}">
                    </textarea>
                </div>
            </div>
        </div>
    </div>
</div>
