<ng-container *ngIf="!this.routeData">
  <div class="text-center quote-empty">
    <img class="empty-quote-img" src="/assets/img/howhelp_2.png" />
    <div class="title top40">Peace of mind for your middle mile freight</div>
    <div class="description top40">Peace of mind for you, unmatched service, quality and flexibility for your freight.</div>
  </div>
</ng-container>
<ng-container *ngIf="this.routeData">
  <div class="quote-result">
    <img class="background-img" src="/assets/img/howhelp_2.png" />
    <div class="result-quote-header bottom10">
      <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 16, lg: 16 }">
        <div nz-col class="gutter-row" nzXs="24" nzSm="12" nzMd="11" nzLg="12">
          <div class="left-header flex-wrap">
            <div class="text-1">RECOMMENDED LOADS</div>
            <div class="text-2">Get ASAP Delivery <nz-switch class="switch" nzSize="small" [(ngModel)]="isASAPDelivery" (click)="clickSwitchASAP()"></nz-switch></div>
          </div>
        </div>
        <ng-container *ngIf="distance">
          <div nz-col class="gutter-row" nzXs="24" nzSm="12" nzMd="13" nzLg="12">
            <div class="right-header flex">
              <div>{{getAddress(filters.pickAddr)}}</div>
              <div class="flex">
                <div class="point start-point">
                  <div class="point16"></div>
                  <div class="point10"></div>
                  <div class="point4"></div>
                </div>
                <div class="line">
                  {{getMiles(distance)}} miles
                </div>
                <div class="point end-point">
                  <div class="point16"></div>
                  <div class="point10"></div>
                  <div class="point4"></div>
                </div>
              </div>
              <div>{{getAddress(filters.dropAddr)}}</div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="result-quote-body">
      <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 16, lg: 16 }">
        <ng-container *ngFor="let quote of quotesOfPage">
          <div nz-col class="gutter-row" nzXs="24" nzSm="12" nzMd="12" nzLg="12">
            <div #freightQuoteFilter [isShowCarrierName]="isShowCarrierName" [routers]="routeData" freight-quote-item [filters]="filters" [quote]="quote" (onBoookLoadBtn)="onBoookLoadBtn($event)"></div>
          </div>
        </ng-container>
        <ng-container *ngIf="!listDataQuote.length">
          <div nz-col class="gutter-row" nzXs="24" nzSm="24" nzMd="24" nzLg="24">
            <div style="text-align: center; margin-top: 30vh;font-weight: 600;font-size: 30px; line-height: 53.25px;">No quotes available.</div>
          </div>
        </ng-container>
      </div>
      <ng-container *ngIf="dataQuotes?.length > pageSize">
        <div class="top20">
          <nz-pagination [nzPageIndex]="pageIndex" [nzPageSize]="pageSize" [nzTotal]="dataQuotes?.length" (nzPageIndexChange)="changePageIndex($event)"></nz-pagination>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>