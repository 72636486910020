<ng-container *ngIf="value.type === 'DOUBLE'">{{ value.value }}</ng-container>
<ng-container *ngIf="value.type === 'INT' || value.type === 'STRING'">{{ value.value }}</ng-container>
<ng-container *ngIf="value.type === 'SERIES'">
    <div finance-value *ngFor="let entry of value.value" [value]="entry"></div>
</ng-container>
<ng-container *ngIf="value.type === 'TABLE'">
    <table class="table">
        <!-- <tr>
            <th>Key</th>
            <th>Value</th>
        </tr> -->
        <tr *ngFor="let item of value.value | keyvalue">
            <td>{{ item.key }}</td>
            <td><div finance-value [value]="item.value" style="min-width: 100px;"></div></td>
        </tr>
    </table>
</ng-container>