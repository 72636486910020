<h2 class="center">Create new Line-haul load</h2>
<div class="bottom10">
    <div class="flex1">
        Shipments: <code class="right5" *ngFor="let shipment of _shipments"> {{ shipment.warpId }}</code>
    </div>
    <div class="flex1">
        Pallets: <code>{{ palletCount }}</code>
    </div>
</div>

<div>Equipment:</div>
<div class="flex bottom15">
    <div class="right20" *ngIf="_lane?.vehicleTypes?.length == 1">
        {{ _lane.vehicleTypes[0].name }}
    </div>
    <div class="right20" *ngIf="_lane?.vehicleTypes?.length > 1">
        <nz-select [nzDropdownMatchSelectWidth]="false" [(ngModel)]="selectedVehicle" (ngModelChange)="onSelectVehicle()">
            <nz-option *ngFor="let v of _lane.vehicleTypes" [nzValue]="v" [nzLabel]="v.name"></nz-option>
        </nz-select>
    </div>
    <div *ngIf="vehicle">
        <label [(ngModel)]="option.selected" nz-checkbox *ngFor="let option of vehicle.options">{{ option.name }}</label>
    </div>    
</div>

<div class="flex">
    <div class="right10">
        <div>Pickup date</div>
        <div>
            <nz-date-picker [disabled]="creating" [nzDisabledDate]="disabledDate" [(ngModel)]="date" nzMode="date" (ngModelChange)="checkReady($event)"></nz-date-picker>
        </div>
    </div>
    <div class="right10">
        <div>From Time</div>
        <div>
            <nz-time-picker [nzMinuteStep]="15" nzFormat="HH:mm" [(ngModel)]="fromTime" nzPlaceHolder="From time" (ngModelChange)="checkReady($event)"></nz-time-picker>
        </div>
    </div>
    <div>
        <div>To Time</div>
        <div>
            <nz-time-picker [nzMinuteStep]="15" nzFormat="HH:mm" [(ngModel)]="toTime" nzPlaceHolder="To time" (ngModelChange)="checkReady($event)"></nz-time-picker>
        </div>
    </div>
</div>
<div class="bottom10 note">
    Time is in Local timezone of pickup location
</div>

<nz-divider></nz-divider>
<div class="buttons flex">
    <div class="right20">
        <button [disabled]="creating" nz-button (click)="onClose && onClose()">Cancel</button>
    </div>
    <div>
        <button nzType="primary" [disabled]="!isReady || creating" nz-button (click)="onCreate()">Confirm</button>
    </div>
</div>