import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { DateUtil } from "@services/date-utils";

@Component({
    selector: '[map-value]',
    templateUrl: './map.html',
    styleUrls: ['./index.scss']
})
export class MapValue extends BaseComponent {
    @Input() value: any = null;
    public search: string = null;
    public toDisplay: any = []

    ngOnInit(): void {
        this.onSearch()
    }

    onSearch() {
        if (!this.value) return []
        if (!this.search) {
            this.toDisplay = Object.entries(this.value.value)
        } else {
            this.toDisplay = Object.entries(this.value.value).filter(k => k[0].indexOf(this.search) >= 0)
        }
    }

    onChangeEvent(event: any){
        this.search = event.target.value;
        this.onSearch();
    }
}