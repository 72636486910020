import { Component, Input } from '@angular/core';
import { BaseComponent } from '@abstract/BaseComponent';
import { DateUtil } from '@services/date-utils';
import { Const } from '@const/Const';

@Component({
  selector: '[rate-detail]',
  templateUrl: './rate-detail.html',
  styleUrls: ['./rate-detail.scss',]
})
export class RateDetail extends BaseComponent {
    @Input() item
}