import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Const } from "@const/Const";
import { PlanningService } from "@services/planning.service";
import dayjs from "dayjs";
import _ from "underscore";

@Component({
    selector: '[new-linehaul-route-form]',
    templateUrl: './index.html',
    styleUrls: ['./index.scss']
})
export class NewLinehaulRouteForm extends BaseComponent {
    service: PlanningService
    public date: any = null
    public fromTime: Date = null
    public toTime: Date = null
    public isReady: boolean = false
    public creating: boolean = false
    public palletCount: number = 0
    public today = dayjs().startOf('day')
    public vehicle = null
    public selectedVehicle

    @Input() tz: string
    _lane: any
    _shipments: any[] = []
    @Input() onClose
    @Input() onOk

    @Input() set shipments(v) {
        this._shipments = v
        this.palletCount = v?.length ? _.reduce(v.filter(it => it.metadata?.zero_load !== 'true').map(it => it.workload), (a,b) => a + b) : 0
    }

    @Input() set lane(v) {
        this._lane = v
        if (v.vehicleTypes?.length == 1) {
            const options = v.vehicleTypes[0].options.map(o => Object.assign({}, {name: o, selected: false}))
            this.vehicle = Object.assign({}, v.vehicleTypes[0], {options})
            this.selectedVehicle = v.vehicleTypes[0]
        }
        // this.selectedVehicle = v.vehicleTypes[0]
    }

    constructor() {
        super()
        this.service = new PlanningService(this.api)
    }

    checkReady(event) {
        if (!this.date || !this.fromTime || !this.toTime) {
            this.isReady = false
            return
        }
        if (!this.vehicle) {
            this.isReady = false
            return
        }
        const d = dayjs(this.date)
        if (dayjs(Date.now()).startOf('day').isAfter(d)) {
            this.showErr('Pickup Date cannot be in the past')
            return
        }
        
        if (this.fromTime > this.toTime) {
            this.isReady = false
            return
        }

        this.isReady = true
    }

    onSelectVehicle() {
        const options = this.selectedVehicle.options.map(o => Object.assign({}, {name: o, selected: false}))
        this.vehicle = Object.assign({}, this.selectedVehicle, {options})
        this.checkReady(null)
    }

    disabledDate = (current: Date): boolean => dayjs(current).isBefore(this.today)

    onCreate() {
        const shipmentIds = this._shipments.map(it => it.id)
        this.creating = true
        const options = this.vehicle.options.filter(it => it.selected).map(it => it.name)
        const vehicle = Object.assign({}, this.vehicle, {options})
        const date = dayjs(this.date).startOf('day').toISOString().split('T')[0]
        const fromTime = this.fromTime.toTimeString().substring(0, 5)
        const toTime = this.toTime.toTimeString().substring(0, 5)
        const fromDateTime = `${date}T${fromTime}:00`
        const toDateTime = `${date}T${toTime}:00`

        this.service.scheduleShipment(this._lane.id, null, shipmentIds, vehicle, fromDateTime, toDateTime).subscribe(res => {
            this.api.POST(Const.APIURI_JOBS + `/${res.id}/bol`).subscribe((x) => {
                console.log('Refreshed BOL')
            })
            this.api.POST(Const.APIURI_JOBS + `/${res.id}/load_tender`).subscribe((x) => {
                console.log('Refreshed Load Tender')
            })
            this.creating = false
            this.onOk && this.onOk()
        })
    }
}