<div *ngIf="onProgress" class="nodata"><i nz-icon nzTheme="outline" nzType="loading"></i></div>

<div *ngIf="!onProgress" class="dashboard-child-container form-detail no-padding flex">
  <div class="flex" style="width: 100%;background: white;padding: 20px">
    <div style="width: 100%">
      <div class="flex"><b>Owner: </b><ng-container>
          <a class="left5" *ngIf="getOwnerInfo(model)"
            [routerLink]="getRouterLinkToOwner(model)">{{getOwnerInfo(model)}}</a>
          <div class="left5" *ngIf="!getOwnerInfo(model)">N/A</div>
        </ng-container>
      </div>
      <div class="top15"><b>Date: </b>{{getTransactionDate(model)}}</div>
      <div class="top15"><b>Expired Date: </b>{{getExpiredDate(model) || 'N/A'}}</div>
      <div class="top15"><b>Amount: </b>{{getAmountValue(model) || 'N/A'}}</div>
      <div class="top15 flex" style="align-items: center"><b>Status: </b>
        <nz-tag class="left5" [nzColor]="getStatusColor(model.status)">{{statusText(model.status)}}</nz-tag>
        <div *ngIf="!isAdminReadOnlyRole && hasIssue(model)" style="margin-left: 6px">
          <i (click)="onChangeStatus(model)" nz-button nz-icon nz-tooltip nzTooltipPlacement="top"
            nzTooltipTitle="Update status" nzType="edit" style="cursor: pointer;"></i>
        </div>
      </div>
      <div class="top15"><b>Code: </b>{{getTransactionCode(model) || 'N/A'}}<i (click)="onCopyTransactionCode(model)"
          *ngIf="getTransactionCode(model)" class="left5" nz-button nz-icon nzType="copy" style="cursor: pointer;"></i>
      </div>
      <div class="top15"><b>External Code: </b>{{getExternalCode(model) || 'N/A'}}<i (click)="onCopyExternalCode(model)"
          *ngIf="getExternalCode(model)" class="left5" nz-button nz-icon nzType="copy" style="cursor: pointer;"></i>
      </div>
      <div class="top15 flex"><b>Description: </b>
        <div class="left5" [innerHTML]="getDescriptionHtml(model)"></div>
      </div>
    </div>
    <ng-container *ngIf="!isAdminReadOnlyRole">
      <button (nzOnConfirm)="onRefund(model)" *ngIf="needRefund(model)" [disabled]="model.onProgress"
        [nzLoading]="model.onProgress" nz-button nz-popconfirm nzPopconfirmPlacement="top"
        nzPopconfirmTitle="Are you sure refund to owner?" nzType="primary">
        <i nz-icon nzTheme="outline" nzType="dollar"></i>Refund
      </button>
    </ng-container>
  </div>
  <div class="left20" style="width: 600px;background: white;padding: 20px">
    <div class="bottom20"><b>Status History</b></div>
    <div *ngIf="!getStatusHistory(model)">N/A</div>
    <ng-container *ngIf="getStatusHistory(model)">
      <nz-timeline>
        <ng-container *ngFor="let history of getStatusHistory(model)">
          <nz-timeline-item>
            <div>
              <div> <nz-tag [nzColor]="getStatusColor(history.to)">{{statusText(history.to)}}</nz-tag></div>
              <div style="margin-top: 4px;font-size: 12px">{{getHistoryDate(history)}}</div>
              <div style="margin-top: 5px" *ngIf="history.reason">{{history.reason}}</div>
            </div>
          </nz-timeline-item>
        </ng-container>
      </nz-timeline>
    </ng-container>
  </div>
</div>