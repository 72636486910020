import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '@abstract/BaseComponent';

@Component({
  selector: '[quote-item]',
  templateUrl: './index.html',
  styleUrls: ['../index.scss',]
})
export class QuoteItem extends BaseComponent {
    @Input() item: any
    @Output() onDelete: EventEmitter<any> = new EventEmitter<any>();
    @Output() onUpdate: EventEmitter<any> = new EventEmitter<any>();

    onDeleteItem() {
        this.onDelete && this.onDelete.emit(this.item.id)
    }

    onChange() {
        // this.item[type] = event
        console.log(this.item)
        this.onUpdate && this.onUpdate.emit(this.item)
    }
    getTotal(item){
        return (item.weight || 0) * (item.quantity || 0)
    }
}