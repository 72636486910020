<div style="padding: 20px;">
  <div class="medium">
    Confirm Shipment Details
  </div>
  
  <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 16, lg: 16 }">
    <div nz-col class="gutter-row top20" nzXs="24" nzSm="24" [nzMd]="this.routers?.length ? 12 : 24" [nzLg]="this.routers?.length ? 12 : 24">
      <div class="p-30">
        <div class="pickup p-relative">
          <div class="icon"><i nz-icon nzType="environment" nzTheme="outline"></i></div>
          <div class="flex p-relative">
            <div class="line"></div>
            <div>
              <div class="title-address">{{getAddressText(shipment?.pickAddr)}}</div>
              <div class="sub-address">Pickup on {{getPickDate()}}</div>
            </div>
          </div>
        </div>
        <div class="dropoff p-relative top20">
          <div class="icon"><i nz-icon nzType="environment" nzTheme="outline"></i></div>
          <div class="title-address">{{getAddressText(shipment?.dropAddr)}}</div>
          <div class="sub-address">Estimated Delivery {{deliveryDate}}</div>
        </div>
        <ng-container *ngIf="getvaluePackaging(shipment?.packaging)" >
          <div class="shipment-type bottom20 p-relative">
            <div><img class="icon" src="/assets/img/ic_Schedule.png" /></div>
            <div [innerHTML]="getvaluePackaging(shipment?.packaging)"></div>
          </div>
        </ng-container>
        <ng-container *ngIf="shipment?.pickupServices.length && shipment?.pickupServices[0].name" >
          <div class="pickup-option bottom15 p-relative">
            <ng-container *ngFor="let item of shipment?.pickupServices; let i=index;">
              <ng-container *ngIf="i==0">
                <img class="icon" src="/assets/img/load.png" />
              </ng-container>
              <span>{{item.name}}<ng-container *ngIf="i<(shipment?.pickupServices.length - 1)" >, </ng-container></span>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="shipment?.dropoffServices.length && shipment?.dropoffServices[0].name">
          <div class="dropoff-option bottom15 p-relative">
            <ng-container *ngFor="let item of shipment?.dropoffServices; let i=index;">
              <ng-container *ngIf="i==0">
                <img class="icon" src="/assets/img/load.png" />
              </ng-container>
              <span>{{item.name}}<ng-container *ngIf="i<(shipment?.pickupServices.length - 1)" >, </ng-container></span>
            </ng-container>
          </div>
        </ng-container>
        <div class="shipment-detail p-relative">
          <ng-container *ngIf="shipment?.isHazardous">
            <div class="bottom15"><img class="icon" src="/assets/img/shipment-details.png" /> Includes Hazardous Items</div>
          </ng-container>
          <ng-container *ngIf="shipment?.isTemperatureControlled">
            <div class="bottom15"><img class="icon" src="/assets/img/shipment-details.png" /> Requires Temperature Control</div>
          </ng-container>
        </div>
        <div></div>

        
      </div>
    </div>
    <ng-container *ngIf="this.routers?.length">
      <div nz-col class="gutter-row top20" nzXs="24" nzSm="24" nzMd="12" nzLg="12">
        <div #mapElm id="dispatch-map" style="width: 100%; height: 300px;"></div>
      </div>
    </ng-container>
  </div>
  <div class="text-center top20">
    <button nz-button nzType="primary" (click)="onBtnConfirmAndContinue()">Confirm & Continue</button>
  </div>
</div>

