import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DataorchService } from "@services/dataorch.service";
import dayjs from "dayjs";
import _ from "underscore";

@Component({
    selector: '[update-job-notes-form]',
    templateUrl: './job-ref-nums-form.html',
})
export class JobRefNumsForm extends BaseComponent {
    dataorch: DataorchService
    public refNums: any = null

    _job: any = null
    saving = false
    @Input() set job(v) {
        this._job = v
        const refNums = (v.refNums ?? []).map(it => Object.assign({}, it))
        if (!refNums.length) {
            refNums.push({
                type: '',
                number: ''
            })
        }
        this.refNums = refNums
    }

    @Input() onClose
    @Input() onOk

    constructor() {
        super()
        this.dataorch = new DataorchService(this.api)
    }

    onAdd() {
        this.refNums.push({
            type: '',
            number: ''
        })
    }

    onRemove(ref) {
        ref.delete = true
    }

    onConfirm() {
        this.saving = true
        const refNums = this.refNums.filter(it => it.number).filter(it => !it.delete)
        this.dataorch.updateJobRefNums(this._job.id, refNums).subscribe(res => {
            console.log(res)
            this.saving = false
            this.onOk && this.onOk()
            this._job.refNums = refNums
        })
    }
}