<div *ngIf="_data.lane">
    <h3>{{ _data.lane.name }} Linehaul</h3>
    <div>{{ _data.lane.description }}</div>
    <!-- <div class="bottom10 top10">
        <img alt="Truck type" class="icon" src="assets/img/truck1.svg" /> Equipments:
        <span *ngFor="let v of _data.lane.vehicleTypes">
            {{ v.name }}
            <span *ngIf="v.palletCapacity">[{{ v.palletCapacity }} pallets]</span>
        </span>
    </div> -->
    <div *ngIf="_data.lane.deliveryInfos?.length" class="bottom20">
        <div class="flex">
            <div class="stop" *ngFor="let info of _data.lane.deliveryInfos; index as i">
                <div class="arrow" *ngIf="i>0"><span nz-icon nzType="forward" nzTheme="outline"></span></div>
                <div class="arrow" *ngIf="i == 0"><span nz-icon nzType="home" nzTheme="outline"></span></div>
                <div *ngIf="info.locationName">
                    <a *ngIf="info.warehouseId" target="_blank" [routerLink]="[routeAdminCrossdockWarehouseJob]" [queryParams]="{warehouseId: info.warehouseId}">{{ info.locationName }}</a>
                    <span *ngIf="!info.warehouseId">{{ info.locationName }}</span>
                </div>
                <div *ngIf="!info.locationName && info.warehouseId">
                    <a *ngIf="info.warehouseId" target="_blank" [routerLink]="[routeAdminCrossdockWarehouseJob]" [queryParams]="{warehouseId: info.warehouseId}">View Warehouse</a>
                </div>
                <div>
                    <span class="right5">{{ info.addr.street }},</span>
                    <span>{{ info.addr.city }}, {{ info.addr.state }} {{ info.addr.zipcode }}</span>
                </div>
            </div>
        </div>
    </div>
</div>