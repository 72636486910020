import { Route, Stop, RoutingContext, Cost } from "./route";

function getCost(c: Cost) {
    if (c == null) return 0
    return c.distance * 0.75 / 1609 + c.time * 20 / 3600
}

function calculateTrafficCost(from: Stop, to: Stop, context: RoutingContext) {
    if (from == null || to == null) return 0
    const c = context.matrix[from.locId][to.locId]
    return getCost(c)
}

function checkSwap(stops: Stop[], iFrom: number, iTo: number, context: RoutingContext) {
    let initial = 0
    let prev = iFrom == 0 ? null : stops[iFrom - 1]
    const visited = []

    for (let i = iFrom; i < iTo+1; i++) {
        if (visited.indexOf(stops[i].info.warpId) >= 0) return false
        visited.push(stops[i].info.warpId)

        const next = i < stops.length ? stops[i] : null
        initial += calculateTrafficCost(prev, next, context)
        prev = next
    }
    if (iTo + 1 < stops.length) {
        initial += calculateTrafficCost(stops[iTo], stops[iTo + 1], context)
    }


    prev = iFrom == 0 ? null : stops[iFrom - 1]
    let cost = 0

    for (let i = iTo; i >= iFrom; i--) {
        const next = i < 0 ? null : stops[i]

        cost += calculateTrafficCost(prev, next, context)
        prev = next
    }
    if (iTo + 1 < stops.length) {
        cost += calculateTrafficCost(stops[iFrom], stops[iTo + 1], context)
    }

    // console.log(cost, initial)
    return (cost < initial);
}

function swap(stops: Stop[], iFrom: number, iTo: number) {
    const newOrders = []
    for (let i = 0; i < iFrom; i++) {
        newOrders.push(stops[i])
    }
    for (let i = iTo; i >= iFrom; i--) {
        newOrders.push(stops[i])
    }
    for (let i = iTo+1; i < stops.length; i++) {
        newOrders.push(stops[i])
    }
    return newOrders
}

export default function twoOpt(route: Route, context: RoutingContext, maxIter=10000) {
    let iter = 0
    const start = route.startLocation ? 1 : 0
    while (iter < maxIter) {
        let found = false
        iter += 1
        for (let i = start; i < route.stops.length - 1; i ++) {
            if (!found)
            for (let j = i + 1; j < route.stops.length; j++) {
                // console.log(i,j)
                if (checkSwap(route.stops, i, j, context)) {
                    found = true
                    route.stops = swap(route.stops, i, j)
                    // console.log('FOUND', i, j)
                    break
                }
            }
        }
        if (!found) break
    }
    console.log(`Complete TwoOpt at iterations ${iter}`)
}