import { Component, HostListener, Input } from "@angular/core";
import { Const } from "@const/Const";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { InputHelper } from "@services/input-helper";

@Component({
  selector: "[ltl-item-form]",
  templateUrl: './index.html',
  styleUrls: ['../../../../../../dialogs/dialogs.scss', './index.less'],
})
export class LTLItemForm extends BaseFormDialog1 {
  private initObjectvalue = {
    length: null,
    width: null,
    height: null,
    qty: 1,
    weightPerUnit: null,
    weightUnit: Const.WeightUnits[0],
    weightTotal: 0,
  }
  _objectValue = this.initObjectvalue;

  @Input() get objectValue() { return this._objectValue; }
  @Input() onCloseDialog: () => void = () => {};
  set objectValue(value) {
    if (value) {
      this._objectValue = value;
    } else {
      this._objectValue = this.initObjectvalue;
    }
  }

  constructor() {
    super();
  }
  
  get needUpdate() {
    return this.objectValue.length && this.objectValue.width && this.objectValue.height && this.objectValue.qty && this.objectValue.weightPerUnit;
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
  
  onInputKeyPressNumberOnly(event) {
    return InputHelper.handleInputKeyPressNumberOnly(event);
  }

  onChangeObjectValue(key, value) {
    this.objectValue[key] = value;
  }
  onBtnSave() {
    for (let key of ['length', 'width', 'height', 'qty', 'weightPerUnit']) {
      if (this.objectValue[key] != null && typeof this.objectValue[key] != 'number') {
        this.objectValue[key] = Number(this.objectValue[key]);
      }
    }
    this.objectValue['weightTotal'] = this.objectValue['weightPerUnit'] * this.objectValue['qty'];
    this.updateSuccess(this.objectValue);
    this.closeDialog();
  }
  public closeDialog() {
    this.onCloseDialog();
    this.nzModal?.destroy();
  }

}
