import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Const } from '@const/Const';
import { BaseDialog } from '@dialogs/base-dlg';
import { environment } from '@env/environment';
import { DateUtil } from '@services/date-utils';
import { Mapbox } from '@services/mapbox';

@Component({
  selector: '[confirm-booking-quote]',
  templateUrl: './index.html',
  styleUrls: ['./index.less' ]
})
export class ConfirmBookingQuote extends BaseDialog {
  @Input() request: any;
  @Input() rateOption: any;
  @Input() shipment: any;
  @Input() response: any;

  @Input() routers: any = [];
  @Input() onConfirmShipmentDetail: any;
  @Input() onBookComplete: any;

  packagingTypeList = Const.packagingTypeList;

  pickupAddress: any = null
  dropoffAddress: any = null
  pickupDate: any = null
  deliveryDate: any = null
  items: any[] = []
  booking: boolean = false
  carrier: string = null

  private mapBox: any;
  @ViewChild('mapElm') mapElm: ElementRef<HTMLElement>;

  constructor() {
    super();
  }
  
  ngOnInit(): void {
    if (this.routers?.length > 0) {
      this.mapBox = new Mapbox();
      setTimeout(() => {
        this.mapBox.setupMap(this.routers, [], [], Const.MapBox.style.light, Const.MapBox.FitBoundsBy.routes);
      }, 200);
    }
    const { deliveryInfos } = this.request || (this.shipment || {})
    this.items = this.request?.items || this.shipment?.items
    const pickup = deliveryInfos.filter(it => it.type === 'PICKUP')[0]
    const dropoff = deliveryInfos.filter(it => it.type === 'DROPOFF')[0]
    this.pickupAddress = pickup?.addr
    this.dropoffAddress = dropoff?.addr
    const pickupFrom = pickup?.appointmentInfo?.from || pickup?.windows[0]?.from
    const { option, quote } = this.rateOption || {}
    const { transitTime } = option || {}
    this.pickupDate = pickupFrom ? DateUtil.format(pickupFrom, Const.FORMAT_GUI_DATE_SHORT) : 'N/A'
    this.deliveryDate = (pickupFrom && transitTime) ? DateUtil.format(new Date(pickupFrom).getTime() + transitTime * 1000, Const.FORMAT_GUI_DATE_SHORT) : 'N/A'

    const { carrierName, carrierCode } = this.rateOption?.option || {}
    this.carrier = carrierCode ? `[${carrierCode}] ${carrierName}` : ''
  }

  onBtnConfirmAndContinue() {
    if (this.shipment) {
      this.onBookShipment()
    } else {
      this.onConfirmShipmentDetail();
      this.closeDialog();  
    }
  }

  onCancel() {
    this.closeDialog()
  }

  onBookShipment() {
    this.booking = true
    // call book
    const url = environment.backendUrl + `/v2/pricing/quote/shipments/${this.shipment.id}/book`
    const { quote, option} = this.rateOption
    const payload = {
        quoteId: quote.id,
        optionId: option.id,
        option: option,
        responseId: this.response.id,
    }
    this.api.POST(url, payload).subscribe((res) => {
        this.booking = false
        const { externalId } = res
        this.shipment.externalId = externalId
        this.onBookComplete && this.onBookComplete(externalId)
        this.closeDialog();
    }, (err) => {
        console.log(err)
        this.booking = false
        if (err.message)
        this.showErr(err.message)
    })
}

}