<form class="form-detail" [formGroup]="formInput" nz-form>
  <div class="bottom30 flex">
    <div class="f20" style="flex: 1; height: 50px;">Please select filter conditions</div>
    <button *ngIf="canClear" nz-button class="btn1" (click)="onBtnClear()"><i nz-icon nzType="clear" nzTheme="outline"></i>Clear filter</button>
  </div>

  <div class="bottom20" *ngFor="let key of formKeys">
    <div class="form-label-v2" *ngIf="getLabel(key)">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
    <div>
      <ng-container [ngSwitch]="key">
        <nz-input-group *ngSwitchCase="'warpIds'" [nzSuffix]="tplWarpIdOptions" class="{{key}}">
          <nz-select nzBackdrop="true" class="{{key}}" style="min-width: 150px;" 
            nzMode="tags" [nzMaxMultipleCount]="maxNumWarpId"
            [nzDropdownClassName]="key"
            [nzTokenSeparators]="[',']"
            [formControlName]="key" 
            [nzPlaceHolder]="getPlaceHolder(key)" >
            <nz-option [nzLabel]="hintWarpId" nzValue="" nzDisabled></nz-option>
          </nz-select>
        </nz-input-group>
        <ng-template #tplWarpIdOptions>
          <div nz-dropdown [nzDropdownMenu]="menu1" nzPlacement="bottomRight" style="cursor: pointer;">
            <span nz-icon nzType="more"></span>
          </div>
          <nz-dropdown-menu #menu1="nzDropdownMenu">
            <ul nz-menu>
              <li *ngFor="let mode of ['normal', 'range']" 
                nz-menu-item 
                (click)="onWarpIdModeChange(mode)">{{getNameWarpIdMode(mode)}}
                <span *ngIf="warpIdMode == mode" nz-icon nzType="check" nzTheme="outline" style="margin-left: 15px;"></span>
              </li>
            </ul>
          </nz-dropdown-menu>
        </ng-template>

        <ng-container *ngSwitchCase="'isMultiStop'">
          <div class="form-item top15">
            <div class="form-label-v2"></div>
            <div style="padding-top: 5px">
              <label nz-checkbox [formControlName]="key">Multi-stop</label>
            </div>
          </div>
        </ng-container>

        <select-by-searching *ngSwitchCase="'clientIds'"
          [version]="2"
          [formControlName]="key"
          [nzMode]="'multiple'"
          [showSubClient]="true"
          [(listData)]="listClients"
          [placeholder]="getPlaceHolder(key)"
          (modelChange)="onClientChange($event)">
        </select-by-searching>

        <select-by-searching *ngSwitchCase="'carrierId'"
          [version]="2"
          type="carrier"
          [getAllAtOnce]="false"
          [formControlName]="key"
          [(listData)]="listCarriers"
          [placeholder]="getPlaceHolder(key)"
          (modelChange)="onCarrierChange($event)">
        </select-by-searching>

        <nz-select nzBackdrop="true" *ngSwitchCase="'warehouseId'" style="width: 100%"
          nzAllowClear nzShowSearch
          [formControlName]="key" (ngModelChange)="onWarehouseChange($event)"
          [nzNotFoundContent]="noDataWarehouse">
          <nz-option *ngFor="let item of listWarehouses" [nzLabel]="item.name" [nzValue]="item.id"></nz-option>
        </nz-select>

        <nz-select nzBackdrop="true" *ngSwitchCase="'shipmentType'" style="width: 100%"
          nzAllowClear nzShowSearch
          [formControlName]="key"
          [nzNotFoundContent]="noDataWarehouse">
          <nz-option *ngFor="let item of listShipmentTypes" [nzLabel]="getShipmentTypeName(item)" [nzValue]="item"></nz-option>
        </nz-select>

        <nz-select nzBackdrop="true" *ngSwitchCase="'isCrossDock'" style="width: 100%"
          nzAllowClear [formControlName]="key">
          <nz-option [nzLabel]="'Yes'" [nzValue]="'true'"></nz-option>
          <nz-option [nzLabel]="'No'" [nzValue]="'false'"></nz-option>
        </nz-select>

        <nz-select nzBackdrop="true" *ngSwitchCase="'status'" style="width: 100%"
          nzAllowClear nzShowSearch nzMode="multiple"
          [formControlName]="key" (ngModelChange)="onInputItemChanged(key, $event)">
          <nz-option *ngFor="let item of listStatus" [nzLabel]="getStatusOrder(item)" [nzValue]="item"></nz-option>>
        </nz-select>

        <nz-select nzBackdrop="true" *ngSwitchCase="'podStatus'" style="width: 100%"
          nzAllowClear [formControlName]="key">
          <nz-option [nzLabel]="'Need POD'" [nzValue]="'need_pod'"></nz-option>
          <nz-option [nzLabel]="'Need confirm POD'" [nzValue]="'need_confirm'"></nz-option>
          <!-- <nz-option [nzLabel]="'Not confirmed yet'" [nzValue]="'not_confirmed'"></nz-option> -->
          <nz-option [nzLabel]="'Has been confirmed'" [nzValue]="'confirmed'"></nz-option>
          <nz-option [nzLabel]="'Has Issue'" [nzValue]="'has_issue'"></nz-option>
        </nz-select>

        <nz-select nzBackdrop="true" *ngSwitchCase="'tags'" style="width: 100%"
        nzAllowClear [formControlName]="key" nzMode="multiple">
          <nz-option *ngFor="let item of listTags" [nzLabel]="item.name" [nzValue]="item.key"></nz-option>>
          <nz-option [nzLabel]="'On Hold'" [nzValue]="'HOLD'"></nz-option>
        </nz-select>

        <nz-select nzBackdrop="true" *ngSwitchCase="'invoiceStatus'" style="width: 100%"
          nzAllowClear [formControlName]="key">
          <nz-option [nzLabel]="'Not sent yet'" [nzValue]="'not_sent'"></nz-option>
          <nz-option [nzLabel]="'Has been sent'" [nzValue]="'sent'"></nz-option>
        </nz-select>

        <ng-container *ngSwitchCase="'routing'">
          <nz-select nzBackdrop="true" style="width: 100%"
            nzAllowClear [formControlName]="key"
            (ngModelChange)="onInputItemChanged(key, $event)">
            <nz-option [nzLabel]="'Not routed yet'" [nzValue]="'not_routed'"></nz-option>
            <nz-option [nzLabel]="'Has been routed'" [nzValue]="'routed'"></nz-option>
          </nz-select>

          <div class="form-item top15">
            <div class="form-label-v2"></div>
            <div style="padding-top: 5px">
              <label nz-checkbox [(ngModel)]="needRouteChecked" [ngModelOptions]="{standalone: true}" (ngModelChange)="onCheckboxNeedRoute($event)">Need Routing</label>
            </div>
          </div>

          <div class="form-item top15">
            <div class="form-label-v2"></div>
            <div style="padding-top: 5px">
              <label nz-checkbox [(ngModel)]="needAppointmentChecked" [ngModelOptions]="{standalone: true}" (ngModelChange)="onCheckboxNeedAppointment($event)">Need Appointment</label>
            </div>
          </div>
        </ng-container>

        <div *ngSwitchCase="'clientSuccessRepId'">
          <nz-select nzBackdrop="true" nzAllowClear nzShowSearch
            [formControlName]="key" [nzLoading]="isFetchingClientSuccessRep"
            [nzPlaceHolder]="getPlaceHolder(key)" nzDropdownClassName="dispatch-filter-dispatcher"
            (ngModelChange)="onInputItemChanged(key, $event)" style="width: 100%;">
            <nz-option-group nzLabel="Filter by assignment status">
              <nz-option nzValue="no" nzLabel="Not assigned yet"></nz-option>
              <nz-option nzValue="yes" nzLabel="Already assigned"></nz-option>
            </nz-option-group>
            <nz-option-group nzLabel="Filter by apecific user">
              <nz-option *ngFor="let item of listClientSuccessRep"
                [nzValue]="item.id" [nzLabel]="getFullName(item)">
              </nz-option>
            </nz-option-group>
          </nz-select>
        </div>

        <div *ngSwitchCase="'clientSalesRepId'">
          <nz-select nzBackdrop="true" nzAllowClear nzShowSearch
            [formControlName]="key" [nzLoading]="isFetchingClientSalesRep"
            [nzPlaceHolder]="getPlaceHolder(key)" nzDropdownClassName="dispatch-filter-dispatcher"
            (ngModelChange)="onInputItemChanged(key, $event)" style="width: 100%;">
            <nz-option-group nzLabel="Filter by assignment status">
              <nz-option nzValue="no" nzLabel="Not assigned yet"></nz-option>
              <nz-option nzValue="yes" nzLabel="Already assigned"></nz-option>
            </nz-option-group>
            <nz-option-group nzLabel="Filter by apecific user">
              <nz-option *ngFor="let item of listClientSalesRep"
                [nzValue]="item.id" [nzLabel]="getFullName(item)">
              </nz-option>
            </nz-option-group>
          </nz-select>
        </div>

        <div *ngSwitchCase="'carrierSalesRepId'">
          <nz-select nzBackdrop="true" nzAllowClear nzShowSearch
            [formControlName]="key" [nzLoading]="isFetchingCarrierSalesRep"
            [nzPlaceHolder]="getPlaceHolder(key)" nzDropdownClassName="dispatch-filter-dispatcher"
            (ngModelChange)="onInputItemChanged(key, $event)" style="width: 100%;">
            <nz-option-group nzLabel="Filter by assignment status">
              <nz-option nzValue="no" nzLabel="Not assigned yet"></nz-option>
              <nz-option nzValue="yes" nzLabel="Already assigned"></nz-option>
            </nz-option-group>
            <nz-option-group nzLabel="Filter by specific user">
              <nz-option *ngFor="let item of listCarrierSalesRep"
                [nzValue]="item.id" [nzLabel]="getFullName(item)">
              </nz-option>
            </nz-option-group>
          </nz-select>
        </div>
        <div *ngSwitchCase="'pickupState'">
          <nz-select nzBackdrop="true" style="width: 100%;" nzShowSearch nzAllowClear
            [nzPlaceHolder]="getPlaceHolder(key)" [formControlName]="key"
            nzDropdownClassName="dispatch-filter-dispatcher"
            (ngModelChange)="onPickUpStateChanged(key)">
            <nz-option *ngFor="let state of allStates" [nzLabel]="getStateDesc(state)" [nzValue]="state.code"></nz-option>
          </nz-select>
        </div>
        <div *ngSwitchCase="'dropoffState'">
          <nz-select nzBackdrop="true" style="width: 100%;" nzShowSearch nzAllowClear
            [nzPlaceHolder]="getPlaceHolder(key)" [formControlName]="key"
            nzDropdownClassName="dispatch-filter-dispatcher"
            (ngModelChange)="onDeliveryStateChanged(key)">
            <nz-option *ngFor="let state of allStates" [nzLabel]="getStateDesc(state)" [nzValue]="state.code"></nz-option>
          </nz-select>
        </div>

        <input *ngSwitchDefault nz-input [formControlName]="key" [type]="getInputType(key)" [placeholder]="getPlaceHolder(key)">

      </ng-container>
    </div>
  </div>

  <div class="form-label-v2">Intended Pickup date range</div>
  <div class="date-picker-row bottom20 flex">
    <nz-date-picker *ngFor="let key of ['pickupFromDate', 'pickupToDate']; let i = index" class="flex1"
      [ngClass]="{'first':i==0, 'last':i==1}"
      [formControlName]="key" nzFormat="yyyy-MM-dd"
      [nzPlaceHolder]="getPlaceHolder(key)">
    </nz-date-picker>
  </div>

  <div class="form-label-v2">Intended Delivery date range</div>
  <div class="date-picker-row bottom20 flex">
    <nz-date-picker *ngFor="let key of ['dropoffFromDate', 'dropoffToDate']; let i = index" class="flex1"
      [ngClass]="{'first':i==0, 'last':i==1}"
      [formControlName]="key" nzFormat="yyyy-MM-dd"
      [nzPlaceHolder]="getPlaceHolder(key)">
    </nz-date-picker>
  </div>

  <div class="form-label-v2">Actual Pickup date range</div>
  <div class="date-picker-row bottom20 flex">
    <nz-date-picker *ngFor="let key of ['actualPickupFromDate', 'actualPickupToDate']; let i = index" class="flex1"
      [ngClass]="{'first':i==0, 'last':i==1}"
      [formControlName]="key" nzFormat="yyyy-MM-dd"
      [nzPlaceHolder]="getPlaceHolder(key)">
    </nz-date-picker>
  </div>

  <div class="form-label-v2">Actual Delivery date range</div>
  <div class="date-picker-row bottom20 flex">
    <nz-date-picker *ngFor="let key of ['actualDropoffFromDate', 'actualDropoffToDate']; let i = index" class="flex1"
      [ngClass]="{'first':i==0, 'last':i==1}"
      [formControlName]="key" nzFormat="yyyy-MM-dd"
      [nzPlaceHolder]="getPlaceHolder(key)">
    </nz-date-picker>
  </div>
</form>

<div form-footer [onProgress]="onProgress"
  [canClickOK]="needUpdate && !onProgress" [canClickCancel]="!onProgress"
  [nzIconOK]="'filter'" [labelOK]="'OK'" (onOK)="onBtnSave()"
  (onCancel)="onClose()"></div>
