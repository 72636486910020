import { Component, QueryList, Input, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormAddressUS } from '@app/admin/base/form-address-us/comp';
import { FormBookAppointment } from '@app/admin/shipments/book-appointment';
import { InputHelper } from '@services/input-helper';
import { BaseQuoteShipmentChildForm } from './base-child-form';
import { NzOptionSelectionChange } from 'ng-zorro-antd/auto-complete';
import { Utils } from '@services/utils';
import { MasterData } from '@services/master.data';
import { FormUtil } from '@services/form-util';
import { DialogService } from '@dialogs/dialog.service';
import { DateUtil } from '@services/date-utils';
import { Const } from '@const/Const';
import { AddressUSSmartyMetadata } from '@wearewarp/types/data-model';

@Component({
  selector: '[quote-shipment-dropoff]',
  templateUrl: './quote-shipment-dropoff.html',
  styleUrls: [
    '../../../detail.scss',
    '../../../../../styles/row-col.scss',
    '../../../../../styles/form-v2.scss',
    '../index.less',
  ]
})
export class QuoteShipmentDropoff extends BaseQuoteShipmentChildForm {
  @Input() filters: any;
  @Input() quote: any;
  @Input() clientId: any;
  @Input() set model(value) {super.model = value}
  get model() {return super.model}
  locationId: number = 0;
  public getLocationId() {
    return this.locationId;
  }

  @ViewChild('addrForms') addrForms: FormAddressUS;
  formAddressBindData = null;

  protected formGroupDeclaration: FormGroupDeclaration = {
    warehouseId: {label: ''},
    locationName: {label: 'Delivery Location Name', required: true},
    contactName: {label: 'Contact Name', required: true},
    contactPhone: {label: 'Contact Phone', inputType: 'tel', required: true, getValue: InputHelper.getValuePhone, formatValue: InputHelper.formatPhone},
    contactEmail: {label: 'Contact Email'},
    secondaryContact: {label: 'Secondary Contact', required: false, type: 'formGroup', childItem: {
      contactName: {label: 'Contact Name'},
      contactPhone: {label: 'Contact Phone', inputType: 'tel', getValue: InputHelper.getValuePhone, formatValue: InputHelper.formatPhone},
      contactEmail: {label: 'Contact Email'},
    }},
    addr: {label: 'Delivery Location', required: true},
    requiresAppointment: {label: 'Location requires appointment', type: 'boolean', initialValue: false},
    appointmentInfo: {label: '', type: 'formGroup', childItem: FormBookAppointment.declaration},
    refNum: {label: 'Reference Number'},
    windows: {label: '', type: 'formArray', initialValue: [{}], childItem: {
      date: {label: 'Delivery Date', type: 'date', required: true},
      fromTime: {label: 'Time window', type: 'time', required: true, placeHolder: 'from time'},
      toTime: {label: '', type: 'time', required: true, placeHolder: 'to time'},
    }},
    accessCode: {label: 'Access Code'},
    instructions: {label: 'Delivery Instruction', multiline: true},
    note: {label: 'Note', multiline: true},
  }

  get childKey(): string {
    return 'dropInfo';
  }
  
  constructor() {
    super();
    this.allStates = MasterData.getStatesUS();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.onRequiresAppointmentChange();
    this.onClientChane(this.clientId, false);
  }

  ngAfterViewInit(): void {
    this.bindDataFilterToModel();
  }

  protected bindDataFilterToModel() {
    if (!this.filters) return;
    if (this.deliveryDate) {
      this.setItemValue(`windows[0].date`, this.deliveryDate);
    }
    let dropoffServiceOptionIds = [];
    for (let obj of this.filters.dropoffServices) {
      if (obj._id) {
        dropoffServiceOptionIds.push(obj._id);
      }
    }
    if (Utils.isArrayNotEmpty(dropoffServiceOptionIds)) {
      this.serviceOptions.selectedItems = dropoffServiceOptionIds;
    }
  }

  get deliveryDate() {
    if (this.quote.legs.length) {
      return this.quote.legs[this.quote.legs.length - 1]?.scheduled_delivery_date;
    }
    return null;
  }

  allStates = [];

  serviceOptions = {
    selectedItems: [],
    allItems: MasterData.ShipmentServiceOptionsDelivery,
    sum: () => this.sumServiceOptions(),
    remove: (index) => this.removeServiceOptions(index)
  }

  private sumServiceOptions(): string {
    let count;
    count = this.serviceOptions.selectedItems.length;
    return `${count} ${count > 1 ? 'options' : 'option'}`
  }

  private removeServiceOptions(index: number) {
    let arr = [];
    for (let i = 0; i < this.serviceOptions.selectedItems.length; i++) {
      if (i != index) {
        arr.push(this.serviceOptions.selectedItems[i]);
      }
    }
    this.serviceOptions.selectedItems = arr;
  }

  onClientChane(clientId, isClearLocation: boolean) {
    // get list locations
    // let url = `${Const.APIURI_WAREHOUSES}?filter=${JSON.stringify({clientIds: clientId})}&limit=-1`;
    let url = `${Const.APIURI_WAREHOUSES}?limit=-1`;
    this.api.GET(url).subscribe(
      resp => {
        this.listLocations = resp.data.list_data;
        this.setlocationsFiltered(resp.data.list_data.slice(0));
        if (isClearLocation) {
          this.setLocation(null);
        }
      }, err => {
        this.showErr(err);
      }
    );
  }

  public listLocations = []
  public locationsFiltered = [];

  public setlocationsFiltered(data) {
    this.locationsFiltered = data;
  }

  onValueChange(value: string): void{
    this.locationsFiltered = this.listLocations.filter(location => location.name.toLowerCase().startsWith(value.toString().toLowerCase()));
    const item = this.locationsFiltered?.find(location => location._id == this.getLocationId());
    if (!item) {
      this.locationId = 0;
    }
  }

  onLocationSelected(event: NzOptionSelectionChange, locationObject: any) {
    if (!event.isUserInput) {
      return;
    }
    this.setLocation(locationObject);
  }

  private setLocation(locationObject: any) {
    if (locationObject?.pickAddr?.zipcode && this.filters?.dropZipcode && locationObject.pickAddr.zipcode.toString() != this.filters.dropZipcode.toString()) {
      this.showErr(`Please choose a location that has exactly zipcode ${this.filters?.dropZipcode}`);
      this.locationId = 0;
      this.setItemValue(`warehouseId`, null);
      return this.setItemValue(`locationName`, '');
    }
    this.setItemValue(`addr`, locationObject?.pickAddr);
    this.setItemValue(`contactName`, locationObject?.contactName);
    this.setItemValue(`contactPhone`, locationObject?.phone);
    this.setItemValue(`contactEmail`, locationObject?.email);
    this.setItemValue(`secondaryContact.contactName`, locationObject?.secondaryContact?.contactName);
    this.setItemValue(`secondaryContact.contactPhone`, locationObject?.secondaryContact?.phone);
    this.setItemValue(`secondaryContact.contactEmail`, locationObject?.secondaryContact?.email);
    this.setItemValue(`accessCode`, locationObject?.dropDetails?.accessCode);
    this.setItemValue(`instructions`, locationObject?.dropDetails?.instructions);
    this.setItemValue(`note`, locationObject?.dropDetails?.note);

    if (locationObject?.requireAppointment) {
      this.setItemValue(`requiresAppointment`, true);
      this.updateRequireForDateTime(false)
    } else {
      this.setItemValue(`requiresAppointment`, false);
      this.updateRequireForDateTime(true)
    }
    if (Utils.isArrayNotEmpty(locationObject?.serviceOptions)) {
      for (let item of locationObject?.serviceOptions) {
        if (this.serviceOptions.allItems.map(obj => obj._id).includes(item)) {
          if (!this.serviceOptions.selectedItems.includes(item)) {
            this.serviceOptions.selectedItems.push(item);
          }
        }
      }
    }
    if (!locationObject) {
      this.locationId = 0;
      this.setItemValue(`warehouseId`, null);
      this.setItemValue(`locationName`, '');
    } else {
      this.setItemValue(`warehouseId`, locationObject?.id);
      this.locationId = locationObject._id;
    }
  }

  private getFormAddress(): FormAddressUS {
    return this.addrForms;
  }

  private updateRequireForDateTime(isRequired: boolean) {
    let childKeys = ['date', 'fromTime', 'toTime'];
    for (let childKey of childKeys) {
      this.formGroupDeclaration.windows.childItem[childKey].required = isRequired;
    }
    if (!this.formInput) {
      return;
    }
    let fa = this.getFormArrayTimeWindows();
    for (let i = 0; i < fa.length; i++) {
      let fg = fa.at(i);
      for (let childKey of childKeys) {
        let fc = <FormControl>fg.get(childKey);
        if (fc) {
          // Nếu requires appointment thì các trường requested date/time của [pickInfo/dropInfo] ko bắt buộc nữa
          if (!isRequired) {
            fc.removeValidators(Validators.required);
          } else {
            fc.addValidators(Validators.required);
          }
          fc.updateValueAndValidity();
        }
      }
    }
  }

  private getFormArrayTimeWindows(): FormArray {
    return <FormArray>this.formInput.get('windows');
  }

  getControlTimeWindows() {
    return this.getFormArrayTimeWindows()?.controls ?? [];
  }

  isRequireAppointment(): boolean {
    return this.getItemValue(`requiresAppointment`);
  }

  onRequiresAppointmentChange() {
    let isAppointment = this.isRequireAppointment();
    this.updateRequireForDateTime(!isAppointment)
  }

  onBtnBookAppointment() {
    if (!this.isEditOrCreate) {
      return;
    }
    let currentData;
    let fgAppointmentInfo;
    let fgDropInfo = <FormGroup>this.formInput;
    fgAppointmentInfo = <FormGroup>fgDropInfo.get('appointmentInfo');
    if (fgAppointmentInfo) {
      currentData = FormUtil.getFormGroupData(fgAppointmentInfo, FormBookAppointment.declaration);
    }
    DialogService.openFormDialog1(FormBookAppointment, {
      nzComponentParams: {
        model: currentData,
        onSave: data => this.addAppointmentData(data)
      },
      nzClassName: 'modal-no-padding modal-service-option',
    });
  }

  public appointmentDesc = null;

  getAppointmentText(): string {
    if (!this.appointmentDesc) {
      return 'Book Appointment.';
    } else {
      return this.appointmentDesc;
    }
  }

  private addAppointmentData(data) {
    if (!data) {
      return;
    }
    let fg;
    let fgDropInfo = <FormGroup>this.formInput;
    fg = <FormGroup>fgDropInfo.get('appointmentInfo');
    for (let key of ['date', 'fromTime', 'toTime']) {
      fg.get(key).setValue(data[key]);
    }
    this.setAppointmentDataDesc();
  }

  private setAppointmentDataDesc() {
    let date;
    let fromTime;
    date = this.formInput.get('appointmentInfo').get('date').value;
    fromTime = this.formInput.get('appointmentInfo').get('fromTime').value;
    if (date && fromTime) {
      this.appointmentDesc = `Appointment: ${DateUtil.format(date, 'M/D/YYYY')}, at ${DateUtil.format(fromTime, 'h:mm A')}`;
    } else {
      this.appointmentDesc = null;
    }
  }

  onSelectTimeChange(childKey, value) {
    switch (childKey) {
      case 'fromTime':
        let fc;
        fc = (<FormArray>this.formInput?.get('windows'))?.at(0)?.get('toTime');
        if (fc && !fc.value) {
          fc.setValue(value);
        }
        break;
    }
  }

  public listAddressAutoComplete = [];

  onAddressAutoComplete(list: Array<SmartySuggestion>) {
    this.listAddressAutoComplete = list;
  }

  onSelectSuggestedAddress(event: NzOptionSelectionChange, elm: HTMLInputElement, index: number) {
    this.getFormAddress()?.onSelectSuggestedAddress(event, elm, index);
  }

  addrKey(subkey: string): string {
    return `addr.${subkey}`;
  }

  onInputKeyPress(event, key) {
    switch (key) {
      case 'contactPhone':
        return InputHelper.handleInputKeyPressNumberOnly(event);
      case 'addr.street':
      case 'addr.zipcode':
        let arr = key.split('.');
        let childKey = arr[1];
        return this.getFormAddress()?.onInputKeyPress(event, childKey);
      // case 'refNum':
      //   return InputHelper.handleInputKeyPressAlphaNumericOnly(event);
      default:
        return super.onInputKeyPress(event, key);
    }
  }

  onInputChanged(event, key) {
    switch (key) {
      case 'contactPhone':
        return InputHelper.handleInputChangePhone(event, <FormControl>this.formInput.get(key));
      case 'addr.street':
      case 'addr.zipcode':
        let arr = key.split('.');
        let childKey = arr[1];
        return this.getFormAddress()?.onInputChanged(event, childKey);
      // case 'refNum':
      //   return InputHelper.handleInputChangeAlphaNumericOnly(event, <FormControl>this.formInput.get(key));
      default:
        return super.onInputChanged(event, key);
    }
  }

  protected getFormData_JSON(isCreateNew: boolean): object {
    // Xử lý một số trường hợp đặc biết trước khi gọi super.getFormData_JSON().
    // Convert các trường {date: Date, fromTime: Date, toTime: Date} thành time window dạng {from: ISOString, to: ISOString}
    let customData = {};
    let formAddr = this.formInput.get("addr");
    customData['addr'] = formAddr.value;
    let metadata: AddressUSSmartyMetadata = customData['addr'].metadata;
    let timezone = metadata?.timeZoneStandard;
    let fa = this.getFormArrayTimeWindows();
    let windows = [];
    for (let i = 0; i < fa.length; i++) {
      let fg = fa.at(i);
      let date = <Date>fg.get('date').value;
      let fromTime = <Date>fg.get('fromTime').value;
      let toTime = <Date>fg.get('toTime').value;
      let fromToObj = DateUtil.toTimeWindow(date, fromTime, toTime, timezone);
      if (fromToObj) {
        windows.push(fromToObj);
      }
    }
    customData['windows'] = windows;
    let fg = this.formInput.get('appointmentInfo');
    let date = <Date>fg.get('date').value;
    let fromTime = <Date>fg.get('fromTime').value;
    let toTime = <Date>fg.get('toTime').value;
    customData['appointmentInfo'] = DateUtil.toTimeWindow(date, fromTime, toTime, timezone);
    let data: any = super.getFormData_JSON(isCreateNew);
    for (let childKey of ['windows', 'appointmentInfo', 'addr']) {
      data[childKey] = customData[childKey];
    }
    data.serviceOptions = this.serviceOptions.selectedItems;
    return data;
  }
  get needUpdate(): boolean {
    const contactPhone = this.formInput?.get('contactPhone')?.value
    if (contactPhone) {
      if (!InputHelper.isValidPhone(contactPhone)) {
        return false;
      }
    }
    return super.needUpdate;
  }

}