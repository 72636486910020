<div class="history-item" nz-row [nzGutter]="{md:16}" (click)="onViewItem()">
    <div nz-col nzSm="4">{{quoteTime}}</div>
    <div nz-col nzSm="5"><code class="shipment-type">{{request.shipmentType}}</code> {{client?.name}}</div>
    <div nz-col nzSm="3">{{pickupDate}}</div>
    <div nz-col nzSm="3">{{pickup?.addr?.zipcode}} <i nz-icon nzType="arrow-right" style="color: #aaa" nzTheme="outline"></i> {{dropoff?.addr?.zipcode}}</div>
    <div nz-col nzSm="3" *ngIf="request.vehicleType">{{request.vehicleType || 'N/A'}}</div>
    <div nz-col nzSm="3" *ngIf="!request.vehicleType" class="na">N/A</div>
    <div nz-col nzSm="3" title="min rate"><span *ngIf="rate">
        <span class="label right3">[{{rate.source}}]</span>
        <span decimal [value]="rate.rate.avg"></span>
    </span></div>
    <div nz-col nzSm="2" *ngIf="booked">{{request.status}}</div>
</div>
