import { Component, ElementRef, ViewChild } from "@angular/core";
import { FormArray } from "@angular/forms";
import { Const } from "@const/Const";
import { BaseFormDrawer } from "@drawers/base-form";
import { DateUtil } from "@services/date-utils";
import { MasterData } from "@services/master.data";
import { Utils } from "@services/utils";
import { ShipmentType } from "@wearewarp/types";

@Component({
  selector: '[create-orders]',
  templateUrl: './create-orders.html',
  styleUrls: ['../../app.scss', '../../dialogs/dialogs.scss', './create-orders.scss']
})
export class CreateOrders extends BaseFormDrawer {
  private get declaration_isCrossDock(): FormControlDeclaration {return {label: 'Using Cross Dock', type: 'boolean', initialValue: false}}

  // for uploading a list of orders via excel/csv
  protected formGroupDeclaration: FormGroupDeclaration = {
    uploadOrderList: {label: 'File for uploading', required: true, type: 'uploadFile'},
    clientId: {label: 'Customer', required: true, placeHolder: 'Please select customer'},
    warehouseId: {label: 'Warehouse', required: true},
    shipmentType: {label: 'Shipment Type', required: true},
    isCrossDock: {...this.declaration_isCrossDock, hidden: true},         // for keeping position
    pickupWindows: {label: 'Pick up windows', type: 'formArray', initialValue: [], childItem: {
      date: {label: '', type: 'date', required: true},
      fromTime: {label: '', type: 'time', required: true},
      toTime: {label: '', type: 'time', required: true},
    }},
    dropoffWindows: {label: 'Drop off windows', type: 'formArray', initialValue: [{}], childItem: {
      date: {label: '', type: 'date', required: true},
      fromTime: {label: '', type: 'time', required: true},
      toTime: {label: '', type: 'time', required: true},
    }},
  };
    
  isRequired(key): boolean {
    switch (key) {
      case 'pickupWindows': return false;
      case 'dropoffWindows': return true;
      default: return super.isRequired(key);
    }
  }
  
  public listShipmentTypes = [
    Const.ShipmentTypes.fullTruckLoad,
    Const.ShipmentTypes.lessThanTruckload,
    Const.ShipmentTypes.parcel,
  ];
  public templateLink;
  public listClients = [];
  public listWarehouses = [];
  public get txtBtnOK() {return 'Upload'}
  public get iconBtnOK() {return 'upload'}
  public get noDataWarehouse() {
    if (!this.formInput.get('clientId').value) return 'Please select customer first';
    return 'No data';
  }
  
  public errMsg;
  public get labelSelectFile(): string {
    let key = 'uploadOrderList';
    return this.hasAttachedFile(key) ? this.getFileDesc(key) : 'Select file (excel/csv)'
  }
  
  @ViewChild('inputFile') inputFile: ElementRef<HTMLInputElement>;
  
  constructor() {
    super();
  }
  
  ngOnInit() {
    let listTemplates = MasterData.getTemplateOrder();
    if (Utils.isArrayNotEmpty(listTemplates)) {
      this.templateLink = listTemplates[0];
    }
    super.ngOnInit();
  }
  
  getTemplateName(url: string): string {
    let arr = url.split('/');
    return arr[arr.length - 1];
  }
  
  protected getApiUrl(): string {
    return Const.APIV2(`${Const.APIURI_ORDERS}/import-legacy`);
  }

  private get isSettingUsingCrossDockAvaliable(): boolean {
    return !!this.formInput.controls.isCrossDock;
  }

  protected updateFormInputKeys(): void {
    if (this.isSettingUsingCrossDockAvaliable) {
      this._formInputKeys = Object.keys(this.formGroupDeclaration)
    } else {
      super.updateFormInputKeys();
    }
  }

  onShipmentTypeChange(value: ShipmentType) {
    const itemKey = 'isCrossDock';
    if (value == Const.ShipmentTypes.lessThanTruckload) {
      let clientId = this.getItemValue('clientId');
      let client = this.listClients.filter(it => it.id == clientId)[0];
      let clientSettingCrossDock = client?.isUseCrossDock;
      this.formGroupDeclaration.isCrossDock = undefined;
      this.addItemToFormGroup(itemKey, this.declaration_isCrossDock, clientSettingCrossDock);
    } else {
      this.removeFormGroupItem(itemKey);
    }
  }
  
  onClientChange(itemId) {
    let client = this.listClients.filter(it => it.id == itemId)[0] || {};
    let isClientSettingCrossDockYes = client.isUseCrossDock;
    if (Utils.isArrayNotEmpty(client.warehouses)) {
      this.listWarehouses = client.warehouses;
    } else {
      this.listWarehouses = [];
    }
    if (this.listWarehouses.length == 1) {
      this.formInput.get('warehouseId').setValue(this.listWarehouses[0].id);
    } else {
      this.formInput.get('warehouseId').setValue(null);
    }
    if (this.isSettingUsingCrossDockAvaliable && isClientSettingCrossDockYes) {
      this.setItemValue('isCrossDock', isClientSettingCrossDockYes);
    }
  }
  
  protected getFormData_JSON(isCreateNew: boolean) {
    let warehouseId = this.getItemValue('warehouseId');
    let warehouse = this.listWarehouses.filter(it => it.id == warehouseId)[0];
    let timezone = warehouse?.pickAddr?.metadata?.timeZoneStandard;
    let keys = ['pickupWindows', 'dropoffWindows'];
    let times = {}
    for (let key of keys) {
      let fa = <FormArray>this.formInput.get(key);
      times[key] = [];
      for (let i = 0; i < fa.length; i++) {
        let fg = fa.at(i);
        let date = <Date>fg.get('date').value;
        let fromTime = <Date>fg.get('fromTime').value;
        let toTime = <Date>fg.get('toTime').value;
        let fromToObj = DateUtil.toTimeWindow(date, fromTime, toTime, timezone);
        times[key].push(fromToObj);
      }
    }
    let json: any = super.getFormData_JSON(isCreateNew);
    for (let key of keys) {
      for (let i = 0; i < json[key].length; i++) {
        json[key][i] = times[key][i];
      }
    }
    return json;
  }
  
  protected showErr(err) {
    this.errMsg = Utils.getErrorString(err);
  }
  
  protected showInfo(message: any): void {
    // do nothing
  }
  
  protected startProgress() {
    super.startProgress();
    this.errMsg = '';
  }

}