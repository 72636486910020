<div class="flex">
    <div class="flex1">
        <a target="_blank" class="side-link" [routerLink]="[routeAdminDispatchList, route.jobId]">
            {{ route.name }}
        </a>
    </div>
    <div class="right5 status">
        <span [class]="'status-' + job.status">{{ job.status }}</span>
    </div>
    <div>
        <nz-switch [(ngModel)]="route.display" nzSize="small" (ngModelChange)="onToggleShow()"></nz-switch>
    </div>
</div>
<div>
    {{ route.shipments.length }} Shipments:
    <code *ngFor="let s of route.shipments" class="right5" style="display: inline-block; font-size: 0.9em;">
        {{ s.warpId }}
    </code>
</div>
<ng-container *ngIf="job">
    <div *ngIf="earliestWindow">
        <span class="right5" nz-icon nzType="clock-circle" nzTheme="outline"></span>
        <span>{{ earliestWindow }}</span>
    </div>
    <div *ngIf="job.carrier">
        <span *ngIf="job.assignedCarrier?.cost" class="right5 clickable" (click)="onBtnViewBid($event)">
            <span nz-icon nzType="dollar" nzTheme="outline"></span> {{ job.assignedCarrier.cost.grandTotal }}
        </span>
        <span nz-icon nzType="user" nzTheme="outline"></span> {{ job.carrier.basicInfo.name }}
    </div>
    <div *ngIf="!job.carrier">
        <span class="warning right10">No Carrier!</span>
        <span (click)="onBtnViewBid($event)" *ngIf="bid" class="clickable">
            <span nz-icon nzType="dollar" nzTheme="outline"></span>
            View Bid
        </span>
    </div>
    <!-- <div *ngIf="route.metadata.job.driver">
        Driver: {{ route.metadata.job.driver.firstName }} {{ route.metadata.job.driver.lastName }}
    </div> -->

    <div *ngIf="_route.deliveryStatus" route-card-compact [data]="{id: job.id, job: job, tasks: _route.deliveryStatus}" [map]="map"></div>
</ng-container>
