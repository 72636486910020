import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Const } from "@const/Const";
import { BizUtil } from "@services/biz";
import { PlanningService } from "@services/planning.service";
import { Utils as ServiceUtils } from "@services/utils";
import { UpdateShipmentReviewStatusForm } from "../../update-review-status";
import { MasterData } from "@services/master.data";
import { ModalHelper, UpdateTimeWindows } from "@wearewarp/ng-antd";
import { UIHelper } from "@services/UIHelper";
import dayjs from "dayjs";
import _ from "underscore";
import { DataorchService } from "@services/dataorch.service";
import { RollShipmentForm } from "../../roll-shipment-form";

@Component({
    selector: '[need-routing-shipment-list]',
    templateUrl: './index.html',
    styleUrls: ['./index.scss']
})
export class NeedRoutingShipmentList extends BaseComponent {
    _shipments: any[] = null
    planned: any[] = []
    pendingAppt: any[] = []
    unplanned: any[] = null
    next2days: any[] = []
    next2daysBuckets: any[] = []
    overdue: any[] = []
    future: any[] = []
    notScheduled: any[] = []
    service: PlanningService
    dataorch: DataorchService
    filterError: string = null
    filteredCount: any = null
    checked: boolean = false
    indeterminate: boolean = false
    setOfCheckedId = new Set<string>();
    routePlanningSessions = Const.routePlanningSessions

    get shipments() {
        return this._shipments
    }
    @Input() set shipments(v) {
        this.calculateDays()
        this._shipments = v.map(this.processShipment)
        // this.planned = this._shipments.filter(it => it.session?.id)
        // const apptRequired = (it) => (it.dropoff.requiresAppointment && !it.dropoff.appointmentInfo?.from) || (it.pickup.requiresAppointment && !it.pickup.appointmentInfo?.from)
        // this.pendingAppt = this._shipments.filter(it => !it.session?.id).filter(apptRequired)
        // this.unplanned = this._shipments.filter(it => !it.session?.id).filter(it => !apptRequired(it))
        this.filterShipments()
    // update checked id
        const allIds = new Set<string>();
        for (let s of v) {
            allIds.add(s.id)
        }
        this.setOfCheckedId = new Set([...this.setOfCheckedId].filter(it => allIds.has(it)))
    }

    @Output() onRemove: EventEmitter<any> = new EventEmitter<any>();
    @Output() onPlanningAdded: EventEmitter<any> = new EventEmitter<any>();
    @Output() hoverShipment: EventEmitter<any> = new EventEmitter<any>();
    @Output() clickShipment: EventEmitter<any> = new EventEmitter<any>();
    @Output() reviewStatusUpdate: EventEmitter<any> = new EventEmitter<any>();
    @Output() deliveryInfoUpdated: EventEmitter<any> = new EventEmitter<any>();
    @Output() shipmentsUpdated: EventEmitter<any> = new EventEmitter<any>();
  
    constructor(private modalHelper: ModalHelper) {
        super()
        this.service = new PlanningService(this.api)
        this.dataorch = new DataorchService(this.api)
        this.processShipment = this.processShipment.bind(this)
        this.rollShipmentsToDate = this.rollShipmentsToDate.bind(this)
    }

    loading = false
    refreshCheckedStatus(): void {
        const listOfData = this._shipments.filter(it => !it.excluded)
        this.checked = listOfData.every(({ id }) => this.setOfCheckedId.has(id));
        this.indeterminate = listOfData.some(({ id }) => this.setOfCheckedId.has(id)) && !this.checked;
    }

    updateCheckedSet(id: string, checked: boolean): void {
        if (checked) {
            this.setOfCheckedId.add(id);
        } else {
            this.setOfCheckedId.delete(id);
        }
    }

    onItemChecked(id: string, checked: boolean): void {
        this.updateCheckedSet(id, checked);
    }

    onAllChecked(checked: boolean): void {
        this._shipments
          .forEach(({ id }) => this.updateCheckedSet(id, checked));
    }

    processShipment(shipment) {
        const pickup = BizUtil.getPickInfo(shipment)
        const dropoff = BizUtil.getDropInfo(shipment)

        const { metadata } = shipment || {}
        const { mile, issue } = metadata || {}
        const transitType = shipment.shipmentTransitType == 'none' ? 'main' : shipment.shipmentTransitType

        return {
            id: shipment.id,
            warpId: shipment.warpId,
            code: shipment.code,
            excluded: shipment.excluded,
            clientName: shipment.client?.name || shipment.metadata?.client?.name,
            pickup,
            dropoff,
            pickupWindow: pickup ? BizUtil.getDeliveryInfoTime(pickup, {showWindow: true, format: 'M/D h:mm A', formatDateOnly: 'M/D'}) || '-' : '-',
            dropoffWindow: dropoff ? BizUtil.getDeliveryInfoTime(dropoff, {showWindow: true, format: 'M/D h:mm A', formatDateOnly: 'M/D'}) || '-' : '-',
            issue,
            orderId: shipment.order?.id ?? shipment.metadata?.order?.id,
            parentId: shipment.metadata?.parent?.id,
            orderWarpId: shipment.order?.warpId ?? shipment.metadata?.order?.warpId,
            reviewStatus: shipment.review?.status,
            transitType: transitType  ? ((mile ? mile + ' ' : '')  + transitType) : 'main',
            readiness: shipment.metadata.readiness,
            session: shipment.metadata.session,
            metadata: shipment.metadata
        }
    }

    filterShipments() {
        const t0 = Date.now()
        this.planned = this._shipments.filter(it => it.session?.id)
        const apptRequired = (it) => (it.dropoff.requiresAppointment && !it.dropoff.appointmentInfo?.from) || (it.pickup.requiresAppointment && !it.pickup.appointmentInfo?.from)
        this.pendingAppt = this._shipments.filter(it => !it.session?.id).filter(apptRequired)
        const unplanned = this._shipments.filter(it => !it.session?.id).filter(it => !apptRequired(it))

        const isComing = (shipment) => {
            const { pickupDate, dropoffDate } = shipment?.metadata || {}
            if (pickupDate && this.coming.includes(pickupDate)) return true
            if (dropoffDate && this.coming.includes(dropoffDate)) return true
            return false
        }
        const isFuture = (shipment) => {
            const { pickupDate, dropoffDate } = shipment?.metadata || {}
            if (pickupDate == 'N/A' && dropoffDate == 'N/A') return false
            if (pickupDate && pickupDate != 'N/A' && pickupDate <= this.nextWorkingDay) return false
            if (dropoffDate && dropoffDate != 'N/A' && dropoffDate <= this.nextWorkingDay) return false
            return true
        }
        const isPast = (shipment) => {
            const { pickupDate, dropoffDate } = shipment?.metadata || {}
            if (pickupDate == 'N/A' && dropoffDate == 'N/A') return false
            if (pickupDate && pickupDate != 'N/A' && pickupDate >= this.today) return false
            if (dropoffDate && dropoffDate != 'N/A' && dropoffDate >= this.today) return false
            return true
        }
        const noDate = (shipment) => {
            const { pickupDate, dropoffDate } = shipment?.metadata || {}
            return pickupDate == 'N/A' && dropoffDate == 'N/A'
        }
        const getDeliveryDate = (shipment) => {
            const { pickupDate, dropoffDate } = shipment?.metadata || {}
            if (pickupDate == 'N/A') return dropoffDate
            return pickupDate
        }
        const sortKeyByDateLevel = (shipment) => (shipment.readiness?.level ?? 0).toString() + getDeliveryDate(shipment) + (shipment.clientName ?? '')
        const sortKeyByDateLevel2 = (shipment) => (5 - (shipment.readiness?.level ?? 0)).toString() + getDeliveryDate(shipment) + (shipment.clientName ?? '')
        const sortKeyByLevel = (shipment) => (shipment.readiness?.level ?? 0).toString() + (shipment.clientName ?? '')

        this.next2days = unplanned.filter(isComing)
        this.future = _.sortBy(unplanned.filter(isFuture), sortKeyByDateLevel2)
        this.overdue = _.sortBy(unplanned.filter(isPast), sortKeyByDateLevel).reverse()
        this.notScheduled = _.sortBy(unplanned.filter(noDate), it => it.warpId).reverse()

        const next2days = unplanned.filter(isComing)
        const next2daysBuckets = []
        next2daysBuckets.push({
            'name': 'First Leg',
            'shipments': _.sortBy(next2days.filter(it => it.metadata.mile == 'first'), sortKeyByLevel).reverse()
        })
        next2daysBuckets.push({
            'name': 'Last Leg',
            'shipments': _.sortBy(next2days.filter(it => it.metadata.mile == 'last'), sortKeyByLevel).reverse()
        })
        next2daysBuckets.push({
            'name': 'Main + middle Leg',
            'shipments': _.sortBy(next2days.filter(it => it.metadata.mile != 'last' && it.metadata.mile != 'first'), sortKeyByLevel).reverse()
        })
        this.next2daysBuckets = next2daysBuckets
        const t1 = Date.now()
        console.log(`${t1} ShipmentList::filterShipments: Done processing shipments after ${t1 - t0}ms`)
    }

    canAdd: boolean = false
    checkCanAdd() {
        this.canAdd = this.shipments?.length && this.shipments.filter(it => it.issue).length < 1
    }

    async onAddBtn() {
        for (let shipment of this.shipments) {
            shipment.addingStatus = 'ADDING'
            await this.service.addShipmentToPlanning(shipment.id).subscribe((res) => {
                shipment.addingStatus = 'ADDED'
                shipment.session = {
                    id: res.sessionId
                }
                this.onPlanningAdded.emit(shipment)
            }, (err) => {
                shipment.addingStatus = 'ERROR'
            })
        }
    }

    removeShipment(event, id) {
        event.stopPropagation()
        let removed = false
        this._shipments.filter(it => it.id === id).map((r) => {
            r.excluded = !r.excluded
            removed = r.excluded
        })
        this.checkCanAdd()

        this.onRemove.emit({id, removed})
    }

    addToPlanning(event, id) {
        event.stopPropagation()
        const shipment = this._shipments.filter(it => it.id === id)[0]
        if (!shipment) return
        shipment.addingStatus = 'ADDING'
        // const apptRequired = (it) => (it.dropoff.requiresAppointment && !it.dropoff.appointmentInfo?.from) || (it.pickup.requiresAppointment && !it.pickup.appointmentInfo?.from)
        this.service.addShipmentToPlanning(id).subscribe((res) => {
            shipment.addingStatus = 'ADDED'
            shipment.session = {
                id: res.sessionId,
            }
            // this.planned = this._shipments.filter(it => it.session?.id)
            // this.pendingAppt = this._shipments.filter(it => !it.session?.id).filter(apptRequired)
            // this.unplanned = this._shipments.filter(it => !it.session?.id).filter(it => !apptRequired(it))
            // this.onPlanningAdded.emit(shipment)
            this.filterShipments()
        }, (err) => {
            shipment.addingStatus = 'ERROR'
        })
    }

    removeRoutingRequirement(event, item) {
        event.stopPropagation()
        this.modalHelper.confirmDelete({
            message: `Are you sure you want to mark shipment ${item.code ?? ''} [${item.warpId}] as routing no longer needed?`,
            fnOk: () => {
                this.dataorch.updateShipmentMetadata(item.id, {needRouting: false}).subscribe((res) => {
                    item.excluded = true
                    item.removed = true
                })
            }
        })
    }

    onCopyId(event, id) {
        event.stopPropagation()
        if (!id) return
        ServiceUtils.copyTextToClipboard(id.toString(), (e) => {
            if (e) {
                this.showErr("Cannot copy to clipboard");
            } else {
                this.showSuccess(
                    `Copied ${id} to the clipboard`
                );
            }
        })  
    }
    onCopyIds() {
        const ids = this._shipments.filter(it => this.setOfCheckedId.has(it.id)).filter(it => it.warpId).filter(it => !it.excluded).map(it => it.warpId.toString())
        ServiceUtils.copyTextToClipboard(ids.join(", "), (e) => {
            if (e) {
                this.showErr("Cannot copy to clipboard");
            } else {
                this.showSuccess(
                    `Copied ${ids.length} ids to the clipboard`
                );
            }
        })    
    }

    updateShipmentReviewStatus(event, shipment) {
        event.stopPropagation()
        this.modalService.create({
            nzTitle: `Update Review Status for shipment ${shipment.warpId}`,
            nzContent: UpdateShipmentReviewStatusForm,
            nzWidth: "800px",
            nzComponentParams: {
                id: shipment.id,
                warpId: shipment.warpId,
                status: shipment.reviewStatus || {}
            },
            nzOnOk: (comp) => {
                comp.onSave().subscribe((res) => {
                    const { review } = res.data
                    const { status } = review
                    shipment.reviewStatus = status
                    this.reviewStatusUpdate.emit(res.data)
                }, (err) => {
                    this.showErr(err)
                })
            },
            nzOnCancel: (comp) => {
                comp.onCancel()
            }
        })
    }

    onRollShipments() {
        const selected = this._shipments.filter(it => this.setOfCheckedId.has(it.id))
        if (!selected.length) return
        this.rollShipmentsToDate(selected)
    }

    onBtnRollToDate(shipment) {
        this.rollShipmentsToDate([shipment])
    }

    rollShipmentsToDate(shipments) {
        const modalRef = this.modalHelper.open(RollShipmentForm, {
            nzTitle: "Move Shipment to different Date",
            nzComponentParams: {
                shipments,
                onComplete: (results) => {
                    this.shipmentsUpdated.emit(results)
                    this.setOfCheckedId.clear()
                    modalRef.close()
                },
                onCancel: () => {
                    modalRef.close()
                }
            },
            nzFooter: null
        })
    }

    onTabChange() {
        this.setOfCheckedId.clear()
    }
    
    onBtnEditWindowsTime(shipment, deliveryInfo) {
        UpdateTimeWindows.openModal(this.modalHelper, {
            onSubmitError: err => UIHelper.showErr(err),
            onSubmitSucceeded: res => {
                const s = this._shipments.filter(it => it.id === shipment.id)[0]
                if (!s) return
                if (deliveryInfo.type === 'PICKUP') {
                    s.pickup = res.data
                    s.pickupWindow = BizUtil.getDeliveryInfoTime(res.data, {showWindow: true, format: 'M/D h:mm A', formatDateOnly: 'M/D'}) || '-'
                } else {
                    s.dropoff = res.data
                    s.dropoffWindow = BizUtil.getDeliveryInfoTime(res.data, {showWindow: true, format: 'M/D h:mm A', formatDateOnly: 'M/D'}) || '-'
                }
                this.deliveryInfoUpdated.emit({id: shipment.id, deliveryInfo: res.data})
            },
            nzTitle: `Shipment ${shipment.warpId} ${deliveryInfo.type} Time Windows`,
            nzComponentParams: {
            timezone: deliveryInfo.addr?.metadata?.timeZoneStandard,
            model: {
                windows: deliveryInfo.windows,
                reasonCodeId: deliveryInfo.reasonCodeId,
            },
            reasonCodes: MasterData.getChangeDateTimeReasons(),
            submit: data => this.updateDeliveryInfo(shipment.id, deliveryInfo.id, data),
            }
        });
    }

    private updateDeliveryInfo(shipmentId, deliveryInfoId, data) {
        const url = Const.APIV2(`shipments/${shipmentId}/delivery-info/${deliveryInfoId}`)
        return this.api.PUT(url, data)
    }

    readinessColors = ['red', 'black', 'gray', 'orange', 'blue', 'green']

    today: string = ''
    tomorrow: string = ''
    nextWorkingDay: string = ''
    coming: string[] = []

    calculateDays() {
        const now = dayjs()
        const today = now.startOf('day')
        const tomorrow = today.add(1, 'day')
        const dow = tomorrow.day()
        const nextWorkingDay = dow == 0 ? tomorrow.add(1, 'day') : dow == 6 ? tomorrow.add(2, 'day') : tomorrow
        const coming: any[] = []
        let day = today
        coming.push(day)
        while (day.isBefore(nextWorkingDay)) {
            day = day.add(1, 'day')
            coming.push(day)
        }
        this.today = today.format('YYYY-MM-DD')
        this.tomorrow = tomorrow.format('YYYY-MM-DD')
        this.nextWorkingDay = nextWorkingDay.format('YYYY-MM-DD')
        this.coming = coming.map(it => it.format('YYYY-MM-DD'))
    }
}