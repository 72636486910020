<style>
  .form-label-v2 {
    font-weight: 300;
  }
  /* .input-dimen {
    max-width: 120px;
  } */
  .line-break {
    width: 100%;
  }
</style>
<div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 16, lg: 16 }">
  <div nz-col class="gutter-row" nzXs="24" nzSm="24" nzMd="24" nzLg="24">
    <div nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu" [(nzVisible)]="visible"  (nzVisibleChange)="onDropdownVisibleChange($event)">
      <div class="input-select" #inputSelect [ngClass]="{error: isError && (!optionSelected)}">
        <div class="input-text">
          <img src="/assets/img/ic_inbox.png" />
          <ng-container *ngIf="!optionSelected">
            <div class="placeholder">{{placeholder}}</div>
          </ng-container>
          <ng-container *ngIf="optionSelected">
            <div class="value" [innerHTML]="optionSelected">
            </div>
          </ng-container>
        </div>
        <ng-container *ngIf="optionSelected">
          <i class="icon" nz-icon nzType="close-circle" nzTheme="outline" (click)="onRemoveValue();$event.stopPropagation()"></i>
        </ng-container>
        <ng-container *ngIf="!optionSelected">
          <i nz-icon nzType="caret-down" nzTheme="outline"></i>
        </ng-container>
      </div>
    </div>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <div #backgroupDropdown class="quote-backgroup-dropdown package">
        <div style="position: relative; width: 100%;">
          <!-- <div class="" style="position: absolute; top: -30px; width: 100%;">
            <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 16, lg: 16 }">
              <ng-container *ngFor="let item of packagingTypeList" >
                <div nz-col class="gutter-row" nzXs="8" nzSm="8" nzMd="8" nzLg="8">
                  <button (click)="onChangeObjectValue('packagingType', item)" [ngClass]="{selected: objectValue.packagingType==item}" class="item">{{getPackagingTypeName(item)}}</button>
                </div>
              </ng-container>
            </div>
          </div> -->
        </div>
        <div class="line-break bottom20"></div>
        <ng-container *ngIf="objectValue.packagingType=='Parcel'">
          <div>Select Size <span class="label-mark-required"></span></div>
          <div class="line-break"></div>
          <div class="flex top20" style="display: flex; justify-content: center; width: 100%;">
            <ng-container *ngFor="let item of parcelSize" >
              <div (click)="onChangeObjectValue('parcelSizeSelected', item)" [ngClass]="{selected: objectValue.parcelSizeSelected==item}" class="item">{{item}}</div>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="objectValue.packagingType=='Pallet'">
          <ul>
            <ng-container *ngFor="let item of objectValue.ltlItems; let i = index;">
              <li><span><a (click)="onOpenLTLItemForm(i)" [innerHTML]="getLabelForLTLItem(item, false, i)"></a>
                <button (click)="onRemoveLTLItem(i)" nz-button nzType="text"
                [nzDanger]="true">
                <i nz-icon [nzType]="'close'"></i>
              </button></span></li>
            </ng-container>
            <a class="bold" (click)="onOpenLTLItemForm(null)">Add more</a>
          </ul>
          
        </ng-container>
        <ng-container *ngIf="objectValue.packagingType=='FTL'">
          <div>Equipment <span class="label-mark-required"></span></div>
          <div class="line-break"></div>
          <div class="flex top10" style="display: flex; justify-content: center; width: 100%;">
            <ng-container *ngFor="let item of datEquipmentList" >
              <div (click)="onChangeObjectValue('equipment', item)" [ngClass]="{selected: objectValue.equipment==item}" class="item" style="margin: 0 10px; padding: 0 10px;">{{item}}</div>
            </ng-container>
          </div>
          <div class="top20">Dimensions (in.)</div>
          <div class="line-break"></div>
          <div class="flex top10" style="place-content: center;">
            <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 16, lg: 16 }">
              <div nz-col class="gutter-row" nzXs="16" nzSm="16" nzMd="16" nzLg="16">
                <div class="form-label-v2">Weight <span class="label-mark-required"></span></div>
                <nz-form-item >
                  <nz-form-control>
                    <nz-input-group [nzSuffix]="suffixWeightUnit">
                      <input 
                        class="input-dimen" nz-input 
                        [(ngModel)]="objectValue.weightTotal" 
                        (keypress)="onInputKeyPressNumberOnly($event)"
                      >
                    </nz-input-group>
                    <ng-template #suffixWeightUnit>
                      <div class="flex weight-unit">
                        <div class="weight-unit-item" [ngClass]="{selected: objectValue.weightUnit=='lbs'}" (click)="onChangeObjectValue('weightUnit', 'lbs')">lbs</div>
                        <div class="weight-unit-item" [ngClass]="{selected: objectValue.weightUnit=='kgs'}" (click)="onChangeObjectValue('weightUnit', 'kgs')">kg</div>
                      </div>
                    </ng-template>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="top20 text-center footer">
          <button nz-button class="btn-done" (click)="onClickBtnDone()">Done</button>
        </div>
      </div>
      
    </nz-dropdown-menu> 
  </div>
</div>