<div class="dashboard-child-container no-padding list">
  <div class="list-header">
    <search-box #searchBox (doSearch)="doSearch($event)" placeHolder="Search by name, ID or address"
      [searchKeyword]="searchKeyword"></search-box>
    <div class="flex1"></div>
    <button *ngIf="!isAdminReadOnlyRole" nz-button (click)="onBtnCreate()"><i nz-icon nzType="plus"
        nzTheme="outline"></i>Create Template</button>
  </div>
  <div class="list-body">
    <nz-table #nzTable nzBordered="true" [nzData]="listData" nzSize="small" [nzTotal]="totalCount"
      [nzFrontPagination]="false" [nzShowPagination]="totalCount > listData.length" [nzPageSize]="limit"
      [nzLoading]="isLoading" [nzLoadingIndicator]="tplLoading" [nzNoResult]="tplNoData" [nzSize]="'default'"
      (nzPageIndexChange)="onDataListPageChanged($event)">
      <ng-template #tplNoData>
        <div *ngIf="!isLoading" class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
      </ng-template>
      <ng-template #tplLoading>
        <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
      </ng-template>
      <thead (nzSortOrderChange)="onSortOrderChange($event)">
        <tr>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="name">Name</th>
          <th nzColumnKey="type">Type</th>
          <th nzColumnKey="action">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of listData; let i = index">
          <td><a [routerLink]="[routeAdminLabelTemplateList, item._id]">{{item.name}}</a></td>
          <td>{{getTypeText(item.type)}}</td>

          <td class="actions w50">
            <div class="flex">
              <button (click)="onBtnDetail(item)" nz-button nzType="text" nzShape="circle"><i nz-icon nzType="eye"
                  nzTheme="outline"></i></button>
            </div>
          </td>
        </tr>
      </tbody>
    </nz-table>

  </div>
</div>
