import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";

@Component({
    selector: '[update-shipment-review-status]',
    templateUrl: './index.html',
    styleUrls: ['./index.scss']
})
export class UpdateShipmentReviewStatusForm extends BaseComponent {
    _status: {[id: string]: string}
    _initial: {[id: string]: string}
    @Input() id: string
    @Input() warpId
    @Input() code
    get status() {
        return this._status
    }
    set status(v) {
        this._status = Object.assign({}, v)
    }

    onSave() {
        return this.api.POST(`${Const.APIV2(Const.APIURI_SHIPMENTS)}/${this.id}/review_status`, {status: this._status})
    }
    onCancel() {
    }
}