<div style="padding: 20px;">
  <div class="medium">
    Exfreight Quotes
  </div>
  <div>
    <div>Pickup zipcode: <b>{{filters.pickAddr.street}}, {{filters.pickAddr.state}}, {{filters.pickAddr.zipcode}}</b></div>
    <div>Delivery zipcode: <b>{{filters.pickAddr.street}}, {{filters.dropAddr.state}}, {{filters.dropAddr.zipcode}}</b></div>
    <div>Request pickup date: <b>{{filters.pickDate}}</b></div>
    Items: 
    <ul>
      <ng-container *ngFor="let item of filters.packaging.ltlItems; let i = index">
        <li>{{getDescriptionShipmentItem(item)}}</li>
      </ng-container>
    </ul>
  </div>
  <div class="top20"><b>Result</b></div>
  <div class="result-quote-body top20">
    <ng-container *ngIf="isLoading">
      <i nz-icon nzType="loading" nzTheme="outline"></i>
    </ng-container>
    <ng-container *ngIf="!isLoading">
      <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 16, lg: 16 }">
        <ng-container *ngFor="let quote of quotesOfPage">
          <div nz-col class="gutter-row" nzXs="24" nzSm="12" nzMd="12" nzLg="12">
            <div class="quote-item" #freightQuoteFilter [logId]="logId" [shipment]="shipment" [isShowCarrierName]="isShowCarrierName" freight-quote-item [filters]="filters" [quote]="quote" (onBoookLoadBtn)="onBoookLoadBtn($event)"></div>
          </div>
        </ng-container>
        <ng-container *ngIf="!listDataQuote.length">
          <div nz-col class="gutter-row" nzXs="24" nzSm="24" nzMd="24" nzLg="24">
            <div style="text-align: center; font-weight: 600;font-size: 30px; line-height: 53.25px;">No quotes available.</div>
          </div>
        </ng-container>
      </div>
      <ng-container *ngIf="dataQuotes?.length > pageSize">
        <div class="top20">
          <nz-pagination [nzPageIndex]="pageIndex" [nzPageSize]="pageSize" [nzTotal]="dataQuotes?.length" (nzPageIndexChange)="changePageIndex($event)"></nz-pagination>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>

