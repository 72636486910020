import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";

@Component({
    selector: '[add-vehicle-form]',
    templateUrl: './index.html',
    styleUrls: ['./index.scss']
})
export class AddShipmentToGroupForm extends BaseComponent {
    newGroupName: string = null
    groupName: string = null

    @Input() existing: string[] = []
    @Input() shipmentIds: string[] = []

    updateGroupName() {
        this.groupName = this.newGroupName
    }
}