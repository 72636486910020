import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { BaseFormItem } from "@app/admin/base/form-item";
import { Const } from "@const/Const";
import { InputHelper } from "@services/input-helper";
import { Log } from "@services/log";
import { MasterData } from "@services/master.data";
import { ZipcodeService } from "@services/zipcode.service";
import { AddressUSSmartyMetadata } from "@wearewarp/types/data-model";
import { Packaging } from "./packaging";
import { PickDate } from "./pick-date";
import { ServiceOption } from "./service-options";

@Component({
  selector: '[freight-quote-filter]',
  templateUrl: './index.html',
  styleUrls: ['./index.less']
})
export class FreightQuoteFilter extends BaseFormItem {
  private zipcodeService: ZipcodeService;

  addressType = Const.AddressTypeArray;
  serviceOptions = [
    {label: 'Pickup services', items: MasterData.ShipmentServiceOptionsPickup},
    {label: 'Delivery services', items: MasterData.ShipmentServiceOptionsDelivery},
    {label: 'Additional services', items: MasterData.ShipmentServiceOptionsAddition},
  ]

  protected formGroupDeclaration: FormGroupDeclaration = {
    clientId: { label: '', placeHolder: 'Select Customer' },
    pickZipcode: { label: '', placeHolder: 'Pickup zip code', required: true, validators: this.validatePickZipcode.bind(this) },
    dropZipcode: { label: '', placeHolder: 'Delivery zip code', required: true, validators: this.validateDropZipcode.bind(this) },
    pickDate: { label: '', placeHolder: 'Requested pickup date' },
    packaging: { label: '', placeHolder: 'Packaging', type: 'formGroup', childItem: {
      packagingType: {label: ''},
      equipment: {label: ''},
      qty: {label: 'Number of Units', type: 'number', required: true},
      weightTotal: {label: 'Weight Total', type: 'number', required: true},
      weightUnit: {label: '', initialValue: Const.WeightUnits[0], required: true},
      length: {label: 'Length', type: 'number', required: true},
      width: {label: 'Width', type: 'number', required: true},
      height: {label: 'Height', type: 'number', required: true},
      parcelSizeSelected: {label: 'Height'},
      ltlItems: { label: '', placeHolder: '', type: 'array', childItem: {
        qty: {label: 'Number of Units', type: 'number', required: true},
        weightPerUnit: {label: 'Weight Per Unit', type: 'number', required: true},
        weightTotal: {label: 'Weight Total', type: 'number', initialValue: 0, required: true},
        weightUnit: {label: '', initialValue: Const.WeightUnits[0], required: true},
        length: {label: 'Length', type: 'number', required: true},
        width: {label: 'Width', type: 'number', required: true},
        height: {label: 'Height', type: 'number', required: true},
        }
      }
    }},
    isHazardous: {label: 'Hazardous Material', type: 'boolean', initialValue: false},
    isTemperatureControlled: {label: 'Temperature Controlled', type: 'boolean', initialValue: false},
    pickupServices: {label: '', type: 'array', initialValue: [{}]},
    dropoffServices: {label: '', type: 'array', initialValue: [{}]}
  }

  @ViewChild('serviceOptions') serviceOptionsComponent: ServiceOption;
  @ViewChild('packaging') packaging: Packaging;
  @ViewChild('pickDate') pickDate: PickDate;

  public listStatus = Const.OrderStatusArray;
  public listShipmentTypes = Const.ShipmentTypesArray;
  listClients = [];
  listItemTypes = ['Parcel', 'Pallet', 'FTL']
  @Input() textBtnSearch:string = 'Search';
  @Input() isLoading:boolean = false;
  @Input() errors:any = {};
  @Output() onSearch: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFilterUpdate: EventEmitter<any> = new EventEmitter<any>();
  
  constructor() {
    super();
    this.zipcodeService = new ZipcodeService(this.api)
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
  
  protected getFormData_JSON(isCreateNew: boolean): object {
    let json: any = super.getFormData_JSON(true); // always get full data
    if (json.pickZipcode) {
      json.pickAddr = {
        city: this.zipcodeInfo.pickZipcode.city,
        state: this.zipcodeInfo.pickZipcode.state,
        zipcode: json.pickZipcode,
      }
    }
    if (json.dropZipcode) {
      json.dropAddr = {
        city: this.zipcodeInfo.dropZipcode.city,
        state: this.zipcodeInfo.dropZipcode.state,
        zipcode: json.dropZipcode,
      }
    }
    return json;
  }

  protected beforeBindModel(model): any {
    return model;
  }

  protected afterBindModel(): void {
    this.onAddressVerified(this.model.pickAddr?.metadata);
  }
  getValueByKey(key) {
    return this.getFormItemValue(key);
  }

  isDisableServiceOptionItem(item): boolean {
    let arr = this.getItemValue('serviceOptions');
    for (let obj of arr) {
      if (obj.name == item) {
        return true;
      }
    }
    return false;
  }

  public timezone = '';
  onAddressVerified(data: AddressUSSmartyMetadata) {
    if (!data) return;
    this.timezone = data.timeZone;
  }

  onClickIncludesItem(key) {
    this.formInput.get(key).setValue(!this.formInput.get(key)?.value );
    this.onFilterUpdate.emit(this.getFormData_JSON(true));
  }

  onBtnDone(value, key) {
    this.formInput.get(key).setValue(value);
    if (key == 'packaging' && value?.equipment == Const.DatEquipments.REEFER && value.packagingType == Const.FreightQuotePackageType.FTL) {
      this.formInput.get('isTemperatureControlled').setValue(true);
    }
    this.onFilterUpdate.emit(this.getFormData_JSON(true));
  }

  onBtnFilter() {
    this.onSearch.emit(this.getFormData_JSON(true));
  }

  onInputChanged(event, key) {
    this.onFilterUpdate.emit(this.getFormData_JSON(true));
    switch (key) {
      case 'pickZipcode':
      case 'dropZipcode':
        this.errors[key] = null;
        return InputHelper.handleInputChangeNumberOnly(event, <FormControl>this.formInput.get(key));;
      default:
        return super.onInputChanged(event, key);
    }
  }
  clientModelChange(value) {
    if (this.errors?.clientId) {
      this.errors.clientId = null;
    }
    this.onFilterUpdate.emit(this.getFormData_JSON(true));
  }

  onInputKeyPress(event: KeyboardEvent, key): boolean {
    switch (key) {
      case 'pickZipcode':
      case 'dropZipcode':
        return InputHelper.handleInputKeyPressNumberOnly(event);
      default:
        return super.onInputKeyPress(event, key);
    }
  }

  public zipcodeInfo = {
    pickZipcode: {
      isLoading: false,
      desc: null,
      error: null,
      city: null,
      state: null,
    },
    dropZipcode: {
      isLoading: false,
      desc: null,
      error: null,
      city: null,
      state: null,
    }
  };

  onInputFocusOut(event, key) {
    switch (key) {
      case 'pickZipcode':
      case 'dropZipcode':
        let zipcode = this.getItemValue(key) ?? '';
        if (zipcode.length == 0) {
          return;
        }
        if (zipcode == this.zipcodeInfo[key].zipcode) {
          return;
        }
        this.zipcodeInfo[key] = {};
        this.zipcodeInfo[key].isLoading = true;
        this.zipcodeService.get(zipcode, resp => {
          this.zipcodeInfo[key].zipcode = zipcode;
          this.zipcodeInfo[key].city = resp.city;
          this.zipcodeInfo[key].state = resp.state;
          this.zipcodeInfo[key].desc = `${resp.city}, ${resp.state}`;
          this.zipcodeInfo[key].isLoading = false;
          this.formInput?.get(key)?.updateValueAndValidity();
          Log.d('search zipcode success. ', this.zipcodeInfo);
        }, err => {
          this.zipcodeInfo[key].isLoading = false;
          this.zipcodeInfo[key].error = true;
          this.zipcodeInfo[key].desc = 'Invalid zip code'
          this.formInput?.get(key)?.updateValueAndValidity();
          Log.e('search zipcode failed. ', this.zipcodeInfo);
        })
        return;
      default:
        return super.onInputFocusOut(event, key);
    }
  }

  private validateZipInfo(key) {
    if (this.zipcodeInfo[key].error) {
      return {error: 'Invalid zip code'};
    }
    return null;
  }

  private validatePickZipcode(input: FormControl) {
    return this.validateZipInfo('pickZipcode');
  }

  private validateDropZipcode(input: FormControl) {
    return this.validateZipInfo('dropZipcode');
  }

}