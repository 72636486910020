import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { MapModule } from '../../components/map/module';
import { NzTableModule } from 'ng-zorro-antd/table';

import { UiCommonModule } from '../../components/common/module';
import { LinehaulLaneList } from './lane-list';
import { LinehaulLaneListScreen } from './lane-list/screen';
import { LinehaulLaneDetail } from './lane-info';
import { LinehaulLaneDetailScreen } from './lane-detail';
import { LinehaulLaneInventory } from './lane-inventory';
import { TransitNetworkModule } from '@app/admin/transit-network/module';
import { NewLinehaulRouteForm } from './new-route-form';
import { LinehaulRollRouteForm } from './roll-form';
import { LinehaulShipmentContent } from './shipment-content';
import { WarehouseTaskChip } from './warehouse-task-chip';
import { JobNotesForm } from './lane-inventory/job-notes-form';
import { JobRefNumsForm } from './lane-inventory/job-ref-nums-form';
import { DispatchRouteMapModule } from '@app/admin/dispatch/components/dispatch-route-map/module';
import { DeliveryUpdateModule } from '@app/admin/components/delivery-update/module';
import { LinehaulUpdatePickupTimeForm } from './lane-inventory/update-pickup-time-form';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        NzButtonModule,
        NzDatePickerModule,
        NzTimePickerModule,
        NzGridModule,
        NzIconModule,
        FormsModule,
        NzInputModule,
        NzListModule,
        NzTabsModule,
        NzInputNumberModule,
        UiCommonModule,
        NzDividerModule,
        NzPopoverModule,
        NzDropDownModule,
        MapModule,
        NzTableModule,
        NzCheckboxModule,
        NzSelectModule,
        NzToolTipModule,
        NzRadioModule,
        NzDatePickerModule,
        NzSwitchModule,
        NzTagModule,
        TransitNetworkModule,
        DispatchRouteMapModule,
        DeliveryUpdateModule,
    ],
    declarations: [
        LinehaulLaneList,
        LinehaulLaneListScreen,
        LinehaulLaneDetail,
        LinehaulLaneDetailScreen,
        LinehaulLaneInventory,
        NewLinehaulRouteForm,
        LinehaulRollRouteForm,
        LinehaulShipmentContent,
        WarehouseTaskChip,
        JobNotesForm,
        JobRefNumsForm,
        LinehaulUpdatePickupTimeForm,
    ],
    exports: [
    ],
    providers: [
    ]
})

export class PlanningLinehaulModule {}