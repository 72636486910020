<nz-table [nzSize]="'small'" #basicTable [nzData]="toDisplay">
    <thead>
        <tr>
            <th nzCustomFilter>
                <div style="display: flex;">
                    <div style="flex: 1">Name</div>
                    <div style="width: 160px;">
                        <nz-input-group [nzSuffix]="suffixIconSearch">
                            <input type="text" (keyup)="onChangeEvent($event)" nz-input placeholder="input search text" />
                        </nz-input-group>
                        <ng-template #suffixIconSearch>
                            <i nz-icon nzType="search"></i>
                        </ng-template>

                    </div>
                </div>
            </th>
            <th>Value</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let data of basicTable.data">
            <td>{{data[0]}}</td>
            <td><div finance-value [value]="data[1]"></div></td>
        </tr>
    </tbody>
</nz-table>