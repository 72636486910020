<form [formGroup]="formInput" nz-form style="margin: 0px; padding: 0px;">
  <div style="width: 100%">
    <div style="display: flex; justify-content: space-between;align-items: center; width: 100%; padding-left: 4px">
      <nz-tabset [(nzSelectedIndex)]="selectedTabIndex" [nzSize]="'small'" nzAnimated="false" style="width: 100%"
                 type="card">
        <ng-container *ngFor="let tab of filterTabs">
          <nz-tab [nzTitle]="tab.title" style="font-weight: 600"></nz-tab>
        </ng-container>
      </nz-tabset>
      <div style="display: flex;align-items: center;">
        <search-box #searchBox (doSearch)="doSearch($event)"
                    [placeHolder]="'Search by Order ID, Code, Owner name'" [searchKeyword]="searchKeyword"
                    style="width: 400px;max-width: 800px"></search-box>
      </div>
    </div>
    <div style="display: flex; flex-wrap: wrap;padding: 20px;background-color: white;margin-top: 20px">
      <div class="form-item">
        <div class="form-label-v2">Date Range</div>
        <div class="date-picker-row bottom20 flex">
          <nz-date-picker (ngModelChange)="onChangeFilter($event, key)" *ngFor="let key of ['fromDate', 'toDate']; let i = index"
                          [formControlName]="key"
                          [ngClass]="{'first':i==0, 'last':i==1}"
                          [nzPlaceHolder]="getPlaceHolder(key)" class="flex1"
                          nzFormat="yyyy-MM-dd">
          </nz-date-picker>
        </div>
      </div>
      <div *ngIf="isSelectInProgressTab()|| isSelectNeedReviewTab()" class="form-item left10">
        <div class="form-label-v2">Expired Date Range</div>
        <div class="date-picker-row bottom20 flex">
          <nz-date-picker (ngModelChange)="onChangeFilter($event, key)"
                          *ngFor="let key of ['fromExpiredDate', 'toExpiredDate']; let i = index"
                          [formControlName]="key"
                          [ngClass]="{'first':i==0, 'last':i==1}"
                          [nzPlaceHolder]="getPlaceHolder(key)" class="flex1"
                          nzFormat="yyyy-MM-dd">
          </nz-date-picker>
        </div>
      </div>
    </div>
  </div>
</form>
