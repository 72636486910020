import { Component } from '@angular/core';
import { BaseComponent } from '@abstract/BaseComponent';

@Component({
  selector: '[linehaul-lane-list-screen]',
  template: '<div class="screen-container"><div class="outer-box"><div linehaul-lane-list></div></div></div>',
  styleUrls: ['../../screen.scss']
})
export class LinehaulLaneListScreen extends BaseComponent {
    constructor() {
        super();
    }
}