import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: '[decimal-number]',
  template: '<span>{{display}}</span>',
  styleUrls: ['../index.scss',]
})
export class DecimalNumber extends Component implements OnChanges  {
    @Input() value: number | null = null
    @Input() decimals: number = 1

    display: string | null = null

    ngOnChanges(changes: SimpleChanges): void {
        this.display = this.value ? parseFloat(this.value.toFixed(this.decimals)).toLocaleString() : null
    }
    
}