<div class="flex box-container" nz-popover [nzPopoverTitle]="stop.type + ' ' + displayInfo.shipmentId" [nzPopoverContent]="stopInfo" nzPopoverPlacement="right">
    <div class="flex1">
        <span class="right10"><code [class]="['task-type', stop.type]">{{ displayInfo.type }}</code> {{ displayInfo.shipmentId }}</span>
        <span class="sub-title"><img class="icon-detail" src="assets/img/pallet.svg">{{ displayInfo.workload }}</span>
    </div>
    <div class="sub-title" style="align-content: end; align-self: flex-end;">
        <span>{{ displayInfo.mileage }}mi</span>
        <nz-divider nzType="vertical"></nz-divider>
        <span><time-duration [seconds]="displayInfo.time"></time-duration></span>
    </div>
</div>
<ng-template #stopInfo>
    <table>
        <tr>
            <td class="label">Window</td>
            <td>{{displayInfo.window}}</td>
        </tr>
        <tr *ngIf="displayInfo.eta">
            <td class="label">Eta</td>
            <td>{{ displayInfo.eta }}</td>
        </tr>
        <tr>
            <td class="label">GPS</td>
            <td>{{ displayInfo.location?.lat?.toFixed(3) }}, {{ displayInfo.location?.lng?.toFixed(3) }}</td>
        </tr>
    </table>
</ng-template>