<nz-card  class="card-item">
  <div class="flex quote-top">
    <div>
      <div class="shipment-type">{{titleTruck}}</div>
      <div class="shipment-time top10"><img src="/assets/img/av_timer.png" /> {{renderDeliveryDate}}</div>
    </div>
    <div class="truck-img">
      <!-- <img [src]="truckImage" /> -->
      <img *ngFor="let im of truckImages" [class]="im.class" [src]="im.src" [title]="im.title" />
    </div>
  </div>
  <div class="shipment-route top30">
    <div>{{filters?.pickAddr?.state}} → {{filters?.dropAddr?.state}}</div>
    <ng-container *ngIf="quote.isBestMatch">
      <div class="label label-best-match">Best Match</div>
    </ng-container>
    <ng-container *ngIf="quote.isLowestPrice">
      <div class="label label-best-match">Lowest Price</div>
    </ng-container>
    <ng-container *ngIf="quote.isASAP">
      <div class="label label-asap">ASAP Delivery</div>
    </ng-container>
  </div>
  <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 16, lg: 16 }">
    <div nz-col class="gutter-row top20" nzXs="24" nzSm="12" nzMd="24" nzLg="10">
      <div class="price">{{totalCharge}}</div>
    </div>
    <ng-container *ngIf="!isHidenBtnLoadBook">
      <div nz-col class="gutter-row top20" nzXs="24" nzSm="12" nzMd="24" nzLg="14">
        <button class="btn-book-load" nz-button nzType="primary" (click)="onClickBookLoadBtn(quote)">Book Load</button>
      </div>
    </ng-container>
  </div>
  <div class="flex">
    <div>
    </div>
    <div></div>
  </div>
</nz-card>