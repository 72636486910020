import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";

@Component({
  selector: '[finance-value]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class FinanceValue extends BaseComponent {
  @Input() value: any;
}