import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { BaseFormItem } from "@app/admin/base/form-item";
import { ActivatedRoute } from "@angular/router";
import { Utils } from "@services/utils";
import { Const } from "@const/Const";
import { ApiService } from "@services/api.service";

@Component({
  selector: '[filter-performance]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class FilterPerformance extends BaseFormItem {
  @Input() onTimeOption = 'pick';
  @Output() onTimeOptionChange = new EventEmitter<any>();
  @Output() onReload: EventEmitter<any> = new EventEmitter<any>();
  public static get declaration(): FormGroupDeclaration {return {
    clientId: {label: 'Customer', notAcceptEmpty: true, placeHolder: 'Select'},
    day: {label: 'Date', required: true, placeHolder: 'Select', notAcceptEmpty: true},
  }};
  protected formGroupDeclaration: FormGroupDeclaration = FilterPerformance.declaration;

  public filterDayOptions = [
    {name: 'Today', value: 'today'},
    {name: 'Yesterday\'s', value: 'yesterday'},
    {name: 'This week', value: 'thisweek'},
    {name: 'Last week', value: 'lastweek'},
    {name: 'This month', value: 'thismonth'},
    {name: 'Last month', value: 'lastmonth'},
  ];

  public ontimeOptions = [
    {name: 'Pickup On Time', value: 'pick'},
    {name: 'Delivery On Time', value: 'drop'},
  ];

  public loaded: number = 0

  @ViewChild('form') form: ElementRef<HTMLFormElement>;
  constructor(protected activatedRoute: ActivatedRoute) {
    super();
    this.activatedRoute.queryParams.subscribe(p => {
      this.loaded = p.loaded || 0
    })
  }

  ngOnInit(): void {
    let params = Utils.parseQueryStringFromUrl(this.router.url);
    if (params.filter) {
      this.model = JSON.parse(params.filter);
    } else {
      this.model = this.buildDefaultValue();
    }
    super.ngOnInit();
    this.loadData();
  }

  private buildDefaultValue() {
    return {
      clientId: null,
      day: 'today',
    };
  }

  onRefresh() {
    this.onReload.emit()
    this.loadData()
  }

  private buildQuery(): any {
    let q = {}
    let condition: any = this.getFormData_JSON(true);
    const f = JSON.stringify(condition)
    if (f.length > 2) {
      q['filter'] = f
    }
    return q;
  }

  loadData() {
    this.routeWithQueryUrl(this.buildQuery());
  }

  fnOnTimeOptionChange(value) {
    this.onTimeOptionChange.emit(value);
  }

  onChange(value, key) {
    setTimeout(() => {
      this.loadData();
    }, 1); // phải kết thúc hàm onChange thì trong form mới có dữ liệu
  }

  public isExporting = false;

  onBtnExport() {
    this.isExporting = true;
    let condition: any = this.getFormData_JSON(true);
    let filter = JSON.stringify(condition);
    this.api.postExport(`${Const.APIURI_PERFORMANCE}/export`, {filter, download: 1}).subscribe(
      resp => {
        ApiService.handleDownloadResponse(resp);
        this.isExporting = false;
      }, err => {
        this.showErr(err);
        this.isExporting = false;
      }
    );
  }

}
