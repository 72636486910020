<div class="container">
    <div *ngIf="loading">
        <i nz-icon nzType="loading" nzTheme="outline"></i>
    </div>
    <div *ngIf="id !== 'fuel-prices' && config?.active">
        <div class="title">{{ config.active.name }}</div>
        <div class="info">
            <div>
                <i nz-icon nzType="schedule" nzTheme="outline"></i> <span class="label">Effective from</span> {{
                formatDate(config.active.effectiveDate) }} <span class="label">to</span> {{
                formatDate(config.active.expirationDate) }}
            </div>
        </div>
        <div *ngIf="config.active?.value && config.active?.value?.type !== 'TABLE'">
            <div finance-value [value]="config.active.value"></div>
        </div>
        <div *ngIf="config.active?.value?.type === 'TABLE'">
            <div map-value [value]="config.active.value"></div>
        </div>
    </div>
    <div *ngIf="id === 'fuel-prices'">
        <div class="title">Fuel Prices</div>
        <div *ngIf="config?.active">
            <div class="info">
                <div><i nz-icon nzType="schedule" nzTheme="outline"></i> <span class="label">Last Updated</span> {{
                    formatDate(config.active.createdTs) }}</div>
            </div>
            <nz-table [nzSize]="'small'" [nzShowPagination]="false" [nzPageSize]="20" #basicTable
                [nzData]="config.active.dpg | keyvalue">
                <thead>
                    <tr>
                        <th nzCustomFilter>Name</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of basicTable.data">
                        <td>{{data.key}}</td>
                        <td>{{data.value}}</td>
                    </tr>
                </tbody>
            </nz-table>
        </div>
    </div>
</div>