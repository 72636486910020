import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BaseComponent } from '@abstract/BaseComponent';
import { ZipcodeService } from '@services/zipcode.service';

@Component({
  selector: '[zipcode-selector]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss',]
})
export class ZipcodeSelector extends BaseComponent {

    public isLoading = false
    public zipcode
    public zipcodeInfo: any = {}
    public isValid = false
    zipcodeService
    @Input() size: string = 'md'
    @Input() placeholder
    @Output() onNewValue: EventEmitter<any> = new EventEmitter<any>();

    constructor() {
        super();
        this.zipcodeService = new ZipcodeService(this.api)
    }
    
    ngOnInit(): void {
    }

    onBookLoad(data) {
    }

    onBtnBackPreviousStep(event) {
    }

    onInputChanged(event) {
        let text = event.target.value;
        // text = text.replace(/[^0-9]/g, '');
        text = text.trim()
        if (text.length == 0) {
          text = null;
          this.onNewValue.emit(null);
          return
        }
        if (text && text.length > 1) {
          if (text.length == 0) {
            text = '0';
          }
        }
        if (text != event.target.value) {
          event.target.value = text;
        }
        this.zipcode = text.replace(' ', '')
        this.isValid = this.validateZipcode(text)
        if (this.isValid) {
            this.loadZipcode()
            // this.onNewValue.emit({zipcode: this.zipcode})
        } else {
            this.zipcodeInfo = {}
            this.onNewValue.emit(null)
        }
    }

    validateZipcode(z) {
      return this.validateUsZipCode(z) || this.validateCaZipCode(z)
    }

    validateUsZipCode(z) {
      const pattern = /^\d{5}$/
      return pattern.test(z)
    }

    validateCaZipCode(z) {
      const pattern = /^[ABCEGHJKLMNPRSTVXYW]\d[ABCEGHJKLMNPRSTVXYW] ?\d[ABCEGHJKLMNPRSTVXYW]\d$/
      return pattern.test(z)
    }

    loadZipcode() {
        this.zipcodeService.get(this.zipcode, resp => {
            console.log(resp)
            this.zipcodeInfo.zipcode = this.zipcode;
            this.zipcodeInfo.city = resp.city;
            this.zipcodeInfo.state = resp.state;
            this.zipcodeInfo.desc = `${resp.city}, ${resp.state}`;
            this.zipcodeInfo.altCities = resp.altCities ?? [];
            this.zipcodeInfo.isLoading = false;
            this.onNewValue.emit({
                zipcode: this.zipcode,
                city: resp.city,
                state: resp.state
            })
          }, err => {
            this.zipcodeInfo.isLoading = false;
            this.zipcodeInfo.error = true;
            this.zipcodeInfo.desc = 'Invalid zip code'
          })
    }
    onCityChange() {
      this.onNewValue.emit({
        zipcode: this.zipcode,
        city: this.zipcodeInfo?.city,
        state: this.zipcodeInfo?.state
      })
    }
}