<div *ngIf="response?.status === 'BOOKED'" class="top15">
    This quote request has been booked. <button *ngIf="response.metadata?.warpId" ngSize="small" nz-button (click)="viewBookedOrder()">View Order</button>
</div>

<div *ngIf="!options?.length">
    No result found.
</div>

<div nz-row [nzGutter]="{ xs: 16, sm: 16, md: 16, lg: 16 }">
    <div *ngFor="let option of options" nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="12" >
        <div quote-rate
            [booked]="response.metadata?.bookedOption === option.option.id"
            [request]="request" [shipment]="shipment"
            [quote]="option" [response]="response"
            [onBookComplete]="onBookComplete"
            [class]="{box: true, 'booked': response.metadata?.bookedOption === option.option.id}"></div>
    </div>
</div>