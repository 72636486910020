<ng-container>
  <div class="flex">
    <div class="shipment-type flex1">
      <span [ngClass]="{'selected-type': shipmentType == 'FTL'}" (click)="changeShipmentType('FTL')">Truck Load</span>
      |
      <span [ngClass]="{'selected-type': shipmentType == 'LTL'}" (click)="changeShipmentType('LTL')">Less Than Truckload</span>
    </div>  
    <div>
      <a [routerLink]="[routeAdminFreightQuoteFtl, 'history']">
        <span class="button right">History <i nz-icon nzType="right"></i></span>
      </a>
    </div>
  </div>
  <div nz-row [nzGutter]="{md:32, xs: 16}">
    <div nz-col nzMd="8" nzXs="24" nzSm="24">
      <div class="label">Customer<span *ngIf="shipmentType === 'LTL'" class="label-mark-required"></span></div>
      <div client-selector (onNewValue)="onSelectClient($event)"></div>
      <!-- <select-by-searching
            [(listData)]="clients"
            placeholder="Select Customer"
            (modelChange)="clientModelChange($event)"
        ></select-by-searching>     -->
    </div>
    <div nz-col nzMd="8" nzXs="24" nzSm="12">
      <div class="label">Origin Location<span class="label-mark-required"></span></div>
      <div zipcode-selector placeholder="Pickup Zipcode" (onNewValue)="onPickupZipcodeChange($event)"></div>        
    </div>
    <div nz-col nzMd="8" nzXs="24" nzSm="12">
      <div class="label">Destination Location<span class="label-mark-required"></span></div>
      <div zipcode-selector placeholder="Delivery Zipcode" (onNewValue)="onDropoffZipcodeChange($event)"></div>  
    </div>
  </div>

    <div *ngIf="shipmentType == 'FTL'">
      <div nz-row [nzGutter]="{md:32, xs: 16}">
        <div nz-col nzMd="8" nzXs="24" nzSm="24">
          <div class="label">Pickup Date</div>
          <div pick-date #pickDate placeholder="Requested pickup date" (onDone)="onSelectPickupDate($event)"></div>
        </div>
        <div nz-col nzMd="8" nzXs="24" nzSm="24">
          <div class="label">Vehicle<span class="label-mark-required"></span></div>
          <div vehicle-selector (valueChange)="onVehicleTypeChange($event)" [quoting]="true"></div>    
        </div>
      </div>
    </div>

    <div *ngIf="shipmentType == 'LTL'">
      <div nz-row [nzGutter]="{md:32, xs: 16}">
        <div nz-col nzMd="8" nzXs="24" nzSm="24">
          <div class="label">Pickup Date<span class="label-mark-required"></span></div>
          <div pick-date #pickDate placeholder="Requested pickup date" (onDone)="onSelectPickupDate($event)"></div>
        </div>
        <div nz-col nzMd="8" nzXs="24" nzSm="12">
          <div class="label">Pickup Options</div>
          <div service-option #serviceOptionsComponent preffixIcon="/assets/img/pickup_ic.png" [options]="pickupOptions" placeholder="Pickup Services" (onDone)="onSerivceOptionsBtnDone($event, 'pickup')"></div>  
        </div>
        <div nz-col nzMd="8" nzXs="24" nzSm="12">
          <div class="label">Delivery Options</div>
          <div service-option #serviceOptionsComponent preffixIcon="/assets/img/pickup_ic.png" [options]="deliveryOptions" placeholder="Delivery Services" (onDone)="onSerivceOptionsBtnDone($event, 'delivery')"></div>  
        </div>
      </div>

      <div>
        <div class="label">
          Shipping Items<span class="label-mark-required"></span>
          <span class="left10 small-button" (click)="onAddNewItem('Pallet')">
            <i nz-icon nzType="plus" nzTheme="outline"></i>
            Pallets
          </span>
        </div>
        <div style="max-width: 800px;">
          <div *ngIf="items && items.length > 0 " nz-row [nzGutter]="{md:8, xs: 2}" >
            <div nz-col nzXs="1"></div>
            <div nz-col nzXs="3" nzSm="0" class="label">Qty<span class="label-mark-required"></span></div>
            <div nz-col nzXs="0" nzSm="3" class="label">Quantity<span class="label-mark-required"></span></div>
            <div nz-col nzXs="0" nzSM="4" class="label">Packaging</div>
            <div nz-col nzXs="3" nzSM="0" class="label">Pkg</div>
            <div nz-col nzXs="3" class="label">Length<span class="label-mark-required"></span></div>
            <div nz-col nzXs="3" class="label">Width<span class="label-mark-required"></span></div>
            <div nz-col nzXs="3" class="label">Height<span class="label-mark-required"></span></div>
            <div nz-col nzXs="3" class="label">Per Pallet Weight<span class="label-mark-required"></span></div>
            <div nz-col nzXs="3" class="label">Total Weight</div>
          </div>
          <div *ngFor="let item of items">
            <div quote-item style="border-top: solid 1px #efefef" [item]="item" (onDelete)="onDeleteItem($event)" (onUpdate)="onUpdateItem($event)"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="button-search bottom30">
      <button style="width: 100%; padding: 19px 15px; height: 65px;" 
        nz-button nzSize="large" class="" (click)="onBtnSearch()" [disabled]="isLoading || invalid">
        <ng-container *ngIf="isLoading">
          <i nz-icon nzType="loading" nzTheme="outline"></i>
        </ng-container>
        Search
      </button>
    </div>
</ng-container>