<div [class]="size">
    <ng-template #tplIconLocation><i nz-icon nzType="environment"></i></ng-template>
    <ng-template #tplInfo>
      <i *ngIf="isLoading" nz-icon nzType="loading" nzTheme="outline"></i>
      <ng-container *ngIf="!zipcodeInfo?.altCities?.length">
        <div *ngIf="!isLoading && zipcodeInfo?.desc">{{zipcodeInfo.desc}}</div>
      </ng-container>
      <ng-container *ngIf="zipcodeInfo?.altCities?.length">
        <div class="inline">
          <select [(ngModel)]="zipcodeInfo.city" (ngModelChange)="onCityChange()">
            <option *ngFor="let city of zipcodeInfo.altCities" [label]="city + ', ' + zipcodeInfo.state" [value]="city"></option>
          </select>
      </div>
      </ng-container>
    </ng-template>
    <nz-form-control>
      <nz-input-group [nzPrefix]="tplIconLocation" [nzSuffix]="tplInfo">
        <input nz-input
          [ngClass]="{'validation-error': !isValid, 'invalid': !isValid, 'valid': isValid}"
          [placeholder]="placeholder"
          (input)="onInputChanged($event)"
        >
      </nz-input-group>
    </nz-form-control>
  </div>