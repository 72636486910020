import { Component, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, ViewChildren } from "@angular/core";
import { Route, Stop } from "./route";
import { BaseComponent } from "@abstract/BaseComponent";
import { StopListItem } from "./stop_list_item";
import { Const } from "@const/Const";

@Component({
    selector: '[route-order]',
    templateUrl: './route_order.html',
    styleUrls: ['./styles.scss', './route_order.scss']
})
export class RouteOrder extends BaseComponent {
    @Input() route: Route
    movingMode: boolean = false
    movingStop: Stop = null

    @Output() onChangeOrder: EventEmitter<any> = new EventEmitter<any>();
    @Output() onHover: EventEmitter<any> = new EventEmitter<any>();

    @ViewChildren('listItems') listItems: QueryList<StopListItem>;

    validateError(): string|null {
      for (let i = 0; i < this.listItems.length; i++) {
        const item = this.listItems.get(i);
        if (item.type == Const.TaskType.PICKUP && item.timeWindow == null) {
          // Bắt buộc phải có PICKUP time, DROPOFF có thể không có cũng được
          return `${item.name} has no time window`;
        }
      }
      return null;
    }

    triggerMove(stop) {
        this.movingMode = true
        this.movingStop = stop
    }

    cancelMoving() {
        this.movingMode = false
        this.movingStop = null
    }

    moveToTop() {
        const orders = []
        orders.push(this.movingStop.info.id)
        for (let stop of this.route.stops) {
            if (stop.info.id === this.movingStop.info.id) continue
            orders.push(stop.info.id)
        }
        this.onChangeOrder.emit(orders)
        this.cancelMoving()
    }

    moveStopToAfter(stop: Stop) {
        if (stop.info?.id === this.movingStop?.info?.id) return
        // calculate new order
        const orders = []
        let corressponding: any = null
        for (let s of this.route.stops) {
            if (s.info.warpId === this.movingStop?.info?.warpId && s.info?.id != this.movingStop?.info?.id) {
                corressponding = s.info?.id
            }
            if (s.info.id !== this.movingStop.info.id) {
                orders.push(s.info.id)
                if (s.info.id === stop.info.id) {
                    orders.push(this.movingStop.info.id)
                }    
            }
        }

        // check eligibility
        const ind = orders.indexOf(this.movingStop?.info?.id)
        if (this.movingStop.info.type === 'PICKUP') {
            if (corressponding && ind > orders.indexOf(corressponding)) {
                this.showErr('Cannot move Pickup Stop to after its corresponding Dropoff Stop')
                return
            }
        }
        if (this.movingStop.info.type === 'DROPOFF') {
            if (corressponding && ind < orders.indexOf(corressponding)) {
                this.showErr('Cannot move Dropoff Stop to before its corresponding Pickup Stop')
                return
            }
        }

        this.onChangeOrder.emit(orders)
        this.cancelMoving()
    }
}