import { Component, forwardRef, Inject, Injector, Input, OnInit, Self } from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  FormControlName,
  NgControl,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
import { BaseInputComponent } from "@app/admin/components/forms/base-custom-input";
import { Const } from "@const/Const";
import { ApiService } from "@services/api.service";
import { TransferItem, TransferSelectChange, TransferChange } from "ng-zorro-antd/transfer";

import _ from "underscore";
const MATCHING_TYPE = {
  0: {
    color: "warning",
    label: "Unmatched",
  },
  1: {
    color: "success",
    label: "Matched",
  },
};

@Component({
  selector: "pool-transfer-carrier-input",
  templateUrl: "./transfer-carrier-input.component.html",
  styleUrls: ["./transfer-carrier-input.component.scss"],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => PoolTransferCarrierInputComponent), multi: true },
  ],
})
export class PoolTransferCarrierInputComponent extends BaseInputComponent {
  public selected: string[] | number[] = [];
  private matchedIds: string[] = [];
  public api;
  list: TransferItem[] = [];

  @Input()
  public isLoading?: boolean = true;


  constructor(@Inject(Injector) protected injector: Injector, api: ApiService) {
    super(injector);
    this.api = api;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.onLoadData();
  }

  get value() {
    return this.selected
  }

  //override
  set value(value: any) {
    this.selected = value;
    //seclected Data
    this.updateSelectedOnGUI();
    this.onChange(this.selected);
    this.onTouch(this.selected);
  }

  updateSelectedOnGUI() {
    let forceList = this.list.map((item) => {
      item.direction = this.value?.includes(item.key) ? "right" : "left"
      return item
    });
    this.list=[...forceList]
    // console.log('updateSelectedOnGUI',this.selected);
  }

  get error() {
    const errors = this.control?.errors;
    if (!errors || Object.keys(errors).length == 0) return;
    const firstKey = Object.keys(errors)[0];
    return _.get(errors, [firstKey, "en"]) || errors[firstKey];
  }

  writeValue(value: any): void {
    if (!value || value.length == 0) return;
    this.value = value || [];
  }

  /**
   * Load carrier list on init.
   */
  onLoadData() {
    this.isLoading = true;

    this.api.GET(`${Const.APIURI_CARRIERS}/list/all_for_bid`).subscribe((response) => {
      const carriers = _.get(response, ["data", "list_data"], []);
      this.list = carriers.map((carrier) => ({
        key: carrier.id,
        title: carrier.basicInfo?.name,
        status: carrier.status,
        mc:carrier.basicInfo?.mc,
        dot:carrier.basicInfo?.dot,
      }));
      this.updateCarrierList();
      this.isLoading = false;
    });
  }

  /**
   * Update selected value when click transfer button on template
   * @param ret : TransferChange - Information of data records has changed.
   */
  change(ret: TransferChange): void {
    const listKeys = ret.list.map((l) => l.key);
    if (ret.from == "left") {
      //selected new items
      this.value = [...(this.value || []), ...listKeys];
    } else {
      //remove selected items
      this.value = this.value.filter((key) => !listKeys.includes(key));
    }
  }

  /**
   * Cập nhật lại carrier list khi list update hoặc matchedList update.
   * Thay đổi trạng thái của từng carrier trong list
   * Sắp xếp lại Carrier theo thứ tự: matched, status, name
   * Auto selected các matched carrier
   */
  updateCarrierList({ updateMatchedIds } = { updateMatchedIds: false }) {
    this.list.map((item) => {
      item.tag = this.matchedIds.indexOf(item.key);
    });

    //sort by tag desc, status desc, title asc
    this.list = _(this.list)
      .chain()
      .sortBy(function (patient) {
        return patient.title;
      })
      .sortBy(function (patient) {
        let status = 0;
        switch (patient.status) {
          case Const.CarrierStatus.active: return 0;
          case Const.CarrierStatus.temporary: return 1;
          case Const.CarrierStatus.pending: return 2;
          case Const.CarrierStatus.rejected: return 3;
          default: return 9
        }
      })
      .sortBy(function (patient) {
        return patient.tag * -1; //reverse sort => sort tag desc
      })
      .value();

    //auto selected matchedIds
    this.updateSelectedOnGUI();
  }
  //thay regex fix lỗi kg search dc special character
  filterCarrier(inputValue: string = '', item: any): boolean {
    inputValue = (inputValue ?? '').toLowerCase();
    let title = (item?.title ?? '').toLowerCase();
    let mc = (item?.mc ?? '').toLowerCase();
    let dot = (item?.dot ?? '').toLowerCase();
    return title.includes(inputValue) || mc.includes(inputValue) || dot.includes(inputValue);
  }

  //cast function in template.
  $asTransferItems = (data: unknown): TransferItem[] => data as TransferItem[];

  $asCarrierStatusText = (status) => {
    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const statusKey = Object.keys(Const.CarrierStatus).filter((key) => Const.CarrierStatus[key] == status)[0] || "";
    return capitalizeFirstLetter(statusKey);
  };

  $asCarrierMatchingType = (matchingTypeNumber: number) => {
    //matchingTypeNumber !=-1 là có index trong matching. tức là có match. index ==-1 là không match
    return MATCHING_TYPE[matchingTypeNumber != -1 ? 1 : 0];
  };
}
