<h3>
    <span class="right10">Route Bid for <code>{{ bid?.jobCode || _job.code }}</code></span>
    <a style="font-size: 0.8em;" *ngIf="bid" [routerLink]="[routeAdminCarrierSales, bid.id]" target="_blank">View Detail</a>
    <span style="font-size: 0.8em;" *ngIf="loadingBid"><i nz-icon nzType="loading" nzTheme="outline"></i></span>
</h3>

<ng-container *ngIf="loadingBid">
    <div class="container loading-container">
        <i nz-icon nzType="loading" nzTheme="outline"></i>
    </div>
</ng-container>

<ng-container *ngIf="!style || style == 'full'">
    <div class="flex top-pannel">
        <div class="flex2">
            <ng-container *ngTemplateOutlet="locationInfo"></ng-container>
        </div>
        <div class="flex1">
            <ng-container *ngTemplateOutlet="bidSettings"></ng-container>
        </div>
        <div class="flex1">
            <ng-container *ngTemplateOutlet="equipments"></ng-container>
        </div>
    </div>
        
    <div class="flex">
        <div class="left-pannel">
            <ng-container *ngTemplateOutlet="bidSummary"></ng-container>
        </div>
        <div class="flex1">
            <ng-container *ngTemplateOutlet="carrierList; context: {$implicit: shownCarriers}"></ng-container>
        </div>
    </div>
</ng-container>


<!-- components -->

<ng-template #equipments>
    <div class="bottom5 enabled-settings">
        <img class="truck1 right5" style="width: 16px;" />
        Equipments
    </div>
    <ng-container *ngIf="bid">
    <div *ngFor="let vehicle of bid.vehicles" class="bottom5">
        <div class="enabled-settings">{{ vehicle.name }}</div>
        <div *ngIf="vehicle.options?.length" style="padding-left: 10px">
            <span class="right5 option">w/</span>
            <span *ngFor="let opt of vehicle.options" class="right3 option">
                <normalized-string [str]="opt"></normalized-string>
            </span>
        </div>
    </div>
    </ng-container>
</ng-template>

<ng-template #bidSettings>
    <div class="bottom5">
        <span class="right5" nz-icon nzType="control" nzTheme="outline"></span>
        <span class="enabled-settings" *ngIf="bid?.type">
            <ng-container *ngIf="bid?.type == 1">Manual Review</ng-container>
            <ng-container *ngIf="bid?.type == 2">Direct Bid</ng-container>
        </span>
        <span *ngIf="!bid?.type" class="disabled-settings">Bidding Mode</span>
    </div>
    <div class="bottom5">
        <span *ngIf="bid?.isAllowPlaceBid" class="enabled-settings">
            <span class="right5" nz-icon nzType="check" nzTheme="outline"></span>
            Carrier can place bid
        </span>
        <span *ngIf="!bid?.isAllowPlaceBid" class="disabled-settings">
            <span class="right5" nz-icon nzType="minus" nzTheme="outline"></span>
            Carrier cannot place bid
        </span>
    </div>
    <div class="bottom5">
        <span *ngIf="bid?.isShowBasePrice" class="enabled-settings">
            <span class="right5" nz-icon nzType="check" nzTheme="outline"></span>
            Show WARP Offer
        </span>
        <span *ngIf="!bid?.isShowBasePrice" class="disabled-settings">
            <span class="right5" nz-icon nzType="minus" nzTheme="outline"></span>
            Show WARP Offer
        </span>
    </div>
    <div *ngIf="bid?.basePrice" class="enabled-settings bottom5">
        <span class="right5" nz-icon nzType="dollar" nzTheme="outline"></span>
        Base Price: ${{ bid.basePrice }}
    </div>
</ng-template>

<ng-template #locationInfo>
    <table>
        <tr>
            <td style="vertical-align: top;">
                Pickup
            </td>
            <td>
                <div>
                    <span class="right10">
                        <span class="right3" nz-icon nzType="clock-circle" nzTheme="twotone"></span>
                        <time-window-str  *ngIf="job?.pickDt" [data]="{from: job.pickDt.time, to: job.pickDt.timeTo, tz: job.pickDt.timezone}"></time-window-str>
                    </span>
                </div>
                <div class="bottom5">
                    <span class="right3" nz-icon nzType="environment" nzTheme="twotone"></span>
                    <address-str *ngIf="bid?.pickupAddress" [data]="{address: bid.pickupAddress}"></address-str>
                </div>                
            </td>
        </tr>
        <tr>
            <td style="vertical-align: top;">
                <span class="right5">Dropoff</span>
            </td>
            <td>
                <div>
                    <span class="right10">
                        <span class="right3" nz-icon nzType="clock-circle" nzTheme="twotone" [nzTwotoneColor]="'#F8C60D'"></span>
                        <time-window-str *ngIf="job?.dropDt" [data]="{from: job.dropDt.time, to: job.dropDt.timeTo, tz: job.dropDt.timezone}"></time-window-str>
                    </span>
                </div>
                <div>
                    <span class="right3" nz-icon nzType="environment" nzTheme="twotone" [nzTwotoneColor]="'#F8C60D'"></span>
                    <address-str *ngIf="bid?.dropoffAddress" [data]="{address: bid.dropoffAddress}"></address-str>
                </div>                
            </td>
        </tr>
    </table>
</ng-template>

<ng-template #carrierList let-carriers>
    <div style="min-height: 200px;">
    <nz-table #carrierTable nzBordered="true" [nzData]="carriers" [nzPageSize]="8" nzSize="small" [nzShowPagination]="carriers.length > 8">
        <thead>
            <tr>
                <th nzWidth="400px">Carrier</th>
                <th nzWidth="140px">Placed Bid</th>
                <th>Rate</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let carrier of carrierTable.data" [class]="carrier.carrierId == accepted?.carrierId ? 'accepted': 'normal'">
                <td>{{ carrier.carrierName }}</td>
                <td>
                    <date-time-str [data]="{time: carrier.when}"></date-time-str>
                </td>
                <td>
                    <span *ngIf="carrier.price" class="right5 font-medium">${{ carrier.price || '' }}</span>
                    <span *ngIf="carrier.carrierId == accepted?.carrierId">
                        <span style="color: green;" nz-icon nzType="check" nzTheme="outline"></span>
                    </span>
                    <span *ngIf="carrier.state == 1">
                        <span nz-icon nzType="close" nzTheme="outline" style="color: red;"  nz-tooltip nzTooltipTitle="Refused"></span>
                    </span>
                </td>
                <td>
                    <div *ngIf="!accepted && carrier.price">
                        <span class="clickable" (click)="onBtnAccept(carrier)" >Accept</span>
                    </div>
                </td>
            </tr>
        </tbody>
    </nz-table>
    </div>
</ng-template>

<ng-template #bidSummary>
    <ng-container *ngIf="bid">
        <div>
            <span class="font-medium">{{ _carriers.length }}</span> Carriers
        </div>
        <div class="bottom10">
            <div>
                <span class="font-medium">{{ placedCount || 0 }}</span> Bids Placed
            </div>
            <div>
                <span class="clickable small" (click)="toggleShowCarriers()">
                    <span *ngIf="showAll">(Showing all)</span>
                    <span *ngIf="!showAll">(Showing bids placed only)</span>
                </span>
            </div>
        </div>
        <div *ngIf="bid.metadata.refusedCount">
            {{ bid.metadata.refusedCount }} Refuses
        </div>
        <div *ngIf="accepted">
            <div>
                Accepted Bid:
                <span class="right5" nz-icon nzType="dollar" nzTheme="outline"></span>
                <span class="font-medium">{{ accepted.price ?? accepted.basePrice ?? 0.0 }}</span>
            </div>
            <div>
                Carrier: <span class="font-medium">{{ accepted.carrierName }}</span>
            </div>
        </div>
    </ng-container>
</ng-template>