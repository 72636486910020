<div class="box-container" (mouseenter)="onHoverRoute.emit(route)" (mouseleave)="onHoverRoute.emit(null)" (click)="onClick?.emit(_route)">
    <div class="route-info">
        <div class="flex">
            <div class="flex1 title">{{ displayInfo.vehicleType }}</div>
            <div class="center" *ngIf="displayInfo.workload">
                <img class="icon-detail" src="assets/img/pallet.svg">
                {{ displayInfo.workload }}/{{ displayInfo.capacity }}
            </div>
            <div class="flex1"  style="text-align: right; align-content: right;">
                <nz-switch [(ngModel)]="_route.display" nzSize="small" (ngModelChange)="onToggleShowRouteOnMap()"></nz-switch>
            </div>
        </div>
        <div>
            {{ displayInfo.shipmentCount }} <span class="sub-title">shipments: </span>
            <code class="right5 sub-title" *ngFor="let s of shipments">{{ s.warpId }}</code>
        </div>
        <div class="flex">
            <div class="flex1">{{ displayInfo.stopCount }} <span class="sub-title">stops</span></div>
            <div *ngIf="displayInfo.earliestWindow" nz-tooltip nzTooltipTitle="Earliest Dropoff Time">
                <span class="right5 sub-title" nz-icon nzType="clock-circle" nzTheme="outline"></span>
                <span class="sub-title">{{ displayInfo.earliestWindow }}</span>
            </div>    
        </div>
        <div class="flex sub-title">
            <div nz-tooltip nzTooltipTitle="Total Mileage">
                <span nz-icon nzType="node-index" nzTheme="outline"></span>
                {{ displayInfo.mileage }}mi
            </div>
            <nz-divider nzType="vertical"></nz-divider>
            <div nz-tooltip nzTooltipTitle="Driving Time">
                <span class="right3" nz-icon nzType="car" nzTheme="outline"></span>
                <time-duration [seconds]="displayInfo.travelTime"></time-duration>
            </div>
            <nz-divider nzType="vertical"></nz-divider>
            <div nz-tooltip nzTooltipTitle="Service Time" style="color: #888;">
                <span class="right3" nz-icon nzType="user-switch" nzTheme="outline"></span>
                <time-duration [seconds]="displayInfo.extraTime"></time-duration>
            </div>
        </div>
        <div class="flex">
            <div class="flex1">
                <button nz-button nzSize="small" (click)="onExportBtn($event)">Export</button>
            </div>
            <div>
                <button nz-button nzSize="small" (click)="toggleFull($event)">
                    <span *ngIf="!full">View <span nz-icon nzType="down" nzTheme="outline"></span></span>
                    <span *ngIf="full">Hide <span nz-icon nzType="up" nzTheme="outline"></span></span>
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="full">
        <div *ngFor="let stop of route.stops" class="stop-info" (mouseenter)="onHoverStop.emit(stop)" (mouseleave)="onHoverStop.emit(null)">
            <div planning-stop-detail [stop]="stop"></div>
        </div>
    </div>
</div>