import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { DataorchService } from "@services/dataorch.service";
import { PlanningService } from "@services/planning.service";
import _ from "underscore";

@Component({
    selector: '[route-card-compact]',
    templateUrl: './index.html',
    styleUrls: ['./index.scss']
})
export class RouteCardCompact extends BaseComponent {
    dataorch: DataorchService
    planning: PlanningService
    
    _data: {
        id?: string,
        job?: any,
        tasks?: any[]
    } = {}
    loading: boolean = false

    @Input() map
    
    @Input() set data(v: {id?: string, job?: any, tasks?: any[]}) {
        this._data = v
        if (!v.job && v.id) {
            this.loadJob()   
        }
        if (!v.tasks && v.id) {
            this.loadJobTasks()   
        }
    }

    loadJob() {
        this.planning.getJob(this._data.id).subscribe((res) => {
            this._data.job = res
        })
    }

    loadJobTasks() {
        this.planning.loadJobTasks(this._data.id).subscribe((res) => {
            this._data.tasks = res
        })
    }

    constructor() {
        super()
        this.dataorch = new DataorchService(this.api)
        this.planning = new PlanningService(this.api)
    }
}