<div [class]="['stop-list-item', stop.info.type, moving ? 'moving' : 'static']" *ngIf="stop.info">
    <div class="dot type"></div>
    <div class="line" *ngIf="!last"></div>
    <div class="item-header">
        <div>
            <code (click)="copyWarpId()" class="type">{{ name }}</code>
        </div>
        <div style="font-size: 0.8em; margin-top: 3px;">
            <i nz-icon nzType="clock-circle" nzTheme="outline" class="right5"></i>
            <code *ngIf="timeWindow" style="color: #666;">{{timeWindow}}</code>
            <code *ngIf="!timeWindow" class="danger">No time window</code>
        </div>
        <div class="right-button">
            <span (click)="onTriggerMove.emit()" *ngIf="!movingMode">
                <span nz-icon nzType="border-verticle" nzTheme="outline"></span>
            </span>
            <span (click)="onCancelMoving.emit()" *ngIf="moving">
                Cancel
            </span>
        </div>
    </div>
    <div class="address" *ngIf="!stop.costToNext || stop.costToNext?.distance">
        <div>
            {{ stop.info.addr.street }}
        </div>
        <div>
            {{ stop.info.addr.city }}, {{ stop.info.addr.state }} {{ stop.info.addr.zipcode }}
        </div>
    </div>
    <div *ngIf="stop.costToNext?.distance" class="cost-box"  >
        <div class="line"></div>
        <span class="cost">
        {{ (stop.costToNext.distance / 1609).toFixed(1) }} mi, {{ (stop.costToNext.time / 3600).toFixed(1) }} h
        </span>
    </div>
    <div *ngIf="movingMode && !moving" class="moving-location" (click)="onMoveAfterMe.emit()">
        <div class="arrow"></div>
    </div>
</div>