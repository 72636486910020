<div class="dashboard-child-container no-padding list">
  <div class="list-header small">
    <div filter-performance #filterPerformance [(onTimeOption)]="onTimeOption" (onTimeOptionChange)="onTimeOptionChange($event)"></div>
  </div>

  <div nz-row class="performance-body">
    <div nz-col nzXs="24" nzMd="6" class="performance-chart">
      <ng-container *ngIf="shouldShowChart">
        <ng-container *ngFor="let key of ['pick', 'drop']">
          <ng-container *ngTemplateOutlet="tplChart; context: {data: dataChart[key], key}"></ng-container>
        </ng-container>
      </ng-container>
    </div>
    <ng-template #tplChart let-data="data" let-key="key">
      <div>
        <!-- <div class="flex data-chart-number">
          <div class="chart-label">Total:</div><div class="flex1">{{data.total}}</div>
        </div>
        <div class="flex data-chart-number">
          <div class="chart-label">On Time:</div><div class="flex1">{{data.ontime}}</div>
        </div>
        <div class="flex data-chart-number">
          <div class="chart-label">Late:</div><div class="flex1">{{data.late}}</div>
        </div>
        <div class="flex data-chart-number">
          <div class="chart-label">Not Start:</div><div class="flex1">{{data.notstart}}</div>
        </div> -->
        <div class="f18b">{{data.total}} {{key == 'pick' ? 'PICKUPs' : 'DROPOFFs'}}</div>
        <div *ngIf="data.chartOptions" chart-pie id="chart-data-{{key}}" [options]="data.chartOptions"></div>
      </div>
    </ng-template>

    <div nz-col nzXs="24" nzMd="18" class="performance-table">
      <nz-table
        #nzTable nzBordered="true"
        [nzData]="listData"
        [nzTotal]="totalCount"
        [nzFrontPagination]="true"
        [nzHideOnSinglePage]="true"
        (nzCurrentPageDataChange)="onCurrentPageDataChange($event)"
        [nzPageSize]="10"
        [(nzPageIndex)]="pageIndex"
        [nzLoading]="isLoading"
        [nzLoadingIndicator]="tplLoading"
        [nzNoResult]="tplNoData"
        [nzSize]="'default'">
        <ng-template #tplNoData>
          <div *ngIf="!isLoading" class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
        </ng-template>
        <ng-template #tplLoading>
          <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
        </ng-template>
        <thead>
          <tr>
            <th nzWidth="120px">WARP ID</th>
            <th>Customer</th>
            <th>Pick up</th>
            <th>Drop off</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let data of currentPageData; let i = index">
            <ng-container *ngFor="let item of mapOfExpandedData[data.key]">
              <tr *ngIf="(item.parent && item.parent.expand) || !item.parent">
                <td class="col-first"
                  [nzIndentSize]="item.level*10"
                  [nzShowExpand]="false"
                  [(nzExpand)]="item.expand"
                  (nzExpandChange)="onExpandChange(mapOfExpandedData[data.key], item, $event)">
                  <div class="shipment-right-icons">
                    <a [routerLink]="getRouterLink(item)" [fragment]="getRouterLinkFragment(item)">{{showShipmentWarpId(item.warpId)}}</a>
                    <ng-container *ngIf="item.level==0">
                      <i *ngIf="item.deltaDelayTime" style="margin-left:6px"
                      nz-icon nzType="warning" nzTheme="outline" class="warning"
                      nz-tooltip [nzTooltipTitle]="item.deltaDelayTime"></i>
                      <i nz-icon [nzType]="item.expand ? 'up' : 'down'" nzTheme="outline" 
                        *ngIf="item.children.length>0" style="margin-left:6px"
                        class="clickable" (click)="toggleChildren(data, item)"></i>
                    </ng-container>
                  </div>
                </td>
                <td>{{item.client?.name}}</td>
                <td>
                  <div>{{item.addrPick}}</div>
                  <div *ngIf="item.timePick" class="grey">Expected: {{item.timePick}}</div>
                  <div *ngIf="item.timePickActual" class="grey">Actual: {{item.timePickActual}}</div>
                  <div *ngIf="item.pickLate && !isCanceledOrderStatus(item)" class="danger">Late: {{showLateMinutes(item.pickLate)}}</div>
                </td>
                <td>
                  <div>{{item.addrDrop}}</div>
                  <div *ngIf="item.timeDrop" class="grey">Expected: {{item.timeDrop}}</div>
                  <div *ngIf="item.timeDropActual" class="grey">Actual: {{item.timeDropActual}}</div>
                  <div *ngIf="item.dropLate && !isCanceledOrderStatus(item)" class="danger">Late: {{showLateMinutes(item.dropLate)}}</div>
                </td>
                <td>
                  <div *ngIf="shouldShowStatus(item)" class="shipment order-status {{'status-' + getStatusValue(item)}}">
                    {{showStatusValue(item)}}
                  </div>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </nz-table>
    </div>
  </div>
</div>

