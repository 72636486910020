
<nz-tabset nzCentered [nzAnimated]="false" (nzSelectChange)="onTabChange()">
    <nz-tab [nzTitle]="titleTemplate1">
        <ng-template #titleTemplate1>
            Today - Next Working day
            <nz-badge nzStandalone [nzStyle]="{ backgroundColor: '#221ac4' }" [nzCount]="next2days.length"></nz-badge>
        </ng-template>
        <nz-tabset nzCentered [nzAnimated]="false" (nzSelectChange)="onTabChange()">
            <nz-tab *ngFor="let bucket of next2daysBuckets" [nzTitle]="bucketTitleTemplate">
                <ng-template #bucketTitleTemplate>
                    {{ bucket.name }}
                    <nz-badge nzStandalone [nzStyle]="{ backgroundColor: '#221ac4' }" [nzCount]="bucket.shipments.length"></nz-badge>
                </ng-template>
                <!-- <ng-template nz-tab> -->
                    <!-- <planning-shipment [shipments]="bucket.shipments"></planning-shipment> -->
                <!-- </ng-template> -->
                <ng-container [ngTemplateOutlet]="planningTable" [ngTemplateOutletContext]="{data: bucket.shipments}"></ng-container>   
            </nz-tab>
        </nz-tabset>
        <!-- <ng-container [ngTemplateOutlet]="planningTable" [ngTemplateOutletContext]="{data: next2days}"></ng-container> -->
    </nz-tab>
    <nz-tab [nzTitle]="titleTemplate2">
        <ng-template #titleTemplate2>
            Overdue
            <nz-badge nzStandalone [nzCount]="overdue.length"></nz-badge>
        </ng-template>
        <ng-container [ngTemplateOutlet]="planningTable" [ngTemplateOutletContext]="{data: overdue}"></ng-container>
    </nz-tab>
    <nz-tab [nzTitle]="titleTemplate3">
        <ng-template #titleTemplate3>
            Not Scheduled
            <nz-badge nzStandalone [nzCount]="notScheduled.length"></nz-badge>
        </ng-template>
        <ng-container [ngTemplateOutlet]="planningTable" [ngTemplateOutletContext]="{data: notScheduled}"></ng-container>
    </nz-tab>
    <nz-tab [nzTitle]="titleTemplate4">
        <ng-template #titleTemplate4>
            Future
            <nz-badge nzStandalone [nzStyle]="{ backgroundColor: '#aaa' }" [nzCount]="future.length"></nz-badge>
        </ng-template>
        <ng-container [ngTemplateOutlet]="planningTable" [ngTemplateOutletContext]="{data: future}"></ng-container>
    </nz-tab>
    <nz-tab [nzTitle]="titleTemplate5">
        <ng-template #titleTemplate5>
            Pending Appointment
            <nz-badge nzStandalone [nzCount]="pendingAppt.length"></nz-badge>
        </ng-template>
        <nz-table #basicTable [nzData]="pendingAppt" nzFrontPagination="false" nzPaginationPosition = "top" nzSize="small">
            <thead>
                <tr>
                    <th></th>
                    <th nzWidth="100px">Id</th>
                    <th nzWidth="90px">Order</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Pickup</th>
                    <th></th>
                    <th>Pickup Window</th>
                    <th>Delivery</th>
                    <th>Delivery Window</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of pendingAppt" [class]="{'INVALID': item.issue, 'EXCLUDED': item.excluded, 'PLANNED': item.session}" (mouseenter)="hoverShipment.emit(item.id)" (mouseleave)="hoverShipment.emit(null)" (click)="clickShipment.emit(item.id)">
                    <td>
                        <span nz-dropdown [nzDropdownMenu]="menu">
                            <span nz-icon nzType="ellipsis" nzTheme="outline"></span>
                        </span>
                        <nz-dropdown-menu #menu="nzDropdownMenu">
                            <ul nz-menu nzSelectable>
                                <li nz-menu-item (click)="removeRoutingRequirement($event, item)">
                                    <span nz-icon nzType="file-done" nzTheme="outline" class="right5"></span> Routing no longer needed
                                </li>
                            </ul>
                        </nz-dropdown-menu>                        
                    </td>
                    <td>
                        <i nz-icon nzType="loading" nzTheme="outline" *ngIf="item.addingStatus=='ADDING'"></i>
                        <i nz-icon nzType="check" style="color: green;" nzTheme="outline" *ngIf="item.addingStatus=='ADDED'"></i>
                        <i nz-icon nzType="warning" style="color: red;" nzTheme="outline" *ngIf="item.addingStatus=='ERROR'"></i>
                        <span class="clickable right5" (click)="onCopyId($event, item.warpId)">{{ item.warpId }}</span>
                        <a target="_blank" *ngIf="!item.orderId" [routerLink]="[routeAdminOrderList]" [queryParams]="{search: item.warpId}">
                            <span nz-icon nzType="link" nzTheme="outline"></span>
                        </a>
                        <a target="_blank" *ngIf="item.orderId" [routerLink]="[routeAdminOrderList, item.orderId]">
                            <span nz-icon nzType="link" nzTheme="outline"></span>
                        </a>
                    </td>
                    <td>
                        <span class="clickable right5" (click)="onCopyId($event, item.orderWarpId)">{{ item.orderWarpId || '' }}</span>
                        <ng-container *ngIf="item.parentId">
                            <span nz-popover nzPopoverTitle="Transit Network" nzPopoverTrigger="click" [nzPopoverContent]="contentTemplate" nzPopoverPlacement="bottomLeft">
                                <span style="color: #888;" nz-icon nzType="partition" nzTheme="outline"></span>
                            </span>
                            <ng-template #contentTemplate>
                                <div transit-network [id]="item.parentId" [viewOnly]="true"></div>
                            </ng-template>
                        </ng-container>
                    </td>
                    <td>
                        <span *ngIf="item.issue" style="color: red;" nz-icon nzType="warning" nzTheme="outline"
                            nz-popover nzPopoverTitle="Validation Errror" [nzPopoverContent]="item.issue"
                        >
                        </span>
                    </td>
                    <td>
                        {{ item.transitType }}
                    </td>
                    <td>{{item.clientName}}</td>
                    <td>
                        {{ item.pickup?.addr?.state }}-{{ item?.pickup?.addr?.zipcode }}
                    </td>
                    <td>
                        <span *ngIf="item.readiness" [style.color]="readinessColors[item.readiness.level]" nz-icon nzType="like" nzTheme="outline" nz-tooltip [nzTooltipTitle]="item.readiness?.message"></span>
                        <span *ngIf="item.readiness && item.readiness.level === 0" style="color: red;" nz-icon nzType="dislike" nzTheme="outline" nz-tooltip [nzTooltipTitle]="item.readiness?.message"></span>
                    </td>
                    <td>
                        <span class="clickable edit-button" (click)="onBtnEditWindowsTime(item, item.pickup)">
                            <span nz-icon nzType="edit" nzTheme="outline"></span>
                        </span>
                        {{item.pickupWindow}}
                        <span *ngIf="item.pickup.requiresAppointment && !item.pickup.appointmentInfo?.from" style="color: red;">Appt required</span>
                    </td>
                    <td>
                        {{ item.dropoff.addr.state }}-{{ item.dropoff.addr.zipcode }}
                    </td>
                    <td>
                        <span class="clickable edit-button" (click)="onBtnEditWindowsTime(item, item.dropoff)">
                            <span nz-icon nzType="edit" nzTheme="outline"></span>
                        </span>
                        {{item.dropoffWindow}}
                        <span *ngIf="item.dropoff.requiresAppointment && !item.dropoff.appointmentInfo?.from" style="color: red;">Appt required</span>
                    </td>
                    <td>
                        <ng-container *ngIf="item.session">
                            <a target="_blank" [routerLink]="[routePlanningSessions, item.session.id]">
                                <span nz-icon nzType="thunderbolt" nzTheme="outline"></span>
                            </a>
                        </ng-container>
                    </td>
                </tr>
            </tbody>
        </nz-table>
    </nz-tab>
    <nz-tab [nzTitle]="titleTemplate6">
        <ng-template #titleTemplate6>
            Added to Planning
            <nz-badge nzStandalone [nzStyle]="{ backgroundColor: '#52c41a' }" [nzCount]="planned.length"></nz-badge>
        </ng-template>

        <nz-table #basicTable [nzData]="planned" nzFrontPagination="false" nzPaginationPosition = "top" nzSize="small">
            <thead>
                <tr>
                    <th nzWidth="100px">Id</th>
                    <th nzWidth="90px">Order</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Pickup</th>
                    <th></th>
                    <th>Pickup Window</th>
                    <th>Delivery</th>
                    <th>Delivery Window</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of planned" [class]="{'INVALID': data.issue, 'EXCLUDED': data.excluded, 'PLANNED': data.session}" (mouseenter)="hoverShipment.emit(data.id)" (mouseleave)="hoverShipment.emit(null)" (click)="clickShipment.emit(data.id)">
                    <td>
                        <i nz-icon nzType="loading" nzTheme="outline" *ngIf="data.addingStatus=='ADDING'"></i>
                        <i nz-icon nzType="check" style="color: green;" nzTheme="outline" *ngIf="data.addingStatus=='ADDED'"></i>
                        <i nz-icon nzType="warning" style="color: red;" nzTheme="outline" *ngIf="data.addingStatus=='ERROR'"></i>
                        <span class="clickable right5" (click)="onCopyId($event, data.warpId)">{{ data.warpId }}</span>
                        <a target="_blank" *ngIf="!data.orderId" [routerLink]="[routeAdminOrderList]" [queryParams]="{search: data.warpId}">
                            <span nz-icon nzType="link" nzTheme="outline"></span>
                        </a>
                        <a target="_blank" *ngIf="data.orderId" [routerLink]="[routeAdminOrderList, data.orderId]">
                            <span nz-icon nzType="link" nzTheme="outline"></span>
                        </a>
                    </td>
                    <td>
                        <span class="clickable right5" (click)="onCopyId($event, data.orderWarpId)">{{ data.orderWarpId || '' }}</span>
                        <ng-container *ngIf="data.parentId">
                            <span nz-popover nzPopoverTitle="Transit Network" nzPopoverTrigger="click" [nzPopoverContent]="contentTemplate" nzPopoverPlacement="bottomLeft">
                                <span style="color: #888;" nz-icon nzType="partition" nzTheme="outline"></span>
                            </span>
                            <ng-template #contentTemplate>
                                <div transit-network [id]="data.parentId" [viewOnly]="true"></div>
                            </ng-template>
                        </ng-container>
                    </td>
                    <td>
                        <span *ngIf="data.issue" style="color: red;" nz-icon nzType="warning" nzTheme="outline"
                            nz-popover nzPopoverTitle="Validation Errror" [nzPopoverContent]="data.issue"
                        >
                        </span>
                        <!-- <span *ngIf="!data.issue" style="color: green;" nz-icon nzType="check" nzTheme="outline"></span> -->
                    </td>
                    <td>
                        {{ data.transitType }}
                    </td>
                    <td>{{data.clientName}}</td>
                    <td>
                        <!-- <span *ngIf="data.pickup.market">[{{data.pickup.market}}]</span> -->
                        {{ data.pickup?.addr?.state }}-{{ data.pickup?.addr?.zipcode }}
                    </td>
                    <td>
                        <span *ngIf="data.readiness" [style.color]="readinessColors[data.readiness.level]" nz-icon nzType="like" nzTheme="outline" nz-tooltip [nzTooltipTitle]="data.readiness?.message"></span>
                        <span *ngIf="data.readiness && data.readiness.level === 0" style="color: red;" nz-icon nzType="dislike" nzTheme="outline" nz-tooltip [nzTooltipTitle]="data.readiness?.message"></span>
                    </td>
                    <td>
                        <span class="clickable edit-button" (click)="onBtnEditWindowsTime(data, data.pickup)">
                            <span nz-icon nzType="edit" nzTheme="outline"></span>
                        </span>
                        {{data.pickupWindow}}
                    </td>
                    <td>
                        <!-- <span *ngIf="data.dropoff.market">[{{data.dropoff.market}}]</span> -->
                        {{ data.dropoff.addr.state }}-{{ data.dropoff.addr.zipcode }}
                    </td>
                    <td>
                        <span class="clickable edit-button" (click)="onBtnEditWindowsTime(data, data.dropoff)">
                            <span nz-icon nzType="edit" nzTheme="outline"></span>
                        </span>
                        {{data.dropoffWindow}}
                        <span *ngIf="data.dropoff.requiresAppointment && !data.dropoff.appointmentInfo?.from" style="color: red;">Appt required</span>
                    </td>
                    <td>
                        <ng-container *ngIf="data.session">
                            <a target="_blank" [routerLink]="[routePlanningSessions, data.session.id]">
                                <span nz-icon nzType="thunderbolt" nzTheme="outline"></span>
                            </a>
                        </ng-container>
                    </td>
                </tr>
            </tbody>
        </nz-table>
    </nz-tab>
</nz-tabset>

<ng-template #planningTable let-list="data">
    <div *ngIf="setOfCheckedId.size">
        <button nzType="primary" nz-button (click)="onRollShipments()">Roll Selected Shipments</button>
    </div>
    <nz-table #basicTable [nzData]="list" nzFrontPagination="false" nzPaginationPosition = "top" nzSize="small">
        <thead>
            <tr>
                <!-- <th [nzChecked]="checked" [nzIndeterminate]="indeterminate" (nzCheckedChange)="onAllChecked($event)"></th> -->
                <th style="text-align: center;">
                    <span *ngIf="setOfCheckedId.size" class="clickable" (click)="setOfCheckedId.clear()">
                        <span style="color: #888;" nz-icon nzType="close" nzTheme="outline"></span>
                    </span>
                </th>
                <th nzWidth="20px"></th>
                <th nzWidth="100px">Id</th>
                <th nzWidth="90px">Order</th>
                <th></th>
                <th></th>
                <th></th>
                <th>Pickup</th>
                <th></th>
                <th>Pickup Window</th>
                <th>Delivery</th>
                <th>Delivery Window</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let data of list" [class]="{'INVALID': data.issue, 'EXCLUDED': data.excluded, 'PLANNED': data.session}" (mouseenter)="hoverShipment.emit(data.id)" (mouseleave)="hoverShipment.emit(null)" (click)="clickShipment.emit(data.id)">
                <!-- <td>
                    <span nz-icon nzType="delete" nzTheme="outline" class="clickable" (click)="removeShipment($event, data.id)"></span>
                </td> -->
                <td
                    [nzChecked]="setOfCheckedId.has(data.id)"
                    [nzDisabled]="data.excluded"
                    (nzCheckedChange)="onItemChecked(data.id, $event)"
                ></td>
                <td>
                    <span nz-dropdown [nzDropdownMenu]="menu">
                        <span nz-icon nzType="ellipsis" nzTheme="outline"></span>
                    </span>
                    <nz-dropdown-menu #menu="nzDropdownMenu">
                        <ul nz-menu nzSelectable>
                            <li nz-menu-item (click)="removeShipment($event, data.id)">
                                <span nz-icon nzType="delete" nzTheme="outline" class="right5"></span> Remove
                            </li>
                            <li nz-menu-item (click)="updateShipmentReviewStatus($event, data)">
                                <span nz-icon nzType="file-done" nzTheme="outline" class="right5"></span> Update Review Status
                            </li>
                            <li nz-menu-item [nzDisabled]="data.issue" (click)="addToPlanning($event, data.id)">
                                <span nz-icon nzType="plus" nzTheme="outline" class="right5"></span>
                                Add to Planning
                            </li>
                            <li nz-menu-item (click)="removeRoutingRequirement($event, data)">
                                <span nz-icon nzType="file-done" nzTheme="outline" class="right5"></span> Routing no longer needed
                            </li>
                            <li nz-menu-item (click)="onBtnRollToDate(data)">
                                <span nz-icon nzType="hourglass" nzTheme="outline" class="right5"></span> Move To another Date
                            </li>
                        </ul>
                    </nz-dropdown-menu>                        
                </td>
                <td>
                    <i nz-icon nzType="loading" nzTheme="outline" *ngIf="data.addingStatus=='ADDING'"></i>
                    <i nz-icon nzType="check" style="color: green;" nzTheme="outline" *ngIf="data.addingStatus=='ADDED'"></i>
                    <i nz-icon nzType="warning" style="color: red;" nzTheme="outline" *ngIf="data.addingStatus=='ERROR'"></i>
                    <span class="clickable right5" (click)="onCopyId($event, data.warpId)">{{ data.warpId }}</span>
                    <a target="_blank" *ngIf="!data.orderId" [routerLink]="[routeAdminOrderList]" [queryParams]="{search: data.warpId}">
                        <span nz-icon nzType="link" nzTheme="outline"></span>
                    </a>
                    <a target="_blank" *ngIf="data.orderId" [routerLink]="[routeAdminOrderList, data.orderId]">
                        <span nz-icon nzType="link" nzTheme="outline"></span>
                    </a>
                </td>
                <td>
                    <span class="clickable right5" (click)="onCopyId($event, data.orderWarpId)">{{ data.orderWarpId || '' }}</span>
                    <ng-container *ngIf="data.parentId">
                        <span nz-popover nzPopoverTitle="Transit Network" nzPopoverTrigger="click" [nzPopoverContent]="contentTemplate" nzPopoverPlacement="rightBottom">
                            <span style="color: #888;" nz-icon nzType="partition" nzTheme="outline"></span>
                        </span>
                        <ng-template #contentTemplate>
                            <div transit-network [id]="data.parentId" [viewOnly]="true"></div>
                        </ng-template>
                    </ng-container>
                </td>
                <td>
                    <span *ngIf="data.issue" style="color: red;" nz-icon nzType="warning" nzTheme="outline"
                        nz-popover nzPopoverTitle="Validation Errror" [nzPopoverContent]="data.issue"
                    >
                    </span>
                    <!-- <span *ngIf="!data.issue" style="color: green;" nz-icon nzType="check" nzTheme="outline"></span> -->
                </td>
                <td>
                    {{ data.transitType }}
                </td>
                <td>{{data.clientName}}</td>
                <td>
                    <!-- <span *ngIf="data.pickup.market">[{{data.pickup.market}}]</span> -->
                    {{ data.pickup?.addr?.state }}-{{ data.pickup?.addr?.zipcode }}
                </td>
                <td>
                    <span *ngIf="data.readiness" [style.color]="readinessColors[data.readiness.level]" nz-icon nzType="like" nzTheme="outline" nz-tooltip [nzTooltipTitle]="data.readiness?.message"></span>
                    <span *ngIf="data.readiness && data.readiness.level === 0" style="color: red;" nz-icon nzType="dislike" nzTheme="outline" nz-tooltip [nzTooltipTitle]="data.readiness?.message"></span>
                </td>
                <td>
                    <span class="clickable edit-button" (click)="onBtnEditWindowsTime(data, data.pickup)">
                        <span nz-icon nzType="edit" nzTheme="outline"></span>
                    </span>
                    {{data.pickupWindow}}
                </td>
                <td>
                    <!-- <span *ngIf="data.dropoff.market">[{{data.dropoff.market}}]</span> -->
                    {{ data.dropoff.addr.state }}-{{ data.dropoff.addr.zipcode }}
                </td>
                <td>
                    <span class="clickable edit-button" (click)="onBtnEditWindowsTime(data, data.dropoff)">
                        <span nz-icon nzType="edit" nzTheme="outline"></span>
                    </span>
                    {{data.dropoffWindow}}
                    <!-- <span *ngIf="data.dropoff.requiresAppointment && !data.dropoff.appointmentInfo?.from" style="color: red;">Appt required</span> -->
                </td>
                <td>
                    <ng-container *ngIf="data.session">
                        <a target="_blank" [routerLink]="[routePlanningSessions, data.session.id]">
                            <span nz-icon nzType="thunderbolt" nzTheme="outline"></span>
                        </a>
                    </ng-container>
                </td>
            </tr>
        </tbody>
    </nz-table>
</ng-template>