import {Component} from '@angular/core';
import {ApiMethod} from '@app/enum';
import {Const} from '@const/Const';
import {BaseFormDialog1} from '@dialogs/base-form-dlg1';

@Component({
  selector: '[update-transaction-status]',
  templateUrl: './index.html',
  styleUrls: ['../../../app.scss', '../../../dialogs/dialogs.scss']
})
export class UpdateTransactionStatus extends BaseFormDialog1 {
  statusList = [
    Const.FinanceTransactionStatus.SUCCEEDED,
    Const.FinanceTransactionStatus.REFUNDED,
    Const.FinanceTransactionStatus.FAILED
  ];
  getStatusText(status){
    switch (status.toUpperCase()) {
      case Const.FinanceTransactionStatus.SUCCEEDED: return "Succeeded";
      case Const.FinanceTransactionStatus.REFUNDED: return "Refunded";
      case Const.FinanceTransactionStatus.FAILED: return "Failed";
    }
  }
  protected formGroupDeclaration: FormGroupDeclaration = {
    status: {label: 'Status', type: 'string',placeHolder:'Select a status', notAcceptEmpty: true},
  };

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onFilterChange(key: string, value: any) {

  }

  protected buildUrl(method: ApiMethod): string {
    return Const.APIV2(`${Const.APIURI_FINANCE_TRANSACTIONS}/${this.id}/status`);
  }

}
