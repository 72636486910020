import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { Component } from '@angular/core';
import { environment } from '@env/environment';
import { Const } from '@const/Const';
import { FreightQuoteFTLHistoryRequest } from '../history-request';
import dayjs from 'dayjs';

@Component({
  selector: '[freight-quote-book]',
  templateUrl: './index.html',
  styleUrls: ['../index.scss', 'index.scss']
})
export class FreightQuoteBook extends FreightQuoteFTLHistoryRequest {
    public quote
    public _option
    get option() {
        return this._option
    }
    set option(v) {
        this._option = v
        if (this._option?.windows?.length) {
            if (!this.earliestPickup)
                this.earliestPickup = dayjs.tz(this.option?.windows[0].from).startOf('day').toDate()
        }
    }
    optionId: any = null
    client: any = null
    clientId: any = null
    step = 0

    pickupReady = false
    dropoffReady = false
    itemsReady = false
    prevStepName: any = null
    nextStepName: any = null
    locations: any[] = []

    public earliestPickup: any = null
    public earliestDropoff: any = null
    public canContinue: Boolean = false
    public processing: Boolean = false


    ngOnInit(): void {
        super.ngOnInit()
        this.route.params.subscribe(params => {
            this.optionId = params['option_id'];
        });
        this.determineStep()
    }

    parseRequest(): void {
        super.parseRequest()

        // get client
        const { subject } = this.quoteRequest || {}
        if (subject && subject.indexOf("CLIENT") >= 0) {
            this.clientId = parseInt(subject.split('_')[1])
            this.loadClient()
        }
        if (subject && subject.indexOf("CUSTOMER") >= 0) {
            this.clientId = subject.split('_')[1]
            this.loadClient()
        }

        const pickupWindow = (this.pickupInfo?.windows || [])[0]
        if (pickupWindow?.from) {
            this.earliestPickup = dayjs.tz(pickupWindow.from).startOf('day').toDate()
            this.pickupInfo.windows = [{from: this.earliestPickup, to: null}]
        } else if (this.option?.windows?.length) {
            this.earliestPickup = dayjs.tz(this.option?.windows[0].from).startOf('day').toDate()
        }
        const dropoffWindow = (this.dropoffInfo?.windows || [])[0]
        if (dropoffWindow?.from) {
            this.earliestDropoff = dayjs.tz(dropoffWindow.from).startOf('day').toDate()
            this.dropoffInfo.windows = [{from: this.earliestDropoff, to: null}]
        }
    }
    
    parseResponse() {
        if (this.quoteResponse?.quotes)
        for (let quote of this.quoteResponse?.quotes) {
            if (quote.options)
            for (let option of quote?.options) {
                if (option?.id === this.optionId) {
                    this.option = option
                    this.quote = quote
                    // console.log(this.option, this.quote)
                }
            }
        }
    }

    loadClient() {
        const url = `${Const.APIURI_CLIENTS}/${this.clientId}`
        this.api.GET(url).subscribe((res) => {
            if (res.data) {
                this.client = res.data
                // console.log(res.data)
            }
        })

        // load warehouse
        const warehouseUrl = `${Const.APIURI_WAREHOUSES}?clientIds=${this.clientId}&limit=-1`
        this.api.GET(warehouseUrl).subscribe((res) => {
            this.locations = res.data.list_data
        })
    }

    onCancel() {
        this.router.navigate([Const.routeAdminFreightQuoteFtl, this.quoteRequest.id ]);
    }

    determineStep() {
        switch (this.step) {
            case 0:
                this.nextStepName = `Next: Delivery Info`
                this.prevStepName = null
                break
            case 1:
                this.nextStepName = `Next: Review`
                this.prevStepName = 'Back: Pickup Info'
                break
            case 2:
                this.nextStepName = `Confirm and Book`
                this.prevStepName = 'Back: Delivery Info'
                break
            default:
                this.nextStepName = null
                this.prevStepName = null
                break;
        }
    }
    
    onBook() {
        this.processing = true
        // call book
        const url = environment.backendUrl + `/v2/pricing/quote/response/${this.quoteResponse.id}/book`
        const payload = {
            quoteId: this.quote.id,
            optionId: this.optionId,
            request: this.quoteRequest,
            option: this.option,
        }
        this.api.POST(url, payload).subscribe((res) => {
            this.processing = false
            const { orderId } = res
            this.showDialog('The order has been booked.', () => {
                if(orderId){
                    this.router.navigate([Const.routeAdminOrderList, orderId])
                }
            })
        }, (err) => {
            console.log(err)
            this.processing = false
            if (err.message)
            this.showErr(err.message)
        })
    }

    onNextStep() {
        if (this.step > 2) return
        if (this.step  == 2) {
            return this.onBook()
        }
        this.step = this.step + 1
        this.determineStep()
        this.checkContinue()
    }

    onPrevStep() {
        if (this.step < 1) return
        this.step = this.step - 1
        this.determineStep()
        this.checkContinue()
    }
  
    onIndexChange(event) {
        this.step = event
    }

    checkContinue() {
        if (this.step == 0) {
            this.canContinue = this.pickupReady
        } else if (this.step == 1) {
            this.canContinue = this.dropoffReady
        } else if (this.step == 2) {
            this.canContinue = this.itemsReady
        } else {
          this.canContinue = false;
        }
    }

    onPickupInfoReady(event) {
        this.pickupReady = event;

        // check earliest dropoff
        if (this.pickupReady) {
            const dropoffWindow = (this.dropoffInfo?.windows || [])[0]
            if (dropoffWindow?.from) {
                this.earliestDropoff = dayjs.tz(dropoffWindow.from).startOf('day').toDate()
            }
            const pickupWindow = this.pickupInfo.windows[0]
            const pickupDate = pickupWindow?.from
            if (pickupDate) {
                let pickupTime = dayjs.tz(pickupDate).add(this.option?.transitTime || 0, 'second').startOf('day').add(-1, 'second')
                if (!this.earliestDropoff) {
                    this.earliestDropoff = pickupTime.toDate()
                } else {
                    if (pickupTime.isAfter(this.earliestDropoff)) {
                        this.earliestDropoff = pickupTime.toDate()
                    }
                }
            }
        }


        this.checkContinue()
    }
}
