<div class="form-header">
  <div class="form-title f18b">Please select filter conditions</div>
  <i class="ic-close clickable" (click)="onBtnCancel()" nz-icon nzType="close" nzTheme="outline"></i>
</div>

<form #form [formGroup]="formInput" nz-form style="padding: 26px;">
  <div class="form-inner-box">
    <ng-container *ngFor="let key of ['clientId']">
      <div nz-row [nzGutter]="12">
        <div nz-col nzXs="24">
          <div class="form-item"  [ngClass]="key">
            <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
            <div>
              <nz-form-item>
                <nz-form-control>
                  <select-by-searching
                    [version]="2"
                    [formControlName]="key" (modelChange)="onChange($event, key)"
                    [placeholder]="getPlaceHolder(key)" dropdownClassName="dispatch-filter-customer">
                  </select-by-searching>
                </nz-form-control>
              </nz-form-item>
              
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <div nz-row [nzGutter]="12">
      <ng-container *ngFor="let key of ['fromDate', 'toDate']">
        <ng-container [ngSwitch]="key">
          <div nz-col nzXs="24" nzMd="12">
            <div class="form-item" [ngClass]="key">
              <div class="form-label-v2">
                {{getLabel(key)}}
                <span *ngIf="isRequired(key)" class="label-mark-required"></span>
              </div>
              <div *ngSwitchCase="'fromDate'">
                <nz-form-item>
                  <nz-form-control>
                    <nz-date-picker
                      style="width: 100%;"
                      [formControlName]="key" nzFormat="yyyy-MM-dd"
                      (ngModelChange)="onChange($event, key)"
                    >
                    </nz-date-picker>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div *ngSwitchCase="'toDate'">
                <nz-form-item>
                  <nz-form-control>
                    <nz-date-picker
                      style="width: 100%;"
                      [formControlName]="key" nzFormat="yyyy-MM-dd"
                      (ngModelChange)="onChange($event, key)"
                    >
                    </nz-date-picker>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</form>

<div form-footer class="no-border"
  [onProgress]="isExporting"   
  [canClickOK]="needUpdate" [canClickCancel]="true" 
  nzIconOK="file-excel" labelOK="Export" (onOK)="onBtnExport()" 
  (onCancel)="onBtnCancel()"></div>