import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { BaseComponent } from '@abstract/BaseComponent';
import { environment } from '@env/environment';
import { Utils } from '@services/utils';

@Component({
  selector: '[freight-quote-ftl]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss',]
})
export class FreightQuoteFTL extends BaseComponent {
    public isLoading: boolean = false
    public quoteResponse: any = null
    public request: any = null

    @ViewChild('requestLink') requestLink: ElementRef<HTMLAnchorElement>;
    constructor() {
        super();
    }
    
    ngOnInit(): void {
    }

    onSearch(data) {
        this.quoteResponse = null
        const { pickup, dropoff, vehicleType, clientId, shipmentType, pickupOptions, deliveryOptions, pickupDate, items } = data
        const payload = {
            deliveryInfos: [
                {
                    type: 'PICKUP',
                    serviceOptions: pickupOptions,
                    addr: pickup,
                }, {
                    type: 'DROPOFF',
                    addr: dropoff,
                    serviceOptions: deliveryOptions,
                }
            ],
            vehicleType,
            shipmentType,
            subject: clientId ? `CUSTOMER_${clientId}` : null,
            items
        }
        if (pickupDate) {
            payload['deliveryInfos'][0]['windows'] = [{
                from: pickupDate,
                to: pickupDate
            }]
        }
        this.quote(payload)
    }

    quote(payload) {
        this.request = payload
        this.isLoading = true
        const url = environment.backendUrl + `/v2/pricing/quote`
        this.api.POST(url, payload, { timeout: 3 * 60 * 1000 }).subscribe((res) => {
            this.isLoading = false
            this.quoteResponse = res
            console.log(this.quoteResponse)            
        }, (err) => {
            this.quoteResponse = null
            this.isLoading = false
            this.showErr('Error while sending quote request!')
        })
    }

    onBtnCopyRouteLink() {
        Utils.copyTextToClipboard(this.requestLink.nativeElement.href, () => {
            this.showSuccess('Quote Request Link copied to ClipBoard')
        })
    }
}