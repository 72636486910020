  <div class="ltl-item-form">
    <div>Dimensions (in.)</div>
    <div class="line-break"></div>
    <div class="flex top20" style="place-content: center;">
      <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 16, lg: 16 }">
        <div nz-col class="gutter-row" nzXs="8" nzSm="8" nzMd="8" nzLg="8">
          <div class="form-label-v2">Length <span class="label-mark-required"></span></div>
          <nz-form-item>
            <nz-form-control>
                <input class="input-dimen" nz-input [(ngModel)]="objectValue.length" (keypress)="onInputKeyPressNumberOnly($event)">
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col class="gutter-row" nzXs="8" nzSm="8" nzMd="8" nzLg="8">
          <div class="form-label-v2">Width <span class="label-mark-required"></span></div>
          <nz-form-item>
            <nz-form-control>
                <input class="input-dimen" nz-input [(ngModel)]="objectValue.width" (keypress)="onInputKeyPressNumberOnly($event)">
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col class="gutter-row" nzXs="8" nzSm="8" nzMd="8" nzLg="8">
          <div class="form-label-v2">Height <span class="label-mark-required"></span></div>
          <nz-form-item>
            <nz-form-control>
                <input class="input-dimen" nz-input [(ngModel)]="objectValue.height" (keypress)="onInputKeyPressNumberOnly($event)">
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col class="gutter-row" nzXs="16" nzSm="16" nzMd="16" nzLg="16">
          <div class="form-label-v2">Weight / Unit<span class="label-mark-required"></span></div>
          <nz-form-item >
            <nz-form-control>
              <nz-input-group [nzSuffix]="suffixWeightUnit">
                <input class="input-dimen" nz-input [(ngModel)]="objectValue.weightPerUnit" (keypress)="onInputKeyPressNumberOnly($event)">
              </nz-input-group>
              <ng-template #suffixWeightUnit>
                <div class="flex weight-unit">
                  <div class="weight-unit-item" [ngClass]="{selected: objectValue.weightUnit=='lbs'}" (click)="onChangeObjectValue('weightUnit', 'lbs')">lbs</div>
                  <div class="weight-unit-item" [ngClass]="{selected: objectValue.weightUnit=='kgs'}" (click)="onChangeObjectValue('weightUnit', 'kgs')">kg</div>
                </div>
              </ng-template>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col class="gutter-row" nzXs="8" nzSm="8" nzMd="8" nzLg="8">
          <div class="form-label-v2">Quantity <span class="label-mark-required"></span></div>
          <nz-form-item>
            <nz-form-control>
                <input class="input-dimen" nz-input [(ngModel)]="objectValue.qty" (keypress)="onInputKeyPressNumberOnly($event)">
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>
  </div>
  <div form-footer
  [canClickOK]="needUpdate"
  [canClickCancel]="!onProgress"
  [labelOK]="'Save'"
  (onOK)="onBtnSave()" (onCancel)="closeDialog()"></div>