import { Component, Input } from "@angular/core";
import { Utils } from "@services/utils";
import { Const } from "@const/Const";
import { DialogService } from "@dialogs/dialog.service";
import { BaseDialog } from "@dialogs/base-dlg";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { startOfDay, differenceInCalendarDays } from 'date-fns'
import { environment } from "@env/environment";

@Component({
  selector: '[activate-settings-dialog]',
  templateUrl: './index.html',
  styleUrls: ['../../../../dialogs/dialogs.scss', './index.scss']
})
export class SelectFormulaDialog extends BaseDialog {
  @Input() subject: any;
  @Input() scope: string = '';
  onDone: (data) => void = (data) => {};

  public formulaList: any = []
  public formulaId: string = null
  public formula = null;
  public parameters = [];

  public isLoading = false;
  public inProgress = false;

  public startOfDay(result: Date): Date {
    return result ? startOfDay(result) : null;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.loadFormula();
  }
  
  onBtnCancel() {
    this.closeDialog();
  }

  onOk() {
    this.onActivate();
  }

  public get currentStep() {
    if (!this.formulaId) return 1
    return 2
  }

  onSelectFormula(item) {
      this.formulaId = item.id
      this.formula = item
      this.formula.parameters = item.parameters.filter(p => p.type === 'CONFIG')
  }

  onSave() {
    const setting = {
        subject: this.subject,
        scope: this.scope,
        formulaId: this.formulaId,
        parameters: this.formula.parameters
    }

    const url = `${environment.ratingUrl}/${Const.APIURI_FINANCE_SETTINGS}/initiate`
    this.isLoading = true;
    this.api.POST(url, setting).subscribe(
        resp => {
            this.isLoading = false;
            this.onDone(resp);
            this.closeDialog()
        },
        err => {
            this.isLoading = false;
        }
    )

  }

  goBack() {
      if (this.currentStep == 2) {
          this.formulaId = null
          this.formula = null
          this.parameters = []
      }
  }

  onParameterChange(e, item) {
      item.value.value = parseFloat(e.target.value)
  }

  loadFormula() {
    const url = `${environment.ratingUrl}/${Const.APIURI_FINANCE_FORMULA}`
    this.isLoading = true;
    this.api.GET(url).subscribe(
        resp => {
            this.formulaList = resp;
            this.isLoading = false;
        },
        err => {
            this.isLoading = false;
        }
    )
  }

  onActivate() {
//     if (this.inProgress) return;
//     this.inProgress = true;
//     const url = this.effective ? `${Const.APIURI_FINANCE_SETTINGS}/detail/${this.setting.id}/activate` : `${Const.APIURI_FINANCE_SETTINGS}/detail/${this.setting.id}/expire`;
//     this.api.POST(url, this.effectiveDate).subscribe(
//         resp => {
//             this.setting.status = 'ACTIVE'
//             if (this.effective)
//                 this.setting.effectiveDate = this.effectiveDate
//             else
//                 this.setting.expirationDate = this.effectiveDate
//             this.inProgress = false;
//             this.closeDialog();
//         }, err => {
//             this.inProgress = false;
//         }
//     )
  }

}