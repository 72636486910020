<div class="form-header no-border no-padding-bottom">
  <div class="form-title f18b">{{strTitle}}</div>
  <i class="ic-close clickable" (click)="onBtnCancel()" nz-icon nzType="close" nzTheme="outline"></i>
</div>

<form class="form-detail shipment-payment" [formGroup]="formInput" nz-form style="padding: 26px;">
  <div class="flex-column">
    <ng-container [formArrayName]="'listCards'">
      <form *ngFor="let item of getControlListCards(); let arrIndex = index" nz-form [formGroupName]="arrIndex">
        <div class="payment-card">
          <div>{{getListCardAt(arrIndex).cardNumber}}</div>
          <div><label nz-checkbox [formControlName]="'isUsing'" (ngModelChange)="onBtnCardChange(arrIndex)"></label></div>
        </div>
      </form>
    </ng-container>
    <ng-container *ngFor="let key of ['isAddNewCard', 'newCardInfo']">
      <div *ngIf="isBool(key)" class="payment-card">
        <div>{{getLabel(key)}}</div>
        <div><label nz-checkbox [formControlName]="key" (ngModelChange)="onRequiresAddNewCardChange()"></label></div>
      </div>
      <div *ngIf="!isBool(key) && getCheckValueFor(key) === true">
        <form nz-form *ngIf="key == 'newCardInfo'" [formGroupName]="key">
          <div nz-row *ngFor="let row of rowsAddNewCard">
            <div nz-col *ngFor="let col of row.columns" [nzSpan]="col.span" [nzOffset]="col.offset">
              <div class="form-label-v2 single-line fix-height">{{getLabel(fullKey(key, col.key))}}<span *ngIf="isRequired(fullKey(key, col.key)) && getLabel(fullKey(key, col.key)).length > 0" class="label-mark-required"></span></div>
              <nz-form-item>
                <nz-form-control>
                  <input nz-input [formControlName]="col.key"
                    [type]="getInputType(fullKey(key, col.key))" 
                    [placeholder]="getPlaceHolder(fullKey(key, col.key))"
                    (input)="onInputChanged($event, col.key)" 
                    (keypress)="onInputKeyPress($event, col.key)">
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </form>
      </div>
    </ng-container>
  </div>
  <div class="text-center top10">
    <button nz-button [disabled]="!needUpdate" nzType="primary" (click)="onBtnConfirm()" style="width:100%;">Confirm</button>
  </div>
</form>


