<div>
    <div class="label">Location</div>
    <div *ngIf="info.locationName">{{info.locationName}}</div>
    <div>{{info.addr.street}}, {{info.addr.city}}, {{info.addr.state}} {{info.addr.zipcode}}</div>

    <div nz-row [nzGutter]="{ md: 16 }">
        <div nz-col nzSm="12">
            <div class="label">Contact Person</div>
            <div>{{info.contact.name}}</div>
        </div>
        <div nz-col nzSm="12">
            <div class="label">Contact Info</div>
            <div>{{info.contact.phone || ''}} <span *ngIf="info.contact.phone && info.contact.email">, </span>{{info.contact.email || ''}}</div>
        </div>
    </div>
    <div>
        <div class="label">Pickup Date & Time</div>
        <div>{{window}}</div>
    </div>
    <div>
        <div class="label">Service Options</div>
        <div>
            <span *ngIf="!info.serviceOptions || !info.serviceOptions.length">N/A</span>
            <span *ngFor="let item of info.serviceOptions" class="service-option">{{item}}</span>
        </div>
    </div>
    <div nz-row [nzGutter]="{ md: 16 }">
        <div nz-col nzSm="12">
            <div class="label">Reference Number</div>
            <div>{{info.refNum || 'N/A'}}</div>
        </div>
        <div nz-col nzSm="12">
            <div class="label">Access Code</div>
            <div>{{info.accessCode || 'N/A'}}</div>
        </div>
    </div>
    <div *ngIf="info.instructions">
        <div class="label">Instructions</div>
        <div>{{info.instructions}}</div>
    </div>
    <div *ngIf="info.note">
        <div class="label">Note</div>
        <div>{{info.note}}</div>
    </div>

</div>