<form nz-form [formGroup]="formInput" class="form-detail quote-shipment-delivery-form">
  <div class="group">
  <div nz-row [nzGutter]="{ md: 16 }">
    <div nz-col *ngFor="let key of ['locationName']" nzXs="24" nzMd="12">
      <div class="form-label-v2">
        {{getLabel(key)}}
        <span *ngIf="isRequired(key)" class="label-mark-required"></span>
      </div>
      <nz-form-item>
        <nz-form-control>
          <ng-container>
            <input nz-input [formControlName]="key"
            [nzAutocomplete]="autoComplete1"
            (ngModelChange)="onValueChange($event)">
            <nz-autocomplete #autoComplete1 [nzDefaultActiveFirstOption]="false">
              <nz-auto-option *ngFor="let item of locationsFiltered; let i = index" 
                [nzValue]="item.name" (selectionChange)="onLocationSelected($event, item)">
                {{item.name}}
              </nz-auto-option> 
            </nz-autocomplete>
          </ng-container>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div *ngFor="let key of ['addr']" nz-col nzXs="24" nzMd="12">
      <div class="form-label-v2">
        {{getLabel(key)}}
        <span *ngIf="isRequired(key)" class="label-mark-required"></span>
      </div>
      <nz-form-item>
        <nz-form-control>
          <form-address [zipcode]="filters.dropZipcode" [formControlName]="key"></form-address>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <nz-form-item>
    <nz-form-control>
      <div *ngFor="let key of ['requiresAppointment']"
        nz-checkbox [formControlName]="key" 
        (ngModelChange)="onRequiresAppointmentChange()">
        {{getLabel(fullKey(key))}}
      </div>
    </nz-form-control>
  </nz-form-item>
  <div *ngIf="isRequireAppointment()" class="bottom20">
    <a (click)="onBtnBookAppointment()" class="underline">{{getAppointmentText()}}</a>
  </div>
  <div class="bold f14 top10">Primary Contact</div>
  <div nz-row [nzGutter]="{ md: 16}">
    <div *ngFor="let key of ['contactName']" nz-col nzXs="24" nzMd="12">
      <div class="form-label-v2">
        {{getLabel(key)}}
        <span *ngIf="isRequired(key)" class="label-mark-required"></span>
      </div>
      <nz-form-item>
        <nz-form-control>
          <input nz-input [formControlName]="key" 
            [type]="getInputType(key)" 
            [placeholder]="getPlaceHolder(key)" >
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col nzXs="24" nzMd="12">
      <div nz-row [nzGutter]="{ md: 16 }">
        <div *ngFor="let key of ['contactPhone']" nz-col nzXs="24" nzMd="12">
          <div class="form-label-v2">
            {{getLabel(key)}}
            <span *ngIf="isRequired(key)" class="label-mark-required"></span>
          </div>
          <nz-form-item>
            <nz-form-control>
              <input nz-input [formControlName]="key" 
                [type]="getInputType(key)" 
                [placeholder]="getPlaceHolder(key)"
                (input)="onInputChanged($event, key)" 
                (keypress)="onInputKeyPress($event, key)" >
            </nz-form-control>
          </nz-form-item>
        </div>
        <div *ngFor="let key of ['contactEmail']" nz-col nzXs="24" nzMd="12">
          <div class="form-label-v2">
            {{getLabel(key)}}
            <span *ngIf="isRequired(key)" class="label-mark-required"></span>
          </div>
          <nz-form-item>
            <nz-form-control> 
              <input nz-input [formControlName]="key" 
                [type]="getInputType(key)" 
                [placeholder]="getPlaceHolder(key)"
                (input)="onInputChanged($event, key)" 
                (keypress)="onInputKeyPress($event, key)" >
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>
  </div>
  <div class="bold f14b top10">Secondary Contact</div>
  <form nz-form *ngFor="let key of ['secondaryContact']" [formGroupName]="key">
    <div nz-row [nzGutter]="{ md: 32}">
      <div nz-col nzXs="24" nzMd="12">
        <div nz-row [nzGutter]="{ md: 16 }">
          <div *ngFor="let childKey of ['contactName']" nz-col nzXs="24" nzMd="16">
            <div class="form-label-v2">
              {{getLabel(fullKey(key, childKey))}}
              <span *ngIf="isRequired(fullKey(key, childKey))" class="label-mark-required"></span>
            </div>
            <nz-form-item>
              <nz-form-control>
                <input nz-input [formControlName]="childKey"
                  [type]="getInputType(fullKey(key, childKey))"
                  [placeholder]="getPlaceHolder(fullKey( key, childKey))" >
              </nz-form-control>
            </nz-form-item>
          </div>
          <div *ngFor="let childKey of ['contactPhone']" nz-col nzXs="24" nzMd="8">
            <div class="form-label-v2">
              {{getLabel(fullKey(key, childKey))}}
              <span *ngIf="isRequired(fullKey(key, childKey))" class="label-mark-required"></span>
            </div>
            <nz-form-item>
              <nz-form-control>
                <input nz-input [formControlName]="childKey"
                  [type]="getInputType(fullKey(key, childKey))"
                  [placeholder]="getPlaceHolder(fullKey(key, childKey))"
                  (input)="onInputChanged($event, childKey)"
                  (keypress)="onInputKeyPress($event, childKey)" >
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </div>
      <div *ngFor="let childKey of ['contactEmail']" nz-col nzXs="24" nzMd="12">
        <div class="form-label-v2">
          {{getLabel(fullKey(key, childKey))}}
          <span *ngIf="isRequired(fullKey(key, childKey))" class="label-mark-required"></span>
        </div>
        <nz-form-item>
          <nz-form-control>
            <input nz-input [formControlName]="childKey"
              [type]="getInputType(fullKey(key, childKey))"
              [placeholder]="getPlaceHolder(fullKey(key, childKey))"
              (input)="onInputChanged($event, childKey)"
              (keypress)="onInputKeyPress($event, childKey)" >
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </form>
  <div>
    <ng-container [formArrayName]="'windows'">
      <form *ngFor="let item of getControlTimeWindows(); let arrIndex = index" nz-form [formGroupName]="arrIndex" class="pick-up-time">
        <div nz-row [nzGutter]="{ md: 16}">
          <div nz-col nzXs="24" nzMd="12">
            <div *ngFor="let childKey of ['date']; let i = index">
              <div class="form-label-v2 single-line fix-height">
                {{getLabel(fullKey('windows', childKey))}}
                <span *ngIf="isRequired(fullKey('windows', childKey)) && i < 2" class="label-mark-required"></span>
              </div>
              <nz-form-item>
                <nz-form-control>
                  <nz-date-picker [nzDisabled]="!!deliveryDate" [formControlName]="childKey" nzFormat="yyyy-MM-dd"style="width: 100%;"></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <div nz-col nzXs="24" nzMd="12">
            <div nz-row [nzGutter]="{ md: 16}">
              <div nz-col nzXs="12" *ngFor="let childKey of ['fromTime', 'toTime']; let i = index">
                <div class="form-label-v2 single-line fix-height">
                  {{getLabel(fullKey('windows', childKey))}}
                  <span *ngIf="isRequired(fullKey('windows', childKey)) && i < 2" class="label-mark-required"></span>
                </div>
                <nz-form-item>
                  <nz-form-control>
                    <nz-time-picker [nzMinuteStep]="15" style="width: 100%;" [formControlName]="childKey" nzFormat="HH:mm" 
                      [nzPlaceHolder]="getPlaceHolder(fullKey('windows', childKey))" [ngClass]="childKey"
                      (ngModelChange)="onSelectTimeChange(childKey, $event)">
                    </nz-time-picker>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ng-container>
  </div>
  <div nz-row [nzGutter]="{ md: 16}">
    <div nz-col nzXs="24" nzMd="12">
      <div class="form-label-v2 single-line fix-height">Service options</div>
      <nz-select nzBackdrop="true"  nzMode="multiple" style="width: 100%;" nzShowSearch nzAllowClear [nzDisabled]="!isEditOrCreate"
        nzPlaceHolder="Select service options" [nzMaxTagCount]="0" [nzMaxTagPlaceholder]="tagPlaceHolder"
        [ngModelOptions]="{standalone: true}" [(ngModel)]="serviceOptions.selectedItems">
        <nz-option *ngFor="let item of serviceOptions.allItems" [nzLabel]="item.name" [nzValue]="item._id"></nz-option>
      </nz-select>
      <ng-template #tagPlaceHolder>{{serviceOptions.sum()}}</ng-template>
      <nz-tag *ngFor="let item of serviceOptions.selectedItems; let i = index" style="margin-top: 5px;"
        [nzMode]="isEditOrCreate ? 'closeable' : 'default'" nzColor="processing" (nzOnClose)="serviceOptions.remove(i)">
        {{getServiceOptionName(item)}}
      </nz-tag>
    </div>
    <div nz-col nzXs="24" nzMd="12">
      <div nz-row [nzGutter]="{ md: 16}">
        <div *ngFor="let key of ['refNum', 'accessCode']" nz-col nzXs="24" nzMd="12">
          <div class="form-label-v2 single-line fix-height">
            {{getLabel(key)}}
            <span *ngIf="isRequired(key)" class="label-mark-required"></span>
          </div>
          <nz-form-item>
            <nz-form-control>
              <input *ngIf="!isMultiline(key)"
                nz-input [formControlName]="key"
                [type]="getInputType(key)" 
                [placeholder]="getPlaceHolder(key)"
                (input)="onInputChanged($event, key)" 
                (keypress)="onInputKeyPress($event, key)" >
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>
  </div>
  <div nz-row>
    <ng-container *ngFor="let key of ['instructions', 'note']">
      <div nz-col nzXs="24">
        <div class="form-label-v2 single-line fix-height">
          {{getLabel(key)}}
          <span *ngIf="isRequired(key)" class="label-mark-required"></span>
        </div>
        <nz-form-item class="ref-num">
          <nz-form-control>
            <input *ngIf="!isMultiline(key)"
              nz-input [formControlName]="key"
              [type]="getInputType(key)" 
              [placeholder]="getPlaceHolder(key)"
              (input)="onInputChanged($event, key)" 
              (keypress)="onInputKeyPress($event, key)" >
            <textarea *ngIf="isMultiline(key)"
              nz-input [formControlName]="key"
              [placeholder]="getPlaceHolder(key)"
              (input)="onInputChanged($event, key)" 
              (keypress)="onInputKeyPress($event, key)"
              [nzAutosize]="{minRows: 2, maxRows: 4}">
            </textarea>
          </nz-form-control>
        </nz-form-item>
      </div>
    </ng-container>
  </div>
  </div>
</form>