import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import _ from "underscore";

@Component({
    selector: 'route-progress-bar-tick',
    templateUrl: './tick.html',
    styleUrls: ['./tick.scss']
})
export class RouteProgressBarTick extends BaseComponent {
    _stop: any

    statuses: any[] = []

    @Input() map

    @Input() set stop(v) {
        this._stop = v
        this.processStats()
    }

    onMouseIn() {
        const locations = [this._stop?.location].filter(it => it)
        this.map?.loadHighlightLocations(locations)
    }

    onMouseOut() {
        const locations = []
        this.map?.loadHighlightLocations(locations)
    }

    processStats() {
        const tasksCount = this._stop.tasks.length || 1
        const count = _.countBy(this._stop.tasks.map(it => it.status || 'created'))

        const knownStatuses = [
            Const.TaskStatus.succeeded,
            Const.TaskStatus.enroute,
            Const.TaskStatus.arrived,
            Const.TaskStatus.failed,
            Const.TaskStatus.pickupFailed,
            Const.TaskStatus.created,
            Const.TaskStatus.canceled,
        ]

        const statuses: any[] = knownStatuses.map(status => Object.assign({}, {
            status,
            count: count[status] || 0,
            height: `${100 * (count[status] || 0) / tasksCount}%`
        }))
        let acc = 0
        for (let s of statuses) {
            s.bottom = `${ acc * 100 / tasksCount }%`
            acc += s.count
        }
        this.statuses = statuses.filter(it => it.count)
    }

    constructor() {
        super()
    }
}