<div class="outer-container" [class]="displayInfo.issue ? 'issued': 'normal'" (mouseenter)="onHover.emit(shipment)" (mouseleave)="onHover.emit(null)">
    <div class="general-info">
        <div class="flex">
            <div class="flex1 main-title">
                <code class="right5">{{ displayInfo.warpId }}</code>
                <span *ngIf="displayInfo.issue" nz-tooltip [nzTooltipTitle]="displayInfo.issue">
                    <i nz-icon nzType="warning" style="color: red;" nzTheme="outline"></i>
                </span>
            </div>
            <div class="flex1 sub-title"><img class="icon-detail" src="assets/img/pallet.svg"> {{ displayInfo.workload }}</div>
            <div>
                <span nzSize="small" nz-dropdown [nzDropdownMenu]="menu" nzType="text" class="sub-title clickable">...</span>

                <nz-dropdown-menu #menu="nzDropdownMenu">
                    <ul nz-menu>
                      <li nz-menu-item (click)="onRemoveButton()">Remove</li>
                      <li nz-menu-item (click)="onAddToGroupBtn()">Add to Group</li>
                      <li nz-menu-item *ngIf="_shipment.group" (click)="onUnGroupBtn()">Un-Group</li>
                    </ul>
                  </nz-dropdown-menu>
            </div>
        </div>
        <!-- <div class="sub-title"><i nz-icon nzType="user" nzTheme="outline"></i> {{ displayInfo.clientName }}</div> -->
    </div>
    <div class="stop-list">
        <div class="flex">
            <div [class]="['flex1', 'stop', stop.info.type]" *ngFor="let stop of displayInfo.stops; let i = index" nz-popover nzPopoverTrigger="click" [nzPopoverTitle]="stop.info.type + ' ' + displayInfo.warpId" [nzPopoverContent]="stop.info.addr?.street + ', ' + stop.info.addr?.city">
                <!-- <div class="tiny-text blur">{{ stop.type }} | {{ stop.market }}</div> -->
                <div>{{ stop.info.addr?.state }} {{ stop.info.addr?.zipcode }}</div>
                <div class="sub-title blur">{{ stop.window }}</div>
                <div *ngIf="i > 0" class="arrow"><span nz-icon nzType="arrow-right" nzTheme="outline"></span></div>
            </div>
        </div>
    </div>
</div>