import { Component } from "@angular/core";
import { ApiMethod } from "@app/enum";
import { Const } from "@const/Const";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { FormControl, Validators } from '@angular/forms';

/**
 * Tên class là OrderStatus nhưng là update bản ghi shipment chứ không phải order
 */
@Component({
  selector: '[order-status]',
  templateUrl: './order-status.html',
  styleUrls: ['../../app.scss', '../../dialogs/dialogs.scss']
})
export class OrderStatus extends BaseFormDialog1 {
  protected formGroupDeclaration: FormGroupDeclaration = {
    status: {label: 'Status', required: true},
    statusInfo: {label: '', type: 'formGroup', childItem: {
      cancelReason: {label: 'Reason'},
      comment: {label: 'Remarks'},
      }
    }
  }
  
  public currentStatus;
  public listStatusRequireReason = [
    Const.OrderStatus.canceled,
    Const.OrderStatus.lost,
    Const.OrderStatus.issue,
  ]
  
  public get allStatus() {return Const.OrderStatusArray.filter(it => it != Const.OrderStatus.removed)};
  public get allOrderCancelReasons() {return Object.keys(Const.OrderCancelReasons).map(key => Const.OrderCancelReasons[key])}
  
  constructor() {
    super();
  }
  
  ngOnInit(): void {
    super.ngOnInit();
  }
  
  public afterBindModel(model){
    this.currentStatus = model.status
    this.requireFields()
  }
  
  public updateRequireField(path, value){
    let fc = <FormControl>this.formInput.get(path);
    if (fc) {
      if (!value) {
        fc.removeValidators(Validators.required);
      } else {
        fc.addValidators(Validators.required);
      }
      fc.updateValueAndValidity();
    }
  }
  
  public requireFields (){
    if(this.isShowFormReason()){
      try{
        this.updateRequireField("statusInfo.comment", true)
        this.formGroupDeclaration.statusInfo.childItem['comment'].required = true
        if (this.isCanceled()) {
          this.updateRequireField("statusInfo.cancelReason", true);
          this.formGroupDeclaration.statusInfo.childItem['cancelReason'].required = true
        }
      } catch(e){
      }
    } else {
      try{
        this.updateRequireField("statusInfo.comment", false)
        this.formGroupDeclaration.statusInfo.childItem['comment'].required = false
        this.updateRequireField("statusInfo.cancelReason", false);
        this.formGroupDeclaration.statusInfo.childItem['cancelReason'].required = false
      } catch(e){
      }
    }
  }
  
  public hasData(){
    // this.input
  }
  
  public onChangeStatus(value){
    this.currentStatus = value
    this.requireFields()
  }

  public isCanceled(){
    return this.currentStatus === Const.OrderStatus.canceled;
  }

  public isShowFormReason(){
    return this.listStatusRequireReason.includes(this.currentStatus);
  }

  protected buildUrl(method: ApiMethod): string {
    return Const.APIV2(`${Const.APIURI_SHIPMENTS}/${this.model.id}/status`);
  }

}