import {BaseList} from '../base/list';
import {Component} from '@angular/core';
import {Const} from '@app/const/Const';
import {InputHelper} from '@services/input-helper';
import {DialogService} from '@dialogs/dialog.service';
import {UpdateTransactionStatus} from '@app/admin/finance-transactions/transaction-status';
import {Utils} from '@services/utils';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'finance-transaction-list',
  templateUrl: './list.html',
  styleUrls: ['../list.scss', './style.scss']
})

export class FinanceTransactionList extends BaseList {

  private _selectedTabIndex = 0;

  constructor(private sanitizer: DomSanitizer) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.autoShrinkSideBar();
  }

  onFilterTabChanged(value) {
    this._selectedTabIndex = value;
  }

  isOnInProgressTab() {
    return this._selectedTabIndex == 0;
  }

  isOnNeedReviewTab() {
    return this._selectedTabIndex == 1;
  }

  public getTransactionDate(item) {
    return this.displayDateTime(item?.insert?.when, Const.DATETIME_FORMAT_FROM_DB);
  }

  public getExpiredDate(item) {
    return this.displayDateTime(item?.metadata?.expiredDate, Const.DATETIME_FORMAT_FROM_DB);
  }

  public statusText(item) {
    switch (item.status.toUpperCase()) {
      case Const.FinanceTransactionStatus.SUCCEEDED:
        return 'Succeeded';
      case Const.FinanceTransactionStatus.REFUNDED:
        return 'Refunded';
      case Const.FinanceTransactionStatus.FAILED:
        return 'Failed';
      case Const.FinanceTransactionStatus.PENDING:
        return 'Pending';
      case Const.FinanceTransactionStatus.ISSUE:
        return 'Issue';
      case Const.FinanceTransactionStatus.NOT_CAPTURED:
        return 'Not Captured';
      case Const.FinanceTransactionStatus.NEED_REFUND:
        return 'Need Refund';
      default:
        return 'N/A';
    }
  }

  getStatusColor(item) {
    switch (item.status.toUpperCase()) {
      case Const.FinanceTransactionStatus.SUCCEEDED:
        return 'success';
      case Const.FinanceTransactionStatus.REFUNDED:
        return 'warning';
      case Const.FinanceTransactionStatus.FAILED:
        return 'error';
      case Const.FinanceTransactionStatus.PENDING:
        return 'default';
      case Const.FinanceTransactionStatus.ISSUE:
        return 'orange';
      case Const.FinanceTransactionStatus.NOT_CAPTURED:
        return 'processing';
      case Const.FinanceTransactionStatus.NEED_REFUND:
        return 'volcano';
      default:
        return 'default';
    }
  }

  public getOwnerInfo(item) {
    return item.metadata?.client?.name;
  }

  public getAmountValue(item) {
    return '$' + InputHelper._formatMoney(item.amount, 0);
  }

  public needRefund(item) {
    return item.status == Const.FinanceTransactionStatus.NEED_REFUND;
  }

  public hasIssue(item) {
    return item.status == Const.FinanceTransactionStatus.ISSUE;
  }

  onChangeStatus(index: number) {
    if (this.isAdminReadOnlyRole) {
      return;
    }
    let item = this.listData[index];
    DialogService.openFormDialog1(UpdateTransactionStatus, {
      nzComponentParams: {
        updateSuccess: (resp) => {
          item.status = resp?.data?.status;
          this.onUpdateItemSuccess(item);
        },
        model: item,
        closeOnSuccess: true,
      },
      nzClassName: 'modal-no-padding',
      nzCentered: true,
    });
  }

  onRefund(index: number) {
    if (this.isAdminReadOnlyRole) {
      return;
    }
    let item = this.listData[index];
    item.onProgress = true;
    let url = Const.APIV2(`${Const.APIURI_FINANCE_TRANSACTIONS}/${item.id}/refund`);
    this.api.PUT(url, {}).subscribe(
      resp => {
        item.status = resp?.data?.status;
        this.onUpdateItemSuccess(item);
        this.showSuccess('Refund successfully!');
        item.onProgress = false;
      }, err => {
        this.showErr(err);
        item.onProgress = false;
      }
    );
  }

  getRouterLinkToOrder(item) {
    return `${this.routeAdminOrderList}/${item.metadata?.orderId}`;
  }

  getOrderIdText(item) {
    if(!item.metadata?.warpOrderId) return null;
    return this.showOrderWarpId(item.metadata?.warpOrderId);
  }

  getRouterLinkToOwner(item) {
    return `${this.routeAdminClientList}/${item.metadata?.client?.id}`;
  }

  getTransactionCode(item) {
    return item.code;
  }

  onCopyTransactionCode(item){
    this.onCopyText(item.code);
  }

  getExternalCode(item) {
    return item.externalId;
  }

  onCopyExternalCode(item){
    this.onCopyText(item.externalId);
  }


  onCopyText(text) {
    if (!text) {
      return;
    }
    Utils.copyTextToClipboard(text, e => {
      if (e) {
        this.showErr('Cannot copy to clipboard');
      } else {
        this.showSuccess('Copied to the clipboard');
      }
    });
  }

  getIssueDescription(item) {
    if (item.status != Const.FinanceTransactionStatus.ISSUE) {
      return null;
    }
    if (!item.statusHistories || !Utils.isArray(item.statusHistories) || !Utils.isArrayNotEmpty(item.statusHistories)) {
      return null;
    }
    let issues = item.statusHistories.findAll(history => history.to == Const.FinanceTransactionStatus.ISSUE);
    if (!issues || issues.length == 0) {
      return null;
    }
    let issue = issues[issues.length - 1];//last issue
    if (!issue.reason || issue.reason.length == 0) {
      return null;
    }
    return issue.reason;
  }

  getDescriptionHtml(item) {
    if(!item.description) return 'N/A';
    let orderId= this.getOrderIdText(item);
    if(orderId)  return this.sanitizer.bypassSecurityTrustHtml(item.description.replace(orderId,`<a href="${this.getRouterLinkToOrder(item)}" target="_blank">${orderId}</a>`));
    else return item.description;
  }

  getRouterLinkTransactionDetail(item){
    return [Const.routeAdminFinanceTransaction, item.id];
  }

  protected getApiUrl(): string {
    return Const.APIV2(Const.APIURI_FINANCE_TRANSACTIONS);
  }

}
