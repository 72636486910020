import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LabelTemplateList } from "./list";
import { LabelTemplateDetail } from "./detail";
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzTableModule } from "ng-zorro-antd/table";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzFormModule } from "ng-zorro-antd/form";
import { SearchBoxModule } from "@libs/search-box/search-box.module";
import { DetailModule } from "../base/detail.module";
import { SelectBySearchingModule } from "@libs/select-client/module";
import { FormAddressUSModule } from "../base/form-address-us/module";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzTableModule,
    SearchBoxModule,
    NzSpaceModule,
    NzButtonModule,
    NzSelectModule,
    NzIconModule,
    NzGridModule,
    NzInputModule,
    NzFormModule,
    DetailModule,
    SelectBySearchingModule,
    FormAddressUSModule,
  ],
  declarations: [LabelTemplateList, LabelTemplateDetail],
  exports: [],
  providers: [],
})
export class LabelTemplateModule {}
