
<div class="quote-screen">
    <div>
      <div freight-quote-ftl-search-form (onSearch)="onSearch($event)" [isLoading]="isLoading"></div>
    </div>
    <div>
      <div *ngIf="quoteResponse">
        <h2>Proposed Rates</h2>
        <div>
          <a target="_blank" #requestLink [routerLink]="[routeAdminFreightQuoteFtl,  quoteResponse.requestId]">{{quoteResponse.requestId}}</a>
          <a style="font-size: 20px; margin-left: 10px;" (click)="onBtnCopyRouteLink()" nz-tooltip
            nzTooltipTitle="Copy link for internal WARP team">
            <i nz-icon nzType="link" nzTheme="outline"></i>
          </a>
        </div>
        <div freight-quote-result [request]="request" [response]="quoteResponse"></div>
      </div>
    </div>
</div>