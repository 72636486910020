import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BaseComponent } from '@abstract/BaseComponent';
import { DateUtil } from '@services/date-utils';
import { Const } from '@const/Const';
import dayjs from 'dayjs';
import { ulid } from 'ulid';

@Component({
  selector: '[shipment-quote-review]',
  templateUrl: './index.html',
  styleUrls: ['../index.scss', 'index.scss']
})
export class ShipmentQuoteReview extends BaseComponent {
    @Input() pickupInfo
    @Input() dropoffInfo
    @Input() request

    _option: any = null
    editable: boolean = false
    @Input() set option(v) {
        this._option = v
        this.editable = v.shipmentType === 'FTL'
    }
    @Output() onReady: EventEmitter<any> = new EventEmitter<any>();

    onChange() {
        const { items } = this.request
        for (let item of items) {
            if (!item.name) {
                return this.onReady?.emit(false)
            }
            if (!item.quantity) {
                return this.onReady?.emit(false)
            }
            if (!item.length) {
                return this.onReady?.emit(false)
            }
            if (!item.width) {
                return this.onReady?.emit(false)
            }
            if (!item.height) {
                return this.onReady?.emit(false)
            }
            if (!item.weight) {
                return this.onReady?.emit(false)
            }
        }
        this.onReady?.emit(true)
    }

    onAddNewItem(type) {
        let item = {
            id: ulid(),
            packaging: type,
            quantity: 1
        }
        if (type === 'Pallet') {
            item['length'] = 40
            item['width'] = 48
            item['height'] = 50
        }
        this.request.items.push(item)
    }
}