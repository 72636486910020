import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BaseComponent } from '@abstract/BaseComponent';
import { VehicleTypeService } from '@services/vehicle.service';

@Component({
  selector: '[vehicle-type-selector]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss',]
})
export class VehicleTypeSelector extends BaseComponent {
    types: any[] = []
    vehicleTypeService: VehicleTypeService = null
    vehicleType = null
    public options: any[] = []
    @Output() onNewValue: EventEmitter<any> = new EventEmitter<any>();

    constructor() {
        super()
        this.vehicleTypeService = new VehicleTypeService(this.api)
        this.loadVehicles()
    }

    onValueChange(event) {
        this.onNewValue && this.onNewValue.emit(event)
    }

    loadVehicles() {
        this.vehicleTypeService.listAll(
            (data) => {
                this.types = data.filter(it => it.active !== false)
                let allTypes = {}
                for (let t of this.types) {
                    allTypes[t.code] = t
                }
                this.options = this.types.filter(it => !!it.selectable).filter(it => it.quotable !== false).map(t => Object.assign({}, {
                    label: t.name,
                    groupLabel: allTypes[t.parent]?.name,
                    value: t.code,
                    disabled: !t.selectable
                }))
            },
            () => {
                this.types = []
                this.options = []
            }
        )
    }
}