import { Component, Input } from '@angular/core';
import { Const } from '@const/Const';
import { BaseDialog } from '@dialogs/base-dlg';
import { DateUtil } from '@services/date-utils';
import { Shipment, ShipmentItem } from '@wearewarp/types/data-model';

@Component({
  selector: '[shipment-exfreight-quote]',
  templateUrl: './index.html',
  styleUrls: ['../../../dialogs/dialogs.scss', './index.less' ]
})
export class ShipmentExfreightQuote extends BaseDialog {
  @Input() shipment: Shipment;
  @Input() items: any[];
  @Input() onReloadData;

  listDataQuote = []
  listDataQuoteSortASAPDeliveries = [];
  dataQuotes = [];
  quotesOfPage = [];
  isLoading = false;
  pageIndex = 1;
  pageSize = 10;
  isShowCarrierName = false;
  filters: any;
  logId: string;
  constructor() {
    super();
  }

  onSortListDataQuote(value) {
    return value.sort((a,b)=>{
      if (a?.legs[0]?.scheduled_delivery_date && b?.legs[0]?.scheduled_delivery_date) {
        return new Date(a?.legs[0]?.scheduled_delivery_date).getTime() - new Date(b?.legs[0]?.scheduled_delivery_date).getTime()}
      }
    );
  }


  onBoookLoadBtn(quote) {
    this.closeDialog();
    this.onReloadData();
  }
  
  ngOnInit(): void {
   
    const filters = this.getDataRequestExfreightQuote();
    const requestedPickDate =  filters.pickDate;
    const logInfo = {
      requestPickDate: requestedPickDate,
      portal: Const.PORTAL_LIST.web_admin,
    }
    const params = { ...filters, logInfo }
    this.filters = filters;
    const isError = this.checkDataValidateError();
    if (isError) return;
    this.isLoading = true;
    this.api.POST(`${Const.APIURI_FREIGHT_QUOTE}/search`, params).subscribe(
      resp => { 
        this.listDataQuote = (resp.data.quote ?? []).filter(quote => quote.source == Const.QuoteSource.Exfreight);
        this.pageIndex = 1;
        this.quotesOfPage = this.listDataQuote.slice((this.pageIndex - 1) * this.pageSize, this.pageIndex * this.pageSize);
        this.isShowCarrierName = resp.data.isShowCarrierName;
        this.isLoading = false;
        this.logId = resp.data.logId;
      }, err => {
        this.isLoading = false;
        this.showErr(err);
      }
    );
  }

  checkDataValidateError() {
    let isError = false;
    if (!this.items?.length) {
      isError = true;
      this.showErr('Please add an item to this shipment.');
      return true;
    }
    (this.items ?? []).forEach((item) => {
      if (!isError && (!item.length || !item.width || !item.height || !item.weightPerUnit)) {
        this.showErr(`Please update the length, width, height and weight for ${this.items.length == 1 ? 'item' : 'items'} in this shipment.`);
        isError = true;
      }
    });
    return isError;
  }

  getDataRequestExfreightQuote() {
    const pickInfo = this.shipment.deliveryInfos.find(info => info.type == Const.TaskType.PICKUP);
    const dropoffInfo = this.shipment.deliveryInfos.find(info => info.type == Const.TaskType.DROPOFF);
    let pickDate = pickInfo?.windows?.[0]?.from ?? pickInfo?.appointmentInfo?.from;
    pickDate = DateUtil.displayLocalTime(pickDate, { timezone: pickInfo?.addr?.metadata?.timeZoneStandard, format: Const.FORMAT_GUI_DATE_V3 });
    if (!pickDate) {
      this.showErr('dont have pickup date time')
      return;
    }
    const ltlItems = [];
    (this.items ?? []).forEach((item, index) => {
      ltlItems.push({
        qty: item.qty,
        weightPerUnit: item.weightPerUnit,
        weightTotal: item.weightPerUnit * item.qty,
        weightUnit: item.weightUnit,
        length: item.length,
        width: item.width,
        height: item.height,
        name: item.name ?? `Item ${index + 1}`
      });
    });
    const data = {
      clientId: this.shipment.clientId,
      pickAddr: {
        zipcode: pickInfo.addr?.zipcode,
        state: pickInfo.addr?.state,
        street: pickInfo.addr?.street,
        city: pickInfo.addr?.city
      },
      dropAddr: {
        zipcode: dropoffInfo.addr?.zipcode,
        state: dropoffInfo.addr?.state,
        street: dropoffInfo.addr?.street,
        city: dropoffInfo.addr?.city
      },
      pickZipcode: pickInfo.addr?.zipcode,
      dropZipcode: dropoffInfo.addr?.zipcode,
      pickDate,
      packaging: {
        packagingType:  Const.FreightQuotePackageType.Pallet,
        equipment: Const.DatEquipments.REEFER,
        ltlItems,
      }
    };
    return data;
  }

  changePageIndex(value) {
    this.pageIndex = value;
    this.quotesOfPage = this.listDataQuote.slice((this.pageIndex - 1) * this.pageSize, this.pageIndex * this.pageSize);
  }

  getDescriptionShipmentItem(item) {
    let ret = item.name;
    ret += `(${item.length ?? 0} L x ${item.width ?? 0} W x ${item.height ?? 0} H; ${item.weightTotal} ${item.weightUnit}; Quantity: ${item.qty})`;
    return ret;
  }
}