import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DataorchService } from "@services/dataorch.service";
import dayjs from "dayjs";
import _ from "underscore";

@Component({
    selector: '[linehaul-roll-route-form]',
    templateUrl: './index.html',
    styleUrls: ['./index.scss']
})
export class LinehaulRollRouteForm extends BaseComponent {
    dataorch: DataorchService
    public date: any = null
    public isReady: boolean = false
    public rolling: boolean = false
    public today = dayjs().startOf('day')

    @Input() job

    @Input() onClose
    @Input() onOk

    constructor() {
        super()
        this.dataorch = new DataorchService(this.api)
    }

    checkReady(event) {
        if (!this.date) {
            this.isReady = false
            return
        }
        if (!this.job.pickDt) {
            console.log(`Something is wrong job ${this.job.id} currently does not have pickup time`)
            this.isReady = false
            return
        }

        const jobDate = dayjs(this.job.pickDt.time).tz( this.job.pickDt.timezone )
        const d = dayjs(this.date).tz(this.job.pickDt.timezone, true)

        if (d.startOf('day').isSame(jobDate.startOf('day'))) {
            this.isReady = false
            return
        }
        this.isReady = true
    }

    disabledDate = (current: Date): boolean => dayjs(current).isBefore(this.today)

    onConfirm() {
        this.rolling = true
        // determine days
        const jobDate = dayjs(this.job.pickDt.time).tz( this.job.pickDt.timezone ).startOf('day')
        const d = dayjs(this.date).tz(this.job.pickDt.timezone, true).startOf('day')
        const days = d.diff(jobDate, 'day')
        
        if (days == 0) {
            console.log(jobDate, d, days, "Nothing to roll")
            this.rolling = false
            return
        }

        this.dataorch.rollJob(this.job.id, days).subscribe(res => {
            console.log(res)
            this.rolling = false
            this.onOk && this.onOk()
        })
    }
}