import { Component, ElementRef, ViewChild } from "@angular/core";
import { Const } from "@const/Const";
import { BaseFormDrawer } from "@drawers/base-form";
import { environment } from "@env/environment";
import { MasterData } from "@services/master.data";
import { Utils } from "@services/utils";

@Component({
  selector: '[upload-order-csv]',
  templateUrl: './index.html',
  styleUrls: ['../../../app.scss', '../../../dialogs/dialogs.scss', './index.scss']
})
export class UploadOrderCsv extends BaseFormDrawer {
  // for uploading a list of orders via excel/csv
  protected formGroupDeclaration: FormGroupDeclaration = {
    file: {label: 'File for uploading', required: true, type: 'uploadFile'},
    templateId: {label: 'Template', required: true},
    clientId: {label: 'Customer', required: true, placeHolder: 'Please select customer'},
    warehouseId: {label: 'Warehouse', required: false},
    shipmentType: {label: 'Shipment Type', required: false},
  };
    
  isRequired(key): boolean {
    switch (key) {
      case 'pickupWindows': return false;
      case 'dropoffWindows': return true;
      default: return super.isRequired(key);
    }
  }
  
  public listShipmentTypes = Const.ShipmentTypesArray;
  public templateLink;
  public listClients = [];
  public listWarehouses = [];
  public templates = [];
  public selectedTemplate = null;
  public loadingTemplate = false;
  public client = null;
  public get txtBtnOK() {return 'Upload'}
  public get iconBtnOK() {return 'upload'}
  public get noDataWarehouse() {
    if (!this.formInput.get('clientId').value) return 'Please select customer first';
    return 'No data';
  }
  
  public errMsg;
  public err;
  public get labelSelectFile(): string {
    let key = 'file';
    return this.hasAttachedFile(key) ? this.getFileDesc(key) : 'Select file (excel/csv)'
  }
  
  @ViewChild('inputFile') inputFile: ElementRef<HTMLInputElement>;
  
  constructor() {
    super();
  }
  
  ngOnInit() {
    let listTemplates = MasterData.getTemplateOrder();
    if (Utils.isArrayNotEmpty(listTemplates)) {
      this.templateLink = listTemplates[0];
    }
    super.ngOnInit();
  }
  
  getTemplateName(url: string): string {
    let arr = url.split('/');
    return arr[arr.length - 1];
  }
  
  protected getApiUrl(): string {
    return `${environment.backendUrl}/v2/orders/import`
    // return `${Const.APIURI_ORDERS_V4}/import`;
  }

  loadTemplates() {
    this.templates = []
    this.selectedTemplate = null
    this.loadingTemplate = true

    // call api
    let url = `${environment.backendUrl}/v2/csv-upload-template`;
    this.api.GET(url).subscribe(
      (resp) => {
        this.templates = resp;
        console.log(this.templates)
        this.loadingTemplate = false

        if (this.templates.length == 1) {
            this.formInput.get('templateId').setValue(this.templates[0].id);
            this.selectedTemplate = this.templates[0]
          } else {
            this.formInput.get('templateId').setValue(null);
            this.selectedTemplate = null
          }
      },
      (err) => {
        this.templates = []
        this.selectedTemplate = null
        this.loadingTemplate = false
      }

    )
  }
  
  onClientChange(itemId) {
    this.client = this.listClients.filter(it => it._id == itemId)[0] || {};
    if (Utils.isArrayNotEmpty(this.client.warehouses)) {
      this.listWarehouses = this.client.warehouses;
    } else {
      this.listWarehouses = [];
    }
    this.formInput.get('warehouseId').setValue(null);
    this.loadTemplates()
  }

  onTemplateChange(itemId) {
    this.selectedTemplate = this.templates.filter(it => it.id == itemId)[0] || null;
  }

  protected getFormData_JSON(isCreateNew: boolean) {
    let warehouseId = this.getItemValue('warehouseId');
    let warehouse = this.listWarehouses.filter(it => it._id == warehouseId)[0];
    let timezone = warehouse?.pickAddr?.metadata?.timeZoneStandard;

    let json: any = super.getFormData_JSON(isCreateNew);
    // for (let key of keys) {
    //   for (let i = 0; i < json[key].length; i++) {
    //     json[key][i] = times[key][i];
    //   }
    // }
    return json;
  }
  
  protected showErr(err) {
    console.log(err)
    this.err = err
    this.errMsg = Utils.getErrorString(err);
  }
  
  protected showInfo(message: any): void {
    // do nothing
  }
  
  protected startProgress() {
    super.startProgress();
    this.errMsg = '';
    this.err = null
  }

}