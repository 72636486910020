import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { DataorchService } from "@services/dataorch.service";
import _ from "underscore";

@Component({
    selector: 'warehouse-task-chip',
    template: '<nz-tag [nzColor]="color"><span nz-icon nzType="check-circle" class="right3" *ngIf="success"></span><span nz-icon nzType="question-circle" nzTheme="outline" class="right3" *ngIf="!success"></span>{{ name }}</nz-tag>',
})
export class WarehouseTaskChip extends BaseComponent {
    _task: any
    name: string = null
    success: boolean = false
    color: string = null
    icon: string = null

    @Input() set task(v) {
        this._task = v
        this.name = this.getName(v.type)
        this.success = v.status === 'completed'
        this.color = this.success ? 'success' : 'default'
    }

    getName(type) {
        const nameMap = {
            addWeight: 'Weight',
            uploadProductPhoto: 'Photo',
            uploadBOL: 'BOL'
        }
        return nameMap[type] || type
    }
}