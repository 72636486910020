import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BaseComponent } from '@abstract/BaseComponent';
import { Const } from '@const/Const';
import { DialogService } from '@dialogs/dialog.service';
import { ConfirmShipmentDetail } from '../../confirm-shipment-detail';
import { InputHelper } from '@services/input-helper';
import dayjs from 'dayjs';
import { DateUtil } from '@services/date-utils';
import { ConfirmShipmentBeforeBook } from '../../confirm-shipment-before-book';

@Component({
  selector: '[freight-quote-item]',
  templateUrl: './index.html',
  styleUrls: ['./index.less' ]
})
export class FreightQuoteItem extends BaseComponent {

  @Input() quote: any;
  @Input() filters: any;
  @Input() routers: any;
  @Input() isShowCarrierName = false;
  @Input() isHidenBtnLoadBook: boolean = false;
  @Input() shipment: any;
  @Input() logId: string;
  @Output() onBoookLoadBtn: EventEmitter<any> = new EventEmitter<any>();

  truckImgs = {
    sprinterVan: {src: "/assets/img/Warp-SprinterVan2.png", title: 'Sprinter Van', class: 'small-truck'},
    ftl: {src: "/assets/img/Warp-FullTrailer1.png", title: 'FTL', class: 'large-truck'},
    boxTruck: {src: "/assets/img/Warp-BoxTruck2.png", title: 'Box Truck', class: 'medium-truck'},
    consolidated: {src: "/assets/img/Warp-Trucks1.png", height: 60, title: 'Consolidated', class: 'large-truck'},
  };

  truckTitles = {
    sprinterVan: 'W/:\\RP Sprinter Van',
    ftl: 'W/:\\RP FTL',
    ltl: 'W/:\\RP LTL',
    boxTruck: 'W/:\\RP Box Truck',
    consolidated: 'W/:\\RP Consolidated',
  };

  constructor() {
    super();
  }
  
  ngOnInit(): void {
  } 

  onClickBookLoadBtn(quote) {
    if (this.shipment) {
      DialogService.openDialog(ConfirmShipmentBeforeBook, {
        nzWidth: this.routers?.length > 0 ? 960 : 480,
        nzMaskClosable: false,
        nzComponentParams: {
          quote: quote,
          filters: this.filters,
          shipment: this.shipment,
          logId: this.logId,
          onClose: () => this.onConfirmShipmentDetail(quote)
        },
        nzClassName: 'modal-no-padding modal-service-option',
      });
    } else {
      DialogService.openDialog(ConfirmShipmentDetail, {
        nzWidth: this.routers?.length > 0 ? 960 : 480,
        nzMaskClosable: false,
        nzComponentParams: {
          quote: quote,
          routers: this.routers,
          shipment: this.filters,
          deliveryDate: this.deliveryDate,
          onConfirmShipmentDetail: () => this.onConfirmShipmentDetail(quote)
        },
        nzClassName: 'modal-no-padding modal-service-option',
      });
    }
  }

  onConfirmShipmentDetail(quote) {
    this.onBoookLoadBtn.emit(quote);
  }

  get totalCharge() {
    if (this.quote?.total_charge?.value) {
      let cost = this.quote.total_charge.value;
      if (this.quote?.total_charge?.unit == Const.MonenyUnits.us_cent) {
        cost = Const.convertCentToDollar(cost);
      }
      return InputHelper.formatMoney2(cost);
    }
    if (this.quote?.totalPrice) {
      return Const.convertCentToDollar(this.quote.totalPrice)
    }
    return 'N/A';
  }

  get truckImage() {
    let img = this.truckImgs.boxTruck;
    if (this.quote?.legs?.length > 1) {
      img = this.truckImgs.consolidated;
    }
    if (this.quote.type == 'fcl' || this.quote?.source == Const.QuoteSource.DAT) {
      img = this.truckImgs.ftl;
    }
    return img;
  }
  get truckImages() {
    if (this.quote?.orders) {
      return this.quote.orders.map(o => {
        if (o.shipmentType === 'FTL') return this.truckImgs.ftl;
        if (o.equipmentType === 'SPRINTER') return this.truckImgs.sprinterVan;
        if (o.equipmentType === 'BOX_TRUCK_26') return this.truckImgs.boxTruck;
        if (o.equipmentType === 'TRAILER_53') return this.truckImgs.ftl;
        return this.truckImgs.ftl;
      })
    }
    return [this.truckImage]
  }

  get titleTruck() {
    if (this.isShowCarrierName && this.quote.legs?.[0]?.carrier?.name) {
      let name = `${this.quote.legs?.[0]?.carrier?.name}`;
      const carrier_service_description = this.quote?.bill_of_lading_details?.carrier_service_description;
      if (carrier_service_description) {
        name = `${name} ${carrier_service_description}`;
      }
      return name;
    }
    if (this.quote?.orders) {
      return this.truckTitles.consolidated
    }
    if (this.quote?.source == Const.QuoteSource.WARP) {
      return 'WARP Internal LTL';
    }
    if (this.quote?.source == Const.QuoteSource.DAT) {
      return this.truckTitles.ftl;
    }
    return this.truckTitles.ltl;
  }
  get nameCarrier() {
    return this.quote.legs?.[0]?.carrier?.name ?? 'W/:\\RP';
  }
  get deliveryDate() {
    if (this.quote.legs.length) {
      return DateUtil.format(this.quote.legs[this.quote.legs.length - 1]?.scheduled_delivery_date, Const.FORMAT_GUI_DATE_V2);
    }
  }

  public get eda(): string {
    if (!this.quote?.plusDays) return ''
    const date = dayjs().add(this.quote.plusDays || 0, 'day')
    const { eta_time } = this.quote?.order?.metadata || {}
    const eta = eta_time ? date.add(parseInt(eta_time), 'second') : date
    return `${eta.format(Const.FORMAT_GUI_DATE_V2)}`
  }

  get renderDeliveryDate() {
    if (this.quote.legs.length) {
      return `Estimated Delivery ${this.deliveryDate}`;
    }
    if (this.quote?.plusDays) return `Estimated Delivery ${this.eda}`;
    return 'N/A';
  }
}