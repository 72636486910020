import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { BaseComponent } from '@abstract/BaseComponent';
import { environment } from '@env/environment';
import { Utils } from '@services/utils';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: '[planning-session-detail-screen]',
  template: '<div *ngIf="id" active-planning-session-detail [id]="id" class="screen-container"></div>',
  styleUrls: ['./screen.scss']
})
export class PlanningSessionDetailScreen extends BaseComponent {
    id: string = null
    constructor(protected activatedRoute: ActivatedRoute) {
        super();
        activatedRoute.params.subscribe(params => {
            this.id = params['id'];
        })
    }
}