<nz-table [nzData]="['']" [nzFrontPagination]="false">
    <tbody>
    <tr>
        <td>
            Pickup Location
        </td>
        <td>
            <nz-radio-group [(ngModel)]="_status.PICKUP_LOCATION" nzName="radiogroup">
                <label nz-radio nzValue="SKIP" style="color: #666;">Review Not needed</label>
                <label nz-radio nzValue="NEED_REVIEW" style="color: red;">Need Review</label>
                <label nz-radio nzValue="CONFIRMED" style="color: green;">Confirmed</label>
            </nz-radio-group>
        </td>
    </tr>
    <tr>
        <td>
            Pickup Time
        </td>
        <td>
            <nz-radio-group [(ngModel)]="_status.PICKUP_TIME" nzName="radiogroup">
                <label nz-radio nzValue="SKIP" style="color: #666;">Review Not needed</label>
                <label nz-radio nzValue="NEED_REVIEW" style="color: red;">Need Review</label>
                <label nz-radio nzValue="CONFIRMED" style="color: green;">Confirmed</label>
            </nz-radio-group>
        </td>
    </tr>
    <tr>
        <td>
            Pickup Ready
        </td>
        <td>
            <nz-radio-group [(ngModel)]="_status.PICKUP_READY" nzName="radiogroup">
                <label nz-radio nzValue="SKIP" style="color: #666;">Review Not needed</label>
                <label nz-radio nzValue="NEED_REVIEW" style="color: red;">Need Review</label>
                <label nz-radio nzValue="CONFIRMED" style="color: green;">Confirmed</label>
            </nz-radio-group>
        </td>
    </tr>

    <tr>
        <td>
            Dropoff Location
        </td>
        <td>
            <nz-radio-group [(ngModel)]="_status.DROPOFF_LOCATION" nzName="radiogroup">
                <label nz-radio nzValue="SKIP" style="color: #666;">Review Not needed</label>
                <label nz-radio nzValue="NEED_REVIEW" style="color: red;">Need Review</label>
                <label nz-radio nzValue="CONFIRMED" style="color: green;">Confirmed</label>
            </nz-radio-group>
        </td>
    </tr>
    <tr>
        <td>
            Dropoff Time
        </td>
        <td>
            <nz-radio-group [(ngModel)]="_status.DROPOFF_TIME" nzName="radiogroup">
                <label nz-radio nzValue="SKIP" style="color: #666;">Review Not needed</label>
                <label nz-radio nzValue="NEED_REVIEW" style="color: red;">Need Review</label>
                <label nz-radio nzValue="CONFIRMED" style="color: green;">Confirmed</label>
            </nz-radio-group>
        </td>
    </tr>
    </tbody>
</nz-table>