<div class="tick" (mouseenter)="onMouseIn()" (mouseout)="onMouseOut()" nz-popover [nzPopoverContent]="stopInfo" nzPopoverPlacement="topLeft">
    <div *ngFor="let st of statuses" class="section" [class]="st.status" [style.height]="st.height" [style.bottom]="st.bottom" ></div>
</div>

<ng-template #stopInfo>
    <div class="header">
        <div>{{ _stop.address.street }}</div>
        <div>{{ _stop.address.city }}, {{ _stop.address.state }} {{ _stop.address.zipcode }}</div>
    </div>
    <div *ngFor="let task of _stop.tasks">
        <normalized-string [str]="task.type"></normalized-string> <code class="id right5">{{ task.metadata?.shipmentWarpId ?? task.shipmentId }}</code>
        <span [class]="'status-' + task.status">{{ task.status }}</span>
        <span *ngIf="task.updateAt && task.status != 'created'"> @ {{ task.updateAt }}</span>
    </div>
</ng-template>