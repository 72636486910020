<form nz-form [formGroup]="formInput" class="form-detail quote-filter">
  <div class="group no-padding" style="margin-bottom: 100px">
    <div class="line-group"></div>
    <div class="" style="position: relative">
      <div style="position: absolute; height: 24px; top: 155px; border-left: 2px dashed #92929D;left: 18px;"></div>
    </div>
    <div class="group-content">
      <div *ngFor="let key of ['clientId']">
        <nz-form-item>
          <nz-form-control>
            <select-by-searching
              [version]="2"
              [ngClass]="{'error': errors[key]}"
              [formControlName]="key"
              [(listData)]="listClients"
              (modelChange)="clientModelChange($event)"
              placeholder="Select Customer"
            >
            </select-by-searching>
          </nz-form-control>
        </nz-form-item>
      </div>

      <nz-form-item *ngFor="let key of ['pickZipcode', 'dropZipcode']">
        <ng-template #tplIconLocation><i nz-icon nzType="environment"></i></ng-template>
        <ng-template #tplInfo>
          <i *ngIf="zipcodeInfo[key]?.isLoading" nz-icon nzType="loading" nzTheme="outline"></i>
          <div *ngIf="!zipcodeInfo[key]?.isLoading && zipcodeInfo[key]?.desc">{{zipcodeInfo[key].desc}}</div>
        </ng-template>
        <nz-form-control>
          <nz-input-group [nzPrefix]="tplIconLocation" [nzSuffix]="tplInfo" [ngClass]="{'error': errors[key]}">
            <input nz-input [formControlName]="key"
              [placeholder]="getPlaceHolder(key)"
              (input)="onInputChanged($event, key)"
              (keypress)="onInputKeyPress($event, key)"
              (focusout)="onInputFocusOut($event, key)">
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      
      <ng-container *ngFor="let key of ['pickDate']">
        <div class="top20">
          <div [isError]="errors?.pickDate" pick-date #pickDate placeholder="Requested pickup date" (onDone)="onBtnDone($event, 'pickDate')"></div>
        </div>
      </ng-container>
      <ng-container *ngFor="let key of ['packaging']">
        <div class="top20">
          <div [isError]="errors?.packaging" packaging #packaging placeholder="Packaging" (onDone)="onBtnDone($event, 'packaging')"></div>
        </div>
      </ng-container>
    </div>
    <div class="group-content top30">
      <div class="title-header">ADVANCE OPTIONS</div>
      <div class="top20">
        <div service-option #serviceOptionsComponent preffixIcon="/assets/img/pickup_ic.png" [options]="serviceOptions[0].items" placeholder="Pickup Services" (onDone)="onBtnDone($event, 'pickupServices')"></div>
      </div>
      <div class="top20">
        <div service-option #serviceOptionsComponent preffixIcon="/assets/img/dropoff_ic.png" [options]="serviceOptions[1].items" placeholder="Delivery Services" (onDone)="onBtnDone($event, 'dropoffServices')"></div>
      </div>
    </div>
    <div class="group-content shipment-detail top30">
      <div class="title-header">SHIPMENT DETAILS</div>
      <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 16, lg: 16 }">
        <div nz-col class="gutter-row" nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
          <div class="includes-item" [ngClass]="getValueByKey('isHazardous') ? 'selected' : ''" (click)="onClickIncludesItem('isHazardous')"><img src="/assets/img/icon-haza.png" /> Includes Hazardous Items</div>
        </div>
        <!-- <div nz-col class="gutter-row" nzXs="24" nzSm="12" nzMd="24" nzLg="24" nzXl="12">
          <div class="includes-item" [ngClass]="getValueByKey('isTemperatureControlled') ? 'selected' : ''" (click)="onClickIncludesItem('isTemperatureControlled')"><img src="/assets/img/icon-temp.png" /> Requires Temperature Control</div>
        </div> -->
      </div>
    </div>
    <div class="button-search bottom30">
      <button style="width: 100%; padding: 19px 15px; height: 65px;" 
        nz-button nzSize="large" class="" (click)="onBtnFilter()" [disabled]="isLoading">
        <ng-container *ngIf="isLoading">
          <i nz-icon nzType="loading" nzTheme="outline"></i>
        </ng-container>
        {{textBtnSearch}}
      </button>
    </div>
  </div>
</form>
