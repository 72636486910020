import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { Utils } from "@app/admin/components/map";
import { BizUtil } from "@services/biz";

@Component({
    selector: '[shipment-quickview]',
    templateUrl: './index.html',
    styleUrls: ['./index.scss']
})
export class ShipmentQuickView extends BaseComponent {
    _shipment: any
    displayInfo: any = {}
    get shipment() {
        return this._shipment
    }
    @Input() set shipment(v) {
        this._shipment = v
        this.processShipment()
    }

    processShipment() {
        const { client, metadata } = this._shipment || {}
        const pickup = BizUtil.getPickInfo(this._shipment)
        const dropoff = BizUtil.getDropInfo(this._shipment)
        this.displayInfo = {
            clientName: client?.name || metadata?.client?.name,
            pickupTime: Utils.getDisplayTime(pickup),
            dropoffTime: Utils.getDisplayTime(dropoff),
            pickup,
            dropoff
        }
    }
}