<form nz-form *ngIf="formInput" [formGroup]="formInput" class="form-detail">
  <div class="box-with-title">
    <div class="box-title">Template Information</div>
    <div class="box-content">
      <div nz-row [nzGutter]="{ md: 16}">
        <div *ngFor="let key of ['name', 'type', 'zplString']; let i = index" nz-col [nzSpan]="12">
          <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
          <nz-form-item>
            <nz-form-control>
              <ng-container [ngSwitch]="key">
                <nz-select *ngSwitchCase="'type'"
                  [formControlName]="key"
                  [nzPlaceHolder]="getPlaceHolder(key)"
                >
                  <nz-option *ngFor="let type of types" [nzValue]="type.value" [nzLabel]="type.label"></nz-option>
                </nz-select>
                <textarea *ngSwitchCase="'zplString'" nz-input
                  [placeholder]="getPlaceHolder(key)"
                  [nzAutosize]="{ minRows: 4, maxRows: 15 }"
                  [formControlName]="key"
                ></textarea>
                <input *ngSwitchDefault nz-input [formControlName]="key"
                  [type]="getInputType(key)"
                  (input)="onInputChanged($event, key)"
                  (keypress)="onInputKeyPress($event, key)"
                />
              </ng-container>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>
  </div>

  <div nz-row [nzGutter]="16">
    <div nz-col nzSpan="24">
      <div class="box-with-title">
        <div class="box-title">Variables</div>
        <div class="box-content">
          <ng-container *ngFor="let groupKey of ['variables']" [formArrayName]="groupKey">
            <form *ngFor="let item of getArrayControls(groupKey); let i = index" nz-form [formGroupName]="i">
              <div nz-row [nzGutter]="16">
                <div nz-col *ngFor="let col of row1.columns" [nzSpan]="col.span" [nzOffset]="col.offset">
                  <div *ngIf="col.key !== 'action'">
                    <div class="form-label-v2">
                      {{getLabel(fullKey(groupKey, col.key))}}
                      <span *ngIf="isRequired(fullKey(groupKey, col.key))" class="label-mark-required"></span>
                    </div>
                    <nz-form-item>
                      <nz-form-control>
                        <input nz-input
                          [formControlName]="col.key"
                          [type]="getInputType(fullKey(groupKey, col.key))"
                          [placeholder]="getPlaceHolder(fullKey(groupKey, col.key))"
                          (input)="onInputChanged($event, col.key)"
                          (keypress)="onInputKeyPress($event, col.key)"
                        >
                      </nz-form-control>
                    </nz-form-item>
                  </div>

                  <div *ngIf="col.key === 'action' && (isEditing || isCreateNew)" [ngClass]="col.key">
                    <div class="form-label-v2">&nbsp;</div>
                    <nz-form-item>
                      <nz-form-control>
                        <nz-space class="group-button">
                          <ng-container *ngIf="getArrayControls(groupKey).length > 1 && !isAdminReadOnlyRole && (isCreateNew || isEditing)">
                            <span *nzSpaceItem
                              nz-icon nzType="minus-circle"
                              nzTheme="twotone" [nzTwotoneColor]="'#ff4d4f'"
                              (click)="removeItemInFormArray(groupKey, i)"
                            ></span>
                          </ng-container>
                        </nz-space>
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                </div>
              </div>
            </form>
            <button *ngIf="!isAdminReadOnlyRole && (isCreateNew || isEditing)" nz-button nzType="dashed"
              (click)="addItemToFormArray(groupKey)"><i nz-icon nzType="plus"></i>Add a variable
            </button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div style="height: 50px;">
    <div *ngIf="!isAdminReadOnlyRole" detail-footer-buttons [isCreateNew]="isCreateNew" [isEditing]="isEditing"
      [onProgress]="onProgress" [needUpdate]="needUpdate" (onEdit)="onBtnEdit()" (onSave)="onBtnSave()"
      (onCancel)="onBtnCancel()">
    </div>
  </div>
</form>
