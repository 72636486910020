import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BizUtil } from "@services/biz";
import { DateUtil } from "@services/date-utils";
import _ from 'underscore'
import { BidCard } from "../../bid-card";

@Component({
    selector: '[exported-job-detail]',
    templateUrl: './index.html',
    styleUrls: ['./index.scss', '../index.scss']
})
export class ExportedJobDetail extends BaseComponent {
    _route: any = null
    job: any = null
    bid: any = null
    shipments: any[] = []
    earliestWindow: string = null
    @Output() onToggleShowRoute: EventEmitter<any> = new EventEmitter<any>()

    get route() {
        return this._route
    }

    @Input() map
    @Input() set route(v) {
        this._route = v
        this.job = v.metadata?.job
        this.bid = v.metadata?.bid
        this.shipments = v.metadata?.shipments || []
        const dropoffs = this.shipments.map(it => BizUtil.getDropInfo(it)).filter(it => it)
        const windows = _.sortBy(dropoffs.map(BizUtil.getTimeWindowForDeliveryInfo).filter(it => it), it => it.window?.to)
        const earliest = windows[0]
        this.earliestWindow = earliest?.window ? DateUtil.displayTimeWindow(earliest.window, {timezone: earliest.tz, format: 'MM/DD h:mm a', formatDateOnly: 'MM/DD'}) : null
    }

    onToggleShow() {
        this.onToggleShowRoute.emit(this._route)
    }

    public onBtnViewBid(event) {
        event.stopPropagation()
        if (!this.bid) {
            return
        }
        let modalRef: any = null
        modalRef = this.modalService.create({
            nzContent: BidCard,
            nzWidth: 900,
            nzComponentParams: {
                data: {
                    id: this.bid.id,
                    job: this.job,
                    // bid: this.bid
                },
                onBidAccepted: this.onBidAccepted
            },
            nzFooter: null
        })
    }

    onBidAccepted({bid, job, offer}) {
        this.job = job
        this._route.metadata.job = job
    }
}