import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { RouterModule } from '@angular/router';
import { StopListItem } from './stop_list_item';
import { RouteOrder } from './route_order';
import { FormsModule } from '@angular/forms';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { MapModule } from '../components/map/module';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzBadgeModule } from 'ng-zorro-antd/badge';


import { UiCommonModule } from '../components/common/module';

import { PlanningSessionModule } from './session-detail/module';

import { DeliveryPlanning } from '.';
import { PlanningRouteDialog } from './dialog';
import { DeliveryPlanningScreen } from './screen';
import { ActivePlanningSessionList } from './sessions';

import { PlanningSessionDetailScreen } from './session-detail-screen';
import { AddShipmentForm } from './sessions/add-shipment-form';
import { NeedRoutingScreen } from './need-routing';
import { ShipmentQuickView } from './shipment-quickview';
import { NeedRoutingShipmentList } from './need-routing/shipment-list';
import { UpdateShipmentReviewStatusForm } from './update-review-status';
import { TransitNetworkModule } from '../transit-network/module';
import { PlanningLinehaulModule } from './linehaul/module';
import { LtlVolumeScreen } from './volume';
import { PlanningShipmentsComponent } from './need-routing/shipment-list/planning-shipments';
import { RollShipmentForm } from './roll-shipment-form';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        NzButtonModule,
        NzGridModule,
        NzIconModule,
        FormsModule,
        NzInputModule,
        NzListModule,
        NzTabsModule,
        NzInputNumberModule,
        UiCommonModule,
        NzDividerModule,
        NzCardModule,
        NzPopoverModule,
        NzDropDownModule,
        NzBadgeModule,
        MapModule,
        NzTableModule,
        NzCheckboxModule,
        NzSelectModule,
        NzToolTipModule,
        NzRadioModule,
        TransitNetworkModule,
        NzDatePickerModule,
        NzSwitchModule,
        PlanningSessionModule,
        PlanningLinehaulModule,
    ],
    declarations: [
        DeliveryPlanning,
        StopListItem,
        RouteOrder,
        PlanningRouteDialog,
        DeliveryPlanningScreen,
        ActivePlanningSessionList,
        PlanningSessionDetailScreen,
        AddShipmentForm,
        NeedRoutingScreen,
        ShipmentQuickView,
        NeedRoutingShipmentList,
        UpdateShipmentReviewStatusForm,
        LtlVolumeScreen,
        PlanningShipmentsComponent,
        RollShipmentForm,
    ],
    exports: [
        DeliveryPlanning,
        NeedRoutingScreen,
        LtlVolumeScreen,
    ],
    providers: [
    ]
})

export class DeliveryPlanningModule {}