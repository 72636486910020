<!-- List of shipments -->
<div class="bottom15">
    <button nz-button class="right20" (click)="onRefreshBtn()">Refresh <span nz-icon nzType="loading" nzTheme="outline" *ngIf="loading"></span></button>
    <button nz-button (click)="onCreateNewRouteBtn(null)">Create new Route</button>
</div>

<!-- Scheduled for outbounds -->
<ng-container *ngIf="scheduledForOutbound?.jobs">
    <div class="info flex bottom10">
        <div>
            <h3>
                <span nz-icon nzType="book" nzTheme="outline"></span>
                Scheduled Routes
            </h3>
        </div>
        <div class="flex1 divider"></div>
        <div>Total Jobs: <strong>{{ scheduledForOutbound.jobs.length }}</strong></div>
        <nz-divider nzType="vertical"></nz-divider>
        <div class="pallet-count">Total Shipments: <strong>{{ scheduledForOutbound.shipmentCount }}</strong></div>
    </div>

    <div *ngFor="let job of scheduledForOutbound.jobs" class="bottom15">
        <div class="flex bottom5">
            <div class="clickable" (click)="job.show = !job.show">
                <span nz-icon [nzType]="job.show ? 'down' : 'right'" nzTheme="outline"></span>
                <code class="box right5">{{ job.code }}</code>
                <span class="right5" *ngIf="job.pickDt?.time">
                    <span class="right3" nz-icon nzType="clock-circle" nzTheme="outline"></span>
                    <time-window-str [data]="{from: job.pickDt.time, to: job.pickDt.timeTo, tz: job.pickDt.timezone}"></time-window-str>
                </span>
                {{ job.status }}
                <span *ngIf="job.onHold" class="on-hold">HOLD</span>
            </div>
            <div class="left10">
                <a [routerLink]="[routeAdminDispatchList, job.id]" target="_blank">
                    <span nz-icon nzType="link" nzTheme="outline"></span>
                </a>
            </div>
            <div class="left10" *ngIf="showEvents">
                <span class="clickable" nz-icon nzType="history" nzTheme="outline" (click)="showEventHistory('JOB', job.id)"></span>
            </div>
            <div class="flex1 divider dashed"></div>
            <div>Total Shipments: {{ job.shipmentCount }}</div>
            <nz-divider nzType="vertical"></nz-divider>
            <div class="pallet-count">Pallets: {{ job.palletCount ?? 0 }}</div>
        </div>
        <div *ngIf="job.show" class="job-info flex bottom15">
            <div class="right15">
                <div class="title">Vehicle</div>
                <div>
                    <div class="strong" *ngIf="job.requiredVehicle">
                        {{ job.requiredVehicle.name }}
                        <span *ngIf="job.requiredVehicle.options?.length">
                            w/ <code class="right5" *ngFor="let o of job.requiredVehicle.options">{{ o }}</code>
                        </span>
                    </div>
                    <div class="unknown" *ngIf="!job.requiredVehicle">
                        N/A
                    </div>
                </div>
            </div>
            <div class="right15">
                <div class="title">Carrier</div>
                <div>
                    <div class="strong" *ngIf="job.carrier">
                        {{ job.carrier?.basicInfo?.name }}
                    </div>
                    <div *ngIf="!job.carrier">
                        No Carrier Assigned
                    </div>
                </div>
            </div>
            <div class="right15">
                <div class="title">Cost</div>
                <div>
                    <div class="strong" *ngIf="job.assignedCarrier?.cost">
                        ${{ job.assignedCarrier?.cost.grandTotal }}
                    </div>
                    <div class="unknown" *ngIf="!job.assignedCarrier?.cost">
                        N/A
                    </div>
                </div>
            </div>
            <div class="right15">
                <div class="title">Ref#</div>
                <div (click)="onEditJobRefNums(job)" class="clickable" style="max-width: 480px;">
                    <span *ngIf="job.refNums?.length">
                        <code *ngFor="let ref of job.refNums">
                            {{ ref.type }}: {{ ref.number }}
                        </code>
                    </span>
                    <span *ngIf="!job.refNums?.length" class="unknown">N/A</span>
                </div>
            </div>
            <div>
                <div class="title">Notes</div>
                <div (click)="onEditJobNotes(job)" class="clickable" style="max-width: 480px;">
                    <span *ngIf="job.notes">{{ job.notes }}</span>
                    <span *ngIf="!job.notes" class="unknown">N/A</span>
                </div>
            </div>
        </div>
        <div *ngIf="job.show" class="flex bottom10 buttons">
            <div *ngIf="!job.shipments?.length" class="right20">
                <button nzSize="small" nzDanger nz-button (click)="onRemoveJobBtn(job)">Remove</button>
            </div>
            <div class="right5" *ngIf="!job.onHold">
                <button nzSize="small" nz-button (click)=onHoldJobBtn(job)>Put on Hold</button>
            </div>
            <div class="right5" *ngIf="job.onHold">
                <button nzSize="small" nz-button (click)=onUnholdJobBtn(job)>Un-Hold</button>
            </div>
            <div>
                <button nzSize="small" nz-button (click)=onRollRouteBtn(job)>Roll Route</button>
            </div>
            <div>
                <button nzSize="small" nz-button (click)=onChangePickupBtn(job)>Change Pickup Time</button>
            </div>
            <nz-divider nzType="vertical"></nz-divider>
            <div class="right10">
                <button nzSize="small" nz-button (click)=onExportJobManifest(job)>
                    <span nz-icon nzType="download" nzTheme="outline"></span> Manifests
                    <span nz-icon nzType="loading" nzTheme="outline" *ngIf="isExporting"></span>
                </button>
            </div>
            <div>
                <button nzSize="small" nz-button (click)=onDownloadJobShipmentBOLs(job)>
                    <span nz-icon nzType="download" nzTheme="outline"></span> Shipment BOLs
                    <span nz-icon nzType="loading" nzTheme="outline" *ngIf="loadingBol"></span>
                </button>
            </div>
            <nz-divider nzType="vertical"></nz-divider>
            <div class="right10" *ngIf="job.bol">
                <ng-container *ngTemplateOutlet="downloadFile; context: {$implicit: job.bol}"></ng-container>
            </div>
            <div *ngIf="job.loadTender">
                <ng-container *ngTemplateOutlet="downloadFile; context: {$implicit: job.loadTender}"></ng-container>
            </div>
            <ng-container *ngIf="job.saks">
                <nz-divider nzType="vertical"></nz-divider>
                <div class="right5">
                    <button nzSize="small" nz-button (click)=downloadSaksManifest(job)>
                        <span nz-icon nzType="download" nzTheme="outline" *ngIf="!job.isExportingSaksManifest"></span>
                        <span nz-icon nzType="loading" nzTheme="outline" *ngIf="job.isExportingSaksManifest"></span>
                        Saks Manifests
                    </button>
                </div>
            </ng-container>
            <ng-container *ngIf="job.setOfCheckedIds?.size">
                <nz-divider nzType="vertical"></nz-divider>
                <div>
                    <button class="danger" nzSize="small" nz-button (click)=onRemoveShipmentBtn(job)>Remove Shipment</button>
                </div>
            </ng-container>
        </div>
        <nz-table *ngIf="job.show" [nzData]="job.shipments" nzSize="small" [nzShowPagination]="false">
            <thead>
                <tr>
                    <th [nzChecked]="job.allChecked" (nzCheckedChange)="onAllChecked(job, $event)"></th>
                    <th nzWidth="120px">Main Shipment</th>
                    <th nzWidth="100px">Warp ID</th>
                    <th nzWidth="180px">Customer</th>
                    <th nzWidth="280px">Main Shipment's Ref#</th>
                    <th nzWidth="120px">Inventory</th>
                    <th nzWidth="240px">Inbound Leg</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let shipment of job.shipments">
                <tr (click)="onOpenShipment($event, job, shipment)">
                    <td
                        [nzChecked]="job.setOfCheckedIds?.has(shipment.id)"
                        (nzCheckedChange)="onItemChecked(job, shipment.id, $event)"
                    ></td>
                    <ng-container *ngTemplateOutlet="shipmentRow; context: {$implicit: shipment}"></ng-container>
                </tr>
                <tr [nzExpand]="job.expandedSet?.has(shipment.id)">
                    <div *ngIf="job.expandedSet?.has(shipment.id)" linehaul-shipment-content [data]="{shipment,id:shipment.id}"></div>
                </tr>  
                </ng-container>  
            </tbody>
        </nz-table>        
    </div>
    <div class="bottom20"></div>
</ng-container>

<!-- ready for outbound -->
<ng-container *ngIf="readyForOutbound?.shipments">
    <ng-container *ngTemplateOutlet="shipmentListHeader; context: {$implicit: readyForOutbound, title: 'Ready For Outbound'}"></ng-container>
    <ng-container *ngIf="readyForOutbound.show && readyForOutbound.setOfCheckedIds?.size">
        <div class="bottom20 flex buttons">
            <div class="right20">
                Selected: <strong>{{ readyForOutbound.selectedPalletCount }}</strong> Pallets
            </div>
            <div class="right15" *ngIf="scheduledForOutbound?.jobs?.length">
                <button nzSize="small" nz-button nz-dropdown nzTrigger="click" [nzDropdownMenu]="scheduledRoutesSelection">Add To Scheduled Route</button>
                <nz-dropdown-menu #scheduledRoutesSelection="nzDropdownMenu">
                    <ul nz-menu>
                      <li nz-menu-item *ngFor="let job of scheduledForOutbound?.jobs" (click)="onSelectJobForShipmentsBtn(readyForOutbound.setOfCheckedIds, job)">
                        Route <code class="right5 left5">{{ job.code }}</code>
                        <span class="right5"><time-window-str [data]="{from: job.pickDt.time, to: job.pickDt.timeTo, tz: job.pickDt.timezone}"></time-window-str></span>
                        [{{ job.palletCount ?? 0 }} Pallets]
                      </li>
                    </ul>
                  </nz-dropdown-menu>
            </div>
            <div class="right15">
                <button nzSize="small" nz-button (click)="onCreateNewRouteBtn(readyForOutbound.setOfCheckedIds)">Create new Route</button>
            </div>
            <div class="right15"><button nzSize="small" nz-button (click)="copyIds(readyForOutbound.setOfCheckedIds)">Copy Shipment Ids</button></div>
            <div class="right10">
                <button nzSize="small" nz-button (click)=onExportShipmentManifest(readyForOutbound)>
                    <span nz-icon nzType="download" nzTheme="outline"></span> Manifests
                    <span nz-icon nzType="loading" nzTheme="outline" *ngIf="readyForOutbound.isExportingManifest"></span>
                </button>
            </div>
            <div>
                <button nzSize="small" nz-button (click)=onDownloadShipmentsBOLs(readyForOutbound)>
                    <span nz-icon nzType="download" nzTheme="outline"></span> Shipment BOLs
                    <span nz-icon nzType="loading" nzTheme="outline" *ngIf="readyForOutbound.loadingBol"></span>
                </button>
            </div>

        </div>
    </ng-container>
    <nz-table *ngIf="readyForOutbound.show" [nzData]="readyForOutbound.shipments" nzSize="small" [nzShowPagination]="false">
        <thead>
            <tr>
                <th [nzChecked]="readyForOutbound.allChecked" (nzCheckedChange)="onAllChecked(readyForOutbound, $event)"></th>
                <th nzWidth="120px">Main Shipment</th>
                <th nzWidth="100px">Warp ID</th>
                <th nzWidth="180px">Customer</th>
                <th nzWidth="280px">Main Shipment's Ref#</th>
                <th nzWidth="120px">Inventory</th>
                <th nzWidth="240px">Inbound Leg</th>
                <th nzWidth="100px"></th>    
                <th>Received At</th>
            </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let shipment of readyForOutbound.shipments">
            <tr (click)="onOpenShipment($event, readyForOutbound, shipment)">
                <td
                    [nzChecked]="readyForOutbound.setOfCheckedIds?.has(shipment.id)"
                    (nzCheckedChange)="onItemChecked(readyForOutbound, shipment.id, $event)"
                ></td>
                <ng-container *ngTemplateOutlet="shipmentRow; context: {$implicit: shipment}"></ng-container>
                <td>
                    <span *ngIf="shipment.completedInbound">
                        <date-time-str [data]="{time: shipment.completedInbound}"></date-time-str>
                    </span>
                </td>
            </tr>
            <tr [nzExpand]="readyForOutbound.expandedSet?.has(shipment.id)">
                <div *ngIf="readyForOutbound.expandedSet?.has(shipment.id)" linehaul-shipment-content [data]="{shipment,id:shipment.id}"></div>
            </tr>
        </ng-container>
        </tbody>
    </nz-table>
</ng-container>

<!-- received at warehouse for outbound -->
<ng-container *ngIf="arrivedAtWarehouse?.shipments">
    <ng-container *ngTemplateOutlet="shipmentListHeader; context: {$implicit: arrivedAtWarehouse, title: 'Received At Dock'}"></ng-container>
    <ng-container *ngIf="arrivedAtWarehouse.show && arrivedAtWarehouse.setOfCheckedIds?.size">
        <div class="bottom20 flex buttons">
            <div class="right20">
                Selected: <strong>{{ arrivedAtWarehouse.selectedPalletCount }}</strong> Pallets
            </div>
            <div class="right15" *ngIf="scheduledForOutbound?.jobs?.length">
                <button nzSize="small" nz-button nz-dropdown nzTrigger="click" [nzDropdownMenu]="scheduledRoutesSelection">Add To Scheduled Route</button>
                <nz-dropdown-menu #scheduledRoutesSelection="nzDropdownMenu">
                    <ul nz-menu>
                      <li nz-menu-item *ngFor="let job of scheduledForOutbound?.jobs" (click)="onSelectJobForShipmentsBtn(arrivedAtWarehouse.setOfCheckedIds, job)">
                        Route <code class="right5 left5">{{ job.code }}</code>
                        <span class="right5"><time-window-str [data]="{from: job.pickDt.time, to: job.pickDt.timeTo, tz: job.pickDt.timezone}"></time-window-str></span>
                        [{{ job.palletCount ?? 0 }} Pallets]
                      </li>
                    </ul>
                  </nz-dropdown-menu>
            </div>
            <div class="right15">
                <button nzSize="small" nz-button (click)="onCreateNewRouteBtn(arrivedAtWarehouse.setOfCheckedIds)">Create new Route</button>
            </div>
            <div class="right15"><button nzSize="small" nz-button (click)="copyIds(arrivedAtWarehouse.setOfCheckedIds)">Copy Shipment Ids</button></div>
            <div class="right10">
                <button nzSize="small" nz-button (click)=onExportShipmentManifest(arrivedAtWarehouse)>
                    <span nz-icon nzType="download" nzTheme="outline"></span> Manifests
                    <span nz-icon nzType="loading" nzTheme="outline" *ngIf="arrivedAtWarehouse.isExportingManifest"></span>
                </button>
            </div>
            <div>
                <button nzSize="small" nz-button (click)=onDownloadShipmentsBOLs(arrivedAtWarehouse)>
                    <span nz-icon nzType="download" nzTheme="outline"></span> Shipment BOLs
                    <span nz-icon nzType="loading" nzTheme="outline" *ngIf="arrivedAtWarehouse.loadingBol"></span>
                </button>
            </div>

        </div>
    </ng-container>

    <nz-table *ngIf="arrivedAtWarehouse.show" [nzData]="arrivedAtWarehouse.shipments" nzSize="small" [nzShowPagination]="false">
        <thead>
            <tr>
                <th [nzChecked]="arrivedAtWarehouse.allChecked" (nzCheckedChange)="onAllChecked(arrivedAtWarehouse, $event)"></th>
                <th nzWidth="120px">Main Shipment</th>
                <th nzWidth="100px">Warp ID</th>
                <th nzWidth="180px">Customer</th>
                <th nzWidth="280px">Main Shipment's Ref#</th>
                <th nzWidth="120px">Inventory</th>
                <th nzWidth="240px">Inbound Leg</th>
                <th></th>
                <th>Received At</th>
            </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let shipment of arrivedAtWarehouse.shipments">
            <tr (click)="onOpenShipment($event, arrivedAtWarehouse, shipment)">
                <td
                    [nzChecked]="arrivedAtWarehouse.setOfCheckedIds?.has(shipment.id)"
                    (nzCheckedChange)="onItemChecked(arrivedAtWarehouse, shipment.id, $event)"
                ></td>
                <ng-container *ngTemplateOutlet="shipmentRow; context: {$implicit: shipment}"></ng-container>
                <td>
                    <span *ngIf="shipment.completedInbound">
                        <date-time-str [data]="{time: shipment.completedInbound}"></date-time-str>
                    </span>
                </td>
            </tr>
            <tr [nzExpand]="arrivedAtWarehouse.expandedSet?.has(shipment.id)">
                <div *ngIf="arrivedAtWarehouse.expandedSet?.has(shipment.id)" linehaul-shipment-content [data]="{shipment,id:shipment.id}"></div>
            </tr>
        </ng-container>
        </tbody>
    </nz-table>
</ng-container>

<!-- arriving at warehouse for outbound -->
<ng-container *ngIf="arrivingAtWarehouse?.shipments">
    <ng-container *ngTemplateOutlet="shipmentListHeader; context: {$implicit: arrivingAtWarehouse, title: 'Incoming to Dock'}"></ng-container>
    <ng-container *ngIf="arrivingAtWarehouse.show && arrivingAtWarehouse.setOfCheckedIds?.size">
        <div class="bottom20 flex buttons">
            <div class="right20">
                Selected: <strong>{{ arrivingAtWarehouse.selectedPalletCount }}</strong> Pallets
            </div>
            <div class="right15" *ngIf="scheduledForOutbound?.jobs?.length">
                <button nzSize="small" nz-button nz-dropdown nzTrigger="click" [nzDropdownMenu]="scheduledRoutesSelection">Add To Scheduled Route</button>
                <nz-dropdown-menu #scheduledRoutesSelection="nzDropdownMenu">
                    <ul nz-menu>
                      <li nz-menu-item *ngFor="let job of scheduledForOutbound?.jobs" (click)="onSelectJobForShipmentsBtn(arrivingAtWarehouse.setOfCheckedIds, job)">
                        Route <code class="right5 left5">{{ job.code }}</code>
                        <span class="right5"><time-window-str [data]="{from: job.pickDt.time, to: job.pickDt.timeTo, tz: job.pickDt.timezone}"></time-window-str></span>
                        [{{ job.palletCount ?? 0 }} Pallets]
                      </li>
                    </ul>
                  </nz-dropdown-menu>
            </div>
            <div class="right15">
                <button nzSize="small" nz-button (click)="onCreateNewRouteBtn(arrivingAtWarehouse.setOfCheckedIds)">Create new Route</button>
            </div>
            <div class="right15"><button nzSize="small" nz-button (click)="copyIds(arrivingAtWarehouse.setOfCheckedIds)">Copy Shipment Ids</button></div>
        </div>
    </ng-container>
    <nz-table  *ngIf="arrivingAtWarehouse.show" [nzData]="arrivingAtWarehouse.shipments" nzSize="small" [nzShowPagination]="false">
        <thead>
            <tr>
                <th [nzChecked]="arrivingAtWarehouse.allChecked" (nzCheckedChange)="onAllChecked(arrivingAtWarehouse, $event)"></th>
                <th nzWidth="120px">Main Shipment</th>
                <th nzWidth="100px">Warp ID</th>
                <th nzWidth="180px">Customer</th>
                <th nzWidth="280px">Main Shipment's Ref#</th>
                <th nzWidth="120px">Inventory</th>
                <th nzWidth="240px">Inbound Leg</th>
                <th></th>       
                <th nzWidth="240px">Picked up at</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let shipment of arrivingAtWarehouse.shipments">
            <tr (click)="onOpenShipment($event, arrivingAtWarehouse, shipment)">
                <td
                    [nzChecked]="arrivingAtWarehouse.setOfCheckedIds?.has(shipment.id)"
                    (nzCheckedChange)="onItemChecked(arrivingAtWarehouse, shipment.id, $event)"
                ></td>
                <ng-container *ngTemplateOutlet="shipmentRow; context: {$implicit: shipment}"></ng-container>
                <td>
                    <ng-container *ngIf="shipment.inboundLegs[0]?.statusChangeLog?.pickupSuccessful">
                        <span class="icon" nz-icon nzType="calendar" nzTheme="outline"></span>
                        <date-time-str [data]="{time: shipment.inboundLegs[0]?.statusChangeLog?.pickupSuccessful?.changeWhen ?? shipment.inboundLegs[0]?.statusChangeLog?.pickupSuccessful?.when, format: 'MM-DD HH:mm'}"></date-time-str>
                        <span class="icon" class="left10" nz-icon nzType="node-index" nzTheme="outline"></span>
                        <mileage-str [data]="{m: shipment.inboundLegs[0].metadata.traffic.distance, decimals: 0}"></mileage-str>
                    </ng-container>
                </td>
            </tr>
            <tr [nzExpand]="arrivingAtWarehouse.expandedSet?.has(shipment.id)">
                <div *ngIf="arrivingAtWarehouse.expandedSet?.has(shipment.id)" linehaul-shipment-content [data]="{shipment,id:shipment.id}"></div>
            </tr>
            </ng-container>
        </tbody>
    </nz-table>
</ng-container>


<!-- Scheduled Inbound -->
<ng-container *ngIf="scheduledInbound?.shipments">
    <ng-container *ngTemplateOutlet="shipmentListHeader; context: {$implicit: scheduledInbound, title: 'Pickup Scheduled'}"></ng-container>
    <ng-container *ngIf="scheduledInbound.show && scheduledInbound.setOfCheckedIds?.size">
        <div class="bottom20 flex buttons">
            <div class="right20">
                Selected: <strong>{{ scheduledInbound.selectedPalletCount }}</strong> Pallets
            </div>
            <div class="right15" *ngIf="scheduledForOutbound?.jobs?.length">
                <button nzSize="small" nz-button nz-dropdown nzTrigger="click" [nzDropdownMenu]="scheduledRoutesSelection">Add To Scheduled Route</button>
                <nz-dropdown-menu #scheduledRoutesSelection="nzDropdownMenu">
                    <ul nz-menu>
                      <li nz-menu-item *ngFor="let job of scheduledForOutbound?.jobs" (click)="onSelectJobForShipmentsBtn(scheduledInbound.setOfCheckedIds, job)">
                        Route <code class="right5 left5">{{ job.code }}</code>
                        <span class="right5"><time-window-str [data]="{from: job.pickDt.time, to: job.pickDt.timeTo, tz: job.pickDt.timezone}"></time-window-str></span>
                        [{{ job.palletCount ?? 0 }} Pallets]
                      </li>
                    </ul>
                  </nz-dropdown-menu>
            </div>
            <div class="right15">
                <button nzSize="small" nz-button (click)="onCreateNewRouteBtn(scheduledInbound.setOfCheckedIds)">Create new Route</button>
            </div>
            <div class="right15"><button nzSize="small" nz-button (click)="copyIds(scheduledInbound.setOfCheckedIds)">Copy Shipment Ids</button></div>
        </div>
    </ng-container>
    <nz-table  *ngIf="scheduledInbound.show" [nzData]="scheduledInbound.shipments" nzSize="small" [nzShowPagination]="false">
        <thead>
            <tr>
                <th [nzChecked]="scheduledInbound.allChecked" (nzCheckedChange)="onAllChecked(scheduledInbound, $event)"></th>
                <th nzWidth="120px">Main Shipment</th>
                <th nzWidth="100px">Warp ID</th>
                <th nzWidth="180px">Customer</th>
                <th nzWidth="280px">Main Shipment's Ref#</th>
                <th nzWidth="120px">Inventory</th>
                <th nzWidth="240px">Inbound Leg</th>
                <th></th>
                <th>Scheduled Pickup Date</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let shipment of scheduledInbound.shipments">
                <td
                    [nzChecked]="scheduledInbound.setOfCheckedIds?.has(shipment.id)"
                    (nzCheckedChange)="onItemChecked(scheduledInbound, shipment.id, $event)"
                ></td>
                <ng-container *ngTemplateOutlet="shipmentRow; context: {$implicit: shipment}"></ng-container>
                <td>
                    <span *ngIf="shipment.scheduledInbound">
                        <span class="icon" nz-icon nzType="calendar" nzTheme="outline"></span>
                        <date-time-str [data]="{time: shipment.scheduledInbound}"></date-time-str>
                        <span class="icon" class="left10" nz-icon nzType="node-index" nzTheme="outline"></span>
                        <mileage-str [data]="{m: shipment.inboundLegs[0].metadata.traffic.distance, decimals: 0}"></mileage-str>
                    </span>
                </td>
            </tr>
        </tbody>
    </nz-table>
</ng-container>


<!-- Pending for outbound -->
<ng-container *ngIf="pending?.shipments">
    <ng-container *ngTemplateOutlet="shipmentListHeader; context: {$implicit: pending, title: 'Pending'}"></ng-container>
    <ng-container *ngIf="pending.show && pending.setOfCheckedIds?.size">
        <div class="bottom20 flex buttons">
            <div class="right20">
                Selected: <strong>{{ pending.selectedPalletCount }}</strong> Pallets
            </div>
            <div class="right15"><button nzSize="small" nz-button (click)="copyIds(pending.setOfCheckedIds)">Copy Shipment Ids</button></div>
        </div>
    </ng-container>
    <nz-table *ngIf="pending.show" [nzData]="pending.shipments" nzSize="small" [nzShowPagination]="false">
        <thead>
            <tr>
                <th [nzChecked]="pending.allChecked" (nzCheckedChange)="onAllChecked(pending, $event)"></th>
                <th nzWidth="120px">Main Shipment</th>
                <th nzWidth="100px">Warp ID</th>
                <th nzWidth="180px">Customer</th>
                <th nzWidth="280px">Main Shipment's Ref#</th>
                <th nzWidth="120px">Inventory</th>
                <th nzWidth="240px">Inbound Leg</th>
                <th></th>
                <th>Requested Pickup Date</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let shipment of pending.shipments">
                <td
                    [nzChecked]="pending.setOfCheckedIds?.has(shipment.id)"
                    (nzCheckedChange)="onItemChecked(pending, shipment.id, $event)"
                ></td>
                <ng-container *ngTemplateOutlet="shipmentRow; context: {$implicit: shipment}"></ng-container>
                <td>
                    <span *ngIf="shipment.scheduledInbound">
                        <date-time-str [data]="{time: shipment.scheduledInbound}"></date-time-str>
                    </span>
                </td>
            </tr>
        </tbody>
    </nz-table>
</ng-container>

<!-- Departed -->
<ng-container *ngIf="departed?.jobs">
    <div class="info flex top20 bottom10">
        <div>
            <h3>
                <span nz-icon nzType="file-done" nzTheme="outline"></span>
                Departed Routes
            </h3>
        </div>
        <div class="flex1 divider"></div>
        <div>Total Jobs: <strong>{{ departed.jobs.length }}</strong></div>
        <nz-divider nzType="vertical"></nz-divider>
        <div class="pallet-count">Total Shipments: <strong>{{ departed.shipmentCount }}</strong></div>
    </div>

    <div *ngFor="let job of departed.jobs" class="bottom15">
        <div class="flex bottom5">
            <div class="clickable" (click)="job.show = !job.show">
                <span nz-icon [nzType]="job.show ? 'down' : 'right'" nzTheme="outline"></span>
                <code class="box">{{ job.code }}</code>
                {{ job.status }}
            </div>
            <div class="left10">
                <a [routerLink]="[routeAdminDispatchList, job.id]" target="_blank">
                    <span nz-icon nzType="link" nzTheme="outline"></span>
                </a>
            </div>
            <div class="left10">
                <span class="clickable" nz-popover nzPopoverTitle="Delivery Tracking" nzPopoverTrigger="click" [nzPopoverContent]="deliveryTrackingTemplate" nz-icon nzType="global" nzTheme="outline"></span>
                <ng-template #deliveryTrackingTemplate>
                    <div class="map-wrapper">
                        <dispatch-route-map 
                            [data]="{job}"
                            [shipments]="job.shipments"
                        ></dispatch-route-map>              
                    </div>
                </ng-template>
            </div>
            <div class="left10" *ngIf="showEvents">
                <span class="clickable" nz-icon nzType="history" nzTheme="outline" (click)="showEventHistory('JOB', job.id)"></span>
            </div>
            <div class="flex1 divider dashed"></div>
            <div>Total Shipments: {{ job.shipmentCount }}</div>
            <nz-divider nzType="vertical"></nz-divider>
            <div class="pallet-count">Pallets: {{ job.palletCount }}</div>
        </div>
        <div *ngIf="job.show" class="job-info flex bottom15">
            <div class="right15">
                <div class="title">Vehicle</div>
                <div>
                    <div class="strong" *ngIf="job.requiredVehicle">
                        {{ job.requiredVehicle.name }}
                        <span *ngIf="job.requiredVehicle.options?.length">
                            w/ <code class="right5" *ngFor="let o of job.requiredVehicle.options">{{ o }}</code>
                        </span>
                    </div>
                    <div class="unknown" *ngIf="!job.requiredVehicle">
                        N/A
                    </div>
                </div>
            </div>
            <div class="right15">
                <div class="title">Carrier</div>
                <div>
                    <div class="strong" *ngIf="job.carrier">
                        {{ job.carrier?.basicInfo?.name }}
                    </div>
                    <div *ngIf="!job.carrier">
                        No Carrier Assigned
                    </div>
                </div>
            </div>
            <div class="right15">
                <div class="title">Cost</div>
                <div>
                    <div class="strong" *ngIf="job.assignedCarrier?.cost">
                        ${{ job.assignedCarrier?.cost.grandTotal }}
                    </div>
                    <div class="unknown" *ngIf="!job.assignedCarrier?.cost">
                        N/A
                    </div>
                </div>
            </div>
            <div>
                <div class="title">Notes</div>
                <div (click)="onEditJobNotes(job)" class="clickable" style="max-width: 480px;">
                    <span *ngIf="job.notes">{{ job.notes }}</span>
                    <span *ngIf="!job.notes" class="unknown">N/A</span>
                </div>
            </div>
        </div>
        <div *ngIf="job.show" class="flex buttons bottom5">
            <div class="right10">
                <button nzSize="small" nz-button (click)=onExportJobManifest(job)>
                    Export Manifests
                    <span nz-icon nzType="loading" nzTheme="outline" *ngIf="isExporting"></span>
                </button>
            </div>
            <div>
                <button nzSize="small" nz-button (click)=onDownloadJobShipmentBOLs(job)>
                    Download Shipment BOLs
                    <span nz-icon nzType="loading" nzTheme="outline" *ngIf="loadingBol"></span>
                </button>
            </div>
            <nz-divider nzType="vertical"></nz-divider>
            <div class="right10" *ngIf="job.bol">
                <ng-container *ngTemplateOutlet="downloadFile; context: {$implicit: job.bol}"></ng-container>
            </div>
            <div class="right10" *ngIf="job.loadTender">
                <ng-container *ngTemplateOutlet="downloadFile; context: {$implicit: job.loadTender}"></ng-container>
            </div>
            <div class="right5" *ngIf="job.saks">
                <button nzSize="small" nz-button (click)=downloadSaksManifest(job)>
                    <span nz-icon nzType="download" nzTheme="outline" *ngIf="!job.isExportingSaksManifest"></span>
                    <span nz-icon nzType="loading" nzTheme="outline" *ngIf="job.isExportingSaksManifest"></span>
                    Saks Manifests
                </button>
            </div>
        </div>
        <nz-table *ngIf="job.show" [nzData]="job.shipments" nzSize="small" [nzShowPagination]="false">
            <thead>
                <tr>
                    <th [nzChecked]="job.allChecked" (nzCheckedChange)="onAllChecked(job, $event)"></th>
                    <th nzWidth="120px">Main Shipment</th>
                    <th nzWidth="100px">Warp ID</th>
                    <th nzWidth="180px">Customer</th>
                    <th nzWidth="280px">Main Shipment's Ref#</th>
                    <th nzWidth="120px">Inventory</th>
                    <th nzWidth="240px">Inbound Leg</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let shipment of job.shipments">
                <tr (click)="onOpenShipment($event, job, shipment)">
                    <td
                        [nzChecked]="job.setOfCheckedIds?.has(shipment.id)"
                        (nzCheckedChange)="onItemChecked(job, shipment.id, $event)"
                    ></td>
                    <ng-container *ngTemplateOutlet="shipmentRow; context: {$implicit: shipment}"></ng-container>
                </tr>
                <tr [nzExpand]="job.expandedSet?.has(shipment.id)">
                    <div *ngIf="job.expandedSet?.has(shipment.id)" linehaul-shipment-content [data]="{shipment,id:shipment.id}"></div>
                </tr>  
                </ng-container>
            </tbody>
        </nz-table>        
    </div>
</ng-container>

<!-- Completed -->
<div class="info flex top20 bottom10">
    <div>
        <h3 class="clickable" (click)="completed.show = !completed.show">
            <span nz-icon [nzType]="completed?.show ? 'down' : 'right'" nzTheme="outline"></span>
            Completed Routes
        </h3>
    </div>
</div>
<ng-container *ngIf="completed?.show && completed.jobs">
    <div class="flex bottom20">
        <div class="right15">
            <button (click)="loadCompletedJobs(0)" [disabled]="completed?.skip == 0" nz-button nzType="text"><span nz-icon nzType="double-left" nzTheme="outline"></span> First</button>
        </div>
        <div class="right15">
            <button (click)="loadCompletedJobs(completed.skip - 10)" [disabled]="completed?.skip == 0" nz-button nzType="text"><span nz-icon nzType="left" nzTheme="outline"></span> Previous</button>
        </div>
        <div>
            <button (click)="loadCompletedJobs(completed.skip + 10)" [disabled]="completed?.count < 10" nz-button nzType="text">Next <span nz-icon nzType="right" nzTheme="outline"></span></button>
        </div>
    </div>
    <nz-table [nzData]="completed.jobs" nzSize="small" [nzShowPagination]="false" [nzLoading]="loadingCompletedJobs">
        <thead>
            <tr>
                <!-- <th [nzChecked]="completed.allChecked" (nzCheckedChange)="onAllChecked(job, $event)"></th> -->
                <th nzWidth="140px">Route ID</th>
                <th nzWidth="180px">Vehicle</th>
                <th nzWidth="280px">Carrier</th>
                <th nzWidth="100px">Cost</th>
                <th nzWidth="120px">Pickup Date</th>
                <th nzWidth="80px">Shipment</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let job of completed.jobs">
                <tr>
                    <td>
                        <code class="box right10">{{ job.code }}</code>
                        <a [routerLink]="[routeAdminDispatchList, job.id]" target="_blank">
                            <span nz-icon nzType="link" nzTheme="outline"></span>
                        </a>
                    </td>
                    <td>
                        {{ job.requiredVehicle.name }}
                        <span *ngIf="job.requiredVehicle.options?.length">
                            w/ <code class="right5" *ngFor="let o of job.requiredVehicle.options">{{ o }}</code>
                        </span>
                    </td>
                    <td>
                        <div>
                            <div class="strong" *ngIf="job.carrier">
                                {{ job.carrier?.basicInfo?.name }}
                            </div>
                            <div *ngIf="!job.carrier">
                                No Carrier Assigned
                            </div>
                        </div>
                    </td>
                    <td>
                        <div>
                            <div class="strong" *ngIf="job.assignedCarrier?.cost">
                                ${{ job.assignedCarrier?.cost.grandTotal }}
                            </div>
                            <div class="unknown" *ngIf="!job.assignedCarrier?.cost">
                                N/A
                            </div>
                        </div>        
                    </td>
                    <td>
                        <span *ngIf="job.pickDt?.time">
                            <date-time-str [data]="{time: job.pickDt?.time, tz: job.pickDt.timezone, format: 'YYYY-MM-DD'}"></date-time-str>
                        </span>    
                    </td>
                    <td>
                        {{ job.shipments.length ?? '' }}
                    </td>
                    <td>
                        <div class="flex">
                        <div class="right5" *ngIf="job.bol">
                            <ng-container *ngTemplateOutlet="downloadFile; context: {$implicit: job.bol}"></ng-container>
                        </div>
                        <div *ngIf="job.loadTender" class="right5">
                            <ng-container *ngTemplateOutlet="downloadFile; context: {$implicit: job.loadTender}"></ng-container>
                        </div>
                        <div class="right5" *ngIf="job.saks">
                            <button nzSize="small" nz-button (click)=downloadSaksManifest(job)>
                                <span nz-icon nzType="download" nzTheme="outline" *ngIf="!job.isExportingSaksManifest"></span>
                                <span nz-icon nzType="loading" nzTheme="outline" *ngIf="job.isExportingSaksManifest"></span>
                                Saks Manifests
                            </button>
                        </div>
                    </div>
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </nz-table>
</ng-container>


<ng-template #shipmentListHeader let-stats let-title="title">
    <div class="info flex top10 bottom5" >
        <div class="clickable" (click)="stats.show = !stats.show">
            <h3>
                <span nz-icon [nzType]="stats.show ? 'down' : 'right'" nzTheme="outline"></span>
                {{ title }}
            </h3>
        </div>
        <div class="flex1 divider"></div>
        <div>Total Shipments: <strong>{{ stats.shipmentCount }}</strong></div>
        <nz-divider nzType="vertical"></nz-divider>
        <div class="pallet-count">Pallets: <strong>{{ stats.palletCount ?? 0 }}</strong></div>
    </div>
</ng-template>

<ng-template #shipmentRow let-shipment>
    <td>
        <ng-container *ngIf="shipment.metadata?.parent">
            <span class="right5"><code>{{ shipment.metadata.parent.warpId }}</code></span>
            <span class="right5 clickable" nz-popover nzPopoverTitle="Transit Network" (click)="$event.stopPropagation()" nzPopoverTrigger="click" [nzPopoverContent]="contentTemplate" nzPopoverPlacement="bottomLeft">
                <span style="color: #666;" nz-icon nzType="node-collapse" nzTheme="outline"></span>
            </span>
            <ng-template #contentTemplate>
                <div transit-network [id]="shipment.metadata.parent.id" [viewOnly]="true"></div>
            </ng-template>

            <a target="_blank" *ngIf="shipment.metadata.parent.orderId" [routerLink]="[routeAdminOrderList, shipment.metadata.parent.orderId]">
                <span nz-icon nzType="link" nzTheme="outline"></span>
            </a>
        </ng-container>
    </td>
    <td>
        <code>{{ shipment.warpId }}</code>
        <a target="_blank" *ngIf="shipment.orderId" [routerLink]="[routeAdminOrderList, shipment.orderId]">
            <span nz-icon nzType="link" nzTheme="outline"></span>
        </a>
    </td>
    <td>{{ shipment.client?.name }}</td>
    <td>
        <ng-container *ngIf="shipment.metadata?.parent?.deliveryInfos?.length">
            <div *ngIf="shipment.metadata?.parent?.deliveryInfos[0].refNums?.length">
                <code style="margin-right: 2px; margin-bottom: 2px;" (click)="$event.stopPropagation()" *ngFor="let ref of shipment.metadata?.parent?.deliveryInfos[0].refNums">{{ ref }}</code>
            </div>
        </ng-container>
    </td>
    <td [class]="{'zero-load': shipment.metadata?.zero_load == 'true'}">{{ shipment.workload }} {{ shipment.workload > 1 ? 'pallets' : 'pallet' }}</td>
    <td>
        <ng-container *ngIf="shipment.inboundLegs?.length">
            <div *ngFor="let leg of shipment.inboundLegs">
                <code class="right10">{{ leg.warpId }}</code> <span class="status" [class]="'status-' + leg.status ">{{ leg.status }}</span>
            </div>
        </ng-container>
    </td>
    <td>
        <span *ngIf="shipment.bolFileId" class="right10">
        <a (click)="onDownloadShipmentBOL(shipment)">
            <i *ngIf="!shipment?.bolInfo?.isDownloading" nz-icon nzType="file-pdf" nzTheme="outline" class="right5"></i>
            <i *ngIf="shipment?.bolInfo?.isDownloading" nz-icon nzType="loading" nzTheme="outline" class="right5"></i>
            BOL
        </a>
        </span>
    </td>
    <!-- <td>{{ shipment.status }}</td> -->
</ng-template>

<ng-template #downloadFile let-file>
    <nz-button-group>
        <button nzSize="small" [disabled]="file.isDownloading || file.refreshing" nz-button (click)=onDownloadFile(file)>
            <span nz-icon nzType="download" nzTheme="outline"></span> {{ file.title }}
        </button>
        <button nzSize="small" [disabled]="file.isDownloading || file.refreshing" nz-button (click)="onRefreshFile(file)">
            <span nz-icon [nzType]="file.isDownloading || file.refreshing ? 'loading' : 'sync'"></span>
        </button>
    </nz-button-group>
</ng-template>