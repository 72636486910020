import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InputHelper } from '@services/input-helper';
import { NzOptionSelectionChange } from 'ng-zorro-antd/auto-complete';
import { Const } from '@const/Const';
import { BaseComponent } from '@abstract/BaseComponent';
import dayjs from 'dayjs';
import { DateUtil } from '@services/date-utils';

@Component({
    selector: '[delivery-info-input]',
    templateUrl: './index.html',
    styleUrls: [
        './index.scss',
    ]
})
export class DeliveryInfoInput extends BaseComponent {
    private _info;
    @Input() set info(v) {
      this._info = v;
      if (this.info) {
        this.zipcodeOriginal = this.info.addr?.zipcode;
      }
    }
    get info() { return this._info }
    @Input() clientId: any = null
    @Input() locations
    @Input() earliestDate: any = null
    @Output() onReady: EventEmitter<any> = new EventEmitter<any>();
    
    public locationsFiltered = [];
    public locationId
    public locationType
    public serviceDate
    public fromTime: any = null
    public toTime: any = null
    locationName: any = null

    zipcodeOriginal: string;

    ngOnInit(): void {
        this.initData()
    }

    disabledDate = (current: Date): boolean => {
        if (!this.earliestDate) return false
        return current.getTime() < this.earliestDate.getTime()
    }

    initData() {
        if (!this.info) return
        if (!this.info.contact) {
            this.info.contact = {}
        }
        this.locationType = this.info.type.toLowerCase()
        const { windows } = this.info || {}
        const window = (windows || [])[0]
        const { from, to } = window || {}
        const timezone = this.info?.addr?.metadata?.timeZoneStandard;
        this.fromTime = from ? DateUtil.convertLocalTime2(from, timezone)?.toISOString() : null
        this.toTime = to ? DateUtil.convertLocalTime2(to, timezone)?.toISOString() : null
        this.serviceDate = from ? dayjs.tz(from, timezone).startOf('day').toDate() : null
    }

    ngOnChanges(): void {
        this.initData()

        if (this.clientId) {
            // this.onClientChange(this.clientId, false)
        }
        if (this.locations) {
            this.setlocationsFiltered(this.locations)
        }
    }

    onClientChange(clientId, isClearLocation: boolean) {
        // get list locations
        let url = `${Const.APIURI_WAREHOUSES}?clientId=${clientId}&limit=-1`;
        this.api.GET(url).subscribe(
            resp => {
                this.setlocationsFiltered(resp.data.list_data.slice(0));
                if (isClearLocation) {
                    this.setLocation(null);
                }
            }, err => {
                this.showErr(err);
            }
        );
    }

    private setlocationsFiltered(data) {
        this.locationsFiltered = data.filter(it => it.pickAddr?.zipcode === this.zipcodeOriginal)
        // check for previously selected location
        const selected = this.locationsFiltered.filter(it => it._id === this.info?.warehouseId)[0]
        if (selected) {
            this.locationName = selected.name
            this.setLocation(selected)
        }
    }

    onLocationSelected(event: NzOptionSelectionChange, locationObject: any) {
        if (!event.isUserInput) {
            return;
        }
        this.setLocation(locationObject);
    }

    onLocationNameChange() {
      if (typeof this.locationName == 'string') {
        // Đây là trường hợp user gõ vào input
        this.info.locationName = this.locationName;
      } else {
        // Đây là trường hợp user chọn từ auto complete => xử lý ở hàm onLocationSelected()
      }
      this.checkReady();
    }

    private setLocation(locationObject: any) {
        this.info.addr = locationObject.pickAddr
        this.info.warehouseId = locationObject.id
        this.info.locationName = locationObject.name
        if (locationObject.contactName) {
            this.info.contact.name = locationObject.contactName
        }
        if (locationObject.phone) {
            this.info.contact.phone = locationObject.phone
        }
        if (locationObject.email) {
            this.info.contact.email = locationObject.email
        }

        this.checkReady()
    }

    onDateTimeUpdate() {
        this.checkReady()
    }

    getTimezone(){
        return this?.info?.addr?.metadata?.timeZoneStandard
    }

    isReady() {
        if (!this.locationName) return false
        if (!this.info.addr?.street) return false
        if (!this.serviceDate) return false
        if (!this.fromTime) return false
        if (!this.toTime) return false
        if (!this.info.contact?.name) return false
        if (!this.info.contact?.phone) return false
        return true
    }

    convertWindowTime(){
        let data = DateUtil.toTimeWindow(this.serviceDate, this.fromTime, this.toTime, this.getTimezone())
        this.info.windows = [data]
    }

    checkReady() {
        if (this.isReady()) {
            this.convertWindowTime()
            this.onReady?.emit(true)
        }
        else this.onReady?.emit(false)
    }

    onPhoneNumberInputKeyPress(event) {
        return InputHelper.handleInputKeyPressNumberOnly(event);
    }
    onPhoneNumberUpdate(event) {
        let text = InputHelper.formatPhone(event);
        if (text != event) {
            this.info.contact.phone = text
        }
        this.checkReady();
    }
}