<div>
    Are you sure you want to export this route.
</div>
<div>
    Shipments: <code *ngFor="let s of displayInfo.shipmentIds" class="right5">{{ s }}</code>
</div>
<div class="bottom15">
    <div class="right10">Mileage: {{ displayInfo.mileage }}mi</div>
    <div class="right10">Hours: <time-duration [seconds]="displayInfo.time"></time-duration></div>
</div>
<div class="flex">
    <div class="flex1 right10">
        <button [disabled]="exporting" nz-button nzBlock (click)="onCancel()">Cancel</button>
    </div>
    <div class="flex1">
        <button [nzLoading]="exporting" nz-button nzType="primary" nzBlock (click)="onOk()">OK</button>
    </div>
</div>