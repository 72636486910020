import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { DataorchService } from "@services/dataorch.service";
import dayjs from "dayjs";

@Component({
    selector: '[update-shipment-review-status]',
    templateUrl: './index.html',
    styleUrls: ['./index.scss']
})
export class RollShipmentForm extends BaseComponent {
    dataorch: DataorchService
    @Input() shipments: any[] = []
    onComplete: any
    onCancel: any

    constructor() {
        super()
        this.dataorch = new DataorchService(this.api)
        this.calculateDays()
    }

    saving: boolean = false
    async onSaveBtn() {
        if (!this.selectedDate) return
        this.saving = true
        const results = await Promise.all(this.shipments.map(it => this.dataorch.rollShipmentToDate(it.id, this.selectedDate).toPromise()))
        this.saving = false
        this.onComplete && this.onComplete(results)
    }

    onCancelBtn() {
        this.onCancel && this.onCancel()
    }

    days: any[] = []
    selectedDate: string = null

    calculateDays() {
        const now = dayjs()
        const days = []
        const today = now.startOf('day')
        const tomorrow = today.add(1, 'day')

        days.push({
            label: `Today ${today.format('ddd MMM DD')}`,
            value: today.format('YYYY-MM-DD')
        })
        days.push({
            label: `Tomorrow ${tomorrow.format('ddd MMM DD')}`,
            value: tomorrow.format('YYYY-MM-DD')
        })
        for (let index = 2; index < 7; index++){
            const day = today.add(index, 'day')
            days.push({
                label: `${day.format('ddd MMM DD')}`,
                value: day.format('YYYY-MM-DD')
            })
        }
        // days.push({
        //     label: 'Other',
        //     value: 'other'
        // })
        this.days = days
    }
}