import { Component, QueryList, Input, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseQuoteShipmentChildForm } from './base-child-form';
import { FormShipmentItem } from '@app/admin/shipments/shipment-item';
import { DialogService } from '@dialogs/dialog.service';
import { Const } from '@const/Const';
import { FormUtil } from '@services/form-util';
import { Utils } from '@services/utils';

@Component({
  selector: '[quote-shipment-items-details]',
  templateUrl: './quote-shipment-items-details.html',
  styleUrls: [
    '../../../detail.scss',
    '../../../../../styles/row-col.scss',
    '../../../../../styles/form-v2.scss',
    '../index.less'
  ]
})
export class QuoteShipmentItemsDetails extends BaseQuoteShipmentChildForm {
  @Input() filters: any;
  @Input() quote: any;
  private _step: number = 0;
  private isClickEditItemFist: boolean = false;
  @Input() get step() {return this._step}
  set step(value) {
    this._step = value;
    if (value == 2 && !this.isClickEditItemFist) {
      this.onBtnEditItem(0);
      this.isClickEditItemFist = true;
    }
  }
  @Input() set model(value) {super.model = value}
  get model() {return super.model}

  protected formGroupDeclaration: FormGroupDeclaration = {
    listItems: {label: '', type: 'formArray', childItem: FormShipmentItem.declarationNoRequire},
  }

  get itemCount(): number {
    if (!this.formInput) return 0;
    return (<FormArray>this.formInput.get('listItems')).length;
  }
  get isEmpty(): boolean {return this.itemCount == 0}

  get childKey(): string {
    return 'itemsDetails';
  }
  public shipmentType;

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    this.bindDataFilterToModel();
  }

  protected bindDataFilterToModel() {
    if (!this.filters) return;
    if (this.filters?.packaging?.packagingType == Const.FreightQuotePackageType.Parcel) {
      this.shipmentType = Const.ShipmentTypes.parcel;
    } else if (this.filters?.packaging?.packagingType == Const.FreightQuotePackageType.FTL) {
      this.shipmentType = Const.ShipmentTypes.fullTruckLoad;
    } else if (this.filters?.packaging?.packagingType == Const.FreightQuotePackageType.Pallet) {
      this.shipmentType = Const.ShipmentTypes.lessThanTruckload;
      if (this.filters.packaging && this.filters.packaging?.ltlItems?.length) {
        this.filters.packaging.ltlItems.forEach((item, index) => {
          let newItem = Utils.cloneObject(this.itemDataTemp);
          newItem.name = `Item ${index + 1}`
          newItem.height = item.height;
          newItem.length = item.length;
          newItem.width = item.width;
          newItem.weightUnit = item.weightUnit;
          newItem.weightPerUnit = item.weightPerUnit;
          newItem.qty = item.qty;
          newItem.qtyUnit = "Pallet";
          newItem.isHazardous = !!this.filters.isHazardous;
          newItem.isTemperatureControlled = !!this.filters.isTemperatureControlled;
          newItem.sum = this.getItemSumary(newItem);
          this.addItem(newItem);
        });
      } else {
        let newItem = Utils.cloneObject(this.itemDataTemp);
        newItem.height = this.filters.packaging.height;
        newItem.length = this.filters.packaging.length;
        newItem.width = this.filters.packaging.width;
        newItem.weightUnit = this.filters.packaging.weightUnit;
        newItem.weightPerUnit = this.filters.packaging.weightPerUnit;
        newItem.qty = this.filters.packaging.qty;
        newItem.qtyUnit = "Pallet";
        newItem.isHazardous = !!this.filters.isHazardous;
        newItem.isTemperatureControlled = !!this.filters.isTemperatureControlled;
        newItem.sum = this.getItemSumary(newItem);
        this.addItem(newItem);
      }

    }
  }

  get formGroupError() {
    let err = super.formGroupError;
    if (this.itemCount == 0) {
      err = {error: 'Shipment items is required'}
    }
    return err;
  }

  onBtnAddItem() {
    DialogService.openFormDialog1(FormShipmentItem, {
      nzComponentParams: {
        shipmentType: this.shipmentType,
        onSave: data => this.addItem(data)
      },
      nzClassName: 'modal-no-padding modal-shipment-item',
    });
  }

  get isShipmentModeLTL() {
    return this.filters?.packaging?.packagingType == Const.FreightQuotePackageType.Pallet
  }


  onBtnEditItem(index: number) {
    let item = this.getListItemAt(index);
    DialogService.openFormDialog1(FormShipmentItem, {
      nzComponentParams: {
        model: item,
        itemIndex: index,
        shipmentType: this.shipmentType,
        isExfreightQuote: this.isShipmentModeLTL,
        onSave: (data, index) => this.editItem(data, index)
      },
      nzClassName: 'modal-no-padding modal-shipment-item',
    });
  }

  onBtnRemoveItem(index: number) {
    let item = this.getListItemAt(index);
    this.confirmDeletion({
      message: `Remove <b>Item ${index + 1}: ${item.name}</b>?`,
      fnOk: () => this.delItem(index)
    })
  }

  public getListItemAt(index: number): any {
    let fg = <FormGroup>(<FormArray>this.formInput.get('listItems')).at(index);
    return FormUtil.getFormGroupData(fg, <FormGroupDeclaration>this.formGroupDeclaration.listItems.childItem);
  }

  private addItem(data) {
    this.addItemToFormArray('listItems', data);
    this.onListItemsChange();
  }

  private editItem(data, index) {
    (<FormArray>this.formInput.get('listItems')).at(index).patchValue(data);
    this.onListItemsChange();
  }

  private delItem(index: number) {
    (<FormArray>this.formInput.get('listItems')).removeAt(index);
    this.onListItemsChange();
  }

  private onListItemsChange() {
    this.formInput.get('listItems').updateValueAndValidity();
  }

  itemDataTemp = {
    "name": "item",
    "itemId": "1",
    "carrierId": null,
    "carrierTrackingCode": null,
    "itemValue": null,
    "qty": 1,
    "qtyUnit": "",
    "weightPerUnit": 1,
    "weightUnit": "lbs",
    "length": 40,
    "width": 48,
    "height": 1,
    "sizeUnit": "IN",
    "isStackable": false,
    "isTemperatureControlled": false,
    "temperatureControlled": {
        "tempMin": null,
        "tempMax": null
    },
    "isHazardous": false,
    "hazardous": {
        "un": null,
        "packingGroup": null,
        "qty": null,
        "name": null,
        "contact": null,
        "cfr49": null,
        "hazmatClass": null,
        "hazmatDesc": null
    },
    "sum": {
        "volume": 1.1111111111111112,
        "weight": 1,
        "desc": "1 Pallet, 1 lbs, 40L x 48W x 1H in"
    }
  }

}
