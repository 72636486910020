import { Component } from '@angular/core';
import { BaseDialog } from '@dialogs/base-dlg';

@Component({
  selector: '[load-book-successfuly]',
  templateUrl: './success-dialog.html',
  styleUrls: ['./index.less' ]
})
export class LoadBookSuccessfuly extends BaseDialog {

  public onClose: () => void = () => {}

  constructor() {
    super();
  }
  
  ngOnInit(): void {
    super.ngOnInit();
  }

  onBtnClose() {
    this.closeDialog();
    this.onClose();
  }

}