import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { FreightQuote } from './index';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzFormModule } from 'ng-zorro-antd/form';
import { FormAddressUSModule } from '../base/form-address-us/module';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { SearchBoxModule } from '@libs/search-box/search-box.module';
import { FormInputCostModule } from '../base/form-input-cost/module';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { SelectBySearchingModule } from '@libs/select-client/module';
import { FreightQuoteShipmentDetail } from './shipment-detail';
import { QuoteShipmentPickup } from './shipment-detail/child-form/quote-shipment-pickup';
import { QuoteShipmentDropoff } from './shipment-detail/child-form/quote-shipment-dropoff';
import { QuoteShipmentItemsDetails } from './shipment-detail/child-form/quote-shipment-items-details';
import { FormShipmentPayment } from './shipment-detail/shipment-payment';
import { LoadBookSuccessfuly } from './shipment-detail/success-dialog';
import { FreightQuoteSearch } from './search';
import { FreightQuoteResult } from './search/freight-quote-search-result';
import { FreightQuoteFilter } from './search/freight-quote-search-filter';
import { FreightQuoteItem } from './search/quote-item';
import { ConfirmShipmentDetail } from './confirm-shipment-detail';
import { ServiceOption } from './search/freight-quote-search-filter/service-options';
import { Packaging } from './search/freight-quote-search-filter/packaging';
import { PickDate } from './search/freight-quote-search-filter/pick-date';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { LTLItemForm } from './search/freight-quote-search-filter/packaging/ltl-item-form';
import { DetailModule } from '../base/detail.module';
import { SharedModule } from '../shared/shared.module';
import { ShipmentExfreightQuote } from './shipment-exfreight-quote';
import { ConfirmShipmentBeforeBook } from './confirm-shipment-before-book';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzCheckboxModule,
    NzRadioModule,
    NzDividerModule,
    NzTableModule,
    NzButtonModule,
    NzSelectModule,
    NzPaginationModule,
    NzIconModule,
    NzGridModule,
    NzEmptyModule,
    NzInputModule,
    NzFormModule,
    NzDatePickerModule,
    NzStepsModule,
    NzTimePickerModule,
    NzAutocompleteModule,
    NzTagModule,
    NzCollapseModule,
    SearchBoxModule,
    FormAddressUSModule,
    FormInputCostModule,
    NzTimelineModule,
    NzDropDownModule,
    SelectBySearchingModule,
    RouterModule,
    NzToolTipModule,
    NzIconModule,
    NzCardModule,
    NzSwitchModule,
    NzCalendarModule,
    DetailModule,
    SharedModule
  ],
  declarations: [
    FreightQuote,
    FreightQuoteShipmentDetail,
    QuoteShipmentPickup,
    QuoteShipmentDropoff,
    QuoteShipmentItemsDetails,
    FormShipmentPayment,
    LoadBookSuccessfuly,
    FreightQuoteSearch,
    FreightQuoteResult,
    FreightQuoteFilter,
    FreightQuoteItem,
    ConfirmShipmentDetail,
    ServiceOption,
    Packaging,
    PickDate,
    LTLItemForm,
    ShipmentExfreightQuote,
    ConfirmShipmentBeforeBook
  ],
  exports: [
    FreightQuote,
    ShipmentExfreightQuote
  ],
})
export class FreightQuoteModule { }
