<style>
  .form-label-v2 {
    font-weight: 300;
  }
  /* .input-dimen {
    max-width: 120px;
  } */
  .line-break {
    width: 100%;
  }
</style>
<div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 16, lg: 16 }">
  <div nz-col class="gutter-row" nzXs="24" nzSm="24" nzMd="24" nzLg="24">
    <div nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu" [(nzVisible)]="visible">
      <div class="input-select" #inputSelect [ngClass]="{error: (isError && !optionSelected)}">
        <div class="input-text">
          <img src="/assets/img/ic_Schedule.png" />
          <ng-container *ngIf="!optionSelected">
            <div class="placeholder">{{placeholder}}</div>
          </ng-container>
          <ng-container *ngIf="optionSelected">
            <div class="value">{{optionSelected}}
            </div>
            <i class="icon" nz-icon nzType="close-circle" nzTheme="outline" (click)="onRemoveValue();$event.stopPropagation()"></i>
          </ng-container>
        </div>
      </div>
    </div>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <div #backgroupDropdown class="quote-backgroup-dropdown" style="justify-content: center;">
        <div style="position: relative; width: 100%;">
          <div class="" style="position: absolute; top: -30px; width: 100%;">
            <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 16, lg: 16 }">
              <ng-container *ngFor="let item of options" >
                <div nz-col class="gutter-row" nzXs="12" nzSm="12" nzMd="12" nzLg="12">
                  <button (click)="onChangeValue('optionType', item)" [ngClass]="{selected: optionType==item}" class="item">{{namePickDateType(item)}}</button>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="line-break bottom20"></div>
        <ng-container *ngIf="optionType==options[1]">
          <div>When do you want to deliver?</div>
          <div class="line-break"></div>
          <div class="flex top20" style="display: flex; justify-content: center; width: 100%;">
            <ng-container *ngFor="let item of flexibleOptions" >
              <div (click)="onChangeValue('flexibleValue', item)" [ngClass]="{selected: flexibleValue==item}" class="item">{{namePickDateTypeFlexible(item)}}</div>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="optionType==options[0]">
          <div class="line-break"></div>
          <div class="flex top20" style="place-content: center;">
            <nz-calendar [nzDisabledDate]="disabledDate" [nzFullscreen]="false" [(ngModel)]="value" (nzSelectChange)="dateSelectChange($event)">></nz-calendar>
          </div>
        </ng-container>
        <!-- <div class="top20 text-center footer">
          <button nz-button nzGhost class="btn-done" (click)="onClickBtnDone()">Done</button>
        </div> -->
      </div>
      
    </nz-dropdown-menu> 
  </div>
</div>