import { Component, Input } from "@angular/core";
import { Utils } from "@services/utils";
import { Const } from "@const/Const";
import { DialogService } from "@dialogs/dialog.service";
import { BaseDialog } from "@dialogs/base-dlg";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { startOfDay, differenceInCalendarDays } from 'date-fns'
import { environment } from "@env/environment";

@Component({
  selector: '[activate-settings-dialog]',
  templateUrl: './index.html',
  styleUrls: ['../../../../dialogs/dialogs.scss', './index.scss']
})
export class ActivateSettingsDialog extends BaseDialog {
  @Input() setting: any; 
  @Input() effective: boolean = true;
  onDone: (data) => void = (data) => {}
  public effectiveDate;

//   protected formGroupDeclaration: FormGroupDeclaration = {
//     effectiveDate: {label: 'Effective Date', placeHolder: 'Effective Date', initialValue: false, required: true}
//   }

  public isLoading = false;
  public inProgress = false;

  public startOfDay(result: Date): Date {
    return result ? startOfDay(result) : null;
  }

  public get action(): string {
      return this.effective ? 'Activate' : 'Expire'
  }

  public get dateTitle(): string {
    return this.effective ? 'Effective Date' : 'Expiration Date'
}

  ngOnInit(): void {
    super.ngOnInit();
    this.effectiveDate = this.effective ? this.setting?.effectiveDate : null
  }
  
  onBtnCancel() {
    this.closeDialog();
  }

  onOk() {
    if (this.effectiveDate) {
      this.onActivate();
    }
  }

  onChange(d) {
    this.effectiveDate = d;
  }

  onActivate() {
    if (this.inProgress) return;
    this.inProgress = true;
    const url = this.effective ? `${environment.ratingUrl}/${Const.APIURI_FINANCE_SETTINGS}/detail/${this.setting.id}/activate` : `${environment.ratingUrl}/${Const.APIURI_FINANCE_SETTINGS}/detail/${this.setting.id}/expire`;
    this.api.POST(url, this.effectiveDate).subscribe(
        resp => {
            this.setting.status = 'ACTIVE'
            if (this.effective)
                this.setting.effectiveDate = this.effectiveDate
            else
                this.setting.expirationDate = this.effectiveDate
            this.inProgress = false;
            this.closeDialog();
        }, err => {
            this.inProgress = false;
        }
    )
  }


  disabledDate = (current: Date): boolean =>
  // Can not select days before today and today
  differenceInCalendarDays(current, new Date()) <= 0;

  canSave = () => this.effectiveDate && this.effectiveDate > new Date()
}