import { BaseComponent } from "@abstract/BaseComponent";
import { Component } from "@angular/core";
import { Const } from "@const/Const";
import { PlanningService } from "@services/planning.service";
import { AddShipmentForm } from "./add-shipment-form";
import _ from "underscore";
import dayjs from "dayjs";

@Component({
    selector: '[active-planning-session-list]',
    templateUrl: './index.html',
    styleUrls: ['./index.scss']
})
export class ActivePlanningSessionList extends BaseComponent {
    service: PlanningService

    sessions: any[] = []
    days: any[] = []
    loading: boolean = false

    constructor() {
        super()
        this.service = new PlanningService(this.api)

        this.loadSessions()
    }

    loadSessions() {
        this.loading = true
        this.service.listActiveSession().subscribe((res) => {
            this.loading = false
            this.sessions = res.filter(it => it.shipmentCount)
            this.processData(this.sessions)
        }, (err) => {

        })
    }

    processData(data) {
        for (let session of data) {
            session['day'] = dayjs(session['date']).format('dddd, MMM DD')
            session['unrouted'] = (session.shipmentCount || 0) - (session.routedShipmentCount || 0)
        }
        const sorted = _.sortBy(data, 'date')
        const days = _.uniq(sorted.map(it => it.day))
        const byday = _.groupBy(sorted, 'day')
        this.days = days.map(day => Object.assign({day}, {sessions: _.sortBy(byday[day], it => -it['unrouted'])}))
    }

    onRefreshBtn() {
        this.loadSessions()
    }

    selectSession(session) {
        this.router.navigate([ Const.routePlanningSessions, session.id])
    }

    onAddShipmentBtn() {
        let saving: boolean = false
        let modalRef: any = null
        modalRef = this.modalService.create({
            nzTitle: 'Add Shipments',
            nzContent: AddShipmentForm,
            nzWidth: '1000px',
            nzComponentParams: {},
            nzFooter: [
                {
                    label: 'CLOSE',
                    type: 'default',
                    disabled: () => saving,
                    onClick: () => modalRef?.destroy()
                },
            ]
        })
    }

    addShipments(ids) {

    }
}