import { Component } from "@angular/core";
import { Const } from "@const/Const";
import { getDashboard } from "@services/index";
import { BaseList } from "../base/list";

@Component({
  selector: 'loads-booked',
  templateUrl: 'list.html',
  styleUrls: [
    './style.scss',
    '../list.scss'
  ]
})
export class LoadsBooked extends BaseList {

  public isLoading = false;
  public dataChart = {};

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    setTimeout(() => getDashboard().sideBar.isSmallWidth = true, 1);
  }

  protected getApiUrl(): string {
    return '';
  }

  protected handleNavigationEnd(url: string, prevQueryParam: any): void {
    this.getData();
  }

  protected getData() {
    let url = `${Const.APIURI_STATISTIC}/load_booked`;
    let filter = this.queryParams.filter;
    if (!filter) {
      return;
    }
    let qs = new URLSearchParams({filter}).toString();
    url += `?${qs}`;
    this.isLoading = true;
    this.api.GET(url).subscribe(
      resp => {
        let dataChart = {
          booked: {
            data: resp.data.list_data,
            options: {
              xField: 'Date',
              yField: 'count',
            }
          }
        }
        this.dataChart = dataChart
        this.getDataDone(resp);
        this.isLoading = false;
      }, err => {
        this.showErr(err);
        this.isLoading = false;
      }
    );
  }
}
