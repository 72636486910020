<div class="main-container">
    <div class="flex">
        <div class="flex1">
            <span class="type">{{ item.type }}</span>
            <span class="description flex1">{{ item.description }}</span>    
        </div>
        <div class="rate">
            <span class="emphasized" [decimals]="0" decimal-number [value]="item.avg"></span>
        </div>
        <div class="currency">
            <span class="label"> {{item.currency || 'USD'}}</span>
        </div>
    </div>
    <div class="sub-item" *ngFor="let sub of item.items" rate-detail [item]="sub"></div>
</div>