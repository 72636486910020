<form nz-form [formGroup]="formInput" class="form-detail quote-shipment-delivery-form">
  <div class="group">
    <div class="flex bottom40">
      <div class="f16b">Items Details
        <span class="label-mark-required"></span>
      </div>
    </div>

    <ng-container *ngIf="!isEmpty">
      <div class="section-items">
        <div *ngFor="let item of getArrayControls('listItems'); let i = index" class="flex item-row">
          <div style="align-items: center;">
            <div class="item-name right20" *ngIf="getListItemAt(i).name">{{getListItemAt(i).name}}</div>
            <div class="item-desc">{{getListItemAt(i).sum?.desc}}</div>
          </div>
          <div>
            <button nz-button nzType="text" (click)="onBtnEditItem(i)"><i class="item-edit"  nz-icon nzType="edit" nzTheme="outline"></i></button>
            <ng-container *ngIf="!isShipmentModeLTL">
              <button nz-button nzType="text" (click)="onBtnRemoveItem(i)" style="margin-left: 10px"><i class="item-remove"  nz-icon nzType="delete" nzTheme="outline"></i></button>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!isShipmentModeLTL">
      <div>
        <button nz-button (click)="onBtnAddItem()" style="margin-top: 20px;">
          <i nz-icon nzType="plus"></i>Add item
        </button>
      </div>   
    </ng-container>
  </div>
</form>