<h2>
    Roll Job to a different date
</h2>

<div class="bottom20 flex">
<div class="right20">Pickup date</div>
<div>
    <nz-date-picker [disabled]="rolling" [nzDisabledDate]="disabledDate" [(ngModel)]="date" nzMode="date" (ngModelChange)="checkReady($event)"></nz-date-picker>
</div>
</div>
<div class="buttons flex">
    <div class="right20">
        <button [disabled]="rolling" nz-button (click)="onClose && onClose()">Cancel</button>
    </div>
    <div>
        <button nzType="primary" [disabled]="!isReady || rolling" nz-button (click)="onConfirm()">Confirm</button>
    </div>
</div>
