import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { environment } from "@env/environment";
import { DateUtil } from "@services/date-utils";

@Component({
  selector: '[finance-screen]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss', '../detail.scss']
})
export class FinanceScreen extends BaseComponent {
  @Input() value: any;
  public configs: any = [{
    id: 'fuel-prices',
    name: 'Fuel Prices'
  }];
  public loadingConfigs: boolean = false;
  public showingDetail: boolean = false;
  public selected: string = null;

  ngOnInit(): void {
    // load config list
    this.loadConfigs();

    // load fuel prices
    // this.loadFuelPrices();
  }

  private loadConfigs() {
    this.loadingConfigs = true;
    const url = `${environment.ratingUrl}/${Const.APIURI_FINANCE_CONFIG}`;
    this.api.GET(url).subscribe(
      resp => {
        this.configs = [{
          id: 'fuel-prices',
          name: 'Fuel Prices'
        }, ...resp];
        this.loadingConfigs = false;
      }, err => {
        this.loadingConfigs = false;
      }
    )

  }

  public formatDate(t): string {
    if (!t) return 'N/A'
    return DateUtil.format(t, 'YYYY/MM/DD HH:mm')
  }

  onShowDetail(e) {
    this.showingDetail = true;
    e.activatedRoute.params.subscribe(p => {
      this.selected = p['id']
    })
  }

  onHideDetail(e) {
    this.showingDetail = false;
    this.selected = null;
  }

}