import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Const } from '@const/Const';
import { BaseDialog } from '@dialogs/base-dlg';
import { DateUtil } from '@services/date-utils';
import { Mapbox } from '@services/mapbox';
import { Utils } from '@services/utils';
import { AddressUS } from '@wearewarp/types/data-model';

@Component({
  selector: '[confirm-shipment-detail]',
  templateUrl: './index.html',
  styleUrls: ['./index.less' ]
})
export class ConfirmShipmentDetail extends BaseDialog {


  @Input() shipment: any;
  @Input() quote: any;
  @Input() deliveryDate: any;
  @Input() routers: any = [];
  @Input() onConfirmShipmentDetail: any;
  packagingTypeList = Const.packagingTypeList;

  private mapBox: any;
  @ViewChild('mapElm') mapElm: ElementRef<HTMLElement>;

  constructor() {
    super();
  }
  
  ngOnInit(): void {
    if (this.routers?.length > 0) {
      this.mapBox = new Mapbox();
      setTimeout(() => {
        this.mapBox.setupMap(this.routers, [], [], Const.MapBox.style.light, Const.MapBox.FitBoundsBy.routes);
      }, 200);
    }
  }

  onBtnConfirmAndContinue() {
    this.onConfirmShipmentDetail();
    this.closeDialog();
  }
  getvaluePackaging(packaging) {
    switch(packaging?.packagingType){
      case Const.FreightQuotePackageType.Parcel:
        return `${this.getPackagingTypeName(packaging.packagingType)} (${packaging.parcelSizeSelected})`;
      case Const.FreightQuotePackageType.Pallet:
        if (packaging?.ltlItems?.length > 0) {
          let val = '';
          packaging.ltlItems.forEach((item, index) => {
            val += `<div>Item ${index + 1} (${item.length}x${item.width}x${item.height}; ${item.weightTotal} ${item.weightUnit}; Quantity: ${item.qty})</div>`;
          });
          return val;
        } else {
          return `${this.getPackagingTypeName(packaging.packagingType)} (${packaging.length}x${packaging.width}x${packaging.height}; ${packaging.weightTotal} ${packaging.weightUnit}; Quantity: ${packaging.qty})`;
        }
      case Const.FreightQuotePackageType.FTL:
        return `${this.getPackagingTypeName(packaging.packagingType)} (${packaging.equipment} - ${packaging.weightTotal} ${packaging.weightUnit})`;;
      default: 
        return null;
    }
  }

  getPickDate() {
    let date = null;
    if (this.shipment?.pickDate && Utils.isString(this.shipment?.pickDate)) {
      date = new Date(this.shipment.pickDate);
    } else if (this.shipment?.pickDate && Utils.isArrayNotEmpty(this.shipment.pickDate)) {
      date = new Date(this.shipment.pickDate[this.shipment.pickDate.length - 1]);
    }
    if (date) {
      return DateUtil.format(date.toISOString(), Const.FORMAT_GUI_DATE_V2);
    }
    return 'N/A';
  }

  // Địa chỉ mới chỉ có zipcode, city, state
  getAddressText(addr: AddressUS): string {
    if (!addr) return '';
    let street = addr.street;
    if (addr.street2) {
      street += `, ${addr.street2}`;
    }
    let city = `${addr.city}, ${addr.state}, ${addr.zipcode}`;
    if (street) {
      return `${street}, ${city}`;
    } else {
      return city;
    }
  }
}