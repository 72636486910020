import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { BaseComponent } from '@abstract/BaseComponent';
import { environment } from '@env/environment';
import { Const } from '@const/Const';

@Component({
  selector: '[freight-quote-result]',
  templateUrl: './index.html',
  styleUrls: ['../index.scss',]
})
export class QuoteResult extends BaseComponent {
    public quotes
    public options
    @Input() response: any = null
    @Input() request: any = null
    @Input() shipment: any = null
    @Input() descending: boolean
    @Input() onBookComplete: any

    ngOnChanges(): void {
        const { quotes } = this.response || {}
        this.quotes = quotes || []
        let options = []
        for (let quote of this.quotes) {
          // TODO: group by carrier ???
          for (let option of quote.options || [])
          options.push({
            'quote': quote,
            'option': option
          })
        }
        const compareRate = (a,b) => {
          if (!a.option?.rate?.avg) return 1
          if (!b.option?.rate?.avg) return -1
          if (a.option.rate.avg > b.option.rate.avg) return this.descending ? -1 : 1
          if (a.option.rate.avg < b.option.rate.avg) return this.descending ? 1 : -1
          return 0
        }
        options.sort(compareRate)
        this.options = options
    }

    viewBookedOrder() {
      const { metadata } = this.response || {}
      const { warpId, bookedOrder } = metadata
      if (!warpId) return
      if (bookedOrder)
        this.router.navigate([Const.routeAdminOrderList, bookedOrder])
      else
        this.router.navigate([Const.routeAdminOrderList], {queryParams: {search: warpId}})
    }

    onComplete(id) {
      this.response.status = 'BOOKED'
      let metadata = this.response.metadata || {}
      metadata.warpId = this.shipment?.warpId
      metadata.bookedOrder = this.shipment?.orderId
      this.response.metadata = metadata
      this.onBookComplete && this.onBookComplete(id)
    } 
}