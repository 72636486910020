<div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 16, lg: 16 }">
  <div nz-col class="gutter-row" nzXs="24" nzSm="24" nzMd="24" nzLg="24">
    <div nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu" [(nzVisible)]="visible">
      <!-- <nz-input-group [nzSuffix]="suffixIconSearch">
        <div type="text" [(ngModel)]="optionsvalue" nz-input [placeholder]="placeholder" nzSize="large" />
      </nz-input-group> -->
      <div class="input-select" #inputSelect>
        <div class="input-text">
          <ng-container *ngIf="preffixIcon">
            <img src="{{preffixIcon}}" />
          </ng-container>
          <ng-container *ngIf="!optionSelected?.length">
            <div class="placeholder">{{placeholder}}</div>
          </ng-container>
          <ng-container *ngIf="optionSelected?.length > 0">
            <div class="value">{{optionSelected[0]?.name}}
              <ng-container *ngIf="optionSelected?.length > 1">
                <span class="placeholder"> +{{optionSelected?.length - 1}} more</span>
              </ng-container>
            </div>
          </ng-container>
        </div>
        <i nz-icon nzType="caret-down" nzTheme="outline"></i>
      </div>
    </div>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <div #backgroupDropdown class="quote-backgroup-dropdown">
        <div style="position: absolute; top: 20px">Select Service</div>
        <ng-container *ngFor="let item of options; let i = index" >
          <div (click)="onSelect(item, i)" [ngClass]="{selected: item.isSelected}" class="item">{{item.name}}</div>
        </ng-container>
        <div class="top20 text-center footer">
          <button nz-button nzGhost class="btn-done" (click)="onClickBtnDone()">Done</button>
        </div>
      </div>
      
    </nz-dropdown-menu> 
  </div>
</div>