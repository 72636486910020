import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BaseComponent } from '@abstract/BaseComponent';
import { VehicleTypeService } from '@services/vehicle.service';
import { Const } from '@const/Const';
import { DataorchService } from '@services/dataorch.service';

@Component({
  selector: '[client-selector]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss',]
})
export class ClientSelector extends BaseComponent {
    dataorch: DataorchService
    
    clients: any[] = []
    _clientId: string = null
    @Input() size: string = 'md'
    get clientId() {
        return this._clientId
    }
    @Input() set clientId(v) {
        this._clientId = v
    }
    clientName: string = null
    filteredClients: any[] = []
    @Output() onNewValue: EventEmitter<any> = new EventEmitter<any>();

    constructor() {
        super()
        this.dataorch = new DataorchService(this.api)

        this.loadClients()

        this.filterByName = this.filterByName.bind(this)
        this.filter = this.filter.bind(this)
    }

    onValueChange(event) {
        this.onNewValue && this.onNewValue.emit(event)
    }

    onChangeClientNameSearch(event) {
        this.filter()
    }

    filterByName(client) {
        if (!client.name) return false
        if (!this.clientName) return true
        return (client.name.toUpperCase().indexOf(this.clientName.toUpperCase()) >= 0)
    }

    filter() {
        this.filteredClients = this.clients.filter(this.filterByName)
    }

    loadClients() {
        // const url = `${Const.APIURI_CLIENTS}?limit=-1`
        this.dataorch.listClients().subscribe((res) => {
            this.clients = res
            if (this._clientId) {
                const client = this.clients.filter(it => it.id === this._clientId)[0]
                if (client) {
                    this.clientName = client.name
                }
            }
            this.filter()
        })
    }

    onClientSelected(event, item) {
        if (!event.isUserInput) {
            return;
        }
        this._clientId = event?.id
        this.onNewValue.emit(item)
    }

    clear() {
        this.clientName = null
        this._clientId = null
        this.filter()
        this.onNewValue.emit(null)
    }
}