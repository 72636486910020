import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DataorchService } from "@services/dataorch.service";
import dayjs from "dayjs";
import _ from "underscore";

@Component({
    selector: '[update-job-notes-form]',
    templateUrl: './job-notes-form.html',
})
export class JobNotesForm extends BaseComponent {
    dataorch: DataorchService
    public notes: any = null

    _job: any = null
    saving = false
    @Input() set job(v) {
        this._job = v
        this.notes = v.notes ?? ''
    }

    @Input() onClose
    @Input() onOk

    constructor() {
        super()
        this.dataorch = new DataorchService(this.api)
    }

    onConfirm() {
        this.saving = true
        // determine days

        this.dataorch.updateJobNotes(this._job.id, this.notes).subscribe(res => {
            console.log(res)
            this.saving = false
            this.onOk && this.onOk()
            this._job.notes = this.notes
        })
    }
}