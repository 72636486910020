import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { PlanningService } from "@services/planning.service";
import { ModalHelper, UpdateTimeWindows } from "@wearewarp/ng-antd";
import { Utils as ServiceUtils } from "@services/utils";
import { UpdateShipmentReviewStatusForm } from "../../update-review-status";
import { UIHelper } from "@services/UIHelper";
import { BizUtil } from "@services/biz";
import { MasterData } from "@services/master.data";
import { Const } from "@const/Const";

@Component({
    selector: 'planning-shipment',
    templateUrl: 'planning-shipments.html',
    styleUrls: ['./index.scss']
})
export class PlanningShipmentsComponent extends BaseComponent {
    indeterminate: boolean = false
    checked: boolean = false
    setOfCheckedId: Set<string> = new Set()
    service: PlanningService

    constructor(private modalHelper: ModalHelper) {
        super()
        this.service = new PlanningService(this.api)
    }

    ngOnInit(): void {
        console.log(`I will init when tab active`);
    }

    @Input() shipments
    @Output() onShipmentAddedToPlanning: EventEmitter<string> = new EventEmitter()
    @Output() hoverShipment: EventEmitter<any> = new EventEmitter<any>();
    @Output() clickShipment: EventEmitter<any> = new EventEmitter<any>();
    @Output() reviewStatusUpdate: EventEmitter<any> = new EventEmitter<any>();
    @Output() deliveryInfoUpdated: EventEmitter<any> = new EventEmitter<any>();

    onAllChecked(checked: boolean): void {
        this.shipments.forEach(({ id }) => this.updateCheckedSet(id, checked));
    }
    updateCheckedSet(id: string, checked: boolean): void {
        if (checked) {
            this.setOfCheckedId.add(id);
        } else {
            this.setOfCheckedId.delete(id);
        }
    }
    onItemChecked(id: string, checked: boolean): void {
        this.updateCheckedSet(id, checked);
    }

    addToPlanning(event, id) {
        event.stopPropagation()
        const shipment = this.shipments.filter(it => it.id === id)[0]
        if (!shipment) return
        shipment.addingStatus = 'ADDING'
        // const apptRequired = (it) => (it.dropoff.requiresAppointment && !it.dropoff.appointmentInfo?.from) || (it.pickup.requiresAppointment && !it.pickup.appointmentInfo?.from)
        this.service.addShipmentToPlanning(id).subscribe((res) => {
            shipment.addingStatus = 'ADDED'
            shipment.session = {
                id: res.sessionId,
            }
            this.onShipmentAddedToPlanning.emit(id)
        }, (err) => {
            shipment.addingStatus = 'ERROR'
        })
    }

    onCopyId(event, id) {
        event.stopPropagation()
        if (!id) return
        ServiceUtils.copyTextToClipboard(id.toString(), (e) => {
            if (e) {
                this.showErr("Cannot copy to clipboard");
            } else {
                this.showSuccess(
                    `Copied ${id} to the clipboard`
                );
            }
        })  
    }
    onCopyIds() {
        const ids = this.shipments.filter(it => this.setOfCheckedId.has(it.id)).filter(it => it.warpId).filter(it => !it.excluded).map(it => it.warpId.toString())
        ServiceUtils.copyTextToClipboard(ids.join(", "), (e) => {
            if (e) {
                this.showErr("Cannot copy to clipboard");
            } else {
                this.showSuccess(
                    `Copied ${ids.length} ids to the clipboard`
                );
            }
        })    
    }
    removeShipment(event, id) {
        event.stopPropagation()
        let removed = false
        this.shipments.filter(it => it.id === id).map((r) => {
            r.excluded = !r.excluded
            removed = r.excluded
        })
        // this.checkCanAdd()

        // this.onRemove.emit({id, removed})
    }

    updateShipmentReviewStatus(event, shipment) {
        event.stopPropagation()
        this.modalService.create({
            nzTitle: `Update Review Status for shipment ${shipment.warpId}`,
            nzContent: UpdateShipmentReviewStatusForm,
            nzWidth: "800px",
            nzComponentParams: {
                id: shipment.id,
                warpId: shipment.warpId,
                status: shipment.reviewStatus || {}
            },
            nzOnOk: (comp) => {
                comp.onSave().subscribe((res) => {
                    const { review } = res.data
                    const { status } = review
                    shipment.reviewStatus = status
                    this.reviewStatusUpdate.emit(res.data)
                }, (err) => {
                    this.showErr(err)
                })
            },
            nzOnCancel: (comp) => {
                comp.onCancel()
            }
        })
    }
    
    onBtnEditWindowsTime(shipment, deliveryInfo) {
        UpdateTimeWindows.openModal(this.modalHelper, {
            onSubmitError: err => UIHelper.showErr(err),
            onSubmitSucceeded: res => {
                const s = this.shipments.filter(it => it.id === shipment.id)[0]
                if (!s) return
                if (deliveryInfo.type === 'PICKUP') {
                    s.pickup = res.data
                    s.pickupWindow = BizUtil.getDeliveryInfoTime(res.data, {showWindow: true, format: 'M/D h:mm A', formatDateOnly: 'M/D'}) || '-'
                } else {
                    s.dropoff = res.data
                    s.dropoffWindow = BizUtil.getDeliveryInfoTime(res.data, {showWindow: true, format: 'M/D h:mm A', formatDateOnly: 'M/D'}) || '-'
                }
                this.deliveryInfoUpdated.emit({id: shipment.id, deliveryInfo: res.data})
            },
            nzTitle: `Shipment ${shipment.warpId} ${deliveryInfo.type} Time Windows`,
            nzComponentParams: {
            timezone: deliveryInfo.addr?.metadata?.timeZoneStandard,
            model: {
                windows: deliveryInfo.windows,
                reasonCodeId: deliveryInfo.reasonCodeId,
            },
            reasonCodes: MasterData.getChangeDateTimeReasons(),
            submit: data => this.updateDeliveryInfo(shipment.id, deliveryInfo.id, data),
            }
        });
    }
    
    private updateDeliveryInfo(shipmentId, deliveryInfoId, data) {
        const url = Const.APIV2(`shipments/${shipmentId}/delivery-info/${deliveryInfoId}`)
        return this.api.PUT(url, data)
    }

    readinessColors = ['red', 'black', 'gray', 'orange', 'blue', 'green']
}