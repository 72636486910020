<div class="box-container">
    <ng-container *ngIf="loading">
        <div class="loading-container" style="flex-direction: column;">
            <i nz-icon nzType="loading" nzTheme="outline"></i>
        </div>
    </ng-container>
      
    <div class="flex">
        <div class="flex1">
            <div *ngIf="!lanes?.length &&!loading">No active Linehaul Lanes</div>
            <h3 *ngIf="lanes?.length">
                {{ lanes?.length }} Linehaul Lanes
            </h3>
        </div>
        <div>
            <button class="right10" nz-button (click)="onRefreshBtn()">Refresh</button>
        </div>
    </div>
    <div *ngIf="lanes?.length" style="border-top: solid 1px #eee; margin-top: 10px;">
        <nz-list nzItemLayout="horizontal" [nzLoading]="loading">
            <nz-list-item *ngFor="let lane of lanes" (click)="selectLane(lane)" class="clickable">
                <nz-list-item-meta [nzTitle]="lane.name" [nzDescription]="lane.description"></nz-list-item-meta>
                <div *ngIf="lane.deliveryInfos?.length" class="right15">
                    <span *ngFor="let info of lane.deliveryInfos; index as i">
                        <span *ngIf="i > 0" nz-icon nzType="arrow-right" nzTheme="outline"></span>
                        <!-- {{ info.addr.street}}, -->
                        {{ info.addr.city }}, {{ info.addr.state }} {{ info.addr.zipcode}}
                    </span>
                </div>
                <!-- <div *ngIf="lane.vehicleTypes?.length">
                    Vehicles: <code *ngFor="let v of lane.vehicleTypes">{{ v.name }}</code>
                </div> -->
            </nz-list-item>
        </nz-list>
    </div>
</div>