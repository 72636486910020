<div [class]="size">
    <ng-template #tplIconLocation><i nz-icon nzType="environment"></i></ng-template>
    <ng-template #tplInfo>
      <i *ngIf="isLoading" nz-icon nzType="loading" nzTheme="outline"></i>
      <div *ngIf="!isLoading && zipcodeInfo?.desc">{{zipcodeInfo.desc}}</div>
    </ng-template>
    <nz-form-control>
      <nz-input-group [nzPrefix]="tplIconLocation" [nzSuffix]="tplInfo">
        <input nz-input
          [ngClass]="{'validation-error': !isValid, 'invalid': !isValid, 'valid': isValid}"
          [placeholder]="placeholder"
          (input)="onInputChanged($event)"
        >
      </nz-input-group>
    </nz-form-control>
  </div>