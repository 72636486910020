<div class="box-container" *ngIf="renderTemplate == 'TMPL_1'">
    <div class="flex outer-box TMPL_1">
        <div class="general-info-container">
            <ng-container *ngTemplateOutlet="filter"></ng-container>
        </div>
        <div class="flex1 map">
            <div class="map-container">
                <map-container [(map)]="map" (selectShipment)="onSelectShipment($event)" mapStyle="streets-v11"></map-container>
            </div>
            <div *ngIf="selectedShipment" class="selected-shipment-popup">
                <div shipment-quickview [shipment]="selectedShipment"></div>
                <div>
                    <button nz-button (click)="onCloseShipment()" class="right10">Close</button>
                </div>
            </div>
        </div>
        <div *ngIf="showShipmentList" class="shipment-list" [style.width]="displayConfig.shipmentListSize * 80 + 'px'">
            <div need-routing-shipment-list [shipments]="filtered" (onRemove)="onRemoveShipment($event)"
                (reviewStatusUpdate)="onReviewStatusUpdate($event)"
                (deliveryInfoUpdated)="onDeliveryInfoUpdate($event)"
            ></div>
        </div>
    </div>
</div>

<div class="box-container" *ngIf="renderTemplate == 'TMPL_2'">
    <div class="flex outer-box TMPL_2">
        <div class="general-info-container">
            <ng-container *ngTemplateOutlet="filter"></ng-container>
        </div>
        <div class="content-bottom flex">
            <div *ngIf="showShipmentList" style="position: relative;" [style.flex]="displayConfig.shipmentListSize">
                <div class="shipment-list-container">
                    <div need-routing-shipment-list [shipments]="filtered" (onRemove)="onRemoveShipment($event)"
                        (hoverShipment)="onMouseOverShipment($event)" (clickShipment)="onClickShipment($event)"
                    ></div>
                </div>
            </div>
            <div class="map" style="flex: 12;">
                <div class="map-container">
                    <map-container [(map)]="map" (selectShipment)="onSelectShipment($event)"></map-container>
                </div>
                <div *ngIf="selectedShipment" class="selected-shipment-popup">
                    <div shipment-quickview [shipment]="selectedShipment"></div>
                    <div>
                        <button nz-button (click)="onCloseShipment()" class="right10">Close</button>
                    </div>
                </div>
            </div>        
        </div>
    </div>
</div>

<div class="box-container" *ngIf="renderTemplate == 'TMPL_3'">
    <div class="flex outer-box TMPL_2">
        <div class="general-info-container">
            <ng-container *ngTemplateOutlet="filter"></ng-container>
        </div>
        <div class="content-bottom">
            <div class="shipment-list-container">
                <div need-routing-shipment-list [shipments]="filtered" (onRemove)="onRemoveShipment($event)"
                (reviewStatusUpdate)="onReviewStatusUpdate($event)"
                (deliveryInfoUpdated)="onDeliveryInfoUpdate($event)"
                ></div>
            </div>
        </div>
    </div>
</div>

<div class="box-container" *ngIf="renderTemplate == 'TMPL_4'">
    <div class="flex outer-box TMPL_4">
        <div class="general-info-container">
            <ng-container *ngTemplateOutlet="filter"></ng-container>
        </div>
        <div class="content-bottom">
            <div class="shipment-list-container">
                <div need-routing-shipment-list [shipments]="filtered" (onRemove)="onRemoveShipment($event)"
                (clickShipment)="onClickShipment($event)"
                (hoverShipment)="onMouseOverShipment($event)"
                (reviewStatusUpdate)="onReviewStatusUpdate($event)"
                (deliveryInfoUpdated)="onDeliveryInfoUpdate($event)"
                ></div>
            </div>
            <div class="map-container">
                <map-container [(map)]="map"></map-container>
            </div>
        </div>
    </div>
</div>

<ng-template #filter>
    <div class="group">
        <span nz-dropdown [nzDropdownMenu]="menu">
            <span *ngIf="renderTemplate == 'TMPL_1'" nz-icon nzType="border-left" nzTheme="outline"></span>
            <span *ngIf="renderTemplate == 'TMPL_2'" nz-icon nzType="border-top" nzTheme="outline"></span>
            <span *ngIf="renderTemplate == 'TMPL_3'" nz-icon nzType="table" nzTheme="outline"></span>
            <span *ngIf="renderTemplate == 'TMPL_4'" nz-icon nzType="pic-right" nzTheme="outline"></span>
        </span>
        <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
                <li nz-menu-item (click)="switchRenderTemplate('TMPL_1')" [nzDisabled]="renderTemplate == 'TMPL_1'">
                    <span nz-icon nzType="border-left" nzTheme="outline"></span>
                </li>
                <li nz-menu-item (click)="switchRenderTemplate('TMPL_2')" [nzDisabled]="renderTemplate == 'TMPL_2'">
                    <span nz-icon nzType="border-top" nzTheme="outline"></span>
                </li>
                <li nz-menu-item (click)="switchRenderTemplate('TMPL_3')" [nzDisabled]="renderTemplate == 'TMPL_3'">
                    <span nz-icon nzType="table" nzTheme="outline"></span>
                </li>
                <li nz-menu-item (click)="switchRenderTemplate('TMPL_4')" [nzDisabled]="renderTemplate == 'TMPL_4'">
                    <span nz-icon nzType="pic-right" nzTheme="outline"></span>
                </li>
            </ul>
        </nz-dropdown-menu>
    </div>
    <div class="group">
        <!-- <div>Total Shipments: {{ _shipments?.length }}</div> -->
        <div>Shipments: {{ filtered?.length }}</div>
    </div>
    <div class="group">
        <div><label>Date Range:</label></div>
        <div>
            <span *ngFor="let range of freqOptions"
                [class]="['mileage-option', _filter.freq === range ? 'selected' : 'normal']"
                (click)="setFreq(range)"
            >{{ range }}</span>
        </div>
    </div>
    <div class="group" *ngIf="_filter.freq == 'daily'">
        <div><label>Date</label></div>
        <div>
            <nz-date-picker [(ngModel)]="_filter.date" nzMode="date" (nzOnOpenChange)="onDateChange($event)"></nz-date-picker>
        </div>
    </div>
    <div class="group" *ngIf="_filter.freq == 'weekly'">
        <div><label>Week</label></div>
        <div>
            <nz-date-picker [(ngModel)]="_filter.week" nzMode="week" (nzOnOpenChange)="onDateChange($event)"></nz-date-picker>
        </div>
    </div>
    <div class="group" *ngIf="_filter.freq == 'monthly'">
        <div><label>Month</label></div>
        <div>
            <nz-date-picker [(ngModel)]="_filter.month" nzMode="month" (nzOnOpenChange)="onDateChange($event)"></nz-date-picker>
        </div>
    </div>
    <div class="group">
        <div><label>Market</label></div>
        <div>
            <nz-select nzBackdrop="true" nzAllowClear nzShowSearch
                [(ngModel)]="_filter.markets"
                nzMode="multiple"
                style="width: 100%; min-width: 160px;"
                (ngModelChange)="onFilterChange($event)">
                <nz-option *ngFor="let item of markets"
                    [nzValue]="item" [nzLabel]="item">
                </nz-option>
        </nz-select>
        </div>
    </div>
    <div class="group">
        <div><label>Clients</label></div>
        <div>
            <nz-select nzBackdrop="true" nzAllowClear nzShowSearch
                [(ngModel)]="_filter.clients"
                nzMode="multiple"
                style="width: 100%; min-width: 160px;"
                (ngModelChange)="onFilterChange($event)">
                <nz-option *ngFor="let item of clients"
                    [nzValue]="item.id" [nzLabel]="item.name">
                </nz-option>
        </nz-select>
        </div>
    </div>
    <div class="group">
        <div><label>Mileage Range:</label></div>
        <div>
            <span *ngFor="let range of mileageRanges"
                [class]="['mileage-option', _filter.maxMileage === range ? 'selected' : 'normal']"
                (click)="setMaxMileage(range)"
            >{{ range }}</span>
            <span (click)="setMaxMileage(0)" [class]="['mileage-option', _filter.maxMileage === 0 ? 'selected' : 'normal']">All</span>
        </div>
    </div>
    <div class="group">
        <div><label>Leg:</label></div>
        <div>
            <span *ngFor="let range of legTypes"
                [class]="['mileage-option', _filter.leg === range ? 'selected' : 'normal']"
                (click)="setLegType(range)"
            >{{ range }}</span>
        </div>
    </div>
    <div class="group">
        <button nz-button (click)="loadShipments(true)" class="right10" [disabled]="loading">
            <i nz-icon nzType="loading" nzTheme="outline" *ngIf="loading"></i>
            <span nz-icon nzType="reload" nzTheme="outline" *ngIf="!loading"></span>
            Refresh
        </button>
        <ng-container *ngIf="renderTemplate !== 'TMPL_3' && renderTemplate !== 'TMPL_4'">
        <button nz-button (click)="toggleShowShipments()">
            <span *ngIf="!showShipmentList">Show List</span>
            <span *ngIf="showShipmentList">Hide</span>
        </button>
        <span *ngIf="showShipmentList" style="margin-left: 10px;">
            <span class="clickable" nz-icon nzType="caret-left" (click)="changeShipmentListSize(-1)" nzTheme="outline"></span>
            <span class="clickable" nz-icon nzType="caret-right" (click)="changeShipmentListSize(1)" nzTheme="outline"></span>
        </span>
        </ng-container>
    </div>
</ng-template>