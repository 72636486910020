<form #form [formGroup]="formInput" nz-form>
  <div class="form-inner-box">
    <ng-container *ngFor="let key of formInputKeys">
      <ng-container [ngSwitch]="key">
        <div *ngSwitchCase="'type'">
          <div class="form-item" [ngClass]="key">
            <div class="form-label-v2">
              {{getLabel(key)}}
              <span *ngIf="isRequired(key)" class="label-mark-required"></span>
            </div>
            <nz-form-item>
              <nz-form-control>
                <nz-select nzBackdrop="true" nzShowSearch style="min-width: 120px;"
                  [formControlName]="key"
                  [nzPlaceHolder]="getPlaceHolder(key)"
                  (ngModelChange)="onChange($event, key)">
                  <nz-option *ngFor="let item of filterTypeOptions"
                    [nzValue]="item.value" [nzLabel]="item.name">
                  </nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <ng-container *ngIf="['day', 'month'].includes(model.type)">
          <div *ngSwitchCase="'fromDate'">
            <div class="form-item" [ngClass]="key">
              <div class="form-label-v2">
                {{getLabel(key)}}
                <span *ngIf="isRequired(key)" class="label-mark-required"></span>
              </div>
              <nz-form-item>
                <nz-form-control>
                  <nz-date-picker
                    [formControlName]="key"
                    [nzFormat]="model.type == 'month' ? 'yyyy-MM' : 'yyyy-MM-dd'"
                    [nzMode]="model.type == 'month' ? 'month' : 'date'"
                    (ngModelChange)="onChange($event, key)">
                  </nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <div *ngSwitchCase="'toDate'">
            <div class="form-item" [ngClass]="key">
              <div class="form-label-v2">
                {{getLabel(key)}}
                <span *ngIf="isRequired(key)" class="label-mark-required"></span>
              </div>
              <nz-form-item>
                <nz-form-control>
                  <nz-date-picker
                    [formControlName]="key"
                    [nzFormat]="model.type == 'month' ? 'yyyy-MM' : 'yyyy-MM-dd'"
                    [nzMode]="model.type == 'month' ? 'month' : 'date'"
                    (ngModelChange)="onChange($event, key)">
                  </nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="model.type == 'quarter'">
          <div *ngSwitchCase="'year'">
            <div class="form-item" [ngClass]="key">
              <div class="form-label-v2">
                {{getLabel(key)}}
                <span *ngIf="isRequired(key)" class="label-mark-required"></span>
              </div>
              <nz-form-item>
                <nz-form-control>
                  <nz-date-picker
                    [formControlName]="key" nzMode="year"
                    (ngModelChange)="onChange($event, key)">
                  </nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</form>

