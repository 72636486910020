import { BaseComponent } from "@abstract/BaseComponent";
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { Const } from "@const/Const";
import { DateUtil } from "@services/date-utils";

enum PickDateType {
  date,
  flexible,
}
enum PickDateTypeFlexible {
  thisWeek,
  nextWeek,
  thisMonth,
}

@Component({
  selector: '[pick-date]',
  templateUrl: './index.html',
  styleUrls: ['./index.less']
})
export class PickDate extends BaseComponent {
  optionSelected = null ;
  visible = false;
  options = [PickDateType.date, PickDateType.flexible];
  optionType: PickDateType = this.options[0];
  value: Date|Array<Date> = new Date();
  @Input() placeholder: string = '';
  @Input() isError:boolean = false;
  @Output() onDone: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('inputSelect') inputSelect: ElementRef<HTMLElement>;
  @ViewChild('backgroupDropdown') backgroupDropdown: ElementRef<HTMLElement>;

  flexibleOptions: Array<PickDateTypeFlexible> = [
    PickDateTypeFlexible.thisWeek,
    PickDateTypeFlexible.nextWeek,
    PickDateTypeFlexible.thisMonth
  ];
  flexibleValue: PickDateTypeFlexible = this.flexibleOptions[0];
  
  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
  ngAfterViewChecked(): void {
    if (this.backgroupDropdown.nativeElement.offsetWidth) {
      this.backgroupDropdown.nativeElement.style.width = `${this.inputSelect.nativeElement.offsetWidth}px`;
    }
  }
  namePickDateType(v: PickDateType): string {
    switch (v) {
      case PickDateType.date: return "Choose date";
      case PickDateType.flexible: return "I'm flexible";
    }
  }
  namePickDateTypeFlexible(v: PickDateTypeFlexible): string {
    switch (v) {
      case PickDateTypeFlexible.thisWeek: return 'This week';
      case PickDateTypeFlexible.nextWeek: return 'Next Week';
      case PickDateTypeFlexible.thisMonth: return 'This month';
    }
  }

  onClickBtnDone() {
    this.visible = false;
    switch (this.optionType) {
      case PickDateType.date:
        if (this.value != null) {
          const today = new Date().setHours(0, 0, 0, 0);
          const difference: number = today - (<Date> this.value).getTime();
          if (difference < 0) {
            this.optionSelected = DateUtil.format((<Date>this.value).toISOString(), Const.FORMAT_GUI_DATE_V2);
          } else {
            this.showErr('Invalid pickup date, please choose again');
          }
        }
        break;
      case PickDateType.flexible:
        if (this.flexibleValue != null) {
          const dateRange = this.getDateFlexible();
          this.optionSelected = `${DateUtil.format(dateRange.fromDate, Const.FORMAT_GUI_DATE_V2)} to ${DateUtil.format(dateRange.toDate, Const.FORMAT_GUI_DATE_V2)}`;
        }
        break;
      default:
        return;
    }
    if (this.value && this.optionType == PickDateType.date) {
      const today = new Date().setHours(0, 0, 0, 0);
      const difference: number = today - (<Date> this.value).getTime();
      if (difference < 0) {
        this.onDone.emit(this.value);
      }
    } else {
      this.onDone.emit(this.value);
    }
  }
  onRemoveValue() {
    this.visible = false;
    this.optionSelected = '';
    this.value = null;
    this.flexibleValue = null;
    this.onDone.emit(this.value);
  }
  getDateFlexible() {
    let fromDate = null;
    let toDate = null;
    switch(this.flexibleValue) {
      case PickDateTypeFlexible.thisWeek:
        this.value = [DateUtil.toBeginOfThisWeek(), DateUtil.toEndOfThisWeek()];
        fromDate = DateUtil.format(DateUtil.toBeginOfThisWeek().toDateString(), 'YYYY/MM/DD')
        toDate = DateUtil.format(DateUtil.toEndOfThisWeek().toDateString(), 'YYYY/MM/DD');
        return {fromDate, toDate};
      case PickDateTypeFlexible.nextWeek:
        this.value = [DateUtil.toBeginOfNextWeek(), DateUtil.toEndOfNextWeek()];
        fromDate = DateUtil.format(DateUtil.toBeginOfNextWeek().toDateString(), 'YYYY/MM/DD')
        toDate = DateUtil.format(DateUtil.toEndOfNextWeek().toDateString(), 'YYYY/MM/DD');
        return {fromDate, toDate};
      case PickDateTypeFlexible.thisMonth:
        this.value = [DateUtil.toBeginOfThisMonth(), DateUtil.toEndOfThisMonth()];
        fromDate = DateUtil.format(DateUtil.toBeginOfThisMonth().toDateString(), 'YYYY/MM/DD')
        toDate = DateUtil.format(DateUtil.toEndOfThisMonth().toDateString(), 'YYYY/MM/DD');
        return {fromDate, toDate};
      default: 
        return {fromDate: null, toDate: null};
    }
  }
  onChangeValue(key, value) {
    this[key] = value;
    if (key == 'optionType' && this.optionType !== value) {
      this.value = null;
    }
    if (key !== 'optionType') {
      this.onClickBtnDone();
    }
  }
  dateSelectChange(event) {
    this.onClickBtnDone();
  }

  disabledDate = (current: Date): boolean => {
    const today = new Date().setHours(0, 0, 0, 0);
    const difference: number = today - current.getTime();
    return difference > 0;
  }
}