<div class="flex">
    <div class="flex1">
        <a [routerLink]="[routeAdminFreightQuoteFtl]">
            <span class="button"><i nz-icon nzType="left"></i> Back To Search</span>
        </a>    
    </div>
    <div>
        <span class="button right" (click)="loadRequests()">
            <i nz-icon nzType="reload" *ngIf="!loading"></i>
            <i nz-icon nzType="loading" nzTheme="outline" *ngIf="loading"></i>
            Refresh
        </span>
    </div>
</div>
<div class="flex">
    <div class="column right10">
        <div class="label">Customer</div>
        <div size="sm" client-selector [clientId]="filters.customer" (onNewValue)="onSelectClient($event)"></div>
    </div>
    <div class="right10">
        <div class="label">Pickup Date</div>
        <div>
            <nz-date-picker
                nzFormat="yyyy-MM-dd"
                [(ngModel)]="filters.pickupDate"
                (ngModelChange)="updateFilter()"
            ></nz-date-picker>
        </div>
    </div>
    <div class="right10">
        <div class="label">Quote Date</div>
        <div>
            <nz-date-picker
                nzFormat="yyyy-MM-dd"
                [(ngModel)]="filters.quoteDate"
                (ngModelChange)="updateFilter()"
            ></nz-date-picker>
        </div>
    </div>
    <div class="right10">
        <div class="label">Pickup Zipcode</div>
        <div>
            <div size="sm" zipcode-selector placeholder="Delivery Zipcode" (onNewValue)="onPickupZipcodeChange($event)"></div>
        </div>
    </div>
    <div class="right10">
        <div class="label">Dropoff Zipcode</div>
        <div>
            <div size="sm" zipcode-selector placeholder="Delivery Zipcode" (onNewValue)="onDropoffZipcodeChange($event)"></div>
        </div>
    </div>

</div>
<div *ngIf="list?.items" class="box">
    <div class="list-header" nz-row [nzGutter]="{md:16}">
        <div nz-col nzSm="4">Quoted Time</div>
        <div nz-col nzSm="5">
            Type / Customer
            <span freight-quote-history-list-filter [options]="filterOptions.type" [(value)]="filters.type" (valueChange)="updateFilter()"></span>
        </div>
        <div nz-col nzSm="3">Pickup Date</div>
        <div nz-col nzSm="3">Trip</div>
        <div nz-col nzSm="3">Vehicle</div>
        <div nz-col nzSm="3">Rate</div>
        <div nz-col nzSm="2">
            Status
            <span freight-quote-history-list-filter [options]="filterOptions.status" [(value)]="filters.status" (valueChange)="updateFilter()"></span>
        </div>
    </div>
    <div style="min-height: 160px; position: relative;">
        <div *ngFor="let item of list.items" freight-quote-history-list-item [item]="item"></div>
        <div *ngIf="!list?.items?.length">No data</div>
        <div *ngIf="loading" class="loading-wrapper">
            <div class="loading-icon">
                <i nz-icon nzType="loading" nzTheme="outline"></i>
            </div>
        </div>
    </div>

    <div class="footer flex" *ngIf="list">
        <div (click)="navigateFirst()" [class]="{'item': true, 'inactive': !list.skip}"><span nz-icon nzType="double-left" nzTheme="outline"></span> First</div>
        <div (click)="navigatePrev()" [class]="{'item': true, 'inactive': !list.skip}"><span nz-icon nzType="left" nzTheme="outline"></span> Prev</div>
        <div style="padding: 10px;">
            Result: <span class="emphasized">{{ list.skip + 1 }}</span> - <span class="emphasized">{{ list.skip + list.count }}</span>
        </div>
        <div (click)="navigateNext()"  [class]="{'item': true, 'inactive': list.count < list.limit}">Next <span nz-icon nzType="right" nzTheme="outline"></span></div>
    </div>
</div>
