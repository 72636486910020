import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { BaseComponent } from '@abstract/BaseComponent';
import { environment } from '@env/environment';
import { DateUtil } from '@services/date-utils';
import { Const } from '@const/Const';

@Component({
  selector: '[freight-quote-history-list-item]',
  templateUrl: './item.html',
  styleUrls: ['./index.scss', '../index.scss']
})
export class FreightQuoteHistoryListItem extends BaseComponent {
    public request
    public response
    public client
    public quoteTime
    public pickup
    public dropoff
    public pickupDate
    public booked
    public rate

    @Input() set item(value: any) {
        this.request = value?.request
        this.response = value?.response
        this.client = value?.client
        const { deliveryInfos } = this.request || {}
        this.pickup = (deliveryInfos || []).filter(it => it.type === 'PICKUP')?.[0]
        this.dropoff = (deliveryInfos || []).filter(it => it.type === 'DROPOFF')?.[0]
        const requested = this.request?.requestedTs
        this.quoteTime = requested ? DateUtil.format(requested, Const.FORMAT_GUI_DATETIME_V5) : ''

        const window = this.pickup?.windows?.[0]?.from
        this.pickupDate = window ? DateUtil.format(window, Const.FORMAT_GUI_DATE_SHORT) : ''
        this.booked = this.request?.status === 'BOOKED'

        // calculate rate
        let rate = null
        if (this.response?.quotes) {
            for (let quote of this.response?.quotes) {
                if (quote.options) {
                    for (let option of quote.options) {
                        if (option.rate?.avg) {
                            if (rate == null || rate.rate.avg > option.rate?.avg) {
                                rate = option
                            }
                        }
                    }
                }
            }
        }
        this.rate = rate
    }

    onViewItem() {
        this.router.navigate([Const.routeAdminFreightQuoteFtl, this.request.id])
    }

}