import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BaseComponent } from '@abstract/BaseComponent';
import { Const } from '@const/Const';
import { FreightQuoteItem } from '../quote-item';

@Component({
  selector: '[freight-quote-result]',
  templateUrl: './index.html',
  styleUrls: ['./index.less' ]
})
export class FreightQuoteResult extends BaseComponent {

  @ViewChild('freightQuoteFilter') freightQuoteFilter: FreightQuoteItem;

  private _listDataQuote = []
  listDataQuoteSortASAPDeliveries = [];
  dataQuotes = [];
  quotesOfPage = [];
  pageIndex = 1;
  pageSize = 10;
  @Input() get listDataQuote() {return this._listDataQuote}
  set listDataQuote(value) {
    this._listDataQuote = value;
    this.dataQuotes = value;
    this.listDataQuoteSortASAPDeliveries = this.onSortListDataQuote([...value]);
    this.pageIndex = 1;
    this.quotesOfPage = this.dataQuotes.slice((this.pageIndex - 1) * this.pageSize, this.pageIndex * this.pageSize);
  }
  @Input() filters: any;
  @Input() routeData: any;
  @Input() distance = 0;
  @Input() isShowCarrierName = false;
  @Output() onBookLoad: EventEmitter<any> = new EventEmitter<any>();
  routers = [];
  isASAPDelivery = false;

  constructor() {
    super();
  }
  
  ngOnInit(): void {
    
  }

  clickSwitchASAP() {
    setTimeout(() => {
      if (this.isASAPDelivery) {
        this.dataQuotes = this.listDataQuoteSortASAPDeliveries;
      } else {
        this.dataQuotes = this._listDataQuote;
      }
      this.pageIndex = 1;
      this.quotesOfPage = this.dataQuotes.slice((this.pageIndex - 1) * this.pageSize, this.pageIndex * this.pageSize);
    }, 100);
  }

  onSortListDataQuote(value) {
    return value.sort((a,b)=>{
      if (a?.legs[0]?.scheduled_delivery_date && b?.legs[0]?.scheduled_delivery_date) {
        return new Date(a?.legs[0]?.scheduled_delivery_date).getTime() - new Date(b?.legs[0]?.scheduled_delivery_date).getTime()}
      }
    );
  }

  onBoookLoadBtn(quote) {
    this.onBookLoad.emit(quote);
  }
  get getListDataQuote() {
    if (!this.isASAPDelivery) {
      return this.listDataQuote;
    } else {
      const quotes = this.listDataQuote.sort((a,b)=>{
        if (a?.legs[0]?.scheduled_delivery_date && b?.legs[0]?.scheduled_delivery_date) {
          return new Date(a?.legs[0]?.scheduled_delivery_date).getTime() - new Date(b?.legs[0]?.scheduled_delivery_date).getTime()}
        }
      );
      return quotes;
    }
  }

  getAddress(addr) {
    if (!addr) return '';
    return `${addr.city}, ${addr.state}`;
  }
  getMiles(meters) {
    if (!meters) {
      return 0;
    }
    return Const.metersToMiles(meters).toFixed(2);
  }
  changePageIndex(value) {
    this.pageIndex = value;
    this.quotesOfPage = this.dataQuotes.slice((this.pageIndex - 1) * this.pageSize, this.pageIndex * this.pageSize);
  }
}