import { Component } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { FormUtil } from "@services/form-util";

@Component({
  selector: '[shipment-payment]',
  templateUrl: './shipment-payment.html',
  styleUrls: [
    './index.less',
    '../../../dialogs/dialogs.scss', 
    '../../../../styles/form-v2.scss'
  ]
})
export class FormShipmentPayment extends BaseFormDialog1 {
  public onSave: (data) => void = () => {}

  get isCreateNew(): boolean {
    return !this.model;
  }

  public static get declaration(): FormGroupDeclaration {return {
    listCards: {label: '', type: 'formArray', childItem: {
      cardNumber: {label: 'Card Number'},
      isUsing:  {label: '', type: 'boolean', initialValue: false},
    }},
    isAddNewCard: {label: 'Add New Card', type: 'boolean', initialValue: false},
    newCardInfo: {label: '', type: 'formGroup', childItem: {
      cardNumber: {label: 'Card Number', required: false},
      expiryDate: {label: 'Expiry Date', required: false},
      cardCodeCVC: {label: 'Card Code (CVC)', required: false}
    }},
  }}

  protected formGroupDeclaration: FormGroupDeclaration = FormShipmentPayment.declaration;

  public rowsAddNewCard: Array<FormRow> = [
    {columns: [
      {key: 'cardNumber', span: 24, offset: 0}
    ]},
    {columns: [
      {key: 'expiryDate', span: 11, offset: 0},
      {key: 'cardCodeCVC', span: 11, offset: 2},
    ]},
  ]

  get strTitle() {
    return 'Confirm Payment Method';
  }

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    let model = {
      listCards: [
        {
          cardNumber : 'Visa ending with 5002',
          isUsing: true
        },
        {
          cardNumber : 'Visa ending with 2531',
          isUsing: false
        },
      ],
      isAddNewCard: false,
      newCardInfo: null
    }
    this.bindDataModel(model);
  }

  protected bindDataModel(model) {
    FormUtil.bindData(this.formInput, this.beforeBindModel(model), this.formGroupDeclaration);
    this.afterBindModel(model);
    this.setEnableFormGroup(true);
  }

  getCheckValueFor(key: string): boolean {
    switch (key) {
      case 'newCardInfo': return this.formInput.get('isAddNewCard').value;
      default: return false;
    }
  }

  private getFormArrayListCards(): FormArray {
    return <FormArray>this.formInput.get('listCards');
  }

  getControlListCards() {
    return this.getFormArrayListCards()?.controls ?? [];
  }

  onInputKeyPress(event, key) {
    switch (key) {
      default:
        return super.onInputKeyPress(event, key);
    }
  }

  onInputChanged(event, key) {
    switch (key) {
      default:
        return super.onInputChanged(event, key);
    }
  }

  onBtnSave() {
    let data: any = this.getFormData_JSON(true);
    this.onSave(data);
    this.closeDialog();
  }

  public onBtnCancel() {
    let msg = this.canQuit()
    if (msg) {
      this.confirmYesNo('Do you really want to close?', () => this.closeDialog());
    } else {
      this.closeDialog();
    }
  }

  public getListCardAt(index: number): any {
    let fg = <FormGroup>(<FormArray>this.formInput.get('listCards')).at(index);
    return FormUtil.getFormGroupData(fg, <FormGroupDeclaration>this.formGroupDeclaration.listCards.childItem);
  }

  onRequiresAddNewCardChange() {
    let isAddNewCard = this.getItemValue(`isAddNewCard`);
    let childKeys = ['cardNumber', 'expiryDate', 'cardCodeCVC'];
    for (let childKey of childKeys) {
      this.formGroupDeclaration.newCardInfo.childItem[childKey].required = isAddNewCard;
    }
    let fg = <FormGroup>this.formInput.get('newCardInfo');
    for (let childKey of childKeys) {
      let fc = <FormControl>fg.get(childKey);
      if (fc) {
        if (!isAddNewCard) {
          fc.removeValidators(Validators.required);
        } else {
          fc.addValidators(Validators.required);
        }
        fc.updateValueAndValidity();
      }

    }
    if (isAddNewCard) {
      let faListCards = <FormArray>this.formInput.get('listCards');
      for (let i = 0; i < faListCards.length; i++) {
        this.setItemValue(`listCards[${i}].isUsing`, false);
      }
    }
  }

  onBtnCardChange(index: number) {
    let isUsing = this.getItemValue(`listCards[${index}].isUsing`);
    if (isUsing) {
      let faListCards = <FormArray>this.formInput.get('listCards');
      for (let i = 0; i < faListCards.length; i++) {
        if (i != index) {
          this.setItemValue(`listCards[${i}].isUsing`, false);
        }
      }
      this.setItemValue(`isAddNewCard`, false);
      this.onRequiresAddNewCardChange();
    }
  }

  onBtnConfirm() {
    if (!this.needUpdate) {
      return;
    }
    let data = {};
    this.onSave(data);
    this.closeDialog();
  }

  get needUpdate() {
    // Kiểm tra chắc chắn duy nhất 1 card dc chọn
    let countCardSelect = 0;
    let faListCards = <FormArray>this.formInput.get('listCards');
    for (let i = 0; i < faListCards.length; i++) {
      let isUsing = this.getItemValue(`listCards[${i}].isUsing`);
      if (isUsing) countCardSelect++;
    }
    if (this.getItemValue(`isAddNewCard`)) countCardSelect++;
    if (countCardSelect != 1) {
      return false;
    }
    return super.needUpdate;
  }
  
}