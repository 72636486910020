<div class="bottom10">
    Select a date to move Shipment
    <code class="right10" *ngFor="let shipment of shipments">{{ shipment.code }}({{shipment.warpId}})</code>
</div>
<div class="bottom10">
    <nz-radio-group [(ngModel)]="selectedDate">
        <div *ngFor="let day of days" class="top5 bottom5">
            <label nz-radio [nzValue]="day.value">{{ day.label }}</label>
        </div>
    </nz-radio-group>
</div>
<div class="center-children">
    <button class="right10" nz-button (click)="onCancelBtn()">Cancel</button>
    <button nzType="primary" nz-button (click)="onSaveBtn()">Move</button>
</div>