import { DeliveryInfo, LatLng } from "@wearewarp/types/data-model";
import { Cost, Route, RoutingContext, Stop } from "./route";
import { Const } from "@const/Const";
import _ from 'underscore'
import twoOpt from "./two_opt";
import { ThisReceiver } from "@angular/compiler";
import { Utils } from "../components/map";
import { ApiService } from "@services/api.service";

export default class RoutingService {
    context: RoutingContext

    constructor() {
        this.context = {
            locations: {}
        }
    }

    async init(locations: DeliveryInfo[]) {
        this.context.stops = locations.map(it => Object.assign({
            id: it.id,
            type: it.type,
            info: it,
            shipmentId: it.shipment_id,
            locId: Utils.calculateAddressLocationId(it.addr),
            location: Utils.getAdressLatLng(it.addr)
        }))

        // create list of locations
        for (let stop of this.context.stops) {
            this.context.locations[stop.locId] = stop.location
        }

        // query traffic matrix
        this.context.matrix = await this.evaluateTraffic()
        // console.log(this.context)
    }

    plusCost(c1: Cost, c2: Cost): Cost {
        return {
            distance: c1.distance + c2.distance,
            time: c1.time + c2.time
        }
    }

    estimateRouteCost(route: Route) {
        let totalCost = {distance: 0, time: 0}
        if (!route.startTime)
            route.startTime = new Date().getTime() / 1000
        if (!route.stops || !route.stops.length) return
        route.stops[0].arrTime = route.startTime
        for (let i = 0; i < route.stops.length - 1; i++) {
            const current = route.stops[i]
            const next = route.stops[i + 1]
            const cost = this.context.matrix[current.locId][next.locId]
            next.arrTime = current.arrTime + (cost.time || 0)
            next.costFromPrev = cost
            current.costToNext = cost
            if (cost) {
                totalCost = this.plusCost(totalCost, cost)
            }
        }
        route.cost = totalCost
        return route
    }

    async evaluateTraffic(matrix = {}) {
        const latLngs = _.values(this.context.locations).map(it => Object.assign({}, {lat: it.latitude, lng: it.longitude}))
        return this.updateTraffic(latLngs, latLngs, matrix)
    }

    async updateTraffic(from, to, matrix) {
        if (!from || !from.length || !to || !to.length) return Promise.resolve(matrix)
        return ApiService.instance.POST(Const.APIV2('traffic/matrix'), {
            from,
            to
        }).toPromise().then((r) => {
            const { distances, times } = r
            // TODO: handle exception
            for (let i = 0; i < from.length; i ++) {
                const fromId = Utils.latLngId2(from[i])
                for (let j = 0; j < to.length; j ++) {
                    const toId = Utils.latLngId2(to[j])
                    const m = matrix[fromId] || {}
                    m[toId] = {
                        distance: distances[i][j],
                        time: times[i][j],
                    }
                    matrix[fromId] = m
                }
            }
            return matrix
        })
    }

    singleRoute(startLocation: LatLng = null) {
        let route = {
            startLocation,
            stops: this.context.stops
        }
        twoOpt(route, this.context)
        return this.estimateRouteCost(route)
    }

    optimizeRoute(route: Route) {
        const newRoute: Route = {
            startLocation: route.startLocation,
            stops: route.stops
        }
        if (route.startLocation) {
            const start: Stop = {
                locId: Utils.latLngId(route.startLocation),
                location: route.startLocation
            }

            newRoute.stops = [start].concat(newRoute.stops)
        }
        twoOpt(newRoute, this.context)
        this.estimateRouteCost(newRoute)
        if (newRoute.startLocation)
            newRoute.stops = newRoute.stops.slice(1)
        return newRoute
    }

    manualRoute(stopOrders: string[]) {
        let stopMap = {}
        for (let stop of this.context.stops) {
            stopMap[stop.info.id] = stop
        }
        const route = {
            stops: stopOrders.map(it => stopMap[it]).filter(it => it)
        }
        return this.estimateRouteCost(route)
    }

    async addLocations(locs: LatLng[]) {

        const latLngs = _.values(this.context.locations).map(it => Object.assign({}, {lat: it.latitude, lng: it.longitude}))
        let newLoc = []
        for (let loc of locs) {
            const locId = Utils.latLngId(loc)
            if (!this.context.locations[locId]) {
                this.context.locations[locId] = loc
                newLoc.push({lat: loc.latitude, lng: loc.longitude})
            }
        }

        // update matrix
        const promises = [
            this.updateTraffic(newLoc, latLngs.concat(newLoc), this.context.matrix),
            this.updateTraffic(latLngs, newLoc, this.context.matrix),
        ]
        await Promise.all(promises)
    }

    async refreshLocations(locs: LatLng[]) {
        // recalculate locations
        for (let stop of this.context.stops) {
            this.context.locations[stop.locId] = stop.location
        }
        return this.addLocations(locs)
    }
}