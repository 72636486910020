import { BaseComponent } from "@abstract/BaseComponent";
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";

@Component({
  selector: '[service-option]',
  templateUrl: './index.html',
  styleUrls: ['./index.less']
})
export class ServiceOption extends BaseComponent {
  optionSelected = [] ;
  visible = false;
  @Input() options: any;
  @Input() preffixIcon: string = '';
  @Input() placeholder: string = '';
  @Output() onDone: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('inputSelect') inputSelect: ElementRef<HTMLElement>;
  @ViewChild('backgroupDropdown') backgroupDropdown: ElementRef<HTMLElement>;
  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngAfterViewChecked(): void {
    if (this.backgroupDropdown.nativeElement.offsetWidth) {
      this.backgroupDropdown.nativeElement.style.width = `${this.inputSelect.nativeElement.offsetWidth}px`;
    }
  }

  onSelect(item, i) {
    this.options[i].isSelected = !this.options[i].isSelected;
  }

  onClickBtnDone() {
    this.visible = false;
    this.optionSelected = this.options.filter((option) => option.isSelected == true);
    if (this.optionSelected) {
      this.onDone.emit(this.optionSelected);
    }
  }
  
}