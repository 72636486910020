import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { environment } from "@env/environment";
import { DateUtil } from "@services/date-utils";

@Component({
    selector: '[finance-config-detail]',
    templateUrl: './index.html',
    styleUrls: ['./index.scss']
})
export class FinanceConfigDetail extends BaseComponent {
    public id: any = null;

    @Input() subject: string = null;
    public loading = true;
    public config: any = {};
    ngOnInit(): void {
        this.activatedRoute.params.subscribe(params => {
            this.id = params['id']
            this.loadConfig()
        })
    }

    public get isEmpty(): boolean {
        return !this.config?.active || this.config.active.length < 1
    }

    public formatDate(t): string {
        if (!t) return 'N/A'
        return DateUtil.format(t, 'YYYY/MM/DD HH:mm')
    }

    private loadConfig() {
        this.loading = true;
        this.config = null;
        if (!this.id) {
            this.config = null;
            this.loading = false;
            return;
        } else {
            const url = `${environment.ratingUrl}/${Const.APIURI_FINANCE_CONFIG}/${this.id}`;
            this.api.GET(url).subscribe(
                resp => {
                    this.config = {
                        'status': 'success',
                        'active': resp
                    };
                    this.loading = false;
                }, err => {
                    this.loading = false;
                    this.config = {
                        'status': 'error'
                    }
                }
            )
        }
    }

}