import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BizUtil } from "@services/biz";
import { DateUtil } from "@services/date-utils";

@Component({
    selector: '[planning-shipment-detail]',
    templateUrl: './index.html',
    styleUrls: ['./index.scss']
})
export class PlanningShipmentDetail extends BaseComponent {
    _shipment: any = null
    displayInfo: any = {}
    full: boolean = false

    get shipment() {
        return this._shipment
    }
    @Input() set shipment(v) {
        this._shipment = v
        this.processDisplayInfo()
    }

    @Output() onHover: EventEmitter<any> = new EventEmitter<any>();
    @Output() onRemove: EventEmitter<any> = new EventEmitter<any>();
    @Output() onAddToGroup: EventEmitter<any> = new EventEmitter<any>();
    @Output() onUnGroup: EventEmitter<any> = new EventEmitter<any>();

    toggleFull() {
        this.full = !this.full
    }

    processDisplayInfo() {
        const { metadata, workload } = this._shipment || {}
        const { shipment, issue } = metadata || {}
        const pickup = BizUtil.getPickInfo(shipment)
        const dropoff = BizUtil.getDropInfo(shipment)
        const infos = [pickup, dropoff]
        const stops = infos.map(it => Object.assign({
            info: it,
            window: DateUtil.displayTimeWindow((it.windows || [])[0], {format: 'H:mm', formatDateOnly: '', timezone: it.addr.metadata.timeZoneStandard})
        }))
        this.displayInfo = {
            warpId: `${ shipment?.shipmentTransitType === 'leg' ? 'L' : 'S' }-${shipment?.warpId}`,
            clientName: shipment?.metadata?.client?.name,
            workload: workload?.toFixed(0),
            stops,
            issue,
        }
    }

    onMouseClick() {
        this.toggleFull()
    }

    onRemoveButton() {
        this.modalService.confirm({
            nzTitle: "Remove Shipment from Planning",
            nzContent: `Are you sure you want to remove this shipment ${this.displayInfo.warpId}?`,
            nzOnOk: () => {
                const { metadata } = this._shipment || {}
                const { shipment } = metadata || {}
        
                this.onRemove.emit(shipment.id)
            }
        })
    }

    onUnGroupBtn() {
        this.modalService.confirm({
            nzTitle: "Un-Group Shipment",
            nzContent: `Are you sure you want to un-group this shipment ${this.displayInfo.warpId}?`,
            nzOnOk: () => {
                const { metadata } = this._shipment || {}
                const { shipment } = metadata || {}
        
                this.onUnGroup.emit(shipment.id)
            }
        })

    }

    onAddToGroupBtn() {
        const { metadata } = this._shipment || {}
        const { shipment } = metadata || {}

        this.onAddToGroup.emit(shipment.id)
    }
}