<div style="padding-bottom: 10px;">
    <div style="height: 12px; position: relative; margin-bottom: 4px;">
        <div *ngIf="movingMode && movingStop?.info?.type === 'PICKUP'" class="moving-location" (click)="moveToTop()" style="margin-left: 10px; margin-right: 10px;"><div class="arrow"></div></div>
    </div>
    <div *ngFor="let stop of route.stops; let i = index" stop-list-item #listItems
      [last]="i == route.stops.length -1" [stop]="stop" 
      [movingMode]="movingMode" 
      [moving]="movingStop && stop.info?.id === movingStop?.info?.id" 
      (onTriggerMove)="triggerMove(stop)" 
      (onCancelMoving)="cancelMoving()" 
      (onMoveAfterMe)="moveStopToAfter(stop)" 
      (mouseenter)="onHover.emit(stop)" 
      (mouseleave)="onHover.emit(null)">
    </div>
</div>
