import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Location } from '@angular/common';

import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { BaseComponent } from '@abstract/BaseComponent';
import { environment } from '@env/environment';
import { DateUtil } from '@services/date-utils';
import { Const } from '@const/Const';

@Component({
  selector: '[freight-quote-ftl-history-request]',
  templateUrl: './index.html',
  styleUrls: ['../index.scss',]
})
export class FreightQuoteFTLHistoryRequest extends BaseComponent {
    public isLoading: boolean = false
    public quoteRequest: any = null
    public quoteResponse: any = null
    public id

    public origin
    public destination
    public vehicleType
    public pickupDate
    public pickupInfo
    public dropoffInfo

    constructor(
        protected route: ActivatedRoute,
        private location: Location,
    ) {
        super();
    }
    
    
    ngOnInit(): void {
        this.route.params.subscribe(params => {
            this.id = params['id'];
            this.loadRequest()
        });
    }

    parseRequest() {
        const { deliveryInfos, vehicleType } = this.quoteRequest || {}
        this.pickupInfo = (deliveryInfos || []).filter(it => it.type === 'PICKUP')[0]
        this.origin = this.pickupInfo?.addr
        this.dropoffInfo = (deliveryInfos || []).filter(it => it.type === 'DROPOFF')[0]
        this.destination = this.dropoffInfo?.addr
        this.vehicleType = vehicleType
        const pickupWindow = (this.pickupInfo?.windows || [])[0]
        const pickupFrom = pickupWindow?.from
        this.pickupDate = pickupFrom ? DateUtil.dateToString(new Date(pickupFrom), Const.FORMAT_GUI_DATE_SHORT) : null
    }

    loadRequest() {
        this.origin = null
        this.destination = null
        const url = environment.backendUrl + `/v2/pricing/quote/request/${this.id}`
        this.api.GET(url).subscribe((res) => {
            this.quoteRequest = res
            this.parseRequest()
            this.loadResponse(res.responseId)
        })
    }

    parseResponse() {}

    loadResponse(responseId) {
        if (!responseId) {
            this.quoteResponse = null
            return
        }
        const url = environment.backendUrl + `/v2/pricing/quote/response/${responseId}`
        this.api.GET(url).subscribe((res) => {
            this.quoteResponse = res
            this.parseResponse()
        })
    }
    back(): void {
        this.location.back()
    }
}