<form class="form-detail" [formGroup]="formInput" nz-form>
  <div class="f17 bottom30">Update status for shipment <b>{{showShipmentCode(model)}}</b></div>
  
  <div class="bottom20" *ngFor="let key of formInputKeys">
    <div *ngIf="key=='status'">
      <div class="form-register-label">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
      <div class="form-register-value">
        <nz-form-item>
          <nz-form-control>
            <nz-select nzBackdrop="true" [(ngModel)]="currentStatus" (ngModelChange)="onChangeStatus($event)" *ngIf="key=='status'" style="width: 100%;" [formControlName]="key">
              <nz-option *ngFor="let item of allStatus" [nzValue]="item" [nzLabel]="getStatusOrder(item)"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </div>
  
  <form nz-form [formGroupName]="'statusInfo'"  *ngIf="isShowFormReason()">
    <div *ngIf="isCanceled()">
      <div class="form-register-label">{{getLabel('statusInfo.cancelReason')}}<span *ngIf="isRequired('statusInfo.cancelReason')" class="label-mark-required"></span></div>
      <div class="form-register-value">
        <nz-form-item>
          <nz-form-control>
            <nz-select nzBackdrop="true" style="width: 100%;" [formControlName]="'cancelReason'">
              <nz-option *ngFor="let item of allOrderCancelReasons" [nzValue]="item" [nzLabel]="getOrderCancelReason(item)"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div>
      <div class="form-register-label">{{getLabel('statusInfo.comment')}}<span *ngIf="isRequired('statusInfo.comment')" class="label-mark-required"></span></div>
      <div class="form-register-value">
        <nz-form-item>
          <nz-form-control>
            <textarea [formControlName]="'comment'" nz-input [nzAutosize]="{ minRows: 3, maxRows: 5 }"></textarea>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </form>
  
</form>

<div form-footer [onProgress]="onProgress" 
  [canClickOK]="needUpdate && !onProgress" [canClickCancel]="!onProgress" 
  [nzIconOK]="'save'" [labelOK]="'Save'" (onOK)="onBtnSave()" 
  (onCancel)="closeDialog()"></div>