<div *ngIf="loading"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
<div style="padding: 15px;">
    <button nz-button (click)="onSelectNewFormula()">Select New Rule</button>
</div>
<div *ngIf="isEmpty" style="padding: 25px;">
    No Active Settings!
</div>
<div *ngIf="!isEmpty">
    <ng-container *ngFor="let setting of settings.active">
        <div [ngClass]="['setting', setting.status]">
            <div *ngIf="setting.status === 'DRAFT'" class="status draft">
                DRAFT
            </div>
            <div style="display: flex;">
                <div style="flex: 1;">
                    <div><i nz-icon nzType="schedule" nzTheme="outline"></i><span class="label">Effective</span> <span
                            class="date">{{ formatDate(setting.effectiveDate) }}</span> <span>to</span><span
                            class="date">{{ formatDate(setting.expirationDate) }}</span></div>
                    <div><i nz-icon nzType="calculator" nzTheme="outline"></i><span class="label">Rate Rule</span> {{
                        setting.formulaId }}</div>
                </div>
                <div style="width: 220px; text-align: right;">
                    <div *ngIf="setting.status === 'ACTIVE'">
                        <div *ngIf="!setting.expirationDate">
                            <button nz-button (click)="onExpireSettings(setting)" nzDanger>Expire</button>
                        </div>
                    </div>
                    <div *ngIf="setting.status !== 'ACTIVE'">
                        <button style="margin-right: 5px;" nz-button (click)="onActivateSettings(setting)" nzType="primary">Activate</button>
                        <button nz-button (click)="onDeleteSettings(setting)" nzDanger>DELETE</button>
                    </div>
                </div>
            </div>
            <div *ngIf="setting.parameters" class="parameters">
                <div class="header">Configs</div>
                <nz-list>
                    <nz-list-item *ngFor="let item of setting.parameters">
                        <nz-list-item-meta>
                            <nz-list-item-meta-title>{{ item.name }}</nz-list-item-meta-title>
                            <nz-list-item-meta-description>Type: {{ item.valueType }}</nz-list-item-meta-description>
                        </nz-list-item-meta>
                        <div finance-value [value]="item.value"></div>
                    </nz-list-item>
                </nz-list>
            </div>
        </div>
    </ng-container>
</div>