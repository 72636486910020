<div style="padding: 15px;">
    <h3>Delete Rating Settings</h3>

    <p>Please confirm you want to delete this Rating settings.</p>
</div>

  
  <div form-footer [onProgress]="inProgress" 
    [canClickOK]="!inProgress && !isLoading" [canClickCancel]="!inProgress" 
    [nzIconOK]="'filter'" [labelOK]="'Confirm'" (onOK)="onOk()" 
    (onCancel)="onBtnCancel()"></div>
  
  
  