import { BaseComponent } from "@abstract/BaseComponent";
import { Component } from "@angular/core";
import { Const } from "@const/Const";
import { BizUtil } from "@services/biz";
import { DateUtil } from "@services/date-utils";
import { PlanningService } from "@services/planning.service";

@Component({
    selector: '[add-shipment-form]',
    templateUrl: './index.html',
    styleUrls: ['./index.scss']
})
export class AddShipmentForm extends BaseComponent {
    shipments: any[] = null
    shipmentIds: string = null
    adding: boolean=false
    service: PlanningService

    constructor() {
        super()
        this.service = new PlanningService(this.api)
        this.validateWindow = this.validateWindow.bind(this)
        this.processShipment = this.processShipment.bind(this)
    }

    loading = false
    validateWindow(window) {
        if (!window) return null
        if (!window.from && !window.to) return null
        const latest = window.to || window.from
        console.log(latest)
        if (Date.parse(latest) < Date.now()) {
            return 'Time Window is too old!'
        }
        if (window.from === window.to) {
            return 'Time Window is too small. That will cause issue for routing, minimum Time Window is 30 minutes'
        }
    }

    processShipment(shipment) {
        const pickup = BizUtil.getPickInfo(shipment)
        const dropoff = BizUtil.getDropInfo(shipment)
        let issue = null
        const pickupWindow = BizUtil.getTimeWindowForDeliveryInfo(pickup)
        const dropoffWindow = BizUtil.getTimeWindowForDeliveryInfo(dropoff)

        issue = this.validateWindow(pickupWindow.window)
        if (issue) {
            issue = 'Pickup ' + issue
        }
        if (!issue) {
            issue = this.validateWindow(dropoffWindow.window)
            if (issue) {
                issue = 'Delivery ' + issue
            }
        }
        if (!issue) {
            if (dropoffWindow.window?.from && pickupWindow.window?.from) {
                if (Date.parse(dropoffWindow.window?.from) - Date.parse(pickupWindow.window?.from) > 24 * 3600 * 1000) {
                    console.log('Issue', Date.parse(dropoffWindow.window?.from), Date.parse(pickupWindow.window?.from))
                    issue = 'Delivery Time and Pickup Time are too much different. This will cause issue for routing!'
                }
            }
        }

        return {
            id: shipment.id,
            warpId: shipment.warpId,
            pickup,
            dropoff,
            pickupWindow: BizUtil.getDeliveryInfoTime(pickup, {showWindow: true, format: 'M/D h:mm A', formatDateOnly: ''}) || '-',
            dropoffWindow: BizUtil.getDeliveryInfoTime(dropoff, {showWindow: true, format: 'M/D h:mm A', formatDateOnly: ''}) || '-',
            issue
        }
    }
    async loadShipments(ids) {
        this.loading = true
        this.api.POST(Const.APIV2(`shipments/load`), {ids: ids}).subscribe((res) => {
            this.loading = false
            const existings = (this.shipments || []).map(it => it.id)
            this.shipments = (this.shipments || []).concat(res.data.list_data.filter(it => existings.indexOf(it.id) < 0).map(this.processShipment))
            this.checkCanAdd()
        }, (err) => {
            this.loading = false
        })
    }

    canAdd: boolean = false
    checkCanAdd() {
        this.canAdd = this.shipments?.length && this.shipments.filter(it => it.issue).length < 1
    }

    async onAddBtn() {
        for (let shipment of this.shipments) {
            shipment.addingStatus = 'ADDING'
            await this.service.addShipmentToPlanning(shipment.id).subscribe((res) => {
                shipment.addingStatus = 'ADDED'
            }, (err) => {
                shipment.addingStatus = 'ERROR'
            })
        }
    }

    removeShipment(id) {
        this.shipments = this.shipments.filter(it => it.id !== id)
        this.checkCanAdd()
    }

    onShipmentIdsUpdate() {
        const ids = this.shipmentIds.split(/\W/).filter(it => it?.length)
        this.loadShipments(ids)
        this.shipmentIds = null
    }

    onInputKeyPress(event) {
        if (event.key !== 'Enter') return
        this.onShipmentIdsUpdate()
    }
}