<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
<div *ngIf="isError" class="nodata">Cannot fetch data, please try again later</div>

<form nz-form *ngIf="!isLoading && formInput" [formGroup]="formInput" class="form-detail" nzLayout="vertical">

  <div class="carrier-selection">
    <div class="box-with-title">
      <div class="box-title">Basic Information</div>
      <div class="box-content">
        <div nz-row [nzGutter]="12">
          <div nz-col nzSpan="8">
            <nz-form-item>
              <nz-form-label>
                {{getLabel("subjectType")}}
                <span *ngIf="isRequired('subjectType')" class="label-mark-required"></span>
              </nz-form-label>
              <nz-select nzBackdrop="true" [nzDisabled]="true" formControlName="subjectType"
                [nzPlaceHolder]="getPlaceHolder('subjectType')" nzShowSearch nzAllowClear>
                <nz-option nzValue="carrier" nzLabel="Carrier"></nz-option>
                <nz-option nzValue="driver" nzLabel="Driver"></nz-option>
              </nz-select>
            </nz-form-item>
          </div>
          <div nz-col nzSpan="16">
            <nz-form-item>
              <nz-form-label>
                Equipments
              </nz-form-label>
              <div class="group-vehicle">
                <div class="vehicle-selector" [mode]="'multiple'" 
                  [(value)]="vehicleTypes" vehicle-selector [quoting]="false"
                  (valueChange)="onChangeVehicleTypes($event)"
                ></div>

                <div *ngIf="vehicleTypes?.length" class="vehicle-option">
                  <label class="right10">With</label>
                  <label *ngFor="let option of ['TEAM','LIFT_GATE','DROP']"  
                    nz-checkbox  [ngModelOptions]="{standalone: true}"
                    [ngModel]="vehicleSelectOptions.indexOf(option) >= 0"
                    (ngModelChange)="onOptionChange(option, $event)"
                  >
                    <normalized-string [str]="option"></normalized-string>
                  </label>
                </div>
              </div>
            </nz-form-item>
          </div>
          <div nz-col nzSpan="16">
            <nz-form-item>
              <nz-form-label>
                {{getLabel("name")}}
                <span *ngIf="isRequired('name')" class="label-mark-required"></span>
              </nz-form-label>
              <nz-form-control>
                <input nz-input [placeholder]="getPlaceHolder('name')" formControlName="name" />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col nzSpan="24">
            <nz-form-item> 
              <nz-form-label>{{getLabel("description")}}</nz-form-label>
              <nz-form-control>
                <textarea nz-input [placeholder]="getPlaceHolder('description')"
                formControlName="description"></textarea>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col nzSpan="24">
            <nz-form-item> 
              <nz-form-label>{{getLabel("poolType")}}</nz-form-label>
              <nz-form-control>
                <nz-radio-group  formControlName="poolType" (ngModelChange)="onChangeType($event)" nzButtonStyle="solid">
                  <label nz-radio-button nzValue="normal">Normal</label>
                  <label nz-radio-button nzValue="autoPool">Auto Add Carrier</label>
                  <label nz-radio-button nzValue="dedicated">Dedicated</label>
                </nz-radio-group>
              </nz-form-control>
            </nz-form-item>
          </div>
          <a style="margin-bottom: 16px;" nz-button [disabled]="isAutoLoading" *ngIf="poolType==='autoPool'" (click)="syncCarriersToPool()" nzType="link"><span nz-icon nzType="sync" nzTheme="outline"></span>Sync Carriers to Pool</a>

          <div  style="margin-left: 8px; margin-bottom: 16px;" *ngIf="poolType==='dedicated'">
            <b>The system will auto-add this pool to the bid & auto-send bids based on areas, equipments, lane, customer, and days of the week</b>
          </div>

          <div nz-col nzSpan="24">
            <nz-spin *ngIf="isAutoLoading && poolType=='autoPool'" [nzSpinning]="isAutoLoading">
              <nz-alert [nzType]="'info'" [nzMessage]="'Auto Add Carrier Processing'"
                [nzDescription]="'Please wait a moment.'"></nz-alert>
            </nz-spin>
          </div>
          <div class="bottom15" nz-col nzSpan="24">
            <div class="bottom10">Service Coverage Area</div>
            <ng-container *ngIf="areaSelectedList?.length">
              <div class="selected-area bottom15">
                <ng-container *ngFor="let item of areaSelectedList">
                  <div class="label-item">{{item.name}}</div>
                </ng-container>
              </div>
            </ng-container>
            <button nz-button nzType="dashed" nzShape="round" style="margin-bottom: 10px; margin-top: 5px;"
              (click)="onBtnAddArea()"><span nz-icon nzType="plus" nzTheme="outline"></span> {{areaSelectedList?.length ?
              'Edit area' : 'Add Area'}}</button>
          </div>
          
          <!-- <div nz-col nzSpan="24">
            <nz-form-item>
              <nz-form-label>{{getLabel("autoAddCarrier")}}</nz-form-label>
              <nz-form-control>
                <nz-switch (ngModelChange)="onChangeAutoAddCarrier($event)" formControlName="autoAddCarrier"
                  nzSize="small"></nz-switch>
              </nz-form-control>
            </nz-form-item>
            <nz-spin *ngIf="isAutoLoading" [nzSpinning]="isAutoLoading">
              <nz-alert [nzType]="'info'" [nzMessage]="'Auto Add Carrier Processing'"
                [nzDescription]="'Please wait a moment.'"></nz-alert>
            </nz-spin>
          </div> -->
          <!-- <div nz-col nzSpan="24">
            <nz-form-item *ngIf="'isDedicatedPool' as key">
              <nz-form-label>{{getLabel(key)}}</nz-form-label>
              <nz-form-control>
                <nz-switch (ngModelChange)="toggleIsDedicated($event)" [formControlName]=key
                  nzSize="small"></nz-switch>
              </nz-form-control>
            </nz-form-item>
          </div> -->
        </div>
      </div>
    </div>

    <ng-container *ngIf="isDedicatedPoolSelected">
      <br/>
      <div class="box-with-title" *ngIf="'dedicatedInfo' as key">
        <div class="box-title">{{getLabel(key)}}</div>
        <div class="box-content" form-carrier-pool-dedicated-info [formGroupName]="key" [poolId]="editId" [isPriceAvailable]="isPriceAvailable">
        </div>
      </div>
    </ng-container>

    <br/>
    <div class="box-with-title" *ngIf="subjectType =='carrier'">
      <div class="box-title">Carriers <span *ngIf="isRequired('subjectIds')" class="label-mark-required"></span></div>
      <div class="box-content">
        <pool-transfer-carrier-input #transfer_carrier formControlName="subjectIds" [isLoading]="isLoading">
        </pool-transfer-carrier-input>
      </div>
    </div>
  </div>
</form>

<div style="height: 50px; display: flex; justify-content: flex-end;">
  <ng-container *ngIf="requirePermissions([PermissionCode.pool.delete])">
    <button nz-button (click)="onBtnRemove()" [disabled]="onProgress" nzDanger *ngIf="isEditing">
      <i nz-icon nzType="delete" nzTheme="outline"></i>
      Remove Pool
    </button>
  </ng-container>
  <div *ngIf="requirePermissions([PermissionCode.pool.create, PermissionCode.pool.update])" detail-footer-buttons labelButtonCreate="Create New Pool"
    [isCreateNew]="isCreateNew" [isEditing]="isEditing" [onProgress]="onProgress" [needUpdate]="needUpdate"
    (onEdit)="onBtnEdit()" (onSave)="onBtnSave()" (onCancel)="onBtnCancel()">
  </div>
</div>