import { Component, Input } from "@angular/core";
import { BaseDialog } from "@dialogs/base-dlg";
import { StringULID } from "@wearewarp/types";
import { Shipment } from "@wearewarp/types/data-model";

@Component({
    selector: '[planning-dialog]',
    templateUrl: './dialog.html',
    styleUrls: ['./styles.scss']
})
export class PlanningRouteDialog extends BaseDialog {
    @Input() shipments: Shipment[]
    @Input() shipmentIds: StringULID[] = []
    @Input() mode: string = null
    @Input() onRouteCreated: any;
    @Input() canMerge: boolean = false

    routeCreated(event) {
        this.onRouteCreated && this.onRouteCreated(event)
        this.closeDialog()
    }
}