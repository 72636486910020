import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { BaseComponent } from '@abstract/BaseComponent';
import { environment } from '@env/environment';
import { Utils } from '@services/utils';

@Component({
  selector: '[delivery-planning-screen]',
  templateUrl: './screen.html',
  styleUrls: ['./styles.scss', './screen.scss']
})
export class DeliveryPlanningScreen extends BaseComponent {
    constructor() {
        super();
    }
    
    routeCreated(route) {

    }

}