import { Component, Input } from '@angular/core';
import { FormArray } from '@angular/forms';
import { LoadingComponent } from '@app/admin/components/loading/loading.component';
import { Const } from '@const/Const';
import { BaseFormDialog1 } from '@dialogs/base-form-dlg1';
import { DialogService } from '@dialogs/dialog.service';
import { DateUtil } from '@services/date-utils';
import { Utils } from '@services/utils';
import { ZipcodeService } from '@services/zipcode.service';
import { ulid } from 'ulid';

@Component({
  selector: '[create-manual-load-container]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss', '../../../dialogs/dialogs.scss']
})
export class CreateManualLoadContainer extends BaseFormDialog1 {

  public canAddMultiStop = false;
  public isLoading: boolean = false;
  @Input() onSubmitSucceeded: (resp) => void;
  zipcodeService;
  items = [];

  constructor() {
    super();
    this.zipcodeService = new ZipcodeService(this.api)
  }

  protected formGroupDeclaration: FormGroupDeclaration = {
    requiredVehicle: { label: 'Vehicle', notAcceptEmpty: true, placeHolder: 'Select' },
    addressType: { label: 'Address Type', initialValue: 'zipcode'},
    pickInfos: { label: 'Pickup Informations', type: 'formArray', initialValue: [{}], childItem: {
      zipcode: { label: 'Pickup zipcode'},
      address: {label: 'Address' },
      rangeDate: { label: 'Pickup date'},
    }},
    dropInfos: { label: 'Delivery Informations', type: 'formArray', initialValue: [{}], childItem: {
      zipcode: { label: 'Pickup zipcode'},
      address: {label: 'Address' },
      rangeDate: { label: 'Pickup date'},
    }},
  }

  public listAddressTypes = [
    { value: 'zipcode', label: 'Zipcode' },
    { value: 'fullAddress', label: 'Full Address' },
  ]

  onBtnClose() {
    this.closeDialog();
  }

  onVehicleTypeChange(e) {
    this.setItemValue('requiredVehicle', e);
  }

  get isFullAdrressSelected() {
    return this.getItemValue('addressType') === 'fullAddress';
  }

  getLabelAddressType(groupKey: 'pickInfos'|'dropInfos') {
    let addressType = this.isFullAdrressSelected ? 'Address' : 'zipcode';
    let taskType = groupKey === 'pickInfos' ? 'Pickup' : 'Delivery';
    return `${taskType} ${addressType}`;
  }

  onBtnAddStop(groupKey: 'pickInfos'|'dropInfos') {
    if (!this.canAddMultiStop) {
      return;
    }
    this.addItemToFormArray(groupKey);
    this.formInput.get(groupKey).updateValueAndValidity();
  }

  shouldShowRemoveStop(groupKey: 'pickInfos'|'dropInfos') {
    if (!this.formInput) return false;
    let faInfos = <FormArray>this.formInput.get(groupKey);
    return faInfos.length > 1;
  }

  onBtnRemoveStop(groupKey: 'pickInfos'|'dropInfos', index: number) {
    let fa = this.getFormArray(groupKey);
    fa.removeAt(index);
  }

  onZipcodeChange(groupKey: 'pickInfos'|'dropInfos', index: number, e) {
    this.setItemValue(`${groupKey}[${index}].zipcode`, e);
    if (e?.zipcode) {
      this.zipcodeService.getTimeZoneByZipcode(e.zipcode, resp => {
        if (resp) {
          let newData = { ...e, timeZoneStandard: resp }
          this.setItemValue(`${groupKey}[${index}].zipcode`, newData);
        }
      }, err => {
      })
    }
  }

  protected getFormData_JSON(isCreateNew: boolean): object {
    let json: any = super.getFormData_JSON(true); // always get full data
    let dataCustom: any = {};
    dataCustom.requiredVehicle = json.requiredVehicle;
    dataCustom.deliveryInfos = [];
    for (let groupKey of ['pickInfos', 'dropInfos']) {
      let infos = json[groupKey];
      for (let item of infos || []){
        let deliveryInfo: any = {};
        deliveryInfo.type = groupKey === 'pickInfos' ? Const.TaskType.PICKUP : Const.TaskType.DROPOFF;

        if (this.isFullAdrressSelected) {
          deliveryInfo.addr = item.address;
        } else {
          deliveryInfo.addr = {
            zipcode: item.zipcode.zipcode,
            city: item.zipcode.city,
            state: item.zipcode.state,
            location: {
              latitude: item.zipcode.latitude,
              longitude: item.zipcode.longitude,
            },
            metadata: {
              timeZoneStandard: item.zipcode.timeZoneStandard,
              latitude: item.zipcode.latitude,
              longitude: item.zipcode.longitude,
            },
          }
        }

        if (!item.rangeDate) {
          deliveryInfo.windows = [{ from: null, to: null }];
        } else if (Utils.isArrayNotEmpty(item.rangeDate)) {
          //FIXME lay time 7:00AM theo timezone
          let fromPickDate = item.rangeDate[0]?.setHours(7, 0, 0, 0);
          let toPickDate = item.rangeDate[item.rangeDate.length - 1]?.setHours(7, 0, 0, 0);
          let timezone = deliveryInfo.addr?.metadata?.timeZoneStandard;
          fromPickDate = DateUtil.convertLocalTime(fromPickDate, timezone)?.toISOString();
          toPickDate = DateUtil.convertLocalTime(toPickDate, timezone)?.toISOString();
          deliveryInfo.windows = [{
            from: fromPickDate,
            to: toPickDate
          }]
        }
        dataCustom.deliveryInfos.push(deliveryInfo);
      }
    }
    return dataCustom;
  }

  private get canSubmitForm(): boolean {
    for (let groupKey of ['pickInfos', 'dropInfos']) {
      let fa = <FormArray>this.formInput.get(groupKey);
      for (let i = 0; i < fa.length; i++) {
        let fg = fa.at(i);
        let addressTypeKey = this.isFullAdrressSelected ? 'address' : 'zipcode';
        let value = fg?.get(addressTypeKey)?.value;
        if (!value) {
          this.showErr(`Please enter ${addressTypeKey} valid.`)
          return false;
        }
      }
    }
    let fcDate = (<FormArray>this.formInput?.get('pickInfos'))?.at(0)?.get('rangeDate');
    if (!fcDate || !fcDate.value) {
      this.showErr('Please select pickup date.')
      return false;
    }
    for (let item of this.items) {
      if (!item.weight || !item.height || !item.length || !item.weight) {
        this.showErr('Shipping Item is required.')
        return false;
      }
    }
    return true;
  }

  loadingRef;
  onBtnCreateLoad() {
    if (!this.canSubmitForm) return false;
    const payload: any = this.getFormData_JSON(true);
    if (this.items?.length) {
      payload.items = this.items;
    }
    this.isLoading = true;
    this.loadingRef = DialogService.openFormDialog1(LoadingComponent, {});
    this.api.POST(Const.APIV2(`${Const.APIURI_JOBS}/manual-load`), payload).subscribe(
      (resp) => {
        this.isLoading = false;
        this.showSuccess('Load has been created successfully.');
        this.loadingRef?.close();
        this.onSubmitRequestSucceeded(resp);
      },
      (err) => {
        this.showErr(err);
        this.isLoading = false;
        this.loadingRef?.close();
      }
    );
  }

  onSubmitRequestSucceeded(resp) {
    this.onSubmitSucceeded(resp);
    this.closeDialog();
  }

  onAddNewItem(type) {
    let item = {
        id: ulid(),
        packaging: type,
        quantity: 1
    }
    if (type === 'Pallet') {
        item['length'] = 40
        item['width'] = 48
        item['height'] = 50
    }
    this.items.push(item)
  }

  onDeleteItem(id) {
    this.items = this.items.filter(it => it.id !== id)
  }

}