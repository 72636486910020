<div>
    <div class="flex bottom10">
        <div class="flex1"><span class="label">Source: </span><span>{{ quote?.option?.source }}</span></div>
        <div><span class="label">Quoted @ </span>{{quotedTime}}</div>
    </div>

    <div class="flex">
        <div class="flex1">
            <div *ngIf="name" class="bottom10 emphasized">{{name}}</div>
            <div *ngIf="description" class="bottom10">{{description}}</div>
            <div class="flex">
                <div *ngIf="pickupDate" class="right15">
                    <span class="label">Pickup Date: </span> {{ pickupDate }}
                </div>
                <div *ngIf="transitTime" class="bottom10">
                    <span class="label">Transit Time: </span> {{transitTime}}
                </div>
            </div>
            <div *ngIf="option.traffic?.drivingDistance" class="bottom10">Mileage: <span decimal-number [value]="option.traffic.drivingDistance / 1609.34"></span></div>
            <div class="bottom10" rate [rate]="option.rate" [bookable]="bookable"></div>
            <div *ngIf="bookable">
                <button class="btn-book-load" nz-button nzType="primary" (click)="onClickBookLoadBtn()">Book Load</button>
                <span *ngIf="validity" class="left10 label">Valid until {{validity}}</span>
            </div>
        </div>
        <div style="margin-left: 10px" *ngIf="!isMarkedUp">
            <table>
                <tr>
                    <th>Markup</th>
                    <th>Final Price</th>
                </tr>
                <tr>
                    <td>10 %</td>
                    <td><span decimal-number [decimals]="0" [value]="1.1 * option.rate.avg"></span></td>
                </tr>
                <tr>
                    <td>15 %</td>
                    <td><span decimal-number [decimals]="0" [value]="1.15 * option.rate.avg"></span></td>
                </tr>
                <tr>
                    <td>20 %</td>
                    <td><span decimal-number [decimals]="0" [value]="1.2 * option.rate.avg"></span></td>
                </tr>
            </table>

        </div>
    </div>
</div>