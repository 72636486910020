<h3>Select new Formula</h3>

<div *ngIf="isLoading || inProgress"><i nz-icon nzType="loading" nzTheme="outline"></i></div>

<!-- Step 1 select a formula -->
<ng-container *ngIf="currentStep === 1">
    <nz-list>
        <nz-list-item *ngFor="let item of formulaList" style="cursor: pointer;" (click)="onSelectFormula(item)">
            <nz-list-item-meta>
                <nz-list-item-meta-title>{{ item.name }}</nz-list-item-meta-title>
                <nz-list-item-meta-description>{{ item.description }}</nz-list-item-meta-description>
            </nz-list-item-meta>
        </nz-list-item>
    </nz-list>    
</ng-container>

<!-- Step 2 set parameters -->
<ng-container *ngIf="currentStep === 2 && formula">
    <div>Formula: {{ formula.name }}</div>
    <div class="description">{{ formula.description }}</div>
    <nz-list>
        <nz-list-item *ngFor="let item of formula.parameters">
            <ng-container *ngIf="item.type === 'CONFIG'">
            <nz-list-item-meta>
                <nz-list-item-meta-title>{{ item.name }}</nz-list-item-meta-title>
                <nz-list-item-meta-description>{{ item.valueType }}</nz-list-item-meta-description>
            </nz-list-item-meta>
            <div style="width: 160px;">
                <input nz-input [value]="item.value.value" (keyup)="onParameterChange($event, item)" />
            </div>
            </ng-container>
        </nz-list-item>
    </nz-list>

    <div>
        <button nz-button (click)="goBack()">Go Back</button>
        <button nz-button (click)="onSave()">Save</button>
    </div>
</ng-container>
