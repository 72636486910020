<div class="border-top">
    <div *ngIf="name" class="bottom10">{{name}}</div>
    <div>
        <div *ngIf="rate.avg" class="flex1 bottom10">
            <span class="label" *ngIf="!bookable">Average: </span>
            <span class="label" *ngIf="bookable">Rate: </span>
            <span nz-popover
                nzPopoverTitle="Rate Detail"
                [nzPopoverContent]="tplRateDetail">
                <span class="emphasized" [decimals]="0" decimal-number [value]="rate.avg"></span>
                <span class="label"> {{rate.currency || 'USD'}}</span>
                <ng-template #tplRateDetail>
                    <div *ngFor="let item of rate.items" rate-detail [item]="item"></div>
                </ng-template>
            </span>
  
        </div>
        <div *ngIf="rate.low">
            <span class="label">Range: </span> <span [decimals]="0" decimal-number [value]="rate.low"></span> - <span [decimals]="0" decimal-number [value]="rate.high"></span> <span class="label"> {{rate.currency || 'USD' }}</span>
        </div>
    </div>
</div>