import { BaseComponent } from "@abstract/BaseComponent";
import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from "@angular/core";
import { Const } from "@const/Const";
import { DialogService } from "@dialogs/dialog.service";
import { InputHelper } from "@services/input-helper";
import { LTLItemForm } from "./ltl-item-form";

@Component({
  selector: '[packaging]',
  templateUrl: './index.html',
  styleUrls: ['./index.less']
})
export class Packaging extends BaseComponent {
  optionSelected = null ;
  visible = false;
  parcelSizeSelected: string = null;
  packagingTypeList = Const.packagingTypeList;
  packagingType: String = Const.FreightQuotePackageType.Pallet;
  objectValue = {
    packagingType: Const.FreightQuotePackageType.Pallet,
    parcelSizeSelected: null,
    equipment: Const.DatEquipments.REEFER,
    length: null,
    width: null,
    height: null,
    qty: 1,
    weightTotal: null,
    weightUnit: Const.WeightUnits[0],
    ltlItems: [],
  }
  @Input() options: any;
  @Input() isError:boolean = false;
  @Input() placeholder: string = '';
  @Output() onDone: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('inputSelect') inputSelect: ElementRef<HTMLElement>;
  @ViewChild('backgroupDropdown') backgroupDropdown: ElementRef<HTMLElement>;

  parcelSize = ['Envelope', 'Small', 'Medium', 'Large', 'XL'];
  datEquipmentList = [
    Const.DatEquipments.VAN,
    Const.DatEquipments.REEFER,
    Const.DatEquipments.FLATBED,
  ];
  isOpenLTLForm = false;
  
  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngAfterViewChecked(): void {
    if (this.backgroupDropdown.nativeElement.offsetWidth) {
      this.backgroupDropdown.nativeElement.style.width = `${this.inputSelect.nativeElement.offsetWidth}px`;
    }
  }

  onChangeObjectValue(key, value) {
    this.objectValue[key] = value;
    if (key === 'packagingType' && this.objectValue.packagingType == this.packagingTypeList[1] && !this.objectValue.ltlItems?.length) {
      this.onOpenLTLItemForm(null);
    }
  }
  getvaluePackaging() {
    switch(this.objectValue.packagingType){
      case Const.FreightQuotePackageType.Parcel:
        if (!this.objectValue.parcelSizeSelected) {
          return '';
        }
        return `${this.getPackagingTypeName(this.objectValue.packagingType)} (${this.objectValue.parcelSizeSelected})`;
      case Const.FreightQuotePackageType.Pallet:
        if (!this.objectValue.ltlItems?.length) {
          return '';
        }
        let val = '';
        this.objectValue.ltlItems.forEach((item, index) => {
          val += this.getLabelForLTLItem(item, true, index);
        });
        return val;
      case Const.FreightQuotePackageType.FTL:
        if (!this.objectValue.weightTotal) {
          return '';
        }
        return `${this.getPackagingTypeName(this.objectValue.packagingType)} (${this.objectValue.equipment} - ${this.objectValue.weightTotal} ${this.objectValue.weightUnit})`;;
      default: 
        return null;
    }
  }
  getLabelForLTLItem(item, isHtml, index) {
    const text = `Item ${index + 1} (${item.length}x${item.width}x${item.height}; ${item.weightTotal} ${item.weightUnit}; Quantity: ${item.qty})`;
    if (!isHtml) {
      return text;
    }
    return `<div>${text}<div>`; 
  }
  onDropdownVisibleChange(value) {
    if (value && !this.objectValue.ltlItems?.length && this.objectValue.packagingType == Const.FreightQuotePackageType.Pallet) {
      setTimeout(() => {
        this.onOpenLTLItemForm(null);
      }, 100);
    }
  }
  onClickBtnDone() {
    const value = this.getvaluePackaging();
    if (!value) {
      return;
    }
    this.optionSelected = value;
    this.visible = false;
    this.onDone.emit(this.objectValue);
  }
  onRemoveValue() {
    this.visible = false;
    this.optionSelected = '';
    this.objectValue = {
      packagingType: Const.FreightQuotePackageType.Pallet,
      parcelSizeSelected: null,
      equipment: Const.DatEquipments.REEFER,
      length: null,
      width: null,
      height: null,
      qty: 1,
      weightTotal: null,
      weightUnit: Const.WeightUnits[0],
      ltlItems: [],
    };
    this.onDone.emit(this.objectValue);
  }
  onInputKeyPressNumberOnly(event) {
    return InputHelper.handleInputKeyPressNumberOnly(event);
  }
  onRemoveLTLItem(index) {
    if (index!== null && index >= 0) {
      this.objectValue.ltlItems.splice(index, 1);
    }
  }

  onOpenLTLItemForm(index) {
    let data = null;
    this.visible = false;
    if (index >= 0 && index !== null) {
      data = this.objectValue.ltlItems[index];
    }
    DialogService.openFormDialog1(LTLItemForm, {
      nzComponentParams: {
        objectValue: data, 
        closeOnSuccess: true,
        updateSuccess: resp => {
          if (index >= 0 && index !== null) {
            this.objectValue.ltlItems[index] = resp;
          } else {
            this.objectValue.ltlItems.push(resp);
          }
          const value = this.getvaluePackaging();
          if (value) {
            this.optionSelected = value;
          this.onDone.emit(this.objectValue);
          }
          
        },
        onCloseDialog: () => {
          this.visible = true;
        }
      },
      nzClassName: 'modal-no-padding',
    });
  }
  
}