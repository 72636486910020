import { BaseComponent } from "@abstract/BaseComponent";
import { Component } from "@angular/core";
import { DeliveryMap, Utils } from "@app/admin/components/map";
import { Const } from "@const/Const";
import { BizUtil } from "@services/biz";
import _ from 'underscore'
import { getUserPreferences } from "@services/userPref.service";
import { ActivatedRoute } from "@angular/router";
import dayjs from "dayjs";

@Component({
    selector: '[ltl-volume]',
    templateUrl: './index.html',
    styleUrls: ['./index.scss']
})
export class LtlVolumeScreen extends BaseComponent {
    map: DeliveryMap
    
    renderTemplate: string = ''

    constructor(protected activatedRoute: ActivatedRoute) {
        super()
        this.activatedRoute.queryParams.subscribe((r) => this.deserializeQueryParams(r))
        this.processShipmentFilter = this.processShipmentFilter.bind(this)
        this.loadClients()
    }

    ngOnInit(): void {
        getUserPreferences().getPref('LTL_VOLUME-renderTemplate').subscribe((res) => {
            this.renderTemplate = res || 'TMPL_4'
            console.log(this.renderTemplate)
        }, (err) => {
            this.renderTemplate = 'TMPL_1'
        })
        getUserPreferences().getPref('LTL_VOLUME-shipmentListSize').subscribe((res) => {
            this.displayConfig.shipmentListSize = parseInt(res)
        })
 
        this.loadWarehouses()
        this.loadShipments()
    }

    _filter: any = {
        freq: 'daily',
        maxMileage: 0,
        clients: [],
        markets: [],
        leg: 'all'
    }
    mileageRanges = [150, 300]
    freqOptions = ['daily', 'weekly', 'monthly']
    legTypes = ['first', 'last', 'straight', 'all']
    setMaxMileage(range) {
        this._filter.maxMileage = range
        this.onFilterChange(range)
    }
    setLegType(t) {
        this._filter.leg = t
        this.processShipments()
    }

    setFreq(range) {
        this._filter.freq = range
        this.onFilterChange(range)
    }

    _shipments: any[] = []
    dates: string[] = []
    markets: string[] = []
    filtered = []
    locations = []
    get shipments() {
        return this._shipments
    }
    processShipmentFilter(shipment, filter): boolean {
        if (!shipment) return false
        if (!filter) return true
        const { metadata } = shipment
        const { traffic, pickupDate, dropoffDate } = metadata || {}
        const { distance, time } =  traffic || {}
        const { maxMileage, requiresTime, date, markets, excludeLocations, clients, pickupReady, keyword, freq, week, month, leg } = filter || {}
        if (keyword) {
            const { warpId, order } = shipment
            if (warpId?.toString() !== keyword && order?.warpId?.toString() !== keyword) return false
        }
        if (maxMileage) {
            if (distance && distance > (maxMileage * 1609.34)) return false
        }
        if (requiresTime) {
            const infos = shipment.deliveryInfos.filter(it => it.type === 'PICKUP' || it.type === 'DROPOFF')
            let missing = true
            for (let info of infos) {
                if (!this.isMissingTime(info)) {
                    missing = false
                }
            }
            if (missing) return false
        }
        if (pickupReady) {
            const { review } = shipment
            if (review?.status?.PICKUP_READY != 'CONFIRMED') return false
        }
        if (leg && leg != 'all' && leg !== 'straight') {
            const { metadata } = shipment
            const { mile } = metadata || {}
            if (mile !== leg) return false
        }
        if (leg === 'straight') {
            const { shipmentTransitType } = shipment
            if (shipmentTransitType !== 'none') return false
        }
        // if (date) {
        //     if (date === 'N/A') {
        //         if (pickupDate !== date || dropoffDate !== date) {
        //             return false
        //         }    
        //     }
        //     if (pickupDate !== date && dropoffDate !== date) {
        //         return false
        //     }
        // }
        if (clients?.length) {
            if (clients.indexOf(shipment.clientId) < 0) return false
        }
        const pickup = BizUtil.getPickInfo(shipment)
        const dropoff = BizUtil.getDropInfo(shipment)
        if (markets?.length) {        
            if (pickup.market && markets.indexOf(pickup.market) >= 0) return true
            if (dropoff.market && markets.indexOf(dropoff.market) >= 0) return true
            return false
        }
        if (excludeLocations?.length) {
            if (pickup.warehouseId && excludeLocations.indexOf(pickup.warehouseId as string) >= 0) {
                return false
            }
            if (dropoff.warehouseId && excludeLocations.indexOf(dropoff.warehouseId as string) >= 0) {
                return false
            }
        }

        return true
    }
    set shipments(v) {
        // old data
        const excluded: Set<string> = new Set()
        if (this._shipments) {
            for (let s of this._shipments) {
                if (s.excluded) {
                    excluded.add(s.id)
                }
            }
        }
        this._shipments = v
        for (let s of this._shipments) {
            if (excluded.has(s.id)) {
                s.excluded = true
            }
        }
        this.processShipments()
    }

    // check for time window
    isMissingTime(info): boolean {
        if (info.skipAppointment) return false
        if (info.requiresAppointment) {
            if (!info.appointmentInfo?.from) {
                return true
            }
        } else {
            const t = (info.windows || [])[0]
            if (!t?.from) return true
        }
        return false
    }

    calculateDateFacet() {
        const filtered = this._shipments.filter(it => this.processShipmentFilter(it, Object.assign({}, this._filter, {date: null})))
        const dates: string[] = []
        for (let shipment of filtered) {
            dates.push(shipment.metadata.pickupDate)
            dates.push(shipment.metadata.dropoffDate)
        }
        
        this.dates = _.uniq(dates).sort().reverse()
    }
    calculateMarketFacet() {
        const filtered = this._shipments.filter(it => this.processShipmentFilter(it, Object.assign({}, this._filter, {markets: null})))
        const markets: string[] = []
        for (let shipment of filtered) {
            const pickup = BizUtil.getPickInfo(shipment)
            const dropoff = BizUtil.getDropInfo(shipment)
            if (pickup.market) {
                markets.push(pickup.market)
            }
            if (dropoff.market) {
                markets.push(dropoff.market)
            }
        }
        this.markets = _.uniq(markets).sort()
    }

    filterShipments() {
        // this.calculateDateFacet()
        this.calculateMarketFacet()
        // this.calculateClientFacet()

        this._shipments = this._shipments.filter(it => it.review?.needReview !== true)

        this.filtered = this._shipments.filter(it => this.processShipmentFilter(it, this._filter))
    }
    processShipments() {
        // process clients
        for (let shipment of this._shipments) {
            const { client } = shipment || {}
            if (client) {
                this._clientMap[client.id] = client
            }
        }
        for (let shipment of this._shipments) {
            const { clientId, client } = shipment
            if (!client) {
                const c = this._clientMap[clientId]
                if (c) {
                    shipment.client = c
                }
            }

            const pickup = BizUtil.getPickInfo(shipment)
            const dropoff = BizUtil.getDropInfo(shipment)
            shipment.metadata = Object.assign(shipment.metadata, {
                pickupDate: Utils.getDisplayDate(pickup),
                dropoffDate: Utils.getDisplayDate(dropoff)
            })
        }

        this.filterShipments()
        this.processLocations()
        this.processWarehouses()

        this.map?.loadShipments(this.filtered)

        this.map?.refresh()
        this.fitMapBound()
    }

    processLocations() {
        const locationMap = {}
        for (let shipment of this.filtered) {
            for (let info of shipment.deliveryInfos) {
                if (info.warehouseId && info.locationName) {
                    const loc = {id: info.warehouseId, name: info.locationName}
                    if (!locationMap[loc.id]) {
                        locationMap[loc.id] = loc
                    }
                }
            }
        }
        this.locations = Object.values(locationMap)
    }

    fitMapBound() {
        this.map?.fitBoundsToShipment()
    }

    loading: boolean = false
    apiQueryParams: any = {}
    loadShipments(force=false): boolean {
        const query = this.serializeApiQueryParams()
        if (!query['pickupDateFrom'] || !query['pickupDateTo']) {
            this.shipments = []
            return
        }
        if (JSON.stringify(query) === JSON.stringify(this.apiQueryParams) && !force) return false
        this.loading = true
        this.apiQueryParams = query
        const url = `${Const.APIV2(Const.APIURI_SHIPMENTS)}/search`
        this.api.POST(url, query).subscribe((res) => {
            this.loading = false
            this._shipments = this.processData(res.data)
            this.processShipments()
        })
        return true
    }

    processData(data) {
        for (let s of data.list_data) {
            s.client = this._clientMap[s.clientId]
        }
        return data.list_data
    }

    _clients: any[] = []
    _clientMap: any = {}
    get clients() {
        return this._clients
    }
    set clients(v) {
        this._clients = v
        for (let c of v) {
            this._clientMap[c.id] = c
        }
        if (this._shipments) {
            for (let s of this._shipments) {
                s.client = this._clientMap[s.clientId]
            }
        }
    }

    _warehouses: any[] = []
    get warehouses() {
        return this._warehouses
    }
    set warehouses(v) {
        this._warehouses = v
        this.processWarehouses()
        this.map?.refresh()
    }

    processWarehouses() {
        const locationIds: Set<string> = new Set()
        for (let loc of this.locations || []) {
            locationIds.add(loc.id)
        }
        for (let warehouse of this._warehouses) {
            if (locationIds.has(warehouse.id)) {
                warehouse.display = true
            } else {
                warehouse.display = false
            }
        }
        this.map?.loadWarehouses(this._warehouses.filter(it => it.display))
    }

    loadWarehouses() {
        const url = `${Const.APIURI_WAREHOUSES}?limit=-1&filter=${JSON.stringify({warehouseType: 'crossdock'})}`
        this.api.GET(url).subscribe((res) => {
            this.warehouses = res.data.list_data
        })
    }

    loadClients() {
        const url = `${Const.APIURI_CLIENTS}?limit=-1`
        this.api.GET(url).subscribe((res) => {
            this.clients = res.data.list_data
        })
    }

    _selectedShipment: any = null
    get selectedShipment() {
        return this._selectedShipment
    }
    set selectedShipment(v) {
        this._selectedShipment = v
    }
    onCloseShipment() {
        this.selectedShipment = null
    }
    onSelectShipment(s) {
        this.selectedShipment = s
    }
    onDateChange($event) {
        console.log($event, this._filter)
        this.onFilterChange($event)
    }
    onFilterChange(event) {
        this.router.navigate(
            [], 
            {
              relativeTo: this.activatedRoute,
              queryParams: this.serializeQueryParams(),
              queryParamsHandling: ''
            }
        );
        if (!this.loadShipments())
            this.processShipments()
    }
    serializeQueryParams() {
        const params: any = {}
        if (this._filter.markets.length) {
            params['markets'] = this._filter.markets.join(',')
        }
        if (this._filter.clients.length) {
            params['clients'] = this._filter.clients.join(',')
        }
        if (this._filter.maxMileage) {
            params['mileage'] = this._filter.maxMileage
        }
        if (this._filter.pickupReady) {
            params['ready'] = 'true'
        }
        if (this._filter.freq) {
            params['freq'] = this._filter.freq
        }
        if (this._filter.freq === 'monthly') {
            if (this._filter.month) {
                params['month'] = dayjs(this._filter.month).startOf('month').format('YYYY-MM-DD')
            }
        } else if (this._filter.freq === 'weekly') {
            if (this._filter.week) {
                params['week'] = dayjs(this._filter.week).startOf('day').format('YYYY-MM-DD')
            }
        } else {
            if (this._filter.date) {
                params['date'] = dayjs(this._filter.date).startOf('day').format('YYYY-MM-DD')
            }
        }
        return params
    }
    serializeApiQueryParams() {
        const params: any = {
            excludeParent: true
        }
        if (this._filter.markets.length) {
            params['markets'] = this._filter.markets
        }
        if (this._filter.clients.length) {
            params['clients'] = this._filter.clients
        }
        if (this._filter.freq === 'monthly') {
            if (this._filter.month) {
                params['pickupDateFrom'] = dayjs(this._filter.month).startOf('month').format()
                params['pickupDateTo'] = dayjs(this._filter.month).startOf('month').add(1, 'month').format()
            }
        } else if (this._filter.freq === 'weekly') {
            if (this._filter.week) {
                params['pickupDateFrom'] = dayjs(this._filter.week).startOf('week').format()
                params['pickupDateTo'] = dayjs(this._filter.week).startOf('week').add(1, 'week').format()
            }
        } else {
            if (this._filter.date) {
                params['pickupDateFrom'] = dayjs(this._filter.date).startOf('day').format()
                params['pickupDateTo'] = dayjs(this._filter.date).startOf('day').add(1, 'day').format()
            }
        }
        return params
    }

    deserializeQueryParams(params) {
        const { date, markets, clients, mileage, ready, freq, week, month } = params
        if (date) {
            this._filter.date = Date.parse(date + 'T12:00:00')
        }
        if (week) {
            this._filter.week = Date.parse(week + 'T12:00:00')
        }
        if (month) {
            this._filter.month = Date.parse(month + 'T12:00:00')
        }
        if (freq) {
            this._filter.freq = freq
        }
        if (markets) {
            this._filter.markets = markets.split(',')
        }
        if (clients) {
            this._filter.clients = clients.split(',')
        }
        if (mileage) {
            this._filter.maxMileage = parseInt(mileage)
        }
        if (ready) {
            this._filter.pickupReady = ready === 'true'
        }
    }

    displayConfig: any = {
        shipmentListSize: 12
    }
    changeShipmentListSize(inc) {
        let s = this.displayConfig.shipmentListSize + inc
        if (s < 1) s = 1
        if (s != this.displayConfig.shipmentListSize) {
            this.displayConfig.shipmentListSize = s
            getUserPreferences().setPref('NEED_ROUTING-shipmentListSize', s.toString())
            this.map?.resize()
        }
    }
    switchRenderTemplate(tmpl) {
        if (this.renderTemplate === tmpl) return
        getUserPreferences().setPref('NEED_ROUTING-renderTemplate', tmpl)
        this.renderTemplate = tmpl
    }

    showShipmentList: boolean = false
    toggleShowShipments() {
        this.showShipmentList = !this.showShipmentList
        this.map?.resize()
    }

    onRemoveShipment({id, removed}) {
        this._shipments.filter(it => it.id === id).map(r => {
            r.excluded = removed
        })
        // this.processShipments()
    }

    onMouseOverShipment(id) {
        const matched = id ? this._shipments.filter(it => it.id === id)[0] : null
        this.map?.highlightShipment(matched)
    }

    onClickShipment(id) {
        const matched = this._shipments.filter(it => it.id === id)[0]
        if (!matched) return
        this.map?.zoomShipment(matched)
    }

    onReviewStatusUpdate(event) {
        const {id, review} = event
        this._shipments.filter(it => it.id === id).map(r => {
            r.review = review
        })
        this.processShipments()
    }

    onDeliveryInfoUpdate({id, deliveryInfo}) {
        this._shipments.filter(it => it.id === id).map(r => {
            r.deliveryInfos = r.deliveryInfos.map(it => it.id === deliveryInfo.id ? deliveryInfo : it)
        })
        this.processShipments()
    }
}