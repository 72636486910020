import { BaseFormItem } from '@app/admin/base/form-item';
import { Utils } from '@services/utils';

export abstract class BaseQuoteShipmentChildForm extends BaseFormItem {
  abstract get childKey(): string

  get isCreateNew(): boolean {
    if (!this.model) return true;
    if (this.model && Utils.isAllPropertiesNullOrUndefined(this.model)) return true;
    return false;
  }

  get isModelChanged(): boolean {
    return this.model && this.isFormDataChanged();
  }

  protected getFormData(isCreateNew: boolean): object|FormData {
    let childKey = this.childKey;
    let jsonData = {
      [childKey]: this.getFormData_JSON(true) // quan trọng: trường hợp edit cũng lấy tất cả dữ liệu trong form
    };
    let fileKeys = Object.keys(this.fileToUpload);
    if (fileKeys.length > 0) {
      let formData = new FormData();
      for (let key of fileKeys) {
        if (this.fileToUpload[key] instanceof File) {
          formData.append(`${childKey}.${key}`, this.fileToUpload[key], this.fileToUpload[key].name);
        } else if (this.model && this.model[key] && this.model[key].deleted) {
          jsonData[childKey][key] = null;
        }
      }
      formData.append('params', JSON.stringify(jsonData));
      return formData;
    } else {
      return jsonData;
    }
  }

  // to be called from parent component
  formData(isCreateNew: boolean) {
    return this.getFormData(isCreateNew);
  }

  discardChanges() {
    this.reset();
    this.createFormInput(this.model);
  }

  resetCacheData() {
    this.reset();
  }


}