import { Component } from "@angular/core";
import { Order as OrderModel, DeliveryInfo, AddressUS } from '@wearewarp/types/data-model';
import { Const } from "@const/Const";
import { getDashboard } from "@services/index";
import { DateUtil } from "@services/date-utils";
import { BaseComponent } from "@abstract/BaseComponent";
import { ListShipmentExpandUtil } from "@services/list-shipment-expand-util";
import { Utils } from "@services/utils";
import { OrderUtil } from "@services/order";

@Component({
  selector: 'operational-performance',
  templateUrl: 'list.html',
  styleUrls: [
    './style.scss',
    '../list.scss'
  ]
})
export class OperationalPerformance extends BaseComponent {

  public isLoading = false;
  public onTimeOption = 'pick';
  public dataChart = {pick: {}, drop: {}};
  public get shouldShowChart() {
    return !!this.dataChart.pick['chartOptions'] && !!this.dataChart.drop['chartOptions'];
  }
  public listData = [];
  public get totalCount() {return this.listData.length}
  public currentPageData = [];
  public pageIndex = 1;
  
  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    setTimeout(() => getDashboard().sideBar.isSmallWidth = true, 1);
  }

  protected handleNavigationEnd(url: string, prevQueryParam: any): void {
    this.getData();
  }

  private getData() {
    let url = Const.APIURI_PERFORMANCE;
    let filter = this.queryParams.filter;
    if (!filter) {
      return;
    }
    let qs = new URLSearchParams({filter}).toString();
    url += `?${qs}`;
    this.isLoading = true;
    this.api.GET(url).subscribe(
      resp => {
        this.getDataDone(resp);
        this.isLoading = false;
      }, err => {
        this.showErr(err);
        this.isLoading = false;
      }
    );
  }

  private getDataDone(resp) {
    const colorOnTime = '#52c41a';
    const colorLate = '#ff4d4f';
    const colorNotStart = '#9A96A0';
    const colorCanceled = '#1F2937';
    this.listData = resp.data?.list_data ?? [];
    this.dataChart = resp.data?.dataChart;
    for (let key of ['pick', 'drop']) {
      this.dataChart[key]['chartOptions'] = {data: [
        {type: `On Time`, value: this.dataChart[key].ontime, color: colorOnTime},
        {type: 'Late', value: this.dataChart[key].late, color: colorLate},
        {type: 'Not Start', value: this.dataChart[key].notstart, color: colorNotStart},
        {type: 'Canceled', value: this.dataChart[key].canceled, color: colorCanceled}
      ]};
    }
    this.onGetDataSucceeded(resp);
    setTimeout(() => this.pageIndex = 1, 1);
  }

  public mapOfExpandedData: {[key: string]: TreeNodeInterface[]} = {};

  onExpandChange(array: TreeNodeInterface[], data: TreeNodeInterface, isExpanded: boolean) {
    ListShipmentExpandUtil.collapse(array, data, isExpanded);
  }

  toggleChildren(originalItem: TreeNodeInterface, mappedItem: TreeNodeInterface) {
    ListShipmentExpandUtil.toggleChildren(originalItem, mappedItem, this.mapOfExpandedData);
  }

  onCurrentPageDataChange(event) {
    this.currentPageData = event;
  }

  onTimeOptionChange(value) {
  }

  getShipmentId(mappedItem: TreeNodeInterface) {
    let order: any = mappedItem.parent ?? mappedItem;
    return order._id;
  }

  getRouterLink(item) {
    return [this.routeAdminShipmentList, this.getShipmentId(item)]
  }

  getRouterLinkFragment(item) {
    if (item.parent) {
      return `${item._id}`;
    }
    return undefined;
  }

  public getDeliveryInfoDate(deliveryInfo: DeliveryInfo) {
    const formatDateTime = 'MM/DD/YYYY, h:mm a';
    const formatDate = 'MM/DD/YYYY';
    let timezone = deliveryInfo.addr?.metadata?.timeZoneStandard;
    let timeWindow = OrderUtil.getTimeWindowForDeliveryInfo(deliveryInfo);
    if (!timeWindow) return 'N/A';
    return DateUtil.displayTimeWindow(timeWindow, {timezone, format: formatDateTime, formatDateOnly: formatDate});
  }

  protected onGetDataSucceeded(resp: any): void {
    const formatDateTime = 'MM/DD/YYYY, h:mm a';
    for (let item of this.listData) {
      item.children = [];
      item.key = `${item._id}`;
      if (!item.isMultiStop) {
        let pickInfo = item.deliveryInfos.filter(it => it.type == Const.TaskType.PICKUP)[0];
        let dropInfo = item.deliveryInfos.filter(it => it.type == Const.TaskType.DROPOFF)[0];
        item.addrPick = this.getAddressTextV2(pickInfo?.addr);
        item.addrDrop = this.getAddressTextV2(dropInfo?.addr);
        item.timePick = this.getDeliveryInfoDate(pickInfo);
        item.timeDrop = this.getDeliveryInfoDate(dropInfo);
        if (item.onTimeData?.actualPickTime) {
          item.timePickActual = DateUtil.displayLocalTime(item.onTimeData.actualPickTime, {timezone: pickInfo.addr?.metadata?.timeZoneStandard, format: formatDateTime});
        }
        if (item.onTimeData?.actualDropTime) {
          item.timeDropActual = DateUtil.displayLocalTime(item.onTimeData.actualDropTime, {timezone: dropInfo.addr?.metadata?.timeZoneStandard, format: formatDateTime});
        }
        item.pickLate = item.onTimeData?.pickLate;
        item.dropLate = item.onTimeData?.dropLate;
      } else {
        let firstDropInfo = item.deliveryInfos.filter(it => it.type == Const.TaskType.DROPOFF)[0];
        item.pickLate = firstDropInfo.onTimeData?.pickLate;
        // trường hợp multi-stop thì cần tạo ra mảng shipment con từ các điểm DROPOFF
        // mỗi shipment con sẽ là 1 cặp PICKUP/DROPOFF có id trùng nhau
        let dic = {};
        for (let info of item.deliveryInfos) {
          if (info.invoiceFileId && !info.invoice) {
            info.invoice = {_id: info.invoiceFileId, type: 'application/pdf'}
          }
          if (!dic[info.id]) {
            dic[info.id] = {_id: info.id, deliveryInfos: [], key: `${item.key}-${info.id}`, children: []};
            item.children.push(dic[info.id]);
          }
          if (info.type == Const.TaskType.PICKUP) {
            if (!item.addrPick) {
              item.addrPick = this.getAddressTextV2(info.addr);
            }
            let dropInfo = item.deliveryInfos.filter(it => it.type == Const.TaskType.DROPOFF && it.id == info.id)[0];
            info.onTimeData = dropInfo.onTimeData;
            if (!item.timePick) {
              item.timePick = this.getDeliveryInfoDate(info);
              if (info.onTimeData?.actualPickTime) {
                item.timePickActual = DateUtil.displayLocalTime(info.onTimeData.actualPickTime, {timezone: info.addr?.metadata?.timeZoneStandard, format: formatDateTime});
              }
            }
            dic[info.id].deliveryInfos.push(info);
            dic[info.id].addrPick = '';
            dic[info.id].timePick = '';
          } else if (info.type == Const.TaskType.DROPOFF) {
            dic[info.id].deliveryInfos.push(info);
            dic[info.id].addrDrop = this.getAddressTextV2(info.addr);
            dic[info.id].timeDrop = this.getDeliveryInfoDate(info);
            if (info.onTimeData?.actualDropTime) {
              dic[info.id].timeDropActual = DateUtil.displayLocalTime(info.onTimeData.actualDropTime, {timezone: info.addr?.metadata?.timeZoneStandard, format: formatDateTime});
            }
            dic[info.id].dropLate = info.onTimeData?.dropLate;
            for (let key of ['trackingCode', 'status', 'bol', 'invoice', 'invoiceFileId', 'bolFileId']) {
              dic[info.id][key] = info[key];
            }
            item.addrDrop = `${item.children.length} locations`;
          }
        }
      }
    }
    this.mapOfExpandedData = ListShipmentExpandUtil.processListData(this.listData);
  }

  public getAddressTextV2(addr: AddressUS): string {
    if (!addr) return '';
    return `${addr.city}, ${addr.state}, ${addr.zipcode}`;
  }

  public getStatusValue(item): string {
    if (typeof item.status == 'string') return item.status;
    return Const.OrderStatus.needCarrier;
  }

  public showStatusValue(item: OrderModel): string {
    return this.getStatusOrder(this.getStatusValue(item));
  }

  public shouldShowStatus(item): boolean {
    return !item.isMultiStop;
  }

  public showLateMinutes(minutes: number) {
    let txt = '';
    let hours = Math.floor(minutes / 60);
    if (hours > 0) {
      txt = `${hours} hour${hours > 1 ? 's' : ''}`;
      minutes = minutes - hours * 60;
    }
    if (minutes > 0) {
      if (txt.length > 0) {
        txt += ' ';
      }
      txt += `${minutes} minute${minutes > 1 ? 's' : ''}`;
    }
    return txt;
  }

  public isCanceledOrderStatus(item): boolean {
    return this.getStatusValue(item) == Const.OrderStatus.canceled;
  }

}