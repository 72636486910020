<div style="padding: 15px;">
    <h3>{{ action }} Rating Settings</h3>

    <h4>{{ dateTitle }}</h4>
    <nz-date-picker [(ngModel)]="effectiveDate" [nzDisabledDate]="disabledDate" class="first" nzFormat="yyyy-MM-dd" (ngModelChange)="onChange(startOfDay($event))"></nz-date-picker>
</div>

  
  <div form-footer [onProgress]="inProgress" 
    [canClickOK]="!inProgress && canSave()" [canClickCancel]="!inProgress" 
    [nzIconOK]="'filter'" [labelOK]="'Confirm'" (onOK)="onOk()" 
    (onCancel)="onBtnCancel()"></div>
  
  
  