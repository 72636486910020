import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { MapModule } from '../../components/map/module';
import { NzTableModule } from 'ng-zorro-antd/table';

import { UiCommonModule } from '../../components/common/module';

import { ActivePlanningSessionDetail } from './index';
import { PlanningShipmentDetail } from './shipment-detail';
import { AddVehicleForm } from './add-vehicle-form';
import { PlanningRouteDetail } from './route-detail';
import { PlanningStopDetail } from './stop-detail';
import { AddShipmentToGroupForm } from './add-to-group-form';
import { TransitNetworkModule } from '../../transit-network/module';
import { ExportingRouteDialog } from './route-detail/exporting-dialog';
import { ExportedJobDetail } from './exported-job';
import { RouteCardModule } from '../route-card/module';
import { BidCardModule } from '../bid-card/module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        NzButtonModule,
        NzGridModule,
        NzIconModule,
        FormsModule,
        NzInputModule,
        NzListModule,
        NzTabsModule,
        NzInputNumberModule,
        UiCommonModule,
        NzDividerModule,
        NzPopoverModule,
        NzDropDownModule,
        MapModule,
        NzTableModule,
        NzCheckboxModule,
        NzSelectModule,
        NzToolTipModule,
        NzRadioModule,
        TransitNetworkModule,
        NzDatePickerModule,
        NzSwitchModule,
        NzTagModule,
        RouteCardModule,
        BidCardModule,
    ],
    declarations: [
        ActivePlanningSessionDetail,
        PlanningShipmentDetail,
        AddVehicleForm,
        PlanningRouteDetail,
        PlanningStopDetail,
        AddShipmentToGroupForm,
        ExportingRouteDialog,
        ExportedJobDetail,
    ],
    exports: [
        ActivePlanningSessionDetail,
    ],
    providers: [
    ]
})

export class PlanningSessionModule {}