import { BaseList } from "../base/list";
import { Component } from "@angular/core";
import { Const } from "@app/const/Const";
import { ActivatedRoute } from "@angular/router";
import { DateUtil } from "@services/date-utils";
import { InputHelper } from "@services/input-helper";

@Component({
  selector: "label-template-list",
  templateUrl: "./list.html",
  styleUrls: ["../list.scss"],
})
export class LabelTemplateList extends BaseList {
  constructor(protected activatedRoute: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  protected getApiUrl(): string {
    return Const.APIURI_LABEL_TEMPLATES;
  }

  public getTypeText(type) {
    return Const.getLabelTemplateTypeText(type);
  }

  onBtnCreate() {
    // this.comingSoon();
    this.router.navigate([this.routeAdminLabelTemplateList, 'add'])
  }

  onBtnDetail(item = null) {
    this.router.navigate([this.routeAdminLabelTemplateList, item._id])
  }
}
