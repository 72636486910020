<div class="dashboard-child-container no-padding form-detail">
  <div style="font-size: 20px;font-weight: 700;margin-bottom: 12px;">Shipment Details</div>
  <div *ngIf="shouldShowFirstLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
  <ng-container>
    <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 16, lg: 16 }">
      <div nz-col nzXs="24" nzSm="24" nzMd="16" nzLg="16">
        <div class="group">
          <!-- Step on header -->
          <nz-steps [nzCurrent]="step" nzSize="small">
            <nz-step *ngFor="let step of [0, 1, 2]" [nzTitle]="getStepTitle(step)"></nz-step>
          </nz-steps>
        </div>

        <div class="group">
          <!-- Child component -->

          <div [ngClass]="{'hidden': step!= 0}" quote-shipment-pickup #pickInfo [clientId]="clientId" [model]="modelPickUp" [filters]="filters"></div>
          <div [ngClass]="{'hidden': step!= 1}" quote-shipment-dropoff #dropInfo [clientId]="clientId" [model]="modelDropOff" [filters]="filters" [quote]="quote"></div>
          <div [ngClass]="{'hidden': step!= 2}" quote-shipment-items-details #itemsDetails [model]="modelItemsDetails" [filters]="filters" [step]="step" [quote]="quote"></div>

          <!-- Action buttons at bottom -->
          <div class="flex">
            <button style="width: 150px;" nz-button (click)="onBtnBack()">Back</button>
            <div class="flex1"></div>
            <ng-container *ngIf="isShowBtnContinue">
              <button [disabled]="!needUpdate" style="width: 250px;" nz-button nzType="primary" (click)="onBtnNext()">Continue</button>
            </ng-container>
          </div>
        </div>
      </div>
      <div nz-col nzXs="24" nzSm="24" nzMd="8" nzLg="8">
        <div freight-quote-item [isShowCarrierName]="isShowCarrierName" [quote]="quote" [filters]="filters" [isHidenBtnLoadBook]="true"></div>
        <ng-container *ngFor="let groupKey of ['pickInfo', 'dropInfo']">
          <div *ngIf="shouldShowDeliveryInfo(groupKey)" class="group delivery-details">
            <div class="delivery-details-header">
              <div style="font-weight: 700;font-size: 16px;">{{getLabelDeliveryInfo(groupKey)}}</div>
              <div>
                <button nz-button nzType="text" (click)="onBtnEditDelivey(groupKey)"><i class="item-edit"  nz-icon nzType="edit" nzTheme="outline"></i></button>
              </div>
            </div>
            <div class="delivery-details-body">
              <div *ngFor="let key of ['locationName']" class="delivery-details-item">
                <div class="delivery-details-item-label">{{getLabelDeliveryInfo(groupKey,key)}}</div>
                <div class="delivery-details-item-content">
                  {{getValueDeliveryInfo(groupKey,key)}}
                </div>
              </div>
              <div class="delivery-details-item" nz-row>
                <div *ngFor="let key of ['contactPerson']" nz-col nzXs="8">
                  <div class="delivery-details-item-label">{{getLabelDeliveryInfo(groupKey,key)}}</div>
                  <div class="delivery-details-item-content">
                    {{getValueDeliveryInfo(groupKey,key)}}
                  </div>
                </div>
                <div *ngFor="let key of ['contactInfo']" nz-col nzXs="16">
                  <div class="delivery-details-item-label">{{getLabelDeliveryInfo(groupKey,key)}}</div>
                  <div class="delivery-details-item-content">
                    {{getValueDeliveryInfo(groupKey,key)}}
                  </div>
                </div>
              </div>
              <div *ngFor="let key of ['dataAndTime', 'serviceOptions']" class="delivery-details-item">
                <div class="delivery-details-item-label">{{getLabelDeliveryInfo(groupKey,key)}}</div>
                <div class="delivery-details-item-content">
                  {{getValueDeliveryInfo(groupKey,key)}}
                </div>
              </div>
              <div class="delivery-details-item" nz-row>
                <div *ngFor="let key of ['refNum']" nz-col nzXs="12">
                  <div class="delivery-details-item-label">Reference Number</div>
                  <div class="delivery-details-item-content">
                    {{getValueDeliveryInfo(groupKey,key)}}
                  </div>
                </div>
                <div *ngFor="let key of ['accessCode']" nz-col nzXs="12">
                  <div class="delivery-details-item-label">Access Code</div>
                  <div class="delivery-details-item-content">
                    {{getValueDeliveryInfo(groupKey,key)}}
                  </div>
                </div>
              </div>
              <div *ngFor="let key of ['instructions']" class="delivery-details-item">
                <div class="delivery-details-item-label">{{getLabelDeliveryInfo(groupKey,key)}}</div>
                <div class="delivery-details-item-content">
                  {{getValueDeliveryInfo(groupKey,key)}}
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>