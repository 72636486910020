<form #form [formGroup]="formInput" nz-form>
  <div class="form-inner-box">
    <ng-container *ngFor="let key of formInputKeys">
      <div *ngIf="key == 'clientId'" class="form-item" [ngClass]="key">
        <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
        <div>
          <select-by-searching
            [version]="2"
            nzMode="multiple"
            [formControlName]="key" (modelChange)="onChange($event, key)"
            [placeholder]="getPlaceHolder(key)" dropdownClassName="dispatch-filter-customer">
          </select-by-searching>
        </div>
      </div>
      <div *ngIf="key == 'day'" class="form-item" [ngClass]="key">
        <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
        <div>
          <nz-select nzBackdrop="true" nzShowSearch style="min-width: 120px;" 
            [formControlName]="key" 
            [nzPlaceHolder]="getPlaceHolder(key)"
            (ngModelChange)="onChange($event, key)">
            <nz-option *ngFor="let item of filterDayOptions" 
              [nzValue]="item.value" [nzLabel]="item.name">
            </nz-option>
          </nz-select>
        </div>
      </div>
    </ng-container>

    <div class="form-item">
      <div class="form-label-v2"></div>
      <button nz-button type="button" (click)="onBtnExport()" [nzLoading]="isExporting">
        <i nz-icon nzType="file-excel" nzTheme="outline"></i>
        Export
      </button>
    </div>

  </div>
</form>
  
  