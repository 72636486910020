import { Component, Input } from "@angular/core";
import { Utils } from "@services/utils";
import { Const } from "@const/Const";
import { DialogService } from "@dialogs/dialog.service";
import { BaseDialog } from "@dialogs/base-dlg";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { startOfDay, differenceInCalendarDays } from 'date-fns'
import { environment } from "@env/environment";

@Component({
  selector: '[delete-settings-dialog]',
  templateUrl: './index.html',
  styleUrls: ['../../../../dialogs/dialogs.scss', './index.scss']
})
export class DeleteSettingsDialog extends BaseDialog {
  @Input() setting: any; 
  @Input() effective: boolean = true;

//   protected formGroupDeclaration: FormGroupDeclaration = {
//     effectiveDate: {label: 'Effective Date', placeHolder: 'Effective Date', initialValue: false, required: true}
//   }

  public isLoading = false;
  public inProgress = false;

  public startOfDay(result: Date): Date {
    return result ? startOfDay(result) : null;
  }

  public get action(): string {
      return this.effective ? 'Activate' : 'Expire'
  }

  public get dateTitle(): string {
    return this.effective ? 'Effective Date' : 'Expiration Date'
}

  ngOnInit(): void {
    super.ngOnInit();
  }
  
  onBtnCancel() {
    this.closeDialog();
  }

  onOk() {
    const url = `${environment.ratingUrl}/${Const.APIURI_FINANCE_SETTINGS}/detail/${this.setting.id}`;

    this.api.DELETE(url).subscribe(
        resp => {
            this.setting.status = 'DELETED'
            this.inProgress = false;
            this.closeDialog();
        }, err => {
            this.inProgress = false;
        }
    )
  }

  canSave = () => this.setting?.status !== 'ACTIVE'
}