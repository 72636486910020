import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { DataorchService } from "@services/dataorch.service";
import _ from "underscore";

@Component({
    selector: '[linehaul-shipment-content]',
    templateUrl: './index.html',
    styleUrls: ['./index.scss']
})
export class LinehaulShipmentContent extends BaseComponent {
    dataorch: DataorchService
    _data: {
        id?: string,
        shipment?: any,
        pods?: any[]
    } = {}
    loading: boolean = false
    items: any[] = []
    quantityItems: any[] = []

    @Input() set data(v: {id?: string, shipment?: any, pods?: any[]}) {
        this._data = v
        this.items = v.shipment?.items || []
        this.quantityItems = _.flatten(this.items.map(it => it.quantityItems))
        // if (!this._data.pods && v.id) {
        //     this.loadPods(v.id)
        // }
    }

    constructor() {
        super()
        this.dataorch = new DataorchService(this.api)
    }

    loadPods(id) {
        this.loading = true
        this.dataorch.listShipmentPods(id).subscribe(r => {
            this.loading = false
            this._data.pods = r
        })
    }
}