import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { Const } from "@const/Const";
import { BaseFormDrawer } from "@drawers/base-form";
import { environment } from "@env/environment";

@Component({
  selector: '[upload-order-pdf]',
  templateUrl: './index.html',
  styleUrls: ['../../../app.scss', '../../../dialogs/dialogs.scss', './index.scss']
})
export class UploadOrderPdf extends BaseFormDrawer {
  // for uploading a list of orders via excel/csv
  protected formGroupDeclaration: FormGroupDeclaration = {
    file: {label: 'File for uploading', required: true, type: 'uploadFile'},
    clientId: {label: 'Customer', required: true, placeHolder: 'Please select customer'},
    shipmentType: {label: 'Shipment Type', required: true},
  };

  @Input() emitUploadedFile: (file: File) => void = () => {}
    
  isRequired(key): boolean {
    switch (key) {
      case 'pickupWindows': return false;
      case 'dropoffWindows': return true;
      default: return super.isRequired(key);
    }
  }
  
  public listShipmentTypes = Const.ShipmentTypesArray;
  public listClients = [];
  public client = null;
  public selectedFile: File;
  public get txtBtnOK() {return 'Upload'}
  public get iconBtnOK() {return 'upload'}
  
  public errMsg;
  public err;
  public get labelSelectFile(): string {
    let key = 'file';
    return this.hasAttachedFile(key) ? this.getFileDesc(key) : 'Select file (pdf)'
  }
  
  @ViewChild('inputFile') inputFile: ElementRef<HTMLInputElement>;
  
  constructor() {
    super();
  }
  
  ngOnInit() {
    super.ngOnInit();
  }
  
  protected getApiUrl(): string {
    return `${environment.backendUrl}/v2/orders/create-draft-from-pdf`
  }
  
  onClientChange(itemId) {
    this.client = this.listClients.filter(it => it._id == itemId)[0] || {};
  }

  protected getFormData_JSON(isCreateNew: boolean) {
    let json: any = super.getFormData_JSON(isCreateNew);
    json['clientId'] = this.client.id;
    return json;
  }
  
  protected showInfo(message: any): void {
    // do nothing
  }
  
  protected startProgress() {
    super.startProgress();
    this.errMsg = '';
    this.err = null
  }

  onFileSelected(key, files: FileList) {
    super.onFileSelected(key, files);
    this.selectedFile = files[0];
  }

  onBtnSave() {
    this.emitUploadedFile(this.selectedFile);
    super.onBtnSave();
  }

  protected onCreateFailure(err) {
    this.showErrDialog(err);
    this.showErr(err);
  }
}