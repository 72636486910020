<form [formGroup]="formInput" class="form-detail" nz-form>
  <div class="f20 bottom30">Update status for transaction</div>

  <div *ngFor="let key of formInputKeys" class="bottom20">
    <div class="form-register-value">
      <div *ngIf="key=='status'">
        <nz-select (ngModelChange)="onFilterChange(key, $event)" [formControlName]="key"
                   [nzPlaceHolder]="getPlaceHolder(key)" style="width: 100%">
          <ng-container *ngFor="let status of statusList">
            <nz-option [nzLabel]="getStatusText(status)" [nzValue]="status"></nz-option>
          </ng-container>
        </nz-select>
      </div>
    </div>
  </div>

</form>

<div (onCancel)="closeDialog()" (onOK)="onBtnSave()"
     [canClickCancel]="!onProgress" [canClickOK]="needUpdate && !onProgress"
     [labelOK]="'Save'" [nzIconOK]="'save'" [onProgress]="onProgress"
     form-footer></div>
