<div style="width: 800px; height: 600px; position: relative; background-color: aliceblue">
  <div
    delivery-planning
    [shipments]="shipments"
    [mode]="mode"
    [canMerge]="canMerge"
    [shipmentIds]="shipmentIds"
    (onRouteCreated)="routeCreated($event)"
  ></div>
</div>
