<h2>
    Update Pickup Time
</h2>

<div class="flex bottom10">
<div class="right10">
    <div>From Time</div>
    <div>
        <nz-time-picker [nzMinuteStep]="15" nzFormat="HH:mm" [(ngModel)]="fromTime" nzPlaceHolder="From time" (ngModelChange)="checkReady($event)"></nz-time-picker>
    </div>
</div>
<div>
    <div>To Time</div>
    <div>
        <nz-time-picker [nzMinuteStep]="15" nzFormat="HH:mm" [(ngModel)]="toTime" nzPlaceHolder="To time" (ngModelChange)="checkReady($event)"></nz-time-picker>
    </div>
</div>
</div>
<div class="bottom20 note">
    Time is in Local timezone of pickup location
</div>

<div class="buttons flex">
    <div class="right20">
        <button [disabled]="rolling" nz-button (click)="onClose && onClose()">Cancel</button>
    </div>
    <div>
        <button nzType="primary" [disabled]="!isReady || rolling" nz-button (click)="onConfirm()">Confirm</button>
    </div>
</div>
