<div>
    Warp ID: {{ shipment.warpId }}
</div>
<div *ngIf="displayInfo.clientName">
    Customer: {{ displayInfo.clientName }}
</div>
<div>
    <a *ngIf="!shipment.order?.id" [routerLink]="[routeAdminOrderList]" [queryParams]="{search: shipment.warpId}" target="_blank">View Detail</a>
    <a *ngIf="shipment.order?.id" [routerLink]="[routeAdminOrderList, shipment.order.id]" target="_blank">View Detail</a>
</div>

<nz-divider nzText="Pickup" nzOrientation="left"></nz-divider>
<div *ngIf="displayInfo.pickup.market">
    Market {{ displayInfo.pickup.market }}
</div>
<div *ngIf="displayInfo.pickupTime">
    {{ displayInfo.pickupTime }}
</div>
<div *ngIf="displayInfo.pickup.requiresAppointment">
    Requires Appointment
</div>


<nz-divider nzText="Dropoff" nzOrientation="left"></nz-divider>
<div *ngIf="displayInfo.dropoff.market">
    Market {{ displayInfo.dropoff.market }}
</div>
<div *ngIf="displayInfo.dropoffTime">
    {{ displayInfo.dropoffTime }}
</div>
<div *ngIf="displayInfo.dropoff.requiresAppointment">
    Requires Appointment
</div>
