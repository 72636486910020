<div class="box-container" *ngIf="renderTemplate == 'TMPL_1'">
    <div class="flex outer-box">
        <div class="general-info-container">
            <ng-container *ngTemplateOutlet="filter"></ng-container>
        </div>
        <div class="flex1 map">
            <div class="map-container">
                <map-container [(map)]="map" (selectShipment)="onSelectShipment($event)"></map-container>
            </div>
            <div *ngIf="selectedShipment" class="selected-shipment-popup">
                <div shipment-quickview [shipment]="selectedShipment"></div>
                <div>
                    <button nz-button (click)="onCloseShipment()" class="right10">Close</button>
                </div>
            </div>
        </div>
        <div *ngIf="showShipmentList" class="shipment-list" [style.width]="displayConfig.shipmentListSize * 80 + 'px'">
            <div need-routing-shipment-list [shipments]="filtered" (onRemove)="onRemoveShipment($event)"
                (reviewStatusUpdate)="onReviewStatusUpdate($event)"
                (deliveryInfoUpdated)="onDeliveryInfoUpdate($event)"
                (shipmentsUpdated)="onShipmentsUpdated($event)"
            ></div>
        </div>
    </div>
</div>

<ng-container *ngIf="renderTemplate == 'TMPL_5'">
        <div class="general-info-container TMPL_5">
            <ng-container *ngTemplateOutlet="filter"></ng-container>
        </div>
        <div class="shipment-list-container TMPL_5">
            <div need-routing-shipment-list [shipments]="filtered" (onRemove)="onRemoveShipment($event)"
                (clickShipment)="onClickShipment($event)"
                (onPlanningAdded)="onAddedShipmentPlanning($event)"
                (hoverShipment)="onMouseOverShipment($event)"
                (reviewStatusUpdate)="onReviewStatusUpdate($event)"
                (deliveryInfoUpdated)="onDeliveryInfoUpdate($event)"
                (shipmentsUpdated)="onShipmentsUpdated($event)"
        ></div>
        </div>    
        <div class="map-container-bottom">
            <map-container [(map)]="map" (selectShipment)="onSelectShipment($event)"></map-container>
        </div>
        <div *ngIf="selectedShipment" class="selected-shipment-popup">
            <div shipment-quickview [shipment]="selectedShipment"></div>
            <div>
                <button nz-button (click)="onCloseShipment()" class="right10">Close</button>
            </div>
        </div>
</ng-container>

<div class="box-container" *ngIf="renderTemplate == 'TMPL_2'">
    <div class="flex outer-box TMPL_2">
        <div class="general-info-container">
            <ng-container *ngTemplateOutlet="filter"></ng-container>
        </div>
        <div class="content-bottom flex">
            <div *ngIf="showShipmentList" style="position: relative;" [style.flex]="displayConfig.shipmentListSize">
                <div class="shipment-list-container">
                    <div need-routing-shipment-list [shipments]="filtered" (onRemove)="onRemoveShipment($event)"
                        (onPlanningAdded)="onAddedShipmentPlanning($event)"
                        (hoverShipment)="onMouseOverShipment($event)" (clickShipment)="onClickShipment($event)"
                        (shipmentsUpdated)="onShipmentsUpdated($event)"
                    ></div>
                </div>
            </div>
            <div class="map" style="flex: 12;">
                <div class="map-container">
                    <map-container [(map)]="map" (selectShipment)="onSelectShipment($event)"></map-container>
                </div>
                <div *ngIf="selectedShipment" class="selected-shipment-popup">
                    <div shipment-quickview [shipment]="selectedShipment"></div>
                    <div>
                        <button nz-button (click)="onCloseShipment()" class="right10">Close</button>
                    </div>
                </div>
            </div>        
        </div>
    </div>
</div>

<div class="box-container" *ngIf="renderTemplate == 'TMPL_3'">
    <div class="outer-box TMPL_3">
        <div class="general-info-container">
            <ng-container *ngTemplateOutlet="filter"></ng-container>
        </div>
        <div class="content-bottom">
            <div class="shipment-list-container">
                <div need-routing-shipment-list [shipments]="filtered" (onRemove)="onRemoveShipment($event)"
                (reviewStatusUpdate)="onReviewStatusUpdate($event)"
                (onPlanningAdded)="onAddedShipmentPlanning($event)"
                (deliveryInfoUpdated)="onDeliveryInfoUpdate($event)"
                (shipmentsUpdated)="onShipmentsUpdated($event)"
                ></div>
            </div>
        </div>
    </div>
</div>

<div class="box-container" *ngIf="renderTemplate == 'TMPL_4'">
    <div class="outer-box TMPL_4">
        <div class="general-info-container">
            <ng-container *ngTemplateOutlet="filter"></ng-container>
        </div>
        <div class="content-bottom">
            <div class="shipment-list-container">
                <div need-routing-shipment-list [shipments]="filtered" (onRemove)="onRemoveShipment($event)"
                (clickShipment)="onClickShipment($event)"
                (onPlanningAdded)="onAddedShipmentPlanning($event)"
                (hoverShipment)="onMouseOverShipment($event)"
                (reviewStatusUpdate)="onReviewStatusUpdate($event)"
                (deliveryInfoUpdated)="onDeliveryInfoUpdate($event)"
                (shipmentsUpdated)="onShipmentsUpdated($event)"
                ></div>
            </div>
            <div class="map-container">
                <map-container [(map)]="map"></map-container>
            </div>
        </div>
    </div>
</div>

<ng-template #filter>
    <div class="group">
        <span nz-dropdown [nzDropdownMenu]="menu">
            <ng-container *ngFor="let tmpl of renderTemplates">
                <span *ngIf="renderTemplate == tmpl.value" nz-icon [nzType]="tmpl.icon" nzTheme="outline"></span>
            </ng-container>
        </span>
        <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
                <li nz-menu-item *ngFor="let tmpl of renderTemplates" (click)="switchRenderTemplate(tmpl.value)" [nzDisabled]="renderTemplate == tmpl.value">
                    <span nz-icon [nzType]="tmpl.icon" nzTheme="outline"></span>
                </li>
            </ul>
        </nz-dropdown-menu>
    </div>
    <!-- <div class="group">
        <div>Total Shipments: {{ _shipments?.length }}</div>
        <div>Shipments: {{ filtered?.length }}</div>
    </div> -->
    <!-- <div class="group">
        <div><label>Mileage Range:</label></div>
        <div>
            <span *ngFor="let range of mileageRanges"
                [class]="['mileage-option', _filter.maxMileage === range ? 'selected' : 'normal']"
                (click)="setMaxMileage(range)"
            >{{ range }}</span>
            <span (click)="setMaxMileage(0)" [class]="['mileage-option', _filter.maxMileage === 0 ? 'selected' : 'normal']">All</span>
        </div>
    </div> -->
    <div class="group id-search">
        <div><label>Search by Id</label></div>
        <div>
            <nz-input-group [nzSuffix]="inputClearTpl">
            <input nz-input nzAllowClear
                [(ngModel)]="_filter.keyword"
                ngPlaceholder="Shipment or Order id"
                style="width: 100%; min-width: 100px;"
                (ngModelChange)="onFilterChange($event)">
            </nz-input-group>
                <ng-template #inputClearTpl>
                    <span
                      nz-icon
                      class="ant-input-clear-icon"
                      nzTheme="fill"
                      nzType="close-circle"
                      *ngIf="_filter.keyword"
                      (click)="_filter.keyword = null; onFilterChange($event)"
                    ></span>
                  </ng-template>
        </div>
    </div>
    <!-- <div class="group">
        <label nz-checkbox [(ngModel)]="_filter.requiresTime" (ngModelChange)="processShipments()">
            Exclude missing time
        </label>
    </div> -->
    <div class="group">
        <div><label>Date</label></div>
        <div class="date-filter">
            <span class="date-filter-range" nz-dropdown [nzDropdownMenu]="dateMenu">{{ _filter.dateRange }}</span>
            <nz-dropdown-menu #dateMenu="nzDropdownMenu">
                <ul nz-menu>
                  <li nz-menu-item (click)="setDateRange('=')">=</li>
                  <li nz-menu-item (click)="setDateRange('<')">&lt;</li>
                  <li nz-menu-item (click)="setDateRange('>')">&gt;</li>
                </ul>
            </nz-dropdown-menu>
            <nz-select nzBackdrop="true" nzAllowClear nzShowSearch
                [(ngModel)]="_filter.date"
                style="width: 100%; min-width: 130px;"
                (ngModelChange)="onFilterChange($event)">
                <nz-option *ngFor="let item of dates"
                    [nzValue]="item.date" [nzLabel]="item.display">
                </nz-option>
            </nz-select>
        </div>
    </div>
    <div class="group">
        <div><label>Crossdocks</label></div>
        <div>
            <nz-select nzBackdrop="true" nzAllowClear nzShowSearch
                [(ngModel)]="_filter.xdock"
                style="width: 100%; min-width: 160px;"
                (ngModelChange)="onFilterChange($event)">
                <nz-option *ngFor="let item of xdocks"
                    [nzValue]="item.id" [nzLabel]="item.name">
                </nz-option>
        </nz-select>
        </div>
    </div>
    <div class="group">
        <div><label>Market</label></div>
        <div>
            <nz-select nzBackdrop="true" nzAllowClear nzShowSearch
                [(ngModel)]="_filter.markets"
                nzMode="multiple"
                style="width: 100%; min-width: 160px;"
                (ngModelChange)="onFilterChange($event)">
                <nz-option *ngFor="let item of markets"
                    [nzValue]="item" [nzLabel]="item">
                </nz-option>
        </nz-select>
        </div>
    </div>
    <div class="group">
        <div><label>Customers</label></div>
        <div>
            <nz-select nzBackdrop="true" nzAllowClear nzShowSearch
                [(ngModel)]="_filter.clients"
                nzMode="multiple"
                style="width: 100%; min-width: 160px;"
                (ngModelChange)="onFilterChange($event)">
                <nz-option *ngFor="let item of clients"
                    [nzValue]="item.id" [nzLabel]="item.name">
                </nz-option>
        </nz-select>
        </div>
    </div>
    <div class="group">
        <div><label>Exclude Customers</label></div>
        <div>
            <nz-select nzBackdrop="true" nzAllowClear nzShowSearch
                [(ngModel)]="_filter.excludeClients"
                nzMode="multiple"
                style="width: 100%; min-width: 160px;"
                (ngModelChange)="onFilterChange($event)">
                <nz-option *ngFor="let item of clients"
                    [nzValue]="item.id" [nzLabel]="item.name">
                </nz-option>
        </nz-select>
        </div>
    </div>
    <div class="group">
        <div class="bottom5"><label>Leg</label></div>
        <div>
            <span class="transit-type-option clickable" [class]="{'selected': _filter.transitTypes.has(opt.value)}" *ngFor="let opt of transitTypeOptions" (click)="onTransitTypeChange(opt.value)">{{ opt.label }}</span>
            <!-- <span class="transit-type-option clickable" [class]="{'selected': !_filter.transitTypes?.size}" (click)="onTransitTypeChange('all')">all</span> -->

        </div>
    </div>
    <!-- <div class="group">
        <div><label>Readiness</label></div>
        <div>
            <nz-select nzBackdrop="true"
                [(ngModel)]="_filter.readinessLevel"
                style="width: 100%; min-width: 120px;"
                (ngModelChange)="onFilterChange($event)">
                <nz-option *ngFor="let item of readinessLevels"
                    [nzValue]="item.value" [nzLabel]="item.label">
                </nz-option>
        </nz-select>
        </div>
    </div> -->
    <!-- <div class="group">
        <div><label>Validation</label></div>
        <div>
            <nz-select nzBackdrop="true"
                [(ngModel)]="_filter.validated"
                style="width: 100%; min-width: 100px;"
                (ngModelChange)="onFilterChange($event)">
                <nz-option [nzValue]="''" nzLabel="All"></nz-option>
                <nz-option [nzValue]="'no-error'" nzLabel="No Issue"></nz-option>
                <nz-option [nzValue]="'error'" nzLabel="Has Issue"></nz-option>
        </nz-select>
        </div>
    </div> -->
    <!-- <div class="group">
        <label tooltip nzTooltipTitle="Shipments have been confirmed for planning" nz-checkbox [(ngModel)]="_filter.pickupReady" (ngModelChange)="onFilterChange($event)">
            Ready
        </label>
    </div> -->

    <div class="group">
        <button nz-button (click)="loadShipments()" class="right10" [disabled]="loading">
            <i nz-icon nzType="loading" nzTheme="outline" *ngIf="loading"></i>
            <span nz-icon nzType="reload" nzTheme="outline" *ngIf="!loading"></span>
            Refresh
        </button>
        <ng-container *ngIf="renderTemplate === 'TMPL_1' || renderTemplate === 'TMPL_2'">
        <button nz-button (click)="toggleShowShipments()">
            <span *ngIf="!showShipmentList">Show List</span>
            <span *ngIf="showShipmentList">Hide</span>
        </button>
        <span *ngIf="showShipmentList" style="margin-left: 10px;">
            <span class="clickable" nz-icon nzType="caret-left" (click)="changeShipmentListSize(-1)" nzTheme="outline"></span>
            <span class="clickable" nz-icon nzType="caret-right" (click)="changeShipmentListSize(1)" nzTheme="outline"></span>
        </span>
        </ng-container>
    </div>
</ng-template>