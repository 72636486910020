import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { BaseComponent } from '@abstract/BaseComponent';
import { DateUtil } from '@services/date-utils';
import { Const } from '@const/Const';
import dayjs from 'dayjs';

@Component({
  selector: '[delivery-info-quickview]',
  templateUrl: './delivery_info_quickview.html',
  styleUrls: ['../index.scss', 'index.scss']
})
export class DeliveryInfoQuickView extends BaseComponent {
    @Input() info

    get window (): string {
        const { addr } = this.info || {}
        const metadata = addr || {}
        let timezone = metadata?.timeZoneStandard;
        let timeWindow = this.info.windows?.[0];
        if (!timeWindow) return 'N/A';
        return DateUtil.displayTimeWindow(timeWindow, {timezone});
      }
    
}