import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BizUtil } from "@services/biz";
import { DateUtil } from "@services/date-utils";

@Component({
    selector: '[planning-stop-detail]',
    templateUrl: './index.html',
    styleUrls: ['./index.scss', '../index.scss']
})
export class PlanningStopDetail extends BaseComponent {
    _stop: any = null
    displayInfo: any = {}
    full: boolean = false

    get stop() {
        return this._stop
    }
    @Input() set stop(v) {
        this._stop = v
        this.processDisplayInfo()
    }

    @Output() onHover: EventEmitter<any> = new EventEmitter<any>();

    toggleFull() {
        this.full = !this.full
    }

    processDisplayInfo() {
        const { type, shipment, costFromPreviousStop, timeWindow, location } = this._stop || {}
        const { distance, time } = costFromPreviousStop || {}
        const deliveryInfo = type === 'PICKUP' ? BizUtil.getPickInfo(shipment.metadata.shipment) : BizUtil.getDropInfo(shipment.metadata.shipment)
        const { start } = timeWindow || {}
        const window = DateUtil.displayTimeWindow(
            (deliveryInfo?.windows || [])[0],
            {
                format: 'MM/DD, H:mm', formatDateOnly: 'MM/DD',
                timezone: deliveryInfo?.addr.metadata.timeZoneStandard
            }
        )
        const eta = start ? DateUtil.displayLocalTime(start * 1000, {timezone: deliveryInfo?.addr.metadata.timeZoneStandard, format: 'MM/DD, H:mm'}) : ''

        this.displayInfo = {
            type: type.substring(0,1),
            shipmentId: shipment?.metadata?.shipment?.warpId,
            mileage: ((distance || 0) / 1609.34).toFixed(1),
            time: time || 0,
            workload: shipment?.workload, 
            location: location.latlng,
            window,
            eta,
        }
    }

    onMouseClick() {
        this.toggleFull()
    }
}