<div [class]="size">
    <nz-input-group [nzSuffix]="suffixIcon">
        <input nz-input
            [(ngModel)]="clientName"
            [nzAutocomplete]="autoComplete1"
            (ngModelChange)="onChangeClientNameSearch($event)"
        >
    </nz-input-group>
    <ng-template #suffixIcon>
        <span *ngIf="_clientId" class="clickable" (click)="clear()" nz-icon nzType="delete"></span>
    </ng-template>
    <nz-autocomplete #autoComplete1 [nzDefaultActiveFirstOption]="false">
        <nz-auto-option *ngFor="let item of filteredClients" [nzValue]="item.name"
            (selectionChange)="onClientSelected($event, item)">
            <span class="font-medium">{{item.name}}</span>
        </nz-auto-option>
    </nz-autocomplete>
</div>