<form [formGroup]="formInput" nz-form *ngIf="formInput">
  <ng-container *ngIf="'clientIds' as key">
    <div>{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
    <div style="display: flex;">
      <select-by-searching style="min-width: 400px;" [version]="2" [formControlName]="key"
        (modelChange)="onCustomerChange($event)" (onFocus)="onFocusCustomerFilter()" [placeholder]="getPlaceHolder(key)"
        dropdownClassName="carrier-pool-dedicated-customers">
      </select-by-searching>
    </div>
  </ng-container>

  <ng-container *ngIf="'carrierSalesRepId' as key">
    <div class="top20">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
    <div style="display: flex;">
      <nz-select nzBackdrop="true" nzAllowClear nzShowSearch
        [formControlName]="key" [nzLoading]="isFetchingCarrierSaleRep"
        [nzPlaceHolder]="getPlaceHolder(key)"
        style="min-width: 400px;"
      >
        <nz-option *ngFor="let item of listCarrierSales"
          [nzValue]="item.id" [nzLabel]="getFullName(item)">
        </nz-option>
      </nz-select>
    </div>
  </ng-container>

  <ng-container *ngIf="'isTemperatureControlled' as key">
    <div nz-checkbox [formControlName]="key" class="top20">
      {{getLabel(key)}}
    </div>
  </ng-container>

  <div>
    <div class="top20 flex group-lane">
      <div class="text">
        Select lane
      </div>
      
      <div class="action">
        <nz-radio-group formControlName="type">
          <label nz-radio-button nzValue="zipcode">Zipcode</label>
          <label nz-radio-button nzValue="state">State</label>
        </nz-radio-group>
      </div>
    </div>

    <ng-container *ngIf="getType() === 'zipcode'">
      <div class="warning" *ngIf="getArrayControls('lanes').length > 1">Please enter zipcodes in stops order</div>
      <ng-container [formArrayName]="'lanes'">
        <form *ngFor="let item of getArrayControls('lanes'); let index = index" nz-form [formGroupName]="index">
          <div nz-row class="flex1" style="max-width: 920px; padding-bottom: 12px">
            <div nz-col nzSpan="9">
              <div class="lane-input-label">From Zipcode</div>
              <input nz-input #laneInputFrom
                formControlName="from" 
                (keyup)="onInputKeyUp($event)"
              />
            </div>
            <div nz-col nzSpan="2" style="display: flex; justify-content: center;">
              <i nz-icon nzType="arrow-right" nzTheme="outline" style="margin-top: 29px;"></i>
            </div>
            <div nz-col nzSpan="9">
              <div class="lane-input-label">To Zipcode</div>
              <input nz-input #laneInputTo
                formControlName="to"           
                (keyup)="onInputKeyUp($event)"
              />
            </div>
            <div nz-col nzSpan="4">
              <div class="lane-input-label">&nbsp;</div>
              <div class="group-button">
                <div class="link primary btn-add" (click)="onBtnAddLane()">Add</div>  
                <div *ngIf="getArrayControls('lanes').length > 1" class="link danger btn-remove" (click)="onBtnRemoveLane(index)">Remove</div>
              </div>
            </div>
          </div>
        </form>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="getType() === 'state'">
      <ng-container [formArrayName]="'laneStates'">
        <form *ngFor="let item of getArrayControls('laneStates'); let index = index" nz-form [formGroupName]="index">
          <div nz-row class="flex1" style="max-width: 920px; padding-bottom: 12px">
            <div nz-col nzSpan="9">
              <div class="lane-input-label">From</div>
              <nz-select
                [formControlName]="'from'"
                [nzMaxTagCount]="0" [nzShowArrow]="true"
                [nzMaxTagPlaceholder]="tagPlaceHolderFrom"
                [nzDropdownRender]="renderTemplateFrom"
                nzMode="multiple"
                style="width: 100%;"
                nzDropdownClassName="custom-select-multiple"
                nzPlaceHolder="Please select"
              >
                <nz-option nzCustomContent *ngFor="let item of allStates" [nzLabel]="item" [nzValue]="item">
                  <span>{{ item }}</span>
                </nz-option>
              </nz-select>
              <ng-template #tagPlaceHolderFrom let-selected>
                <span>{{ getValueSelected('from', index) }}</span>
              </ng-template>
              <ng-template #renderTemplateFrom>
                <div class="ant-select-item ant-select-item-option ng-star-inserted" 
                  [ngClass]="{'ant-select-item-option-selected ant-select-item-option-active': isSelectAll('from', index)}"
                  (click)="onChangeStateAll('from', index)"
                >
                  <div class="ant-select-item-option-content">
                    <span>All the US</span>
                  </div>
                  <div class="ant-select-item-option-state ng-star-inserted">
                    <span nz-icon nzType="check" nzTheme="outline" *ngIf="isSelectAll('from', index)"></span>
                  </div>
                </div>
              </ng-template>
            </div>
            <div nz-col nzSpan="2" style="display: flex; justify-content: center;">
              <i nz-icon nzType="arrow-right" nzTheme="outline" style="margin-top: 29px;"></i>
            </div>
            <div nz-col nzSpan="9">
              <div class="lane-input-label">To</div>
              <nz-select
                [formControlName]="'to'"
                [nzMaxTagCount]="0" [nzShowArrow]="true"
                [nzMaxTagPlaceholder]="tagPlaceHolderTo"
                [nzDropdownRender]="renderTemplateTo"
                nzMode="multiple"
                style="width: 100%;"
                nzDropdownClassName="custom-select-multiple"
                nzPlaceHolder="Please select"
              >
                <nz-option nzCustomContent *ngFor="let item of allStates" [nzLabel]="item" [nzValue]="item">
                  <span>{{ item }}</span>
                </nz-option>
              </nz-select>
              <ng-template #tagPlaceHolderTo let-selected>
                <span>{{ getValueSelected('to', index) }}</span>
              </ng-template>
              <ng-template #renderTemplateTo>
                <div class="ant-select-item ant-select-item-option ng-star-inserted" 
                  [ngClass]="{'ant-select-item-option-selected ant-select-item-option-active': isSelectAll('to', index)}"
                  (click)="onChangeStateAll('to', index)"
                >
                  <div class="ant-select-item-option-content">
                    <span>All the US</span>
                  </div>
                  <div class="ant-select-item-option-state ng-star-inserted">
                    <span nz-icon nzType="check" nzTheme="outline" *ngIf="isSelectAll('to', index)"></span>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </form>
      </ng-container>
    </ng-container>
  </div>
 
</form>
<div>
  <div nz-row class="top20">Days of week</div>
  <nz-checkbox-group [(ngModel)]="daysOfWeek" (ngModelChange)="changeDaysOfWeek($event)"></nz-checkbox-group>
</div>

<div style="max-width: 770px;">
  <div nz-row class="top20 price-label">
    Base price
    <i class="grey">(only available if there is 1 equipment)</i>
  </div>
  <div display-carrier-cost [jobId]="'no_job'" 
    [isReadOnly]="!isPriceAvailable" 
    [carrierCost]=" getFormData()?.baseRate"
    [saveCarrierCost]="saveCarrierCost" 
    [saveCarrierCostSuccess]="saveCarrierCostSuccess"></div>
</div>