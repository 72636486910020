import { Component, Input } from '@angular/core';
import { BaseComponent } from '@abstract/BaseComponent';
import { DateUtil } from '@services/date-utils';
import { Const } from '@const/Const';

@Component({
  selector: '[rate]',
  templateUrl: './rate.html',
  styleUrls: ['../index.scss',]
})
export class Rate extends BaseComponent {
    @Input() rate
    @Input() bookable
    public name

    ngOnChanges(): void {
        const { type } = this.rate || {}
        this.name = this.getName(type)
    }

    getName(type) {
        if (type === 'GRAND_TOTAL') return ''
        if (type === 'TRANSPORT') return 'Per Mile'
        if (type === 'FUEL_SURCHARGE') return 'Fuel Surchage Per Mile'
        return type
    }
}