import { Utils } from '@services/utils';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Const } from '@const/Const';
import { BaseDetail } from '@app/admin/base/detail';
import { QuoteShipmentPickup } from './child-form/quote-shipment-pickup';
import { QuoteShipmentDropoff } from './child-form/quote-shipment-dropoff';
import { QuoteShipmentItemsDetails } from './child-form/quote-shipment-items-details';
import { InputHelper } from '@services/input-helper';
import { DateUtil } from '@services/date-utils';
import { MasterData } from '@services/master.data';
import { BaseQuoteShipmentChildForm } from './child-form/base-child-form';
import { DialogService } from '@dialogs/dialog.service';
import { LoadBookSuccessfuly } from './success-dialog';
import { ApiMethod } from '@app/enum';
import { FreightQuoteLogsService } from '@services/freight-quote-logs.service';

@Component({
  selector: '[freight-quote-shipment-detail]',
  templateUrl: './index.html',
  styleUrls: ['./index.less',]
})
export class FreightQuoteShipmentDetail extends BaseDetail {
  freightQuoteLogsService: FreightQuoteLogsService;
  private _quote: any;
  set quote(value) {
    this._quote = value;
    if (value) {
      const params = {
        bookedQuoteInfo: value,
      }
      this.freightQuoteLogsService.updateFreightQuoteLog(params);
    }
  }
  @Input() get quote() { return this._quote};
  @Input() isShowCarrierName = false;
  @Input() clientId: any;
  @Input() filters: any;
  @Output() onBtnBackPreviousStep: EventEmitter<any> = new EventEmitter<any>();

  public step = 0;
  public get maxStep() {return 2}
  public shipmentType;
  public equipmentId = null;
  public shipmentModeId = null;
  isShowBtnContinue = true;

  @ViewChild('pickInfo') pickInfo: QuoteShipmentPickup;
  @ViewChild('dropInfo') dropInfo: QuoteShipmentDropoff;
  @ViewChild('itemsDetails') itemsDetails: QuoteShipmentItemsDetails;

  constructor(protected activatedRoute: ActivatedRoute) {
    super(activatedRoute);
    this.freightQuoteLogsService = new FreightQuoteLogsService(this.api);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    this.bindDataFilterToModel();
  }

  orderInfo = {
    pickInfo: {
      label : 'Pick Up Details',
      locationName: { label: 'Pickup Location Name' , value: ''},
      contactPerson: { label: 'Contact Person' , value: ''},
      contactInfo: { label: 'Contact Info' , value: ''},
      dataAndTime: { label: 'Pickup Date & Time' , value: ''},
      serviceOptions: { label: 'Service Options' , value: ''},
      refNum: { label: 'Reference Number' , value: ''},
      accessCode: { label: 'Access Code' , value: ''},
      instructions: { label: 'Instructions' , value: ''}
    },
    dropInfo: {
      label : 'Delivery Details',
      locationName: { label: 'Delivery Location Name' , value: ''},
      contactPerson: { label: 'Contact Person' , value: ''},
      contactInfo: { label: 'Contact Info' , value: ''},
      dataAndTime: { label: 'Delivery Date & Time' , value: ''},
      serviceOptions: { label: 'Service Options' , value: ''},
      refNum: { label: 'Reference Number' , value: ''},
      accessCode: { label: 'Access Code' , value: ''},
      instructions: { label: 'Instructions' , value: ''}
    }
  }

  get currentChild(): BaseQuoteShipmentChildForm {
    switch (this.step) {
      case 0: return this.pickInfo;
      case 1: return this.dropInfo;
      case 2: return this.itemsDetails;
    }
  }

  public modelPickUp;
  public modelDropOff;
  public modelItemsDetails;

  getStepTitle(step: number): string {
    switch (step) {
      case 0: return 'Pickup Address';
      case 1: return 'Delivery Address';
      case 2: return 'Items Details';
    }
  }
  get needUpdate(): boolean {
    return this.currentChild?.needUpdate;
  }

  onBtnNext() {
    if (!this.currentChild.needUpdate) {
      this.showInfo('Please enter required input fields.');
      return;
    }
    if (this.step < this.maxStep) {
      if (this.step == 0) {
        this.modelPickUp = this.pickInfo?.formData(true);
        this.processItem('pickInfo',this.modelPickUp);
      } else if (this.step == 1) {
        this.modelDropOff = this.dropInfo?.formData(true);
        this.processItem('dropInfo',this.modelDropOff);
      }
      this.step++;
    } else {
      if (!this.pickInfo.needUpdate || !this.dropInfo.needUpdate || !this.itemsDetails.needUpdate) {
        return;
      }
      this.modelItemsDetails = this.itemsDetails?.formData(true);
      if (this.modelPickUp && this.modelDropOff && this.modelItemsDetails ) {
        this.createData();
        this.isShowBtnContinue = false;
      }
      // DialogService.openFormDialog1(FormShipmentPayment, {
      //   nzComponentParams: {
      //     onSave: data => {
      //       if (this.modelPickUp && this.modelDropOff && this.modelItemsDetails ) {
      //         this.createData();
      //         this.isShowBtnContinue = false;
      //       }
      //     }
      //   },
      //   nzClassName: 'modal-no-padding modal-shipment-payment',
      // });
    }
  }

  protected buildUrl(method: ApiMethod): string {
    switch (method) {
      case ApiMethod.post:
        return `${Const.APIURI_ORDERS}/create-order-by-thirdparty`;
    }
  }

  protected getFormData_JSON(isCreateNew: boolean): object {
    let data = {};
    if (this.quote?.source == Const.QuoteSource.Exfreight) {
      data['thirdPartyInfo'] = {
        info: {
          route_number: this.quote?.route_number,
          total_charge: this.quote?.total_charge,
          legs: this.quote?.legs,
          rated_items: this.quote?.exfreightRes?.exfreightRatedItems,
          reference_key: this.quote?.exfreightRes?.reference_key,
          logId: this.freightQuoteLogsService.getLogId(),
        }, 
        source: Const.thirdPartyOrderSource.Exfreight
      };
      data['isNotAutoRouteFTL'] = true;
    }
    if (this.quote?.source == Const.QuoteSource.WARP) {
      data['thirdPartyInfo'] = {
        info: this.quote, 
        source: Const.thirdPartyOrderSource.WarpFreightQuote
      };
      data['isCrossDock'] = true;
    }
    if (this.quote.source == Const.QuoteSource.DAT) {
      data['thirdPartyInfo'] = {
        info: this.quote.datRes, 
        source: Const.thirdPartyOrderSource.DAT
      };
    }
    data['clientId'] = this.clientId;
    data['shipmentType'] = this.shipmentType;
    data['shipmentModeId'] = this.shipmentModeId;
    data['equipmentId'] = this.equipmentId;
    if (this.quote?.total_charge?.value) {
      let costTemplate = {
        currency: {
          type: 'USD',
          fxRate: null
        },
        "transitCost" : {
            "rate" : 0,
            "qty" : 1,
            "total" : 0
        },
        "volumeDiscount" : {
            "type" : "percentage",
            "percentage" : null,
            "flatRate" : null,
            "qty" : null,
            "total" : 0
        },
        "subTotal" : 0,
        "fuelCost" : {
            "type" : "rpm",
            "percentage" : null,
            "rpm" : null,
            "qty" : null,
            "total" : 0
        },
        "serviceOptions" : [

        ],
        "grandTotal" : 0
      }
      let costValue = this.quote?.total_charge?.value ?? 0;
      if (this.quote?.total_charge?.unit == Const.MonenyUnits.us_cent) {
        costValue = Const.convertCentToDollar(costValue);
      }
      // Báo giá là giá cuối cùng grandTotal;
      costTemplate.grandTotal = costValue;
      let subTotal = costValue;
      if (this.quote?.fuelCost?.total) {
        costTemplate.fuelCost = this.quote?.fuelCost;
        subTotal = costValue - this.quote.fuelCost.total;
      }
      costTemplate.transitCost.rate = subTotal;
      costTemplate.transitCost.total = subTotal;
      costTemplate.subTotal = subTotal;

      const serviceTemplate = {
        "rate" : null,
        "qty" : 1,
        "total" : null
      }
      for (let item of this.modelPickUp.pickInfo.serviceOptions) {
        let obj = Utils.cloneObject(serviceTemplate);
        obj._id = item;
        obj.dropOffIndex = -1;
        costTemplate.serviceOptions.push(obj);
      }
      for (let item of this.modelDropOff.dropInfo.serviceOptions) {
        let obj = Utils.cloneObject(serviceTemplate);
        obj._id = item;
        obj.dropOffIndex = 0;
        costTemplate.serviceOptions.push(obj);
      }
      data['cost'] = costTemplate;
    }
    data['deliveryInfos'] = [];
    data['listItems']= this.modelItemsDetails.itemsDetails?.listItems;

    let pickData = this.modelPickUp.pickInfo;
    pickData.type = Const.TaskType.PICKUP;
    data['deliveryInfos'].push(pickData);

    let dropData = this.modelDropOff.dropInfo;
    dropData.type = Const.TaskType.DROPOFF;
    let itemId = 0;
    dropData.listItemIds = [];
    for (let itemIndex = 0; itemIndex < data['listItems'].length; itemIndex++) {
      itemId++;
      data['listItems'][itemIndex].id = itemId;
      dropData.listItemIds.push(itemId);
    }
    data['deliveryInfos'].push(dropData);

    return data;
  }

  protected bindDataModel(model) {
    return
  }

  getShipmentModeIdByName(name) {
    if (name) {
      const shipmentMode = MasterData.getAllShipmentModesV2().find(shipmentMode => shipmentMode.name.toLowerCase() == name.toLowerCase());
      if (shipmentMode) {
        return shipmentMode._id;
      }
    }
    return null;
  }

  protected bindDataFilterToModel() {
    if (this.filters?.packaging?.packagingType == Const.FreightQuotePackageType.Parcel) {
      this.shipmentType = Const.ShipmentTypes.parcel;
    } else if (this.filters?.packaging?.packagingType == Const.FreightQuotePackageType.Pallet) {
      this.shipmentType = Const.ShipmentTypes.lessThanTruckload;
    } else if (this.filters?.packaging?.packagingType == Const.FreightQuotePackageType.FTL) {
      this.shipmentType = Const.ShipmentTypes.fullTruckLoad;
      if (this.filters?.packaging?.equipment) {
        this.shipmentModeId = this.getShipmentModeIdByName(this.filters?.packaging?.equipment)
      }
    }
  }

  protected onCreateSuccess(resp) {
    if (resp?.data?._id) {
      const params = {
        orderId: resp.data._id,
        shipmentId: resp.data.shipmentIds?.[0]
      }
      this.freightQuoteLogsService.updateFreightQuoteLog(params);
    }
    if (!this.dropInfo.getLocationId()) {
      const params = {
        clientId:this.clientId,
        name:this.modelDropOff?.dropInfo?.locationName,
        pickAddr:this.modelDropOff?.dropInfo?.addr,
        phone: this.modelDropOff?.dropInfo?.contactPhone,
        email: this.modelDropOff?.dropInfo?.contactEmail,
        contactName: this.modelDropOff?.dropInfo?.contactName
      };
      this.createLocation(params);

    }
    if (!this.pickInfo.getLocationId()) {
      const params = {
        clientId:this.clientId,
        name:this.modelPickUp?.pickInfo?.locationName,
        pickAddr:this.modelPickUp?.pickInfo?.addr,
        phone: this.modelPickUp?.pickInfo?.contactPhone,
        email: this.modelPickUp?.pickInfo?.contactEmail,
        contactName: this.modelPickUp?.pickInfo?.contactName
      };
      this.createLocation(params);

    }
    this.showCompletedDialog()
  }

  private createLocation(params) {
    this.api.POST(`${Const.APIURI_WAREHOUSES}`, params).subscribe(
      resp => {
        this.stopProgress();
      }, err => {
        this.stopProgress();
      }
    );
  }

  private showCompletedDialog(){
    DialogService.openDialog(LoadBookSuccessfuly, {
      nzWidth: 260,
      nzClosable: false,
      nzComponentParams: {
        onClose: () => {
          this.router.navigate([this.routeAdminShipmentList]);
          // this.onBtnBackPreviousStep.emit();
        }
      },
      nzClassName: 'modal-no-padding',
    });
  }
  protected onCreateFailure(err) {
    super.onCreateFailure(err);
    this.isShowBtnContinue = true;
  }

  onBtnBack() {
    if (this.step > 0) {
      this.step--;
    } else {
      this.onBtnBackPreviousStep.emit();
    }
  }

  onBtnEditDelivey(key: 'pickInfo'|'dropInfo') {
    if (key == 'pickInfo') {
      this.step = 0;
    } else {
      this.step = 1;
    }
  }

  protected processItem(key: 'pickInfo'|'dropInfo', model) {
    if (!model || !model[key]) return;
    let data = model[key];
    let addressText = this.getAddressText(data.addr) || 'N/A';
    if (data.locationName) {
      this.orderInfo[key].locationName.value = `${data.locationName} (${addressText})`;
    } else {
      this.orderInfo[key].locationName.value = addressText;
    }
    this.orderInfo[key].contactPerson.value = data.contactName || 'N/A';
    let phoneNumber = 'N/A';
    if (data.contactPhone) {
      phoneNumber = InputHelper.formatPhone(data.contactPhone);
    }
    if (data.contactEmail) {
      this.orderInfo[key].contactInfo.value = `${phoneNumber}, ${data.contactEmail}`;
    } else {
      this.orderInfo[key].contactInfo.value = `${phoneNumber}`;
    }
    this.orderInfo[key].dataAndTime.value = this.getDeliveryInfoDate(data);
    this.orderInfo[key].serviceOptions.value = 'N/A';
    if (Utils.isArrayNotEmpty(data.serviceOptions)) {
      let arr = [];
      for (let service of data.serviceOptions) {
        let option = MasterData.getServiceOptionById(service);
        if (!option) continue;
        arr.push(option.name);
      }
      this.orderInfo[key].serviceOptions.value = arr.join(', ');
    }
    this.orderInfo[key].refNum.value = data.refNum || 'N/A';
    this.orderInfo[key].accessCode.value = data.accessCode || 'N/A';
    this.orderInfo[key].instructions.value = data.instructions || 'N/A';
  }

  public getDeliveryInfoDate(deliveryInfo) {
    let timezone = deliveryInfo.addr?.metadata?.timeZoneStandard;
    let timeWindow = deliveryInfo.windows?.[0];
    if (!timeWindow && deliveryInfo.requiresAppointment) {
      timeWindow = deliveryInfo.appointmentInfo;
    }
    if (!timeWindow) return 'N/A';
    return DateUtil.displayTimeWindow(timeWindow, {timezone});
  }

  shouldShowDeliveryInfo(key: 'pickInfo'|'dropInfo') {
    if (key == 'pickInfo') {
      if (this.step > 0 && this.modelPickUp) {
        return true;
      }
    } else {
      if (this.step > 1 && this.modelDropOff) {
        return true;
      }
    }
    return false;
  }

  getLabelDeliveryInfo(groupKey: 'pickInfo'|'dropInfo', key = '') {
    switch (key) {
      case '': return this.orderInfo[groupKey].label;
      case 'locationName':
      case 'contactPerson':
      case 'contactInfo':
      case 'dataAndTime':
      case 'serviceOptions':
      case 'refNum':
      case 'accessCode':
      case 'instructions':
        return  this.orderInfo[groupKey][key].label;
      default: return '';
    }
  }

  getValueDeliveryInfo(groupKey: 'pickInfo'|'dropInfo', key) {
    switch (key) {
      case 'locationName':
      case 'contactPerson':
      case 'contactInfo':
      case 'dataAndTime':
      case 'serviceOptions':
      case 'refNum':
      case 'accessCode':
      case 'instructions':
        return  this.orderInfo[groupKey][key].value;
      default: return '';
    }
  }

}
