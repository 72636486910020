import {Component, EventEmitter, Output} from '@angular/core';
import {Const} from '@const/Const';
import {BaseFormItem} from '@app/admin/base/form-item';
import {ActivatedRoute} from '@angular/router';
import {Utils} from '@services/utils';
import {DateUtil} from '@services/date-utils';

@Component({
  selector: '[finance-transaction-filter]',
  templateUrl: './index.html',
  styleUrls: [
    '../../../app.scss',
    '../../../drawers/drawer.scss',
    '../../../../styles/date-picker-row.scss',
    '../../../../styles/form-v2.scss'
  ]
})
export class FinanceTransactionFilter extends BaseFormItem {
  @Output() onReload: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFilterTabChanged: EventEmitter<number> = new EventEmitter<number>();
  filterTabs = [
    {
      key: 'in_progress',
      title: 'In Progress',
      status: [Const.FinanceTransactionStatus.PENDING, Const.FinanceTransactionStatus.NOT_CAPTURED]
    },
    {
      key: 'need_review',
      title: 'Needs Review',
      status: [Const.FinanceTransactionStatus.NEED_REFUND, Const.FinanceTransactionStatus.ISSUE]
    },
    {
      key: 'completed',
      title: 'Completed',
      status: [Const.FinanceTransactionStatus.SUCCEEDED, Const.FinanceTransactionStatus.REFUNDED]
    },
    {key: 'failed', title: 'Failed', status: [Const.FinanceTransactionStatus.FAILED]},
  ];
  filterTabsIndex = {in_progress: 0, need_review: 1, completed: 2, failed: 3};

  public condition: any = {};
  public searchKeyword: string = null;
  public sort: any = undefined;
  public loaded: number = 0;
  public page: number = 1;
  protected formGroupDeclaration: FormGroupDeclaration = FinanceTransactionFilter.declaration;

  constructor(protected activatedRoute: ActivatedRoute) {
    super();
    this.activatedRoute.queryParams.subscribe(p => {
      if (p.filter) {
        try {
          this.condition = JSON.parse(p.filter);
        } catch {
          this.condition = {};
        }
      }
      this.sort = p.sort || undefined;
      this.searchKeyword = p.search;
      this.loaded = p.loaded || 0;
      this.page = p.page || 1;
      let tab = p.tab || this.filterTabs[0].key;
      this._selectedTabIndex = this.filterTabsIndex[tab] || 0;
      this.onFilterTabChanged.emit(this._selectedTabIndex);
    });
    if (!this.condition.status) {
      this.condition['status'] = this.filterTabs[this.filterTabsIndex.in_progress].status;
    }
    this.onRefresh();
  }

  public static get declaration(): FormGroupDeclaration {
    return {
      status: {label: 'Status', notAcceptEmpty: true, placeHolder: 'Select from list', initialValue: []},
      searchKeyword: {label: 'Keyword', placeHolder: '', notAcceptEmpty: true},
      fromDate: {
        label: '',
        type: 'date',
        notAcceptEmpty: true,
        placeHolder: 'From date',
        formatValue: v => v,
        getValue: v => v
      },
      toDate: {
        label: '',
        type: 'date',
        notAcceptEmpty: true,
        placeHolder: 'To date',
        formatValue: v => v,
        getValue: v => v
      },
      fromExpiredDate: {
        label: '',
        type: 'date',
        notAcceptEmpty: true,
        placeHolder: 'From date',
        formatValue: v => v,
        getValue: v => v
      },
      toExpiredDate: {
        label: '',
        type: 'date',
        notAcceptEmpty: true,
        placeHolder: 'To date',
        formatValue: v => v,
        getValue: v => v
      },
    };
  }

  private _selectedTabIndex = 0;

  get selectedTabIndex() {
    return this._selectedTabIndex;
  }

  set selectedTabIndex(value) {
    if (this._selectedTabIndex == value) {
      return;
    }
    this.sort = undefined;
    this._selectedTabIndex = value;
    this.onFilterTabChanged.emit(value);
    this.loaded = 0;
    this.page = 1;
    this.condition = {};
    this.condition['status'] = this.filterTabs[value].status;
    this.formInput.reset();
    this.formInput.get('status').setValue(this.condition.status);
    this.onRefresh();
  }

  ngOnInit(): void {
    this.model = Object.assign({}, this.condition, {search: this.searchKeyword});
    super.ngOnInit();
  }

  isSelectInProgressTab() {
    return this._selectedTabIndex == this.filterTabsIndex.in_progress;
  }

  isSelectNeedReviewTab() {
    return this._selectedTabIndex == this.filterTabsIndex.need_review;
  }

  doSearch(s) {
    this.searchKeyword = s;
    this.page = 1;
    this.loadData();
  }

  onRefresh() {
    this.onReload.emit();
    if (this.sort) {
      this.sort = null;
    }
    this.loadData();
  }

  loadData() {
    let query = {page: this.page || 1, loaded: Date.now()};
    if (this.searchKeyword) {
      query['search'] = this.searchKeyword;
    }
    this.reformatConditionObject();
    query['tab'] = this.filterTabs[this._selectedTabIndex].key;
    this.condition.status = this.filterTabs[this._selectedTabIndex].status;
    query['filter'] = JSON.stringify(this.condition);
    if (this.sort) {
      query['sort'] = this.sort;
    }
    this.routeWithQueryUrl(query);
  }

  onChangeFilter(event, key) {
    this.page = 1;
    if (key) {
      if ((key == 'fromDate' || key == 'fromExpiredDate') && event) {
        this.condition[key] = DateUtil.getDateFrom(DateUtil.isoDate(event));
      } else if ((key == 'toDate' || key == 'toExpiredDate') && event) {
        this.condition[key] = DateUtil.getDateTo(DateUtil.isoDate(event));
      } else {
        this.condition[key] = event;
      }
    } else {
      delete this.condition[key];
    }
    this.reformatConditionObject();
    this.loadData();
  }

  private reformatConditionObject() {
    if (this.condition.status && !Utils.isArrayNotEmpty(this.condition.status)) {
      delete this.condition.status;
    }
    if (this.condition.fromDate == null) {
      delete this.condition.fromDate;
    }
    if (this.condition.toDate == null) {
      delete this.condition.toDate;
    }
    if (this.condition.fromExpiredDate == null) {
      delete this.condition.fromExpiredDate;
    }
    if (this.condition.toExpiredDate == null) {
      delete this.condition.toExpiredDate;
    }
  }

}
