import { Component, ElementRef, ViewChild } from '@angular/core';
import { BaseComponent } from '@abstract/BaseComponent';
import { FreightQuoteSearch } from './search';

@Component({
  selector: '[freight-quote]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss',]
})
export class FreightQuote extends BaseComponent {

  public step = 0;
  public get maxStep() {return 1}
  public currentQuote = {};
  clientId = null;
  filters: any = {};
  isShowCarrierName = false;

  @ViewChild('freightQuoteSearch') freightQuoteSearch: FreightQuoteSearch;
  // @ViewChild('mapElm') mapElm: ElementRef<HTMLElement>;
  // @ViewChild('insights') insights: Insights;

  constructor() {
    super();
  }
  
  ngOnInit(): void {
  }

  onBookLoad(data) {
    this.currentQuote = data.quote;
    this.clientId = data.filters.clientId;
    this.filters = data.filters;
    this.isShowCarrierName = data.isShowCarrierName;
    this.step = 1;
  }

  onBtnBackPreviousStep(event) {
    this.step = 0;
  }

}