<div style="padding: 20px;">
  <div class="medium">
    Confirm Shipment Details
  </div>
  
  <div nz-row>
    <div nz-col class="gutter-row top20" nzXs="24" nzSm="24">
      <div class="p-30">
        <div class="pickup p-relative">
          <div class="icon"><i nz-icon nzType="environment" nzTheme="outline"></i></div>
          <div class="flex p-relative">
            <div class="line"></div>
            <div>
              <div class="title-address">{{getAddressText(filters?.pickAddr)}}</div>
              <div class="sub-address">Pickup on {{pickDate}}</div>
            </div>
          </div>
        </div>
        <div class="dropoff p-relative top20">
          <div class="icon"><i nz-icon nzType="environment" nzTheme="outline"></i></div>
          <div class="title-address">{{getAddressText(filters?.dropAddr)}}</div>
          <div class="top20">Estimated Delivery:</div>
          <div class="left10">
            <div>
              <ng-container >
                <div nz-row [nzGutter]="{ md: 16}">
                  <div nz-col nzXs="24" nzMd="12">
                    <div *ngFor="let childKey of ['date']; let i = index">
                      <div class="form-label-v2 single-line fix-height">
                        Date
                      </div>
                      <nz-form-item>
                        <nz-form-control>
                          <nz-date-picker [nzDisabled]="!!newDeliveryDate" [(ngModel)]="newDeliveryDate" nzFormat="yyyy-MM-dd"style="width: 100%;"></nz-date-picker>
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                  </div>
                  <div nz-col nzXs="24" nzMd="12">
                    <div nz-row [nzGutter]="{ md: 16}">
                      <div nz-col nzXs="12">
                        <div class="form-label-v2 single-line fix-height">
                          From Time
                        </div>
                        <nz-form-item>
                          <nz-form-control>
                            <nz-time-picker [nzMinuteStep]="15" style="width: 100%;" nzFormat="HH:mm" 
                              [nzPlaceHolder]="'From'" [(ngModel)]="newFromTime">
                            </nz-time-picker>
                          </nz-form-control>
                        </nz-form-item>
                      </div>
                      <div nz-col nzXs="12">
                        <div class="form-label-v2 single-line fix-height">
                          To Time
                        </div>
                        <nz-form-item>
                          <nz-form-control>
                            <nz-time-picker [nzMinuteStep]="15" style="width: 100%;" nzFormat="HH:mm" 
                              [nzPlaceHolder]="'To'" [(ngModel)]="newToTime">
                            </nz-time-picker>
                          </nz-form-control>
                        </nz-form-item>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="left10" style="text-decoration: line-through; color: #a5a5a5; margin-top: -10px; margin-bottom: 20px;">{{oldDeliveryDate}}</div>
          <div class="top20">Cost:</div>
          <nz-radio-group [(ngModel)]="cost">
            <label nz-radio nzValue="new">{{formatMoney(newCost?.grandTotal ?? 0)}} (Exfreight)</label>
            <label nz-radio nzValue="old">{{formatMoney(oldCost)}}</label>
          </nz-radio-group>
        </div>
      </div>
    </div>
  </div>
  <div class="text-center top20">
    <!-- <button nz-button nzType="default" class="right10" (click)="onBtnConfirmAndContinue(false)">Book Exfreight</button> -->
    <button [disabled]="isDisabled" nz-button nzType="primary" (click)="onBtnConfirmAndContinue(true)" [nzLoading]="isLoading">Confirm & Book Exfreight</button>
  </div>
</div>

