import { Const } from "@const/Const"
import { DateUtil } from "@services/date-utils"
import { AddressUS, DeliveryInfo, JobTrafficCost, LatLng, Task } from "@wearewarp/types/data-model"
import _ from "underscore"


interface DeliveryStop {
    address?: AddressUS,
    location?: LatLng,
    tasks: Array<Task>,
    costFromPreviousStop?: JobTrafficCost,
    distance?: number,
    relativeDistance?: number,
    status?: string,
    types?: Array<string>
}

interface DeliveryLeg {
    distance?: number,
    relativeDistance?: number,
    length?: number,
    relativeLength?: number,
    status: string,
    mileage: string,
    hours: string,
}

export default class RouteProcessing {
    /**
     * Calculate haversine distance between 2 locations
     * @param loc1 lat,lng of location 1
     * @param loc2 lat,lng of location 2
     */
    static haversine(loc1, loc2) {
        if (loc1?.latitude == null || loc1?.longitude == null) return null
        if (loc2?.latitude == null || loc2?.longitude == null) return null

        const radians = (degree) => degree * Math.PI / 180
        const R = 6371000 // earth radius
        
        const phi1 = radians(loc1.latitude)
        const phi2 = radians(loc2.latitude)
        const deltaPhi = radians(loc1.latitude - loc2.latitude)
        const deltaLambda = radians(loc2.longitude - loc1.longitude)
        const a = Math.sin(deltaPhi / 2.0) ** 2 + Math.cos(phi1) * Math.cos(phi2) * Math.sin(deltaLambda / 2.0) ** 2
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    
        return R * c
    }

    static isSameLocation(info1: DeliveryInfo, info2: DeliveryInfo): boolean {
        if (info1 == null || info2 == null) return false
        if (info1.warehouseId != null && info1.warehouseId == info2.warehouseId) return true
        if (info1.addr.street == info2.addr.street && info1.addr.city == info2.addr.city && info1.addr.zipcode == info2.addr.zipcode)
            return true

        const distance = RouteProcessing.haversine(info1.addr.metadata, info2.addr.metadata)
        return (distance != null && distance < 50)
    }

    /**
     * Group tasks by location
     * @param tasks 
     */
    static logScale = (d) => d < 1609.34 ? 1.0 : Math.max(Math.log2(d / 1609.34), 1.0)

    static groupTaskByLocation(tasks): Array<DeliveryStop> {
        const locations = []
        let currentLocation: DeliveryStop = null
        let distance = 0.0
        let relDistance = 0.0
        const canceledShipmentIds = tasks.filter(it => it.status === Const.TaskStatus.canceled).map(it => it.shipmentId)
        tasks = tasks.filter(it => it.status !== Const.TaskStatus.pickupFailed).filter(it => canceledShipmentIds.indexOf(it.shipmentId) < 0)
        if (!tasks.length) return []
        tasks[0].trafficCost = null
        for (let task of tasks) {
            // update at
            const updateAt = task.statusChangeLog?.[task.status]?.when
            task.updateAt = updateAt ? DateUtil.displayLocalTime(updateAt, {format: 'hh:mma'}) : ''
            if (currentLocation != null && RouteProcessing.isSameLocation(currentLocation.tasks[0].info, task.info)) {
                currentLocation.tasks.push(task)
            } else {
                // create new location
                const rel = task.trafficCost?.distance ? RouteProcessing.logScale(task.trafficCost?.distance) : 0.0
                relDistance += rel,
                distance += task.trafficCost?.distance ?? 0.0
                currentLocation = {
                    address: task.info.addr,
                    location: task.location,
                    tasks: [task],
                    costFromPreviousStop: task.trafficCost,
                    distance,
                    relativeDistance: relDistance
                }
                locations.push(currentLocation)
            }
        }
        const completedStatus = [
            Const.TaskStatus.succeeded,
            Const.TaskStatus.failed,
            Const.TaskStatus.pickupFailed,
            Const.TaskStatus.arrived,
        ]

        for (let loc of locations) {
            loc.types = _.uniq(loc.tasks.map(it => it.type))
            loc.relativeDistance = 100 * loc.relativeDistance / (relDistance || 1.0)
            const isComplete = _.all(loc.tasks.map(it => completedStatus.indexOf(it.status) >= 0))
            const isOnGoing = _.any(loc.tasks.map(it => completedStatus.indexOf(it.status) >= 0))
            if (isComplete) {
                loc.status = 'complete'
            } else if (isOnGoing) {
                loc.status = 'inProgress'
            } else {
                loc.status = 'pending'
            }
        }
        return locations
    }

    /**
     * Group tasks by location
     * @param tasks 
     */
    static createJobLegs(stops): Array<DeliveryLeg> {
        const legs = []
        let distance = 0.0
        for (let i = 1; i < stops.length; i++) {
            const prev = stops[i-1]
            const stop = stops[i]
            let status = 'pending'
            if (prev.status == 'complete') {
                if (stop.status == 'pending') {
                    status = 'inProgress'
                } else {
                    status = 'complete'
                }
            }
            const rel = RouteProcessing.logScale(stop.costFromPreviousStop.distance)
            const leg = {
                distance: stop.distance - stop.costFromPreviousStop.distance,
                relativeDistance: distance,
                duration: stop.costFromPreviousStop.distance,
                relativeDuration: rel,
                mileage: (stop.costFromPreviousStop.distance / 1609.34).toFixed(1),
                hours: (stop.costFromPreviousStop.time / 3600).toFixed(1),
                status
            }
            distance += rel
            legs.push(leg)
        }
        for (let leg of legs) {
            leg.relativeDistance = leg.relativeDistance * 100 / distance
            leg.relativeDuration = leg.relativeDuration * 100 / distance
        }
        return legs
    }
}