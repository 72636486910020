<form class="form-detail" [formGroup]="formInput" nz-form>
  <div *ngIf="templateLink" class="bottom15">
    Download template:
    <a [href]="templateLink" target="_blank" style="margin-left: 10px;">{{getTemplateName(templateLink)}}</a>
  </div>
  
  <div class="bottom20" *ngFor="let key of formInputKeys">
    <div *ngIf="key != 'uploadOrderList' && key != 'isCrossDock'" class="form-register-label">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
    <div class="form-register-value">
      <ng-container [ngSwitch]="key">
        <select-by-searching *ngSwitchCase="'clientId'" 
          [version]="2"
          [showSubClient]="true"
          [formControlName]="key" 
          [(listData)]="listClients"
          [placeholder]="getPlaceHolder(key)" 
          (modelChange)="onClientChange($event)">
        </select-by-searching>
        
        <nz-select nzBackdrop="true" *ngSwitchCase="'warehouseId'" style="width: 100%" [formControlName]="key" nzShowSearch [nzNotFoundContent]="noDataWarehouse">
          <nz-option *ngFor="let item of listWarehouses" [nzLabel]="item.name" [nzValue]="item.id"></nz-option>
        </nz-select>
        
        <nz-select nzBackdrop="true" *ngSwitchCase="'shipmentType'" [formControlName]="key" (ngModelChange)="onShipmentTypeChange($event)">
          <nz-option *ngFor="let item of listShipmentTypes" [nzValue]="item" [nzLabel]="getShipmentTypeName(item)"></nz-option>
        </nz-select>

        <div nz-checkbox *ngSwitchCase="'isCrossDock'" [formControlName]="key">{{getLabel(key)}}</div>

        <ng-container *ngSwitchCase="'uploadOrderList'">
          <input #inputFile type="file" hidden accept=".csv,.xlsx,.xls" (change)="onFileSelected(key, inputFile.files)">
          <button nz-button class="btn1" style="width: 100%;" (click)="inputFile.click()">
            <i *ngIf="hasAttachedFile(key)" nz-icon nzType="file-excel" nzTheme="outline"></i>
            {{labelSelectFile}}
            <span *ngIf="!hasAttachedFile(key)" class="label-mark-required"></span>
          </button>
        </ng-container>
        
        <ng-container *ngSwitchCase="'pickupWindows'" [ngTemplateOutlet]="tplTimeWindows" [ngTemplateOutletContext]="{key, form: formInput}"></ng-container>
        
        <ng-container *ngSwitchCase="'dropoffWindows'" [ngTemplateOutlet]="tplTimeWindows" [ngTemplateOutletContext]="{key, form: formInput}"></ng-container>
        
        <form-address-us *ngSwitchCase="'dropAddr'" [showPlaceholderefault]="true" [form]="formInput.get('dropAddr')"></form-address-us>
        
        <input *ngSwitchDefault nz-input [formControlName]="key" [type]="getInputType(key)" [placeholder]="getPlaceHolder(key)">
        
      </ng-container>
    </div>
  </div>
  
  <div *ngIf="errMsg" class="danger" style="white-space: pre-wrap; word-wrap: break-word; margin-top: 40px;">{{errMsg}}</div>
</form>

<ng-template #tplTimeWindows let-key="key" let-form="form">
  <ng-container [formGroup]="form">
    <ng-container [formArrayName]="key">
      <form *ngFor="let item of getArrayControls(key); let i = index" nz-form [formGroupName]="i" class="pick-up-time">
        <nz-form-item class="col-date">
          <nz-form-control>
            <nz-date-picker class="first" formControlName="date" nzFormat="yyyy-MM-dd" nzPlaceHolder="Date"></nz-date-picker>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control>
            <nz-time-picker [nzMinuteStep]="15" class="middle" formControlName="fromTime" nzFormat="HH:mm" nzPlaceHolder="From time"></nz-time-picker>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control>
            <nz-time-picker [nzMinuteStep]="15" class="last" formControlName="toTime" nzFormat="HH:mm" nzPlaceHolder="To time"></nz-time-picker>
          </nz-form-control>
        </nz-form-item>
        <ng-container *ngIf="i==0">
          <i *ngIf="isRequired(key)" class="btn-remove" style="cursor: default;"></i>
          <i *ngIf="!isRequired(key)" nz-icon nzType="close" nzTheme="outline" class="btn-remove" (click)="removeItemInFormArray(key, i)"></i>
        </ng-container>
        <i *ngIf="i>0" nz-icon nzType="close" nzTheme="outline" class="btn-remove" (click)="removeItemInFormArray(key, i)"></i>
      </form>
      <div class="pick-up-time">
        <div class="col-date"><button nz-button (click)="addItemToFormArray(key)" class="btn-add" style="width: 100%;" nzType="dashed"><i nz-icon nzType="plus" nzTheme="outline"></i></button></div>
        <div></div>
        <div></div>
        <i class="btn-remove"></i>
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<div form-footer [onProgress]="onProgress" 
  [canClickOK]="needUpdate && !onProgress" [canClickCancel]="!onProgress" 
  [nzIconOK]="iconBtnOK" [labelOK]="txtBtnOK" (onOK)="onBtnSave()" 
  (onCancel)="onClose()"></div>
