import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { DialogService } from "@dialogs/dialog.service";
import { environment } from "@env/environment";
import { DateUtil } from "@services/date-utils";
import { ActivateSettingsDialog } from "./activate-dialog";
import { DeleteSettingsDialog } from "./delete-dialog";
import { SelectFormulaDialog } from "./select-formula";

@Component({
  selector: '[finance-settings]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class FinanceSettings extends BaseComponent {
    @Input() subject: string = null;
    @Input() scope: string = null;
    public loading = true;
    public settings: any = {};
    ngOnInit(): void {
        this.loadSettings()
    }

    public get isEmpty(): boolean {
        return !this.settings?.active || this.settings.active.length < 1
    }

    public formatDate(t): string {
      if (!t) return 'N/A'
      return DateUtil.format(t, 'YYYY/MM/DD HH:mm')
    }

    onSelectNewFormula() {
      DialogService.openDialog(SelectFormulaDialog, {
        nzComponentParams: {
          subject: this.subject,
          scope: this.scope,
          onDone: setting => {
            this.settings = {
              'status': 'success',
              'active': [...this.settings.active, setting]
            }
          }
        }
      })
    }

    onDeleteSettings(setting) {
      DialogService.openDialog(DeleteSettingsDialog, {
        nzComponentParams: {
          setting: setting
        },
        nzClassName: 'modal-no-padding',
        nzCentered: true,
      })
    }

    onActivateSettings(setting) {
      DialogService.openDialog(ActivateSettingsDialog, {
        nzComponentParams: {
          setting: setting
        },
        nzClassName: 'modal-no-padding',
        nzCentered: true,
      })
    }

    onExpireSettings(setting) {
      DialogService.openDialog(ActivateSettingsDialog, {
        nzComponentParams: {
          setting: setting,
          effective: false,
        },
        nzClassName: 'modal-no-padding',
        nzCentered: true,
      })
    }

    private loadSettings() {
        this.loading = true;
        if (!this.subject) {
          this.settings = null;
          this.loading = false;
          return;
        } else {
          const url = `${environment.ratingUrl}/${Const.APIURI_FINANCE_SETTINGS}/${this.subject}/${this.scope}/active`;
          this.api.GET(url).subscribe(
            resp => {
              this.settings = {
                  'status': 'success',
                  'active': resp
              };
              this.loading = false;
            }, err => {
              this.loading = false;
              this.settings = {
                'status': 'error'
              }
            }
          )
        }
      }
}