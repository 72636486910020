import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { Subscription } from 'rxjs';
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { DateUtil } from "@services/date-utils";
import { Log } from "@services/log";
import { ApiService } from "@services/api.service";
import { Const } from "@const/Const";

@Component({
  selector: '[freight-quote-form-export]',
  templateUrl: './index.html',
  styleUrls: ['../../../dialogs/dialogs.scss', '../../../../styles/form-v2.scss']
})
export class FreightQuoteExportForm extends BaseFormDialog1 {
  @Input() tzShort: string = null;
  isExporting = false;
  public static get declaration(): FormGroupDeclaration {return {
    clientId: {label: 'Customer', notAcceptEmpty: true, placeHolder: 'Select'},
    fromDate: {label: 'From Date', notAcceptEmpty: true},
    toDate: {label: 'To Date', notAcceptEmpty: true},
  }}
  protected formGroupDeclaration: FormGroupDeclaration = FreightQuoteExportForm.declaration;

  get isCreateNew(): boolean {
    return !this.model;
  }
  public listTimezones = DateUtil.listUsTimezones;

  protected getFormData_JSON(isCreateNew: boolean) {
    let data: any = super.getFormData_JSON(isCreateNew);
    let fromDate = null;
    let toDate = null;
    if (data.fromDate) {
      fromDate = DateUtil.toBeginOfDay(new Date(data.fromDate)).toISOString();
    }
    if (data.toDate) {
      toDate = DateUtil.toEndOfDay(new Date(data.toDate)).toISOString();
    }
    return { ...data, fromDate, toDate };
  }

  onBtnExport() {
    let data: any = this.getFormData_JSON(true);
    this.isExporting = true;
    const params = {
      download: 1,
      filter: data
    }
    this.api.postExport(`${Const.APIURI_FREIGHT_QUOTE_LOGS}/export`, params).subscribe(
      resp => {
        ApiService.handleDownloadResponse(resp);
        this.isExporting = false;
      }, err => {
        this.showErr(err);
        this.isExporting = false;
      }
    );
    // this.closeDialog();
  }

  onBtnCancel() {
    this.closeDialog();
  }
  onChange(e, name) {
    
  }

}