<div>
    <nz-steps [nzCurrent]="step" nzSize="small">
        <nz-step nzTitle="Pickup Info"></nz-step>
        <nz-step nzTitle="Delivery Info"></nz-step>
        <nz-step nzTitle="Items"></nz-step>
    </nz-steps>
</div>
<div nz-row [nzGutter]="{md: 24}">
    <div nz-col nzMd="16" nzSm="24">
        <div class="box">
            <div delivery-info-input *ngIf="step==0"
                [info]="pickupInfo" [clientId]="clientId"
                [locations]="locations"
                [earliestDate]="earliestPickup"
                (onReady)="onPickupInfoReady($event)"
            ></div>
            <div delivery-info-input *ngIf="step==1"
                [info]="dropoffInfo" [clientId]="clientId"
                [locations]="locations"
                [earliestDate]="earliestDropoff"
                (onReady)="dropoffReady = $event; checkContinue()"
            ></div>
            <div shipment-quote-review *ngIf="step == 2"
                [dropoffInfo]="dropoffInfo"
                [pickupInfo]="pickupInfo"
                [request]="quoteRequest"
                [option]="option"
                (onReady)="itemsReady = $event; checkContinue()"
            ></div>
        </div>
        <div class="flex buttons">
            <div class="flex1">
                <button nz-button [disabled]="processing" nzDanger (click)="onCancel()">Cancel</button>
            </div>
            <div *ngIf="step > 0" class="flex1">
                <button [disabled]="processing" nz-button nzType="secondary" (click)="onPrevStep()">{{prevStepName}}</button>
            </div>
            <div>
                <button [disabled]="!canContinue || processing" nz-button nzType="primary" (click)="onNextStep()">
                    <i *ngIf="processing" nz-icon nzType="loading" nzTheme="outline"></i>
                    {{nextStepName}}
                </button>
            </div>
        </div>
    </div>
    <div nz-col nzMd="8" nzSm="24">
        <div *ngIf="client" class="box">
            <span class="label">Customer: </span>
            <span class="emphasized">{{client.name}}</span>
        </div>
        <div *ngIf="option" class="box">
            <div class="bottom10">
                <span class="label">Shipment Type: </span> <span class="emphasized">{{ option.shipmentType }}</span>
            </div>
            <div class="bottom15" *ngIf="option.vehicleType?.code">
                <span class="label">vehicle Type: </span> <span class="emphasized">{{ option.vehicleType.code }}</span>
            </div>
            <div class="bottom15"><span class="label">Provider: </span><span class="emphasized">{{ option?.source }}</span></div>
            <div *ngIf="option?.carrierCode" class="bottom10">
                <span class="label">Carrier: </span>
                <span class="emphasized">[{{option?.carrierCode}}] {{option.carrierName}}</span>
            </div>
            <div *ngIf="option?.rate?.avg" class="bottom10">
                <span class="label">Rate: </span>
                <span class="emphasized">{{option.rate.avg}}</span> {{option.rate.currency || 'USD'}}
            </div>
            <div class="bottom10">
                <span class="label">Trip: </span>
                <span>
                    <span class="emphasized">{{ origin.zipcode }}</span> 
                    <i nz-icon nzType="arrow-right" nzTheme="outline" style="margin-left: 20px; margin-right: 20px"></i>
                    <span class="emphasized">{{ destination.zipcode }}</span> 
                </span>
            </div>
        </div>
        <div class="box" *ngIf="quoteRequest?.items">
            <div class="label">Items</div>
            <div *ngFor="let item of quoteRequest?.items">
                <div><img class="icon" src="/assets/img/ic_Schedule.png" /> {{item.quantity}} {{item.packaging}} ({{item.width}} W x {{item.length}} L x {{item.height}} H; {{item.weight}} lbs)</div>    
            </div>
        </div>
</div>