<div class="dashboard-child-container container">
    <h3>Warp Finance system admin</h3>
    <div class="group">
        <div *ngIf="configs" style="display: flex;">
            <div [ngClass]="{'list': true, 'compact' : showingDetail}">
                <nz-list>
                    <nz-list-item *ngFor="let item of configs" [ngClass]="{'selected': item.id === selected}">
                        <nz-list-item-meta>
                            <nz-list-item-meta-avatar [nzSrc]="item.avatar"></nz-list-item-meta-avatar>
                            <nz-list-item-meta-title>
                                <a [routerLink]="['./config', item.id]">{{ item.name }}</a>
                            </nz-list-item-meta-title>
                            <nz-list-item-meta-description *ngIf="item.effectiveDate || item.expirationDate">
                                <span><i nz-icon nzType="schedule" nzTheme="outline"></i> {{ formatDate(item.effectiveDate) }} <i nz-icon nzType="column-width" nzTheme="outline" style="color: #aaa; margin-left: 10px; margin-right: 10px"></i> {{ formatDate(item.expirationDate) }} </span>
                            </nz-list-item-meta-description>
                        </nz-list-item-meta>
                    </nz-list-item>
                </nz-list>
            </div>
            <div [ngClass]="{'detail': true, 'compact' : !showingDetail}">
                <router-outlet (activate)="onShowDetail($event)" (deactivate)="onHideDetail($event)"></router-outlet>
            </div>
        </div>
    </div>
    <div *ngIf="loadingConfigs">
        <i nz-icon nzType="loading" nzTheme="outline"></i>
    </div>

</div>