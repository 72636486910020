
import { Component } from '@angular/core';
import { BaseDetail } from '../base/detail';
import { ActivatedRoute } from '@angular/router';
import { ApiMethod } from '@app/enum';
import { Const } from '@const/Const';

@Component({
  selector: '[label-template-detail]',
  templateUrl: './detail.html',
  styleUrls: ['./detail.scss']
})
export class LabelTemplateDetail extends BaseDetail {
  protected formGroupDeclaration: FormGroupDeclaration = {
    name: { label: "Name", placeHolder: "Name", required: true },
    type: { label: "Type", placeHolder: "Type", required: true },
    zplString: { label: "ZPL String", placeHolder: "ZPL String", required: true },
    variables: { label: '', type: 'formArray', initialValue: [{}], childItem: {
      variable: {label: 'Variable', required: true},
      description: {label: 'Description', required: true}
    }}
  };

  public types = Object.values(Const.LabelTemplateTypes).map(type => {
    return { value: type, label: Const.getLabelTemplateTypeText(type) }
  })

  protected get crudEntity(): string { return 'label-templates' }

  constructor(protected activatedRoute: ActivatedRoute) {
    super(activatedRoute);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  public row1: FormRow = { columns: [
    { key: 'variable', span: 12, offset: 0 },
    { key: 'description', span: 11, offset: 0 },
    {key: 'action', span: 1, offset: 0}
  ]}

  protected getApiUrl(method: ApiMethod = ApiMethod.get): string {
    return Const.APIURI_LABEL_TEMPLATES;
  }
}
