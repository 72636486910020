import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import _ from "underscore";
import RouteProcessing from "./processor";

@Component({
    selector: '[route-progress-bar]',
    templateUrl: './index.html',
    styleUrls: ['./index.scss']
})
export class RouteProgressBar extends BaseComponent {
    _tasks: any[] = []
    stops: any[] = []
    legs: any[] = []
    info: string = null

    @Input() map

    @Input() set tasks(v) {
        this._tasks = v
        this.stops = RouteProcessing.groupTaskByLocation(this._tasks)
        this.legs = RouteProcessing.createJobLegs(this.stops)
    }

    constructor() {
        super()
    }
}