<!-- <div nz-row  [nzGutter]="{ md: 16 }">
    <div nz-col nzSm="12">
        <div class="section-header">Pickup Details</div>
        <div delivery-info-quickview [info]="pickupInfo"></div>
    </div>
    <div nz-col nzSm="12">
        <div class="section-header">Dropoff Details</div>
        <div delivery-info-quickview [info]="dropoffInfo"></div>
    </div>
</div> -->

<div *ngIf="!request.items || !request.items.length">No info available for shipping items</div>
<div *ngIf="editable" class="bottom10">
    <span class="left10 small-button" (click)="onAddNewItem('Pallet')">
        <i nz-icon nzType="plus" nzTheme="outline"></i>
        Pallets
    </span>
</div>
<div *ngIf="request.items && request.items.length">
    <div class="section-header">Shipping Items</div>
    <div nz-row [nzGutter]="{md:8, xs: 2}" >
        <div nz-col nzXs="2" class="label">Qty</div>
        <div nz-col nzXs="3" class="label">Pkg</div>
        <div nz-col nzXs="3" class="label">Width</div>
        <div nz-col nzXs="3" class="label">Length</div>
        <div nz-col nzXs="3" class="label">Height</div>
        <div nz-col nzXs="3" class="label">Weight</div>
        <div nz-col nzXs="6" class="label">Name <span class="label-mark-required"></span></div>
    </div>

    <div *ngFor="let item of request.items">
        <ng-container *ngIf="!editable">
            <div nz-row [nzGutter]="{md:8, xs: 2}">
                <div nz-col nzXs="2">{{item.quantity}}</div>
                <div nz-col nzXs="3">{{item.packaging}}</div>
                <div nz-col nzXs="3">{{item.width}}</div>
                <div nz-col nzXs="3">{{item.length}}</div>
                <div nz-col nzXs="3">{{item.height}}</div>
                <div nz-col nzXs="3">{{item.weight}}</div>
                <div nz-col nzXs="6">
                    <input [ngClass]="{'validation-error': !item.name}" nz-input [(ngModel)]="item.name" (ngModelChange)="onChange()">
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="editable">
            <div nz-row [nzGutter]="{md:8, xs: 2}">
                <div nz-col nzXs="2">
                    <input [ngClass]="{'validation-error': !item.quantity}" nz-input [(ngModel)]="item.quantity" (ngModelChange)="onChange()">
                </div>
                <div nz-col nzXs="3">
                    {{item.packaging}}
                </div>
                <div nz-col nzXs="3">
                    <input [ngClass]="{'validation-error': !item.width}" nz-input [(ngModel)]="item.width" (ngModelChange)="onChange()">
                </div>
                <div nz-col nzXs="3">
                    <input [ngClass]="{'validation-error': !item.length}" nz-input [(ngModel)]="item.length" (ngModelChange)="onChange()">
                </div>
                <div nz-col nzXs="3">
                    <input [ngClass]="{'validation-error': !item.height}" nz-input [(ngModel)]="item.height" (ngModelChange)="onChange()">
                </div>
                <div nz-col nzXs="3">
                    <input [ngClass]="{'validation-error': !item.weight}" nz-input [(ngModel)]="item.weight" (ngModelChange)="onChange()">
                </div>
                <div nz-col nzXs="6">
                    <input [ngClass]="{'validation-error': !item.name}" nz-input [(ngModel)]="item.name" (ngModelChange)="onChange()">
                </div>
            </div>
        </ng-container>
    </div>
</div>