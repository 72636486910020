import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import _ from 'underscore'
import { ExportingRouteDialog } from "./exporting-dialog";
import { BizUtil } from "@services/biz";
import { DateUtil } from "@services/date-utils";

@Component({
    selector: '[planning-route-detail]',
    templateUrl: './index.html',
    styleUrls: ['./index.scss', '../index.scss']
})
export class PlanningRouteDetail extends BaseComponent {
    _route: any = null
    @Input() session: any
    @Input() solutionId: any

    displayInfo: any = {}
    shipments: any[] = []
    full: boolean = false
    @Output() onHoverStop: EventEmitter<any> = new EventEmitter<any>();
    @Output() onHoverRoute: EventEmitter<any> = new EventEmitter<any>();
    @Output() onExport: EventEmitter<any> = new EventEmitter<any>();
    @Output() onClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() onShowRouteOnMap: EventEmitter<any> = new EventEmitter<any>();

    get route() {
        return this._route
    }
    @Input() set route(v) {
        this._route = v
        this.processDisplayInfo()
    }

    @Output() onHover: EventEmitter<any> = new EventEmitter<any>();

    toggleFull(event) {
        event.stopPropagation()
        this.full = !this.full
    }

    processDisplayInfo() {
        const { vehicle, workload, stops, cost } = this._route || {}
        const { travelDistance, travelTime, serviceTime, idleTime } = cost
        const totalTime = travelTime + serviceTime + idleTime
        const extraTime = serviceTime + idleTime
        this.shipments = stops.filter(it => it.type == 'DROPOFF').map(it => it.shipment?.metadata?.shipment).filter(it => it)
        const dropoffs = this.shipments.map(it => BizUtil.getDropInfo(it)).filter(it => it)
        const windows = _.sortBy(dropoffs.map(BizUtil.getTimeWindowForDeliveryInfo).filter(it => it), it => it.window?.to)
        const earliest = windows[0]
        const earliestWindow = earliest?.window ? DateUtil.displayTimeWindow(earliest.window, {timezone: earliest.tz, format: 'MM/DD h:mm a', formatDateOnly: 'MM/DD'}) : null

        const shipmentCount = _.uniq(stops.map(it => it.shipmentId)).length
        let stopCount = 0
        let lastStopLocation = null
        for (let stop of stops) {
            if (stop.location.id !== lastStopLocation) {
                stopCount += 1
                lastStopLocation = stop.location.id
            }
        }

        this.displayInfo = {
            vehicleType: vehicle?.name,
            workload: (workload || [])[0]?.toFixed(0),
            capacity: vehicle?.capacity?.toFixed(0) ?? '-',
            shipmentCount,
            stopCount,
            mileage: ((travelDistance || 0) / 1609.34).toFixed(0),
            time: totalTime || 0,
            travelTime,
            extraTime,
            earliestWindow,
        }
    }
    onExportBtn($event) {
        $event.stopPropagation()

        this.modalService.create({
            nzContent: ExportingRouteDialog,
            nzTitle: 'Exporting Route',
            nzComponentParams: {
                route: this._route,
                session: this.session,
                solutionId: this.solutionId,
                onExported: this.onExport,
            },
            nzFooter: null,
            nzClassName: 'modal-no-padding modal-auto',
            nzCentered: true,
        })
    }

    onToggleShowRouteOnMap() {
        this.onShowRouteOnMap.emit(this._route)
    }
}