<div class="bottom20">
    <table>
        <tr>
            <th>Type</th>
            <th>Number</th>
            <th></th>
        </tr>
        <ng-container *ngFor="let ref of refNums">
            <tr *ngIf="!ref.delete">
                <td>
                    <input nz-input [(ngModel)]="ref.type">
                </td>
                <td>
                    <input nz-input [(ngModel)]="ref.number">
                </td>
                <td style="padding-left: 12px;">
                    <span class="clickable danger" nz-icon nzType="delete" nzTheme="outline" (click)="onRemove(ref)"></span>
                </td>
            </tr>
        </ng-container>
    </table>
    <div>
        <button nz-button nzType="text" (click)="onAdd()"><span nz-icon nzType="plus" nzTheme="outline"></span> Add</button>
    </div>
</div>
<div style="text-align: right;">
    <button class="right15" nz-button (click)="onClose()">Cancel</button>
    <button nz-button nzType="primary" (click)="onConfirm()">Save</button>
</div>