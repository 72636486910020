import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { Const } from '@const/Const';
import { FreightQuoteFilter } from './freight-quote-search-filter';
import { FreightQuoteResult } from './freight-quote-search-result';
import { BaseList } from '@app/admin/base/list';
import { PricingService } from '@services/pricing.service';
import polyline from '@mapbox/polyline';
import dayjs from 'dayjs';
import { Subscription, forkJoin } from 'rxjs';
import { Log } from '@services/log';
import { filter } from 'rxjs/operators';
import { DateUtil } from '@services/date-utils';
import { Utils } from '@services/utils';
import { FreightQuoteLogsService } from '@services/freight-quote-logs.service';

@Component({
  selector: '[freight-quote-search]',
  templateUrl: './index.html',
  styleUrls: ['./index.less',]
})
export class FreightQuoteSearch extends BaseList {
  private pricingService: PricingService
  private freightQuoteLogsService: FreightQuoteLogsService
  @Output() onBookLoadBtn: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('freightQuoteFilter') freightQuoteFilter: FreightQuoteFilter;
  @ViewChild('freightQuoteResult') freightQuoteResult: FreightQuoteResult;
  // @ViewChild('insights') insights: Insights;
  filters: any;
  errors:any = {};
  listDataQuote = [];
  textBtnSearch = 'Search';
  routeData = null;
  distance = 0;
  isLoading: boolean = false;
  exfreightRes = null;
  datRes = null;
  isShowCarrierName = false;
  private sub: Subscription;
  constructor() {
    super();
    this.pricingService = new PricingService(this.api)
    this.freightQuoteLogsService = new FreightQuoteLogsService(this.api);
  }
  
  ngOnInit(): void {
  }

  onBookLoad(quote) {
    this.onBookLoadBtn.emit({ quote: { ... quote, exfreightRes: this.exfreightRes, datRes: this.datRes }, filters: this.filters, isShowCarrierName: this.isShowCarrierName });
  }
  protected getApiUrl(): string {
    return '';
    // throw Error('This method must be overridden')
  }

  getRouters(routeData) {
    const routers = [];
    this.distance = routeData?.cost?.distance || 0;
    if (routeData?.line) {
      let line = polyline.decode(routeData.line)
      const route = line.map(item => {
        return [item[1], item[0]];
      });
      if (route.length > 0) {
        routers.push(route);
      }
    } 
    return routers;
  }


  onSearch(filters) {
    Log.d('onSearch filter', filters);
    this.errors = {};
    for (let key of ['pickZipcode', 'dropZipcode', 'clientId']) {
      if (!filters[key]) {
        this.errors[key] = true;
      }
    }
    // if (!filters.dropAddr?.zipcode) {
    //   this.errors.dropAddr = true;
    // }
    // if (!filters.pickAddr?.zipcode) {
    //   this.errors.pickAddr = true;
    // }

    if (!filters.pickDate) {
      this.errors.pickDate = true;
    }
    if (!filters.packaging || !filters.packaging.packagingType || (filters.packaging.packagingType == "Parcel" && !filters.packaging.parcelSizeSelected)) {
      this.errors.packaging = true;
    }
    if (this.errors.dropAddr || this.errors.pickAddr || this.errors.pickDate || this.errors.packaging ) {
      return;
    }
    this.filters = filters;
    this._search(filters);
  }

  private _search(filters) {
    this.isLoading = true;
    this.sub?.unsubscribe();
    const { isTemperatureControlled, packaging } = filters || {}
    let req: PricingRequest = {
      shipmentType: filters.packaging?.packagingType,
      shipmentMode: isTemperatureControlled ? 'Reefer' : 'Dry',
      originZipcode: filters.pickZipcode, //filters.pickAddr?.zipcode,
      destinationZipcode: filters.dropZipcode, //filters.dropAddr?.zipcode,
      packaging: packaging,
      pickupServices: filters.pickupServices,
      dropoffServices: filters.dropoffServices
    };
    let pickDate = null;
    let requestedPickDate = null;
    if (Utils.isString(filters.pickDate)) {
      pickDate = filters?.pickDate;
      requestedPickDate =  DateUtil.format(pickDate, Const.FORMAT_GUI_DATE_V3);
    } else if (Utils.isArrayNotEmpty(filters.pickDate)) {
      pickDate = filters?.pickDate[filters.pickDate.length - 1];
      requestedPickDate = [DateUtil.format(filters?.pickDate[0], Const.FORMAT_GUI_DATE_V3), DateUtil.format(pickDate, Const.FORMAT_GUI_DATE_V3)];
    }
    pickDate = DateUtil.format(pickDate, Const.FORMAT_GUI_DATE_V3);
    const logInfo = {
      requestPickDate: requestedPickDate,
      portal: Const.PORTAL_LIST.web_admin,
    }
    const params = { ...filters, pickDate, logInfo }

    let observables = [
      this.api.POST(`${Const.APIURI_FREIGHT_QUOTE}/search`, params),
      this.pricingService.createPrice(req, {noError: true}),
      // this.pricingService.createPrice(req, {useDummy: true}),       // use dummy data for testing
    ];
    this.sub = forkJoin(observables).subscribe(results => {
      Log.d('search results: ', results);
      this.isLoading = false;
      this.textBtnSearch = 'Modify & Search';
      let sourceResponse = results[0];
      this.routeData = this.getRouters(sourceResponse.data?.routeData);
      let listDataExfreight = sourceResponse.data?.quote ?? [];
      this.exfreightRes = sourceResponse.data?.exfreightRes ?? [];
      this.datRes = sourceResponse.data.datRes ?? [];
      this.isShowCarrierName = sourceResponse.data.isShowCarrierName;
      let warpResponse = results[1];
      this.listDataQuote = [...listDataExfreight, ...this.mapWarpOffer(warpResponse?.offers ?? [])];
      const internalDistance = warpResponse?.metadata?.eta_distance
      this.distance = this.distance ? this.distance : internalDistance ? parseFloat(internalDistance) : 0;
      //log search query
      this.freightQuoteLogsService.setLogId(sourceResponse.data?.logId);
      this.freightQuoteLogsService.updateFreightQuoteLog({ distance: this.distance, searchResult: this.listDataQuote });
    }, err => {
      Log.e('search error: ', err);
      this.showErr(err);
      this.isLoading = false;
      this.textBtnSearch = 'Modify & Search';
    });
  }

  private mapWarpOffer(listWarpOffers) {
    let getPrice = (offer) => {
      if (!offer?.totalPrice) return null;
      return (offer.totalPrice / 100).toFixed(2);
    }
    let getEda = (offer) => {
      if (!offer?.plusDays) return ''
      const date = dayjs().add(offer.plusDays || 0, 'day')
      const { eta_time } = offer?.orders?.[0]?.metadata || {}
      const eta = eta_time ? date.add(parseInt(eta_time), 'second') : date
      return `${eta.format('YYYY-MM-DD')}`
    }
    return listWarpOffers.map(offer => {
      let quote = {
        type: offer.orders?.[0]?.shipmentType,  // FTL, LTL, PARCEL
        orders: offer.orders,
        quotes: offer.quotes,
        legs: [
          {
            scheduled_pickup_date: '',
            scheduled_delivery_date: getEda(offer),
          }
        ],
        total_charge: {
          value: getPrice(offer)
        },
        source: Const.QuoteSource.WARP
      };
      return quote;
    });
  }

  onFilterUpdate(event){
    this.routeData = null;
  }

}