import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '@abstract/BaseComponent';

@Component({
  selector: '[freight-quote-history-list-filter]',
  templateUrl: './list_filter.html',
  styleUrls: ['./list_filter.scss']
})
export class ListFilter extends BaseComponent {
    public isLoading: boolean = false
    @Input() type: string = null
    @Input() options: any[] = []
    @Input() value: any = null
    @Output() valueChange = new EventEmitter<any>();

    active: boolean = false

    onSelect(option) {
        if (this.value === option?.value) {
            this.value = null
        } else {
            this.value = option.value
        }
        this.valueChange.emit(this.value)
        this.active = false
    }

    toggle() {
        this.active = !this.active
    }
}