import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Const } from "@const/Const";
import { DataorchService } from "@services/dataorch.service";
import { PlanningService } from "@services/planning.service";
import _ from "underscore";

@Component({
    selector: '[linehaul-lane-detail-screen]',
    templateUrl: './index.html',
    styleUrls: ['./index.scss']
})
export class LinehaulLaneDetailScreen extends BaseComponent {
    dataorch: DataorchService
    planning: PlanningService
    loading: boolean = false
    lane: any = null

    id: string

    constructor(protected activatedRoute: ActivatedRoute) {
        super()
        this.dataorch = new DataorchService(this.api)
        this.planning = new PlanningService(this.api)
        activatedRoute.params.subscribe(params => {
            this.id = params['id'];
            this.loadLane(this.id)
        })
    }

    loadLane(id) {
        this.loading = true
        this.planning.getLinehaulLane(id).subscribe(r => {
            this.loading = false
            this.lane = r
        })
    }

    goBack() {
        this.router.navigate([ Const.routePlanningLinehauls])
    }
}