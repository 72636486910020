<div class="dashboard-child-container no-padding list">
  <div class="list-header flex no-padding" style="flex-direction: column; width: 100%;background-color: unset">
    <div (onFilterTabChanged)="onFilterTabChanged($event)" finance-transaction-filter style="width: 100%;"></div>
  </div>
  <div class="list-body">
    <nz-table #nzTable (nzPageIndexChange)="onDataListPageChanged($event)" [nzData]="listData"
      [nzFrontPagination]="false" [nzLoadingIndicator]="tplLoading" [nzLoading]="isLoading" [nzNoResult]="tplNoData"
      [nzPageSize]="limit" [nzShowPagination]="totalCount > listData.length" [nzSize]="'default'" [nzTotal]="totalCount"
      nzBordered="true" nzSize="small">
      <ng-template #tplNoData>
        <div *ngIf="!isLoading" class="nodata">
          <i nz-icon nzTheme="outline" nzType="search"></i>
          No data. (Adjust search keyword or change tab)
        </div>
      </ng-template>
      <ng-template #tplLoading>
        <div class="nodata"><i nz-icon nzTheme="outline" nzType="loading"></i></div>
      </ng-template>
      <thead (nzSortOrderChange)="onSortOrderChange($event)">
        <tr>
          <th>Date</th>
          <th *ngIf="isOnInProgressTab() || isOnNeedReviewTab()">Expired Date</th>
          <th>Status</th>
          <th>Owner</th>
          <th>Amount</th>
          <th>Description</th>
          <th>Code</th>
          <th>External Code</th>
          <th *ngIf="isOnNeedReviewTab() && !isAdminReadOnlyRole">Action</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let item of nzTable.data; let i = index">
          <tr>
            <td>{{getTransactionDate(item) || 'N/A'}}</td>
            <td *ngIf="isOnInProgressTab() || isOnNeedReviewTab()">{{getExpiredDate(item) || 'N/A'}}</td>
            <td>
              <div class="group-item">
                <ng-container *ngIf="statusText(item)">
                  <nz-tag [nzColor]="getStatusColor(item)">{{statusText(item)}}</nz-tag>
                </ng-container>
                <ng-container *ngIf="!statusText(item)">N/A</ng-container>
                <div *ngIf="!isAdminReadOnlyRole && hasIssue(item)" style="margin-left: 6px">
                  <i (click)="onChangeStatus(i)" nz-button nz-icon nz-tooltip nzTooltipPlacement="top"
                    nzTooltipTitle="Update status" nzType="edit" style="cursor: pointer;"></i>
                </div>
              </div>
            </td>
            <td>
              <a *ngIf="getOwnerInfo(item)" [routerLink]="getRouterLinkToOwner(item)">{{getOwnerInfo(item)}}</a>
              <div *ngIf="!getOwnerInfo(item)">N/A</div>
            </td>
            <td>{{getAmountValue(item) || 'N/A'}}</td>
            <td style="max-width: 350px">
              <div [innerHTML]="getDescriptionHtml(item)"></div>
            </td>
            <td>
              <div class="group-item">
                <a [routerLink]="getRouterLinkTransactionDetail(item)">{{getTransactionCode(item) || 'N/A'}}</a>
                <div *ngIf="getTransactionCode(item)" style="margin-left: 6px">
                  <i (click)="onCopyTransactionCode(item)" nz-button nz-icon nzType="copy" style="cursor: pointer;"></i>
                </div>
              </div>
            </td>
            <td>
              <div class="group-item">
                {{getExternalCode(item) || 'N/A'}}
                <div *ngIf="getExternalCode(item)" style="margin-left: 6px">
                  <i (click)="onCopyExternalCode(item)" nz-button nz-icon nzType="copy" style="cursor: pointer;"></i>
                </div>
              </div>
            </td>
            <td *ngIf="isOnNeedReviewTab() && !isAdminReadOnlyRole">
              <button (nzOnConfirm)="onRefund(i)" *ngIf="needRefund(item)" [disabled]="item.onProgress"
                [nzLoading]="item.onProgress" nz-button nz-popconfirm nzPopconfirmPlacement="top"
                nzPopconfirmTitle="Are you sure refund to owner?" nzType="primary">
                <i nz-icon nzTheme="outline" nzType="dollar"></i>Refund
              </button>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </nz-table>
  </div>
</div>